<template>
  <aside class="main-sidebar">
    <div class="aside-logo-menu">
      <LogoComponent />
      <MenuComponent />
    </div>
    <div class="divider"> </div>
    <ContactComponent />
  </aside>
</template>

<script lang="ts">
import LogoComponent from './LogoComponent.vue';
import MenuComponent from './MenuComponent.vue';
import ContactComponent from './ContactComponent.vue';

export default {
  name: 'SidebarComponent',
  components: {
    LogoComponent,
    MenuComponent,
    ContactComponent
  },
}
</script>
