import { Ref } from "vue";
import axios from "axios";

interface FormSubmitOptions {
  formData: any;
  route: string;
  success: Ref<string>;
  info: Ref<string>;
  errors: Ref<string[]>;
  loadingSubmit: Ref<boolean>;
  method?: 'POST' | 'PATCH' | 'PUT' | 'DELETE';
}

const useFormSubmit = ({
  formData,
  route,
  success,
  info,
  errors,
  loadingSubmit,
  method= 'PATCH'
}: FormSubmitOptions) => {

  const handleSubmit = async() => {
    loadingSubmit.value = true;

    const data = { ...formData }

    Object.keys(formData).forEach(key => {
        const value = data[key];

        if (value && typeof value === 'object' && 'key' in value) {
            data[key] = value.key;
        } else {
            data[key] = value;
        }
    });

    await axios
      .post(route, { ...data, _method: method })
      .then((response) => {
        if (response.data?.info) {
          info.value = response.data?.info;
        } else {
          success.value = response.data?.success;
        }

        loadingSubmit.value = false;
      })
      .catch((error) => {
        if (error.response) {
          errors.value = Object.values(
            error.response.data.errors
          ).flat() as string[];
        }

        loadingSubmit.value = false;
      });

    setTimeout(clearMessages, 3500);

    return success.value !== '';
  };

  const clearMessages = () => {
    success.value = "";
    info.value = "";
    errors.value = [];
  };

  return {
    handleSubmit,
  };
};

export default useFormSubmit;
