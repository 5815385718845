<template>
    <div class="container mt-4">
        <div class="row">
            <div class="col-md-12 text-center mb-4">
                <h1>Member Profile</h1>
            </div>
            <p>
                This screen gives you the demographic information on file with the Fund Office. If any of this
                information appears to be incorrect, please e-mail us at psc@iatsenbf.org.
            </p>
            <div v-if="loading">Loading Data...</div>
            <div v-else>
                <div class="d-flex gap-4 justify-content-center">
                    <div class="card w-100">
                        <div class="card-body">
                            <h5 class="mb-4">Personal Information</h5>
                            <div class="d-flex flex-column gap-4">
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">Name:</span>
                                    <span>{{ person.FullName }}</span>
                                </div>
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">Date of Birth: </span>
                                    <span>{{ person.DateofBirth }}</span>
                                </div>
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">Gender: </span>
                                    <span>{{ person.Gender }}</span>
                                </div>
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">Marital Status: </span>
                                    <span>{{ person.MaritalStatus }}</span>
                                </div>
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">Home Local: </span>
                                    <span>{{ person.HomeLocal }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card w-100">
                        <div class="card-body">
                            <h5 class="mb-4">Contact Information</h5>
                            <div class="d-flex flex-column gap-4">
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">Home Phone:</span>
                                    <span>{{ contact.Phones.Home ?? '--' }}</span>
                                </div>
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">Mobile Phone:</span>
                                    <span>{{ contact.Phones.Mobile ?? '--' }}</span>
                                </div>
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">Work Phone:</span>
                                    <span>{{ contact.Phones.Work ?? '--' }}</span>
                                </div>
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">Email Address:</span>
                                    <span>{{ contact.Emails.Primary ?? '--' }}</span>
                                </div>
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">Alternate Email:</span>
                                    <span>{{ contact.Emails.Secondary ?? '--' }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card w-100">
                        <div class="card-body">
                            <h5 class="mb-4">Addresses</h5>
                            <div class="d-flex flex-column gap-4">
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">Address Type:</span>
                                    <span>{{ address?.Type }}</span>
                                </div>
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">Primary:</span>
                                    <span>{{ address.Primary }}</span>
                                </div>
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">Address: </span>
                                    <span>{{ address.Address }}</span>
                                </div>
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">City: </span>
                                    <span>{{ address.City }}</span>
                                </div>
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">State: </span>
                                    <span> {{ address.State }}</span>
                                </div>
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">Country: </span>
                                    <span> {{ address.Country }}</span>
                                </div>
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">Postal Code: </span>
                                    <span> {{ address.PostalCode }}</span>
                                </div>
                                <div class="d-flex gap-2">
                                    <span class="fw-bold">Bad Address: </span>
                                    <span> {{ address.Bad }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-5">
                    <button @click="$router.go(-1)" class="btn btn-secondary">Back</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Person, AddressMethod, Contact } from '@/interfaces/local';
import { onMounted, ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';

export default {
    setup() {
        const loading = ref(true);
        const person: Ref<Person | null> = ref(null);
        const address: Ref<AddressMethod | null> = ref(null);
        const contact: Ref<Contact | null> = ref(null);
        const personId = useRoute().params.PersonID;

        const fetchData = async () => {
            loading.value = true;

            await axios.get(`api/local/member-profile/${personId}`)
                .then(response => {
                    person.value = response.data.Person;
                    address.value = response.data.AddressMethod;
                    contact.value = response.data.Contact;
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })

            loading.value = false;
        };

        onMounted(async () => await fetchData());

        return {
            loading,
            person,
            address,
            contact,
        };
    }
}
</script>