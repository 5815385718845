<template>
    <div class="radio-group">
        <label class="main-label">{{ label }}</label>
        <div class="radio-buttons">
            <div v-for="(option, index) in options" :key="index" class="radio-button">
                <input type="radio" :id="`${name}${index + 1}`" :name="name" :value="value" v-model="selectedValue"
                    @change="handleChange" />
                <label class="body-text" :for="`${name}${index + 1}`">
                    {{ option.value }}
                </label>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue';
import { RadioOption } from '@/interfaces/interfaces';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: '',
        },
        options: {
            type: Array as PropType<RadioOption[]>,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        value: {
            type: [String, Number],
            default: '',
        },
        selectedOption: {
            type: [String, Number],
            default: '',
        },
    },
    setup(props, { emit }) {
        const selectedValue = ref(props.selectedOption);

        watch(() => props.selectedOption, (newValue) => {
            selectedValue.value = newValue;
        });

        const handleChange = (event: Event) => {
            const target = event.target as HTMLInputElement | null;

            if (target) {
                emit('change', target.value);
            }
        }

        return {
            selectedValue,
            handleChange
        };
    },
});
</script>