<template>
    <div class="card transparent">
        <div class="card-header">
            <h2>What is covered/ Reimbursable?</h2>
            <a :href="links.list" target="_blank" class="btn btn-secondary plan-modal"> <i
                class="iatse-icon-list-view-rectangle"> </i> See List </a>
            <a :href="links.form" target="_blank" class="btn btn-primary full-width">
                Download Claim Form
                <img src="@/assets/images/icons/pdf.svg" alt="pdf icon" /> </a>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        links: {
            type: Object,
            required: true
        }
    },
})

</script>