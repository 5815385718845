<template>
    <div v-if="profileStore.loading">Loading Form...</div>
    <form v-else id="update-personal-info-form" class="form update-personal-info">
        <TextInput type="text" label="Participant ID" placeholder="Enter Participant ID" :max=25
            v-model:modelValue="formData.PersonID" :readonly="true" customClass="readonly" />
        <DateInput label="DOB" v-model:modelValue="formData.DateofBirth" :error="inputErrors.DateofBirth" />
        <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
            v-model:modelValue="formData.FirstName" :error="inputErrors.FirstName" />
        <!-- <TextInput type="text" label="Middle Name" placeholder="Enter Middle Name" :max=12 :modelValue="formData.MiddleName" /> -->
        <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
            v-model:modelValue="formData.LastName" :error="inputErrors.LastName" />
        <SelectComponent label="Gender" v-model:selectedOption="formData.Sex" :options=profileStore.genders
            :singleArrow="true" :error="inputErrors.Gender"/>
        <SelectComponent label="Marital Status" v-model:selectedOption="formData.MaritalStatus"
            :options=profileStore.maritalStatuses :singleArrow="true" :error="inputErrors.MaritalStatus"/>
        <div :class="['custom-select links', { 'error': inputErrors.HomeLocal }]">
            <label class="body-text"> Home Local </label>
            <Multiselect v-model="formData.HomeLocal" :options="locals" placeholder="Type to search local" :limit="1"
                :loading="loadingSearch" :searchable="true" :close-on-select="true" openDirection="bottom" label="value"
                track-by="key" @search-change="handleSearch">
            </Multiselect>
        </div>

        <div class="action-buttons">
            <button type="button" class="btn btn-secondary" @click="closeModal"> Discard </button>
            <button type="button" class="btn btn-primary" @click="goToNextStep"> Save and Continue </button>
        </div>
    </form>
</template>

<script lang="ts">
import { ref, reactive, onMounted, defineComponent } from 'vue';
import { SelectOption, Person } from '@/interfaces/interfaces';
import { useProfileStore } from '@/stores/profile';
import axios from 'axios';

import TextInput from '@components/form/TextInput.vue';
import DateInput from '@components/form/DateInput.vue';
import SelectComponent from '@components/form/SelectComponent.vue';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

export default defineComponent({
    components: {
        TextInput,
        SelectComponent,
        DateInput,
        Multiselect
    },
    setup(_, { emit }) {
        const loading = ref(true);
        const loadingSearch = ref(false);
        const profileStore = useProfileStore();
        const changes = reactive({ ...profileStore.changes });
        const formData: Person = reactive({ ...profileStore.personalData });
        const showResults = ref(false);
        const locals = ref<SelectOption[]>([]);
        const searchContainer = ref(null);

        const initialRequiredInputs = {
            FirstName: false,
            LastName: false,
            DateofBirth: false,
        };

        const inputErrors = ref<Record<string, boolean>>({ ...initialRequiredInputs });

        // Handle Search Locals
        const searchLocals = async (searchedLocal: string) => {
            try {
                const response = await axios.get('/api/user/locals', {
                    params: {
                        query: searchedLocal,
                    },
                });

                locals.value = response.data;
            } catch (error) {
                console.error('Error searching locals:', error);
            }
        }

        // Handle Locals Search
        const handleSearch = async (query: string) => {
            locals.value = [];
            showResults.value = false;
            loadingSearch.value = true;

            await searchLocals(query);

            if (locals.value.length > 0) {
                showResults.value = true;
                loadingSearch.value = false;
            } else {
                showResults.value = false;
                locals.value = [];
            }
        };

        const closeModal = () => {
            emit('close');
        }

        const goToNextStep = () => {
            const isValid = validateFields();

            if (!isValid) {
                return;
            }

            profileStore.setPersonalData(formData);

            emit('next');
        }

        // Validate Step 1 Fields
        const validateFields = () => {
            let isValid = true;
            inputErrors.value = { ...initialRequiredInputs }

            Object.keys(inputErrors.value).forEach((key) => {
                if (!formData[key as keyof Person]) {
                    isValid = false;
                    inputErrors.value[key] = true;
                }
                else {
                    inputErrors.value[key] = false;
                }
            });

            if (!isValid) {
                console.error('Validation errors:', inputErrors, formData);
            }

            return isValid;
        };

        onMounted(() => {
            Object.keys(profileStore.changes).forEach((key) => {
                inputErrors.value[key] = true;
            });
        });

        return {
            profileStore,
            formData,
            locals,
            changes,
            loading,
            loadingSearch,
            showResults,
            searchContainer,
            inputErrors,
            handleSearch,
            closeModal,
            goToNextStep
        };
    },
})
</script>