import { RowData } from '@/interfaces/interfaces';

/**
 * Transforms an object with key-value pairs into an array of objects with key and value properties.
 * If a value is an object, it will recursively format the object's entries.
 *
 * @param obj - The object to transform.
 * @returns An array of objects with key and value properties, with nested objects handled recursively.
 */
export const useTableFormatter = <T>(
    obj: Record<string, T>
): {
    key: string | number;
    value:
        | T
        | { key: string | number; value: any }[][]
        | { key: string | number; value: any }[]
        | { data: T };
}[] => {
// export const useTableFormatter = <T>(obj: Record<string, T>): RowData[] | RowData => {
    return Object.entries(obj).map(([key, value]) => {
        if (Array.isArray(value)) {
            // Subitems Array -> format each item recursively
            return {
                key,
                value: value.map((item) =>
                    useTableFormatter(item as Record<string, any>)
                ),
            };
        } else if (typeof value === "object" && value !== null) {
            // Object Value -> format it recursively
            return {
                key,
                value: useTableFormatter(value as Record<string, any>),
            };
        } else {
            // Simple value -> return it
            return {
                key,
                value: { data: value },
            };
        }
    });
};
