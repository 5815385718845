<template>
    <div class="container mt-4">
        <div class="col-md-12 text-center">
            <h1 class="mb-5">My Claims & Reimbursements Summary</h1>
        </div>
        <div class="d-flex justify-content-between align-items-stretch gap-4">
            <!-- Claim Header -->
            <div class="d-flex flex-column align-items-center gap-4 w-25">
                <div class="card w-100">
                    <div class="card-body d-flex flex-column align-items-center gap-2">
                        <span class="fw-bold"> Current MRP Balance: </span>
                        <span class="btn btn-warning"> $ {{ loading ? "Loading..." : data?.MrpBalance }} </span>
                    </div>
                </div>
                <div class="d-flex flex-column align-items-center justify-content-between h-100">
                    <div class="card d-flex align-items-center gap-4 w-100">
                        <div class="card-body d-flex flex-column align-items-center gap-4">
                            <h5>New Claim</h5>
                            <router-link :to="{ name: 'participant.claims.new' }" class="btn btn-secondary">
                                Submit a New Claim
                            </router-link>
                        </div>
                    </div>
                    <div class="card d-flex align-items-center gap-4 w-100">
                        <div class="card-body d-flex flex-column align-items-center gap-4">
                            <h5>Existing Claims</h5>
                            <router-link :to="{ name: 'participant.claims.search' }" class="btn btn-secondary">Go to
                                Existing Claims</router-link>
                        </div>
                    </div>
                    <div>
                        <router-link :to="{ name: 'participant.claims.update.address' }"
                            class="btn btn-secondary">Change
                            Mailing Address / Claims Payment Setup</router-link>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column align-items-center gap-2 w-75">
                <div class="card d-flex align-items-center gap-4 w-100">
                    <div class="card-body d-flex justify-content-between gap-4 w-75">
                        <div class="d-flex justify-content-between align-items-center gap-4">
                            <span class="fw-bold"> What is Covered/ Reimbursable ? </span>
                            <a :href="data?.CoveredList" target="_blank" class="btn btn-secondary">
                                See List
                            </a>
                        </div>
                        <div class="d-flex align-items-center gap-4">
                            <a :href="data?.ClaimForm" target="_blank" class="btn btn-warning">
                                Download Claim Form
                            </a>
                        </div>
                    </div>
                </div>
                <!-- Claims History -->
                <div class="card d-flex align-items-center gap-4 w-100">
                    <div class="card-body d-flex align-items-center gap-4">
                        <div class="d-flex flex-column align-items-center gap-4">
                            <h5> Claims History </h5>
                            <p>Your most recent claims history is displayed below. To view a specific claim listed,
                                click on the claim number. For those received via this website, but not yet
                                reviewed,
                                you can click on the Web Reference number to view /edit your uploaded documents or
                                add
                                additional documents. To search for a specific date range or for claims not listed
                                below
                                please click the Search button and choose your search criteria.

                            </p>
                            <div id="collapse" class=" w-100">
                                <table class="table table-bordered table-striped">
                                    <thead class="table-dark">
                                        <tr class="text-center">
                                            <th>Date Filed</th>
                                            <th>Claim Number</th>
                                            <th>Web Ref #</th>
                                            <th>Claim Status</th>
                                            <th>Claim Doc(s)</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="data?.Claims.length > 0">
                                        <tr v-for="claim in data?.Claims" :key="claim.ClaimID" class="text-center">
                                            <td> {{ claim.DateReceived }} </td>
                                            <td v-if="claim?.ClaimID">
                                                <router-link
                                                    :to="{ name: 'participant.claims.new', params: { ClaimID: claim?.ClaimID, Type: claim?.Type } }">
                                                    {{ claim?.ClaimID }}
                                                </router-link>
                                            </td>
                                            <td v-else> {{ claim.ClaimID == '0' ? '' : claim.ClaimID }} </td>
                                            <td v-if="!claim?.ClaimID">
                                                <router-link
                                                    :to="{ name: 'participant.claims.new', params: { ClaimID: claim?.NEICID, Type: claim?.Type } }">
                                                    {{ claim?.NEICID }}
                                                </router-link>
                                            </td>
                                            <td v-else> {{ claim?.NEICID }} </td>
                                            <td> {{ claim.ClaimStatus }} </td>
                                            <td> {{ claim.DocumentID }} </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr class="text-center">
                                            <td colspan="5"> {{ loading ? "Loading..." : "No Data Available" }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <router-link :to="{ name: 'participant.claims.search' }" class="btn btn-secondary">
                                Search Claims History
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, Ref, onMounted } from 'vue';
import { ClaimsData } from '@/interfaces/health';
import { useStepStore } from '@/stores/step';
import { useClaimStore } from '@/stores/claim';
import axios from 'axios';

export default {
    setup() {
        const data: Ref<ClaimsData | null> = ref(null);
        const stepStore = useStepStore();
        const claimStore = useClaimStore();
        const loading = ref(true);

        const fetchData = async () => {
            loading.value = true;
            claimStore.clearClaim()

            await axios.get('api/participant/claims')
                .then(response => {
                    data.value = response.data;
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })
                .finally(() => loading.value = false);
        };

        onMounted(async () => {
            await fetchData();
            stepStore.setCurrentStep(0);
        });

        return {
            data,
            loading
        };
    }
};
</script>


<style scoped>
#collapse {
    overflow-y: scroll;
    height: 200px;
}

a {
    text-decoration: none;
}
</style>