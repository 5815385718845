import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, _toDisplayString($props.PWHD?.PeriodBegin) + " - " + _toDisplayString($props.PWHD?.PeriodEnd), 1),
    _createElementVNode("td", null, _toDisplayString($props.PWHD.TranProcDate), 1),
    _createElementVNode("td", null, _toDisplayString($props.PWHD.EmployerName), 1),
    _createElementVNode("td", null, _toDisplayString($props.PWHD.ProductionName), 1),
    _createElementVNode("td", null, _toDisplayString($props.PWHD.Plan), 1),
    _createElementVNode("td", null, _toDisplayString($props.PWHD.DaysWorked), 1),
    _createElementVNode("td", null, _toDisplayString($props.PWHD.Amount), 1),
    _createElementVNode("td", null, _toDisplayString($props.PWHD.FirstOrgName), 1)
  ]))
}