import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column gap-2 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LocalAuth = _resolveComponent("LocalAuth")!
  const _component_LocalRegistration = _resolveComponent("LocalRegistration")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.registerType === 'auth')
      ? (_openBlock(), _createBlock(_component_LocalAuth, {
          key: 0,
          states: _ctx.states,
          loadingSubmit: _ctx.loadingSubmit,
          onRegister: _ctx.handleRegister,
          authRequest: _ctx.authRequest,
          success: _ctx.success
        }, null, 8, ["states", "loadingSubmit", "onRegister", "authRequest", "success"]))
      : (_openBlock(), _createBlock(_component_LocalRegistration, {
          key: 1,
          securityQuestions: _ctx.securityQuestions,
          loadingSubmit: _ctx.loadingSubmit,
          localRequest: _ctx.localRequest,
          onRegister: _ctx.handleRegister
        }, null, 8, ["securityQuestions", "loadingSubmit", "localRequest", "onRegister"]))
  ]))
}