<template>
    <div class="container mt-4">
        <div class="row">
            <div class="col-md-12 text-center mb-4">
                <h2> Documents on File For <span> {{ dependentName }} </span> </h2>
            </div>
        </div>
        <div v-if="documents.length > 0" class="row">
            <h6 class="text-center mb-4">DOCUMENTS CURRENTLY ON FILE/PENDING ADMIN PREVIEW</h6>
            <table class="table table-bordered table-striped mb-4">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(document, index) in documents" :key="index">
                        <!-- Adjusted line below to include a router-link -->
                        <td>
                            <router-link :to="`/file/${document.DocumentID}`">
                                {{ document.Type.Description }} {{ document.DocumentID }}
                            </router-link>
                        </td>
                        <td>{{ document.Date }}</td>
                        <td :class="{ 'text-danger': document.Status === 'Pending' }">
                            {{ document.Status }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="text-center mb-4 mt-4"> No documents have been uploaded to your account.
        </div>
        <a href="javascript:history.back()" class="btn btn-secondary">Back</a>
    </div>
</template>

<script lang="ts">
import { ref, Ref, onMounted } from 'vue';
import { Document } from '@interfaces/interfaces';
import { LocationQueryValue, useRoute } from 'vue-router';
import axios from 'axios';

export default {
    setup() {
        const documents: Ref<Document[] | []> = ref([]);
        const dependentName: Ref<string> = ref('');
        const loading = ref(false);
        const route = useRoute();
        const DependentID: Ref<LocationQueryValue | LocationQueryValue[]> = ref(null);
        const PartnerID: Ref<LocationQueryValue | LocationQueryValue[]> = ref(null);
        const Status: Ref<LocationQueryValue | LocationQueryValue[]> = ref(null);
        const headers = [
            'Dependent Name',
            'Relationship',
            'DOB',
        ];

        const fetchData = async () => {
            loading.value = true;
            PartnerID.value = route.query.PartnerID;
            DependentID.value = route.query.DependentID;
            Status.value = route.query.Status;

            await axios.get('api/participant/dependents/documents', {
                params: {
                    PartnerID: PartnerID.value,
                    DependentID: DependentID.value,
                    Status: Status.value
                }
            })
                .then(response => {
                    documents.value = response.data.Documents;
                    dependentName.value = response.data.DependentName;
                    console.log(response.data, 'documents');
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });

            loading.value = false;
        };

        onMounted(async () => {
            await fetchData();
        });

        return {
            documents,
            dependentName,
            headers,
            loading
        };
    },
};
</script>