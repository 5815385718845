<template>
    <div class="container mt-4">
        <div class="row">
            <div class="col-md-12 d-flex flex-column gap-4">
                <h1 class="text-center mb-4">
                    {{ download ? 'View' : '' }} Employee Health Coverage Information Request
                </h1>
                <div class="position-fixed top-0 end-0 p-3" style="z-index: 11">
                    <div class="alert alert-success" v-show="success">{{ success }}</div>
                    <div v-if="errors && errors?.length > 0" class="alert alert-danger">
                        <template v-if="Array.isArray(errors)">
                            <ul>
                                <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                            </ul>
                        </template>

                        <template v-else>
                            <span>{{ errors }}</span>
                        </template>
                    </div>
                </div>
                <CoverageStep1 v-if="currentStep === 1" :instructions="instructions" @handle-next="handleNext" />
                <CoverageStep2 v-if="currentStep === 2" :ipAddress="ipAddress" :download="download"
                    @handle-back="handleBack" @handleNext="handleNext" />
                <DownloadStep v-if="currentStep === 3 && download" :requests="requests" />
                <CoverageStep3 v-if="currentStep === 3 && !download" @handle-back="handleBack"
                    @handleNext="handleNext" />
                <CoverageStep4 v-if="currentStep === 4" @handle-back="handleBack" @handle-submit="handleSubmit"
                    :ssnOption="ssnOption" :excelModel="excelModel" :submitting="submitting" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { onMounted, ref, Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { HealthCoverageRequest } from '@/interfaces/employer';
import { useAuthStore } from '@/stores/auth';
import CoverageStep1 from '@/components/employer/coverage-data/CoverageStep1.vue'
import CoverageStep2 from '@/components/employer/coverage-data/CoverageStep2.vue'
import CoverageStep3 from '@/components/employer/coverage-data/CoverageStep3.vue'
import CoverageStep4 from '@/components/employer/coverage-data/CoverageStep4.vue'
import DownloadStep from '@/components/employer/coverage-data/DownloadStep.vue'
import axios from 'axios';

export default {
    components: {
        CoverageStep1,
        CoverageStep2,
        CoverageStep3,
        CoverageStep4,
        DownloadStep,
    },
    setup() {
        const loading = ref(true);
        const submitting = ref(false);
        const deleting = ref(false);
        const currentStep = ref(1);
        const success = ref(null);
        const errors: Ref<string[] | string | null> = ref(null);
        const ipAddress = ref('');
        const instructions = ref('#');
        const excelModel = ref('#');
        const ssnOption: Ref<string> = ref('0');
        const download = useRoute().params.download as string;
        const requests: Ref<HealthCoverageRequest[]> = ref([]);
        const router = useRouter();
        const authStore = useAuthStore();
        const unreadAlerts: Ref<number> = ref(0);

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/employer/health-coverage-data')
                .then(response => {
                    ipAddress.value = response.data.Ip;
                    instructions.value = response.data.Instructions;
                    excelModel.value = response.data.ExcelModel;
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const getUnreadAlerts = async () => {
            await axios.get('api/user/unread-alerts')
                .then(response => {
                    unreadAlerts.value = response.data;
                });
        }

        const handleSubmit = async (payload: object) => {
            submitting.value = true;
            success.value = null;
            errors.value = [];

            await axios.post('api/employer/health-coverage-data/generate-file', { ...payload })
                .then(response => {
                    success.value = response.data.success;

                    if (success.value) {
                        setTimeout(() => router.push({ name: 'employer.dashboard' }), 1500);
                        errors.value = [];
                    }
                    else {
                        errors.value = response.data.error;
                    }
                })
                .then(() => {
                    getUnreadAlerts();
                    authStore.updateUnreadAlerts(unreadAlerts.value);
                })
                .catch(error => {
                    const res = error.response.data;

                    if (res.error) {
                        errors.value = res.error;
                    }
                    else {
                        errors.value = Object.values(res.errors).flat() as string[];
                    }
                })
                .finally(() => {
                    submitting.value = false;
                    setTimeout(() => errors.value = [], 5000)
                });
        };

        const handleNext = (payload: HealthCoverageRequest[] | string | null) => {
            currentStep.value += 1;
            handlePayload(payload);
        }

        const handleBack = (payload: HealthCoverageRequest[] | string | null) => {
            currentStep.value -= 1;
            handlePayload(payload);
        }

        const handlePayload = (payload: HealthCoverageRequest[] | string | null) => {
            if (currentStep.value === 3) {
                requests.value = payload as HealthCoverageRequest[];
            }

            if (currentStep.value === 4) {
                ssnOption.value = payload as string;
            }
        }

        onMounted(async () => await fetchData());

        return {
            loading,
            submitting,
            deleting,
            ipAddress,
            instructions,
            excelModel,
            ssnOption,
            success,
            errors,
            download,
            requests,
            currentStep,
            handleNext,
            handleBack,
            handleSubmit,
        };
    }
}
</script>