<template>
    <div class="aside-menu">
        <nav>
            <i class="iatse-icon-close btn-close"></i>
            <ul class="sm-pdt-20">
                <li class="active">
                    <router-link :to="{ name: dashboardRoute }">
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="path-1-outside-1_1481_4226" maskUnits="userSpaceOnUse" x="0.0771484" y="-0.314697"
                                width="19" height="19" fill="black">
                                <rect fill="white" x="0.0771484" y="-0.314697" width="19" height="19" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M4.99215 0.685304C4.24343 0.68528 3.61906 0.68526 3.12342 0.751898C2.60026 0.822235 2.12792 0.976961 1.74836 1.35652C1.36881 1.73608 1.21408 2.20841 1.14374 2.73157C1.07711 3.22722 1.07713 3.85156 1.07715 4.60028V4.68697C1.07713 5.43569 1.07711 6.06006 1.14374 6.55571C1.21408 7.07887 1.36881 7.5512 1.74836 7.93076C2.12792 8.31032 2.60026 8.46504 3.12342 8.53538C3.61906 8.60202 4.24341 8.602 4.99213 8.60197H5.07881C5.82754 8.602 6.4519 8.60202 6.94755 8.53538C7.47071 8.46504 7.94305 8.31032 8.3226 7.93076C8.70216 7.5512 8.85689 7.07887 8.92723 6.55571C8.99386 6.06006 8.99384 5.43572 8.99382 4.68699V4.60031C8.99384 3.85159 8.99386 3.22722 8.92723 2.73157C8.85689 2.20841 8.70216 1.73608 8.3226 1.35652C7.94305 0.976961 7.47071 0.822235 6.94755 0.751898C6.4519 0.68526 5.82756 0.68528 5.07884 0.685304H4.99215ZM2.63225 2.2404C2.74085 2.13181 2.90543 2.04245 3.28998 1.99075C3.69251 1.93663 4.23214 1.93531 5.03548 1.93531C5.83883 1.93531 6.37846 1.93663 6.78099 1.99075C7.16554 2.04245 7.33012 2.13181 7.43872 2.2404C7.54732 2.349 7.63667 2.51358 7.68837 2.89813C7.74249 3.30066 7.74382 3.8403 7.74382 4.64364C7.74382 5.44698 7.74249 5.98662 7.68837 6.38915C7.63667 6.7737 7.54732 6.93828 7.43872 7.04688C7.33012 7.15547 7.16554 7.24482 6.78099 7.29653C6.37846 7.35065 5.83883 7.35197 5.03548 7.35197C4.23214 7.35197 3.69251 7.35065 3.28998 7.29653C2.90543 7.24482 2.74085 7.15547 2.63225 7.04688C2.52365 6.93828 2.4343 6.7737 2.3826 6.38915C2.32848 5.98662 2.32715 5.44698 2.32715 4.64364C2.32715 3.8403 2.32848 3.30066 2.3826 2.89813C2.4343 2.51358 2.52365 2.349 2.63225 2.2404Z" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M14.1588 9.85197C13.4101 9.85195 12.7857 9.85193 12.2901 9.91857C11.7669 9.9889 11.2946 10.1436 10.915 10.5232C10.5355 10.9027 10.3807 11.3751 10.3104 11.8982C10.2438 12.3939 10.2438 13.0182 10.2438 13.767V13.8536C10.2438 14.6024 10.2438 15.2267 10.3104 15.7224C10.3807 16.2455 10.5355 16.7179 10.915 17.0974C11.2946 17.477 11.7669 17.6317 12.2901 17.702C12.7857 17.7687 13.41 17.7687 14.1587 17.7686H14.2455C14.9942 17.7687 15.6186 17.7687 16.1142 17.702C16.6374 17.6317 17.1097 17.477 17.4893 17.0974C17.8688 16.7179 18.0236 16.2455 18.0939 15.7224C18.1605 15.2267 18.1605 14.6024 18.1605 13.8537V13.767C18.1605 13.0183 18.1605 12.3939 18.0939 11.8982C18.0236 11.3751 17.8688 10.9027 17.4893 10.5232C17.1097 10.1436 16.6374 9.9889 16.1142 9.91857C15.6186 9.85193 14.9942 9.85195 14.2455 9.85197H14.1588ZM11.7989 11.4071C11.9075 11.2985 12.0721 11.2091 12.4566 11.1574C12.8592 11.1033 13.3988 11.102 14.2022 11.102C15.0055 11.102 15.5451 11.1033 15.9477 11.1574C16.3322 11.2091 16.4968 11.2985 16.6054 11.4071C16.714 11.5157 16.8033 11.6802 16.855 12.0648C16.9092 12.4673 16.9105 13.007 16.9105 13.8103C16.9105 14.6137 16.9092 15.1533 16.855 15.5558C16.8033 15.9404 16.714 16.1049 16.6054 16.2135C16.4968 16.3221 16.3322 16.4115 15.9477 16.4632C15.5451 16.5173 15.0055 16.5186 14.2022 16.5186C13.3988 16.5186 12.8592 16.5173 12.4566 16.4632C12.0721 16.4115 11.9075 16.3221 11.7989 16.2135C11.6903 16.1049 11.601 15.9404 11.5493 15.5558C11.4951 15.1533 11.4938 14.6137 11.4938 13.8103C11.4938 13.007 11.4951 12.4673 11.5493 12.0648C11.601 11.6802 11.6903 11.5157 11.7989 11.4071Z" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M4.99215 9.85197H5.07882C5.82755 9.85195 6.4519 9.85193 6.94755 9.91857C7.47071 9.9889 7.94305 10.1436 8.3226 10.5232C8.70216 10.9027 8.85689 11.3751 8.92723 11.8982C8.99386 12.3939 8.99384 13.0182 8.99382 13.767V13.8536C8.99384 14.6024 8.99386 15.2267 8.92723 15.7224C8.85689 16.2455 8.70216 16.7179 8.3226 17.0974C7.94305 17.477 7.47071 17.6317 6.94755 17.702C6.45191 17.7687 5.82758 17.7687 5.07887 17.7686H4.99216C4.24344 17.7687 3.61906 17.7687 3.12342 17.702C2.60026 17.6317 2.12792 17.477 1.74836 17.0974C1.36881 16.7179 1.21408 16.2455 1.14374 15.7224C1.07711 15.2267 1.07713 14.6024 1.07715 13.8537V13.767C1.07713 13.0182 1.07711 12.3939 1.14374 11.8982C1.21408 11.3751 1.36881 10.9027 1.74836 10.5232C2.12792 10.1436 2.60026 9.9889 3.12342 9.91857C3.61907 9.85193 4.24342 9.85195 4.99215 9.85197ZM3.28998 11.1574C2.90543 11.2091 2.74085 11.2985 2.63225 11.4071C2.52365 11.5157 2.4343 11.6802 2.3826 12.0648C2.32848 12.4673 2.32715 13.007 2.32715 13.8103C2.32715 14.6137 2.32848 15.1533 2.3826 15.5558C2.4343 15.9404 2.52365 16.1049 2.63225 16.2135C2.74085 16.3221 2.90543 16.4115 3.28998 16.4632C3.69251 16.5173 4.23214 16.5186 5.03548 16.5186C5.83883 16.5186 6.37846 16.5173 6.78099 16.4632C7.16554 16.4115 7.33012 16.3221 7.43872 16.2135C7.54732 16.1049 7.63667 15.9404 7.68837 15.5558C7.74249 15.1533 7.74382 14.6137 7.74382 13.8103C7.74382 13.007 7.74249 12.4673 7.68837 12.0648C7.63667 11.6802 7.54732 11.5157 7.43872 11.4071C7.33012 11.2985 7.16554 11.2091 6.78099 11.1574C6.37846 11.1033 5.83883 11.102 5.03548 11.102C4.23214 11.102 3.69251 11.1033 3.28998 11.1574Z" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M14.1588 0.685304C13.4101 0.68528 12.7857 0.68526 12.2901 0.751898C11.7669 0.822235 11.2946 0.976961 10.915 1.35652C10.5355 1.73608 10.3807 2.20841 10.3104 2.73157C10.2438 3.22722 10.2438 3.85157 10.2438 4.60031V4.68697C10.2438 5.4357 10.2438 6.06005 10.3104 6.55571C10.3807 7.07887 10.5355 7.5512 10.915 7.93076C11.2946 8.31032 11.7669 8.46504 12.2901 8.53538C12.7857 8.60202 13.4101 8.602 14.1588 8.60197H14.2455C14.9942 8.602 15.6186 8.60202 16.1142 8.53538C16.6374 8.46504 17.1097 8.31032 17.4893 7.93076C17.8688 7.5512 18.0236 7.07887 18.0939 6.55571C18.1605 6.06006 18.1605 5.43572 18.1605 4.68699V4.60031C18.1605 3.85159 18.1605 3.22722 18.0939 2.73157C18.0236 2.20841 17.8688 1.73608 17.4893 1.35652C17.1097 0.976961 16.6374 0.822235 16.1142 0.751898C15.6186 0.68526 14.9942 0.68528 14.2455 0.685304H14.1588ZM11.7989 2.2404C11.9075 2.13181 12.0721 2.04245 12.4566 1.99075C12.8592 1.93663 13.3988 1.93531 14.2022 1.93531C15.0055 1.93531 15.5451 1.93663 15.9477 1.99075C16.3322 2.04245 16.4968 2.13181 16.6054 2.2404C16.714 2.349 16.8033 2.51358 16.855 2.89813C16.9092 3.30066 16.9105 3.8403 16.9105 4.64364C16.9105 5.44698 16.9092 5.98662 16.855 6.38915C16.8033 6.7737 16.714 6.93828 16.6054 7.04688C16.4968 7.15547 16.3322 7.24482 15.9477 7.29653C15.5451 7.35065 15.0055 7.35197 14.2022 7.35197C13.3988 7.35197 12.8592 7.35065 12.4566 7.29653C12.0721 7.24482 11.9075 7.15547 11.7989 7.04688C11.6903 6.93828 11.601 6.7737 11.5493 6.38915C11.4951 5.98662 11.4938 5.44698 11.4938 4.64364C11.4938 3.8403 11.4951 3.30066 11.5493 2.89813C11.601 2.51358 11.6903 2.349 11.7989 2.2404Z" />
                            </mask>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M4.99215 0.685304C4.24343 0.68528 3.61906 0.68526 3.12342 0.751898C2.60026 0.822235 2.12792 0.976961 1.74836 1.35652C1.36881 1.73608 1.21408 2.20841 1.14374 2.73157C1.07711 3.22722 1.07713 3.85156 1.07715 4.60028V4.68697C1.07713 5.43569 1.07711 6.06006 1.14374 6.55571C1.21408 7.07887 1.36881 7.5512 1.74836 7.93076C2.12792 8.31032 2.60026 8.46504 3.12342 8.53538C3.61906 8.60202 4.24341 8.602 4.99213 8.60197H5.07881C5.82754 8.602 6.4519 8.60202 6.94755 8.53538C7.47071 8.46504 7.94305 8.31032 8.3226 7.93076C8.70216 7.5512 8.85689 7.07887 8.92723 6.55571C8.99386 6.06006 8.99384 5.43572 8.99382 4.68699V4.60031C8.99384 3.85159 8.99386 3.22722 8.92723 2.73157C8.85689 2.20841 8.70216 1.73608 8.3226 1.35652C7.94305 0.976961 7.47071 0.822235 6.94755 0.751898C6.4519 0.68526 5.82756 0.68528 5.07884 0.685304H4.99215ZM2.63225 2.2404C2.74085 2.13181 2.90543 2.04245 3.28998 1.99075C3.69251 1.93663 4.23214 1.93531 5.03548 1.93531C5.83883 1.93531 6.37846 1.93663 6.78099 1.99075C7.16554 2.04245 7.33012 2.13181 7.43872 2.2404C7.54732 2.349 7.63667 2.51358 7.68837 2.89813C7.74249 3.30066 7.74382 3.8403 7.74382 4.64364C7.74382 5.44698 7.74249 5.98662 7.68837 6.38915C7.63667 6.7737 7.54732 6.93828 7.43872 7.04688C7.33012 7.15547 7.16554 7.24482 6.78099 7.29653C6.37846 7.35065 5.83883 7.35197 5.03548 7.35197C4.23214 7.35197 3.69251 7.35065 3.28998 7.29653C2.90543 7.24482 2.74085 7.15547 2.63225 7.04688C2.52365 6.93828 2.4343 6.7737 2.3826 6.38915C2.32848 5.98662 2.32715 5.44698 2.32715 4.64364C2.32715 3.8403 2.32848 3.30066 2.3826 2.89813C2.4343 2.51358 2.52365 2.349 2.63225 2.2404Z"
                                fill="#17388C" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M14.1588 9.85197C13.4101 9.85195 12.7857 9.85193 12.2901 9.91857C11.7669 9.9889 11.2946 10.1436 10.915 10.5232C10.5355 10.9027 10.3807 11.3751 10.3104 11.8982C10.2438 12.3939 10.2438 13.0182 10.2438 13.767V13.8536C10.2438 14.6024 10.2438 15.2267 10.3104 15.7224C10.3807 16.2455 10.5355 16.7179 10.915 17.0974C11.2946 17.477 11.7669 17.6317 12.2901 17.702C12.7857 17.7687 13.41 17.7687 14.1587 17.7686H14.2455C14.9942 17.7687 15.6186 17.7687 16.1142 17.702C16.6374 17.6317 17.1097 17.477 17.4893 17.0974C17.8688 16.7179 18.0236 16.2455 18.0939 15.7224C18.1605 15.2267 18.1605 14.6024 18.1605 13.8537V13.767C18.1605 13.0183 18.1605 12.3939 18.0939 11.8982C18.0236 11.3751 17.8688 10.9027 17.4893 10.5232C17.1097 10.1436 16.6374 9.9889 16.1142 9.91857C15.6186 9.85193 14.9942 9.85195 14.2455 9.85197H14.1588ZM11.7989 11.4071C11.9075 11.2985 12.0721 11.2091 12.4566 11.1574C12.8592 11.1033 13.3988 11.102 14.2022 11.102C15.0055 11.102 15.5451 11.1033 15.9477 11.1574C16.3322 11.2091 16.4968 11.2985 16.6054 11.4071C16.714 11.5157 16.8033 11.6802 16.855 12.0648C16.9092 12.4673 16.9105 13.007 16.9105 13.8103C16.9105 14.6137 16.9092 15.1533 16.855 15.5558C16.8033 15.9404 16.714 16.1049 16.6054 16.2135C16.4968 16.3221 16.3322 16.4115 15.9477 16.4632C15.5451 16.5173 15.0055 16.5186 14.2022 16.5186C13.3988 16.5186 12.8592 16.5173 12.4566 16.4632C12.0721 16.4115 11.9075 16.3221 11.7989 16.2135C11.6903 16.1049 11.601 15.9404 11.5493 15.5558C11.4951 15.1533 11.4938 14.6137 11.4938 13.8103C11.4938 13.007 11.4951 12.4673 11.5493 12.0648C11.601 11.6802 11.6903 11.5157 11.7989 11.4071Z"
                                fill="#17388C" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M4.99215 9.85197H5.07882C5.82755 9.85195 6.4519 9.85193 6.94755 9.91857C7.47071 9.9889 7.94305 10.1436 8.3226 10.5232C8.70216 10.9027 8.85689 11.3751 8.92723 11.8982C8.99386 12.3939 8.99384 13.0182 8.99382 13.767V13.8536C8.99384 14.6024 8.99386 15.2267 8.92723 15.7224C8.85689 16.2455 8.70216 16.7179 8.3226 17.0974C7.94305 17.477 7.47071 17.6317 6.94755 17.702C6.45191 17.7687 5.82758 17.7687 5.07887 17.7686H4.99216C4.24344 17.7687 3.61906 17.7687 3.12342 17.702C2.60026 17.6317 2.12792 17.477 1.74836 17.0974C1.36881 16.7179 1.21408 16.2455 1.14374 15.7224C1.07711 15.2267 1.07713 14.6024 1.07715 13.8537V13.767C1.07713 13.0182 1.07711 12.3939 1.14374 11.8982C1.21408 11.3751 1.36881 10.9027 1.74836 10.5232C2.12792 10.1436 2.60026 9.9889 3.12342 9.91857C3.61907 9.85193 4.24342 9.85195 4.99215 9.85197ZM3.28998 11.1574C2.90543 11.2091 2.74085 11.2985 2.63225 11.4071C2.52365 11.5157 2.4343 11.6802 2.3826 12.0648C2.32848 12.4673 2.32715 13.007 2.32715 13.8103C2.32715 14.6137 2.32848 15.1533 2.3826 15.5558C2.4343 15.9404 2.52365 16.1049 2.63225 16.2135C2.74085 16.3221 2.90543 16.4115 3.28998 16.4632C3.69251 16.5173 4.23214 16.5186 5.03548 16.5186C5.83883 16.5186 6.37846 16.5173 6.78099 16.4632C7.16554 16.4115 7.33012 16.3221 7.43872 16.2135C7.54732 16.1049 7.63667 15.9404 7.68837 15.5558C7.74249 15.1533 7.74382 14.6137 7.74382 13.8103C7.74382 13.007 7.74249 12.4673 7.68837 12.0648C7.63667 11.6802 7.54732 11.5157 7.43872 11.4071C7.33012 11.2985 7.16554 11.2091 6.78099 11.1574C6.37846 11.1033 5.83883 11.102 5.03548 11.102C4.23214 11.102 3.69251 11.1033 3.28998 11.1574Z"
                                fill="#17388C" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M14.1588 0.685304C13.4101 0.68528 12.7857 0.68526 12.2901 0.751898C11.7669 0.822235 11.2946 0.976961 10.915 1.35652C10.5355 1.73608 10.3807 2.20841 10.3104 2.73157C10.2438 3.22722 10.2438 3.85157 10.2438 4.60031V4.68697C10.2438 5.4357 10.2438 6.06005 10.3104 6.55571C10.3807 7.07887 10.5355 7.5512 10.915 7.93076C11.2946 8.31032 11.7669 8.46504 12.2901 8.53538C12.7857 8.60202 13.4101 8.602 14.1588 8.60197H14.2455C14.9942 8.602 15.6186 8.60202 16.1142 8.53538C16.6374 8.46504 17.1097 8.31032 17.4893 7.93076C17.8688 7.5512 18.0236 7.07887 18.0939 6.55571C18.1605 6.06006 18.1605 5.43572 18.1605 4.68699V4.60031C18.1605 3.85159 18.1605 3.22722 18.0939 2.73157C18.0236 2.20841 17.8688 1.73608 17.4893 1.35652C17.1097 0.976961 16.6374 0.822235 16.1142 0.751898C15.6186 0.68526 14.9942 0.68528 14.2455 0.685304H14.1588ZM11.7989 2.2404C11.9075 2.13181 12.0721 2.04245 12.4566 1.99075C12.8592 1.93663 13.3988 1.93531 14.2022 1.93531C15.0055 1.93531 15.5451 1.93663 15.9477 1.99075C16.3322 2.04245 16.4968 2.13181 16.6054 2.2404C16.714 2.349 16.8033 2.51358 16.855 2.89813C16.9092 3.30066 16.9105 3.8403 16.9105 4.64364C16.9105 5.44698 16.9092 5.98662 16.855 6.38915C16.8033 6.7737 16.714 6.93828 16.6054 7.04688C16.4968 7.15547 16.3322 7.24482 15.9477 7.29653C15.5451 7.35065 15.0055 7.35197 14.2022 7.35197C13.3988 7.35197 12.8592 7.35065 12.4566 7.29653C12.0721 7.24482 11.9075 7.15547 11.7989 7.04688C11.6903 6.93828 11.601 6.7737 11.5493 6.38915C11.4951 5.98662 11.4938 5.44698 11.4938 4.64364C11.4938 3.8403 11.4951 3.30066 11.5493 2.89813C11.601 2.51358 11.6903 2.349 11.7989 2.2404Z"
                                fill="#17388C" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M4.99215 0.685304C4.24343 0.68528 3.61906 0.68526 3.12342 0.751898C2.60026 0.822235 2.12792 0.976961 1.74836 1.35652C1.36881 1.73608 1.21408 2.20841 1.14374 2.73157C1.07711 3.22722 1.07713 3.85156 1.07715 4.60028V4.68697C1.07713 5.43569 1.07711 6.06006 1.14374 6.55571C1.21408 7.07887 1.36881 7.5512 1.74836 7.93076C2.12792 8.31032 2.60026 8.46504 3.12342 8.53538C3.61906 8.60202 4.24341 8.602 4.99213 8.60197H5.07881C5.82754 8.602 6.4519 8.60202 6.94755 8.53538C7.47071 8.46504 7.94305 8.31032 8.3226 7.93076C8.70216 7.5512 8.85689 7.07887 8.92723 6.55571C8.99386 6.06006 8.99384 5.43572 8.99382 4.68699V4.60031C8.99384 3.85159 8.99386 3.22722 8.92723 2.73157C8.85689 2.20841 8.70216 1.73608 8.3226 1.35652C7.94305 0.976961 7.47071 0.822235 6.94755 0.751898C6.4519 0.68526 5.82756 0.68528 5.07884 0.685304H4.99215ZM2.63225 2.2404C2.74085 2.13181 2.90543 2.04245 3.28998 1.99075C3.69251 1.93663 4.23214 1.93531 5.03548 1.93531C5.83883 1.93531 6.37846 1.93663 6.78099 1.99075C7.16554 2.04245 7.33012 2.13181 7.43872 2.2404C7.54732 2.349 7.63667 2.51358 7.68837 2.89813C7.74249 3.30066 7.74382 3.8403 7.74382 4.64364C7.74382 5.44698 7.74249 5.98662 7.68837 6.38915C7.63667 6.7737 7.54732 6.93828 7.43872 7.04688C7.33012 7.15547 7.16554 7.24482 6.78099 7.29653C6.37846 7.35065 5.83883 7.35197 5.03548 7.35197C4.23214 7.35197 3.69251 7.35065 3.28998 7.29653C2.90543 7.24482 2.74085 7.15547 2.63225 7.04688C2.52365 6.93828 2.4343 6.7737 2.3826 6.38915C2.32848 5.98662 2.32715 5.44698 2.32715 4.64364C2.32715 3.8403 2.32848 3.30066 2.3826 2.89813C2.4343 2.51358 2.52365 2.349 2.63225 2.2404Z"
                                stroke="#17388C" stroke-width="0.5" mask="url(#path-1-outside-1_1481_4226)" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M14.1588 9.85197C13.4101 9.85195 12.7857 9.85193 12.2901 9.91857C11.7669 9.9889 11.2946 10.1436 10.915 10.5232C10.5355 10.9027 10.3807 11.3751 10.3104 11.8982C10.2438 12.3939 10.2438 13.0182 10.2438 13.767V13.8536C10.2438 14.6024 10.2438 15.2267 10.3104 15.7224C10.3807 16.2455 10.5355 16.7179 10.915 17.0974C11.2946 17.477 11.7669 17.6317 12.2901 17.702C12.7857 17.7687 13.41 17.7687 14.1587 17.7686H14.2455C14.9942 17.7687 15.6186 17.7687 16.1142 17.702C16.6374 17.6317 17.1097 17.477 17.4893 17.0974C17.8688 16.7179 18.0236 16.2455 18.0939 15.7224C18.1605 15.2267 18.1605 14.6024 18.1605 13.8537V13.767C18.1605 13.0183 18.1605 12.3939 18.0939 11.8982C18.0236 11.3751 17.8688 10.9027 17.4893 10.5232C17.1097 10.1436 16.6374 9.9889 16.1142 9.91857C15.6186 9.85193 14.9942 9.85195 14.2455 9.85197H14.1588ZM11.7989 11.4071C11.9075 11.2985 12.0721 11.2091 12.4566 11.1574C12.8592 11.1033 13.3988 11.102 14.2022 11.102C15.0055 11.102 15.5451 11.1033 15.9477 11.1574C16.3322 11.2091 16.4968 11.2985 16.6054 11.4071C16.714 11.5157 16.8033 11.6802 16.855 12.0648C16.9092 12.4673 16.9105 13.007 16.9105 13.8103C16.9105 14.6137 16.9092 15.1533 16.855 15.5558C16.8033 15.9404 16.714 16.1049 16.6054 16.2135C16.4968 16.3221 16.3322 16.4115 15.9477 16.4632C15.5451 16.5173 15.0055 16.5186 14.2022 16.5186C13.3988 16.5186 12.8592 16.5173 12.4566 16.4632C12.0721 16.4115 11.9075 16.3221 11.7989 16.2135C11.6903 16.1049 11.601 15.9404 11.5493 15.5558C11.4951 15.1533 11.4938 14.6137 11.4938 13.8103C11.4938 13.007 11.4951 12.4673 11.5493 12.0648C11.601 11.6802 11.6903 11.5157 11.7989 11.4071Z"
                                stroke="#17388C" stroke-width="0.5" mask="url(#path-1-outside-1_1481_4226)" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M4.99215 9.85197H5.07882C5.82755 9.85195 6.4519 9.85193 6.94755 9.91857C7.47071 9.9889 7.94305 10.1436 8.3226 10.5232C8.70216 10.9027 8.85689 11.3751 8.92723 11.8982C8.99386 12.3939 8.99384 13.0182 8.99382 13.767V13.8536C8.99384 14.6024 8.99386 15.2267 8.92723 15.7224C8.85689 16.2455 8.70216 16.7179 8.3226 17.0974C7.94305 17.477 7.47071 17.6317 6.94755 17.702C6.45191 17.7687 5.82758 17.7687 5.07887 17.7686H4.99216C4.24344 17.7687 3.61906 17.7687 3.12342 17.702C2.60026 17.6317 2.12792 17.477 1.74836 17.0974C1.36881 16.7179 1.21408 16.2455 1.14374 15.7224C1.07711 15.2267 1.07713 14.6024 1.07715 13.8537V13.767C1.07713 13.0182 1.07711 12.3939 1.14374 11.8982C1.21408 11.3751 1.36881 10.9027 1.74836 10.5232C2.12792 10.1436 2.60026 9.9889 3.12342 9.91857C3.61907 9.85193 4.24342 9.85195 4.99215 9.85197ZM3.28998 11.1574C2.90543 11.2091 2.74085 11.2985 2.63225 11.4071C2.52365 11.5157 2.4343 11.6802 2.3826 12.0648C2.32848 12.4673 2.32715 13.007 2.32715 13.8103C2.32715 14.6137 2.32848 15.1533 2.3826 15.5558C2.4343 15.9404 2.52365 16.1049 2.63225 16.2135C2.74085 16.3221 2.90543 16.4115 3.28998 16.4632C3.69251 16.5173 4.23214 16.5186 5.03548 16.5186C5.83883 16.5186 6.37846 16.5173 6.78099 16.4632C7.16554 16.4115 7.33012 16.3221 7.43872 16.2135C7.54732 16.1049 7.63667 15.9404 7.68837 15.5558C7.74249 15.1533 7.74382 14.6137 7.74382 13.8103C7.74382 13.007 7.74249 12.4673 7.68837 12.0648C7.63667 11.6802 7.54732 11.5157 7.43872 11.4071C7.33012 11.2985 7.16554 11.2091 6.78099 11.1574C6.37846 11.1033 5.83883 11.102 5.03548 11.102C4.23214 11.102 3.69251 11.1033 3.28998 11.1574Z"
                                stroke="#17388C" stroke-width="0.5" mask="url(#path-1-outside-1_1481_4226)" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M14.1588 0.685304C13.4101 0.68528 12.7857 0.68526 12.2901 0.751898C11.7669 0.822235 11.2946 0.976961 10.915 1.35652C10.5355 1.73608 10.3807 2.20841 10.3104 2.73157C10.2438 3.22722 10.2438 3.85157 10.2438 4.60031V4.68697C10.2438 5.4357 10.2438 6.06005 10.3104 6.55571C10.3807 7.07887 10.5355 7.5512 10.915 7.93076C11.2946 8.31032 11.7669 8.46504 12.2901 8.53538C12.7857 8.60202 13.4101 8.602 14.1588 8.60197H14.2455C14.9942 8.602 15.6186 8.60202 16.1142 8.53538C16.6374 8.46504 17.1097 8.31032 17.4893 7.93076C17.8688 7.5512 18.0236 7.07887 18.0939 6.55571C18.1605 6.06006 18.1605 5.43572 18.1605 4.68699V4.60031C18.1605 3.85159 18.1605 3.22722 18.0939 2.73157C18.0236 2.20841 17.8688 1.73608 17.4893 1.35652C17.1097 0.976961 16.6374 0.822235 16.1142 0.751898C15.6186 0.68526 14.9942 0.68528 14.2455 0.685304H14.1588ZM11.7989 2.2404C11.9075 2.13181 12.0721 2.04245 12.4566 1.99075C12.8592 1.93663 13.3988 1.93531 14.2022 1.93531C15.0055 1.93531 15.5451 1.93663 15.9477 1.99075C16.3322 2.04245 16.4968 2.13181 16.6054 2.2404C16.714 2.349 16.8033 2.51358 16.855 2.89813C16.9092 3.30066 16.9105 3.8403 16.9105 4.64364C16.9105 5.44698 16.9092 5.98662 16.855 6.38915C16.8033 6.7737 16.714 6.93828 16.6054 7.04688C16.4968 7.15547 16.3322 7.24482 15.9477 7.29653C15.5451 7.35065 15.0055 7.35197 14.2022 7.35197C13.3988 7.35197 12.8592 7.35065 12.4566 7.29653C12.0721 7.24482 11.9075 7.15547 11.7989 7.04688C11.6903 6.93828 11.601 6.7737 11.5493 6.38915C11.4951 5.98662 11.4938 5.44698 11.4938 4.64364C11.4938 3.8403 11.4951 3.30066 11.5493 2.89813C11.601 2.51358 11.6903 2.349 11.7989 2.2404Z"
                                stroke="#17388C" stroke-width="0.5" mask="url(#path-1-outside-1_1481_4226)" />
                        </svg>
                        Dashboard</router-link>
                </li>
                <li class="plan-modal" @click.prevent="openModal('beneficiaries', 'Beneficiaries', 'plan-tables beneficiary')">
                    <a href=""><i class="iatse-icon-Users-Group-Rounded plan-modal"></i>Beneficiaries</a>
                </li>
                <li class="plan-modal" @click.prevent="openModal('work-history', 'Work History', null)"><a href=""><i
                            class="iatse-icon-case plan-modal"></i>Work History</a></li>
                <li><a href="https://octobercms.dev.digitalgarage.ro/en/resources" target="_blank"><i
                            class="iatse-icon-Document-Text"></i>Funds Resources</a></li>
                <li><a href="https://octobercms.dev.digitalgarage.ro/en/faqs" target="_blank"><i
                            class="iatse-icon-question"></i>FAQs</a></li>
                <li class="plan-modal" @click.prevent="openModal('missing-contribution', 'Missing Contributions Form', 'plan-upload plan-card-info plan-profile-update')"><a href=""><i
                            class="iatse-icon-Hand-Pills plan-modal"></i>Missing Contributions</a></li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth';
import { useModalStore } from '@/stores/modal';
import { computed } from 'vue';

export default {
    setup() {
        const authStore = useAuthStore();
        const modalStore = useModalStore();
        const dashboardRoute = computed(() => authStore.getDashboardRoute());

        return {
            dashboardRoute,
            openModal: modalStore.openModal
        }
    }
}
</script>
