<template>
    <div class="container mt-4">
        <div class="row">
            <div class="col-md-12 d-flex flex-column gap-4">
                <h1 class="text-center mb-4">
                    New Claim Submission
                </h1>

                <div class="card">
                    <div class="card-body">
                        Please review your mailing address and/or payment destination below.
                        Please note: Updating mailing address below will update your primary address on file with the
                        Fund Office
                        for all your correspondences.
                        Updating direct deposit information below will update the payment setup on file with the Fund
                        Office for all
                        your payments (Claims and other Benefits – if applicable).
                    </div>
                </div>

                <!-- <ChangeWrapper /> -->

                <div>
                    <router-link class="btn btn-secondary" :to="{ name: 'participant.claims' }">Back</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

export default {
    components: {
    },
}
</script>