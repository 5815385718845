<template>
  <div class="container">
    <h3 class="text-center mb-5 mt-4">Locals</h3>
    <div class="position-fixed top-0 end-0 p-3" style="z-index: 11">
      <div class="alert alert-success" v-show="success">{{ success }}</div>
      <div v-if="errors.length > 0" class="alert alert-danger">
        <ul>
          <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
        </ul>
      </div>
    </div>
    <div class="row" v-if="loading">Loading...</div>
    <div v-else>
      <LocalsRequests :locals="pendingLocals" :paginator="pendingPaginator"
        @fetch-paginator-data="fetchPendingPaginationData" @send-pin="sendPin" @reject-request="rejectRequest" />
      <SearchLocalsFilters :filters="filters" @update-filters="updateFilters" :searching="searching" />
      <SearchLocalsList :locals="locals" :paginator="paginator" @reset-pin="resetPin" @activate="activateAccount"
        @deactivate="deactivateAccount" @fetch-paginator-data="fetchPaginationData" @change-role="changeRole" />
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, ref, Ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import {
  SearchLocal,
  SearchLocalsFormFilters,
  PendingLocal,
  Paginator,
} from "@/interfaces/admin";
import LocalsRequests from "@/components/superadmin/users/LocalsRequests.vue";
import SearchLocalsList from '@/components/superadmin/users/SearchLocalsList.vue';
import SearchLocalsFilters from '@/components/superadmin/users/SearchLocalsFilters.vue';
import axios from "axios";

export default {
  components: {
    SearchLocalsList,
    SearchLocalsFilters,
    LocalsRequests,
  },
  setup() {
    const loading = ref(true);
    const searching = ref(false);
    const locals = ref<SearchLocal[]>([]);
    const pendingLocals = ref<PendingLocal[]>([]);
    const paginator: Paginator = reactive({
      per_page: 0,
      total: 0,
      last_page: 0,
      current_page: 0,
    });
    const pendingPaginator: Paginator = reactive({
      per_page: 0,
      total: 0,
      last_page: 0,
      current_page: 0,
    });
    const page = ref(0);
    const pendingPage = ref(0);
    const route = "api/administrator/users/locals";
    const router = useRouter();
    const success = ref(null);
    const errors: Ref<string[]> = ref([]);
    const filters = reactive<SearchLocalsFormFilters>({
      OrgName: ''
    });

    const fetchData = async (isLoading: boolean) => {
      try {
        if (isLoading) loading.value = true;

        const response = await axios.get(route, {
          params: { ...filters, locals: page.value, pendingLocals: pendingPage.value },
        });

        locals.value = response.data.Locals;
        pendingLocals.value = response.data.PendingLocals;
        Object.assign(paginator, response.data.Paginator);
        Object.assign(pendingPaginator, response.data.PendingPaginator);
      }
      catch (error) {
        console.error("Error:", error);
      }

      if (isLoading) loading.value = false;
    };

    // Handle Send Pin
    const sendPin = async (requestID: number) => {
      router.push({ name: 'register', query: { RequestID: requestID } });
    };

    // Handle Reset Pin
    const resetPin = async (userID: number) => {
      const index = locals.value.findIndex(local => local.UserID === userID);

      try {
        locals.value[index].ResettingPin = true;
        const response = await axios.post(
          `${route}/reset-pin/${userID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;
        if (response.data.error) {
          errors.value = Object.values(
            response.data.error
          ).flat() as string[];
        }
      }
      catch (error: any) {
        errors.value = Object.values(error.response.data.error).flat() as string[];
      }

      locals.value[index].ResettingPin = false;
      await refreshData();
    };

    // Handle Activate Account
    const activateAccount = async (userID: number) => {
      const index = locals.value.findIndex(local => local.UserID === userID);
      locals.value[index].Activating = true;

      try {
        const response = await axios.post(
          `${route}/activate/${userID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;
      }
      catch (error) {
        console.error("Error activating account:", error);
      }

      await refreshData();
      locals.value[index].Activating = false;
    };

    // Handle Deactivate Account
    const deactivateAccount = async (userID: number) => {
      const index = locals.value.findIndex(local => local.UserID === userID);
      locals.value[index].Deactivating = true;

      try {
        const response = await axios.post(
          `${route}/deactivate/${userID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;
      }
      catch (error) {
        console.error("Error deactivating account:", error);
      }

      await refreshData();
      locals.value[index].Deactivating = false;
    };

    // Handle Reject Request
    const rejectRequest = async (requestID: number) => {
      try {
        const index = pendingLocals.value.findIndex(req => req.UILocalRequestID === requestID);
        pendingLocals.value[index].Rejecting = true;
        const response = await axios.post(
          `${route}/reject/${requestID}`,
          { _method: "PATCH" }
        );

        success.value = response.data.success;
        await refreshData();
        pendingLocals.value[index].Rejecting = false;
      }
      catch (error) {
        console.error("Error rejecting request:", error);
      }
    };

    // Handle Change Role
    const changeRole = async (userID: number) => {
      const index = locals.value.findIndex(local => local.UserID === userID);
      locals.value[index].ChangingRole = true;

      try {
        const response = await axios.post(
          `${route}/change-role/${userID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;
      }
      catch (error) {
        console.error("Error changing role:", error);
      }

      await refreshData();
      locals.value[index].ChangingRole = false;
    };

    const refreshData = async () => {
      await fetchData(false);

      setTimeout(() => {
        success.value = null;
        errors.value = [];
      }, 3500);
    };

    // Filter Employers
    const updateFilters = async (
      newFilters: SearchLocalsFormFilters
    ) => {
      page.value = 1;
      Object.assign(filters, newFilters);
    };

    // Fetch Data For Selected Page
    const fetchPaginationData = (pageNumber: number) => {
      page.value = pageNumber;
      fetchData(false);
    };

    // Fetch Data For Selected Page
    const fetchPendingPaginationData = (pageNumber: number) => {
      pendingPage.value = pageNumber;
      fetchData(false);
    };

    watch(filters, async () => {
      searching.value = true;
      await fetchData(false);
      searching.value = false;
    }, { deep: true });

    onMounted(async () => await fetchData(true));

    return {
      filters,
      locals,
      pendingLocals,
      loading,
      searching,
      paginator,
      pendingPaginator,
      success,
      errors,
      sendPin,
      rejectRequest,
      resetPin,
      activateAccount,
      deactivateAccount,
      changeRole,
      updateFilters,
      fetchPaginationData,
      fetchPendingPaginationData
    };
  },
};
</script>