<template>
    <div class="plan-content">
        <form id="add-beneficiary-form" class="form" method="POST">
            <TextInput type="text" label="SSN" name="ssn" :max=11 placeholder="***-**-****"
                v-model:modelValue="formData.SSN" />
            <SelectComponent label="Relationship" v-model:selectedOption="formData.Relationship" :options="relationships"
                :single-arrow="true" />
            <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
                v-model:modelValue="formData.FirstName" />
            <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
                v-model:modelValue="formData.LastName"/>
            <SelectComponent label="Suffix" v-model:selectedOption="formData.Suffix"
                :options="suffixes" :single-arrow="true" />
            <DateInput label="DOB" v-model:modelValue="formData.DateofBirth" />
            <TextInput type="text" label="Address" placeholder="Enter Address" :max=30
                v-model:modelValue="formData.Address" :required="true" />

            <div class="action-buttons">
                <a href="" class="btn btn-secondary" @click.prevent="closeModal"> Discard </a>
                <a href="" class="btn btn-secondary full-width" @click.prevent> Save & Add new Beneficiary </a>
                <a href="" class="btn btn-primary plan-modal" @click.prevent="openModal('assign-fund', 'Assign Fund To Beneficiary', 'plan-upload')">Assign Funds </a>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, Ref, onMounted, reactive, defineComponent } from 'vue';
import { useProfileStore } from '@/stores/profile';
import { useModalStore } from '@/stores/modal';

import TextInput from '@components/form/TextInput.vue';
import DateInput from '@components/form/DateInput.vue';
import SelectComponent from '@components/form/SelectComponent.vue';

export default defineComponent({
    components: {
        TextInput,
        SelectComponent,
        DateInput
    },
    setup(_, { emit }) {
        const profileStore = useProfileStore();
        const formData = reactive({
            SSN: null,
            Relationship: profileStore.relationships[0],
            FirstName: null,
            LastName: null,
            Suffix: profileStore.nameSuffixes[0],
            DateofBirth: null,
            Address: null,
        });
        const route = 'api/participant/beneficiaries/add';
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const errors: Ref<string[]> = ref([]);
        const success = ref('');

        const handleSubmit = () => {
            loadingSubmit.value = true;
        };

        const closeModal = () => {
            emit('close');
        }

        const fetchData = async () => {
            if (!profileStore.generalData) {
                loading.value = true;
                await profileStore.fetchGeneralData();
            }

            loading.value = false;
        };

        onMounted(async () => {
            await fetchData();
        });

        return {
            relationships: profileStore.relationships,
            suffixes: profileStore.nameSuffixes,
            openModal: useModalStore().openModal,
            closeModal,
            loading,
            formData,
            success,
            errors,
            handleSubmit,
        };
    }
})
</script>