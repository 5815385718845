import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = {
  class: "modal-title text-center w-100",
  id: "successModalLabel"
}
const _hoisted_5 = { class: "modal-body text-center" }
const _hoisted_6 = { class: "modal-footer d-flex justify-content-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    ref: "successModal",
    class: "modal"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.title), 1),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-dismiss": "modal",
              "aria-label": "Close",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
            })
          ]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.message), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              "data-dismiss": "modal",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
            }, "Cancel"),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              "data-dismiss": "modal",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)))
            }, "OK")
          ])
        ])
      ])
    ]),
    _: 1
  }, 512))
}