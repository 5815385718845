<template>
    <div v-if="loading" class="col-md-12 text-center my-4">
        <span> Loading... </span>
    </div>
    <!-- Plan Content -->
    <div v-else class="plan-content">
        <!-- General Plan Detail Cards -->
        <PlanGeneral
            text="Information box displaying specific instructions and also the new claim (web reference_ if implemeted) that would be generated upon submission." />

        <!-- Form -->
        <form id="plan-c-mrp-claims-form" class="form plan-c-mrp-claims" method="POST">
            <div class="cards">
                <div class="card transparent">
                    <div class="card-header">
                        <h2>New Claims</h2>
                        <a href="" class="btn btn-primary plan-modal"
                            @click.prevent="openModal('claim-submission', 'New Claim Submission', 'plan-upload plan-card-info')">
                            Submit a New Claim </a>
                    </div>
                </div>
                <div class="card transparent">
                    <div class="card-header">
                        <h2>Existing Claims</h2>
                        <a href="" class="btn btn-primary full-width"
                            @click.prevent="openModal('claim-search', 'Claim History Search', 'plan-upload plan-card-info')">
                            Go To Existing Claim
                        </a>
                    </div>
                </div>
            </div>
            <div class="content">
                <CoveredCard :links="links" />

                <!-- Table -->
                <div class="table-wrapper">
                    <DataTable :table="data" classes="claims small" />
                </div>

                <div class="action-buttons">
                    <a href="" class="btn btn-secondary plan-modal"
                        @click.prevent="openModal('check-destination', 'ACH/Check Destination', 'plan-form', 'vacation')">
                        Change Mailing Address
                    </a>
                    <a href="" class="btn btn-secondary plan-modal"
                        @click.prevent="openModal('check-destination', 'ACH/Check Destination', 'plan-form', 'vacation')">
                        Claims Payment Setup
                    </a>
                    <a href="" class="btn btn-secondary plan-modal"
                        @click.prevent="openModal('claim-search', 'Claim History Search', 'plan-upload plan-card-info')">
                        Search Claim History
                    </a>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, defineComponent, onMounted } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useStepStore } from '@/stores/step';
import { useClaimStore } from '@/stores/claim';
import PlanGeneral from '@/components/cards/PlanGeneral.vue';
import DataTable from '@/components/form/DataTable.vue';
import CoveredCard from '@/components/participant/claims/CoveredCard.vue';
import axios from 'axios';

export default defineComponent({
    components: {
        PlanGeneral,
        DataTable,
        CoveredCard
    },
    setup() {
        const modalStore = useModalStore();
        const stepStore = useStepStore();
        const claimStore = useClaimStore();
        const loading = ref(true);

        const data = {
            headers: [
                { title: 'Date Filled' },
                { title: 'Claim Number' },
                { title: 'Web Ref.' },
                { title: 'Claim Status' },
                { title: 'Claim Doc(s)' },
            ],
            rows: []
        }

        const fetchData = async () => {
            loading.value = true;
            claimStore.clearClaim()

            await axios.get('api/participant/claims')
                .then(response => {
                    data.rows = response.data?.Claims;
                    claimStore.setLinks(response.data?.ClaimForm, response.data?.CoveredList);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })
                .finally(() => loading.value = false);
        };

        onMounted(async () => {
            await fetchData();
            stepStore.setCurrentStep(0);
        });

        return {
            data,
            loading,
            links: claimStore.claimLinks,
            openModal: modalStore.openModal,
        }

    }
})
</script>