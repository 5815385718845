<template>
    <b-modal ref="successModal" class="modal">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-center w-100" id="successModalLabel">{{ title }}</h5>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
                        @click="closeModal"></button>
                </div>
                <div class="modal-body text-center">{{ message }}</div>
                <div class="modal-footer d-flex justify-content-between">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Cancel</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="handleSubmit">OK</button>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, Ref, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { Modal } from "bootstrap";

export default defineComponent({
    props: {
        message: String,
        title: String,
        route: String,
        param: Object as PropType<{ name: string, value: string | number }>,
    },
    setup(props, { emit }) {
        const router = useRouter();
        const successModal = ref(null);
        const modal: Ref<Modal | null> = ref(null);

        const closeModal = () => {
            modal.value?.hide();

            const routeParam = props.param;

            if (routeParam) {
                router.push({ name: props.route, params: { [routeParam.name]: routeParam.value } });
            }
            else {
                router.push({ name: props.route });
            }
        }

        const handleSubmit = () => {
            // modal.value?.hide();
            emit('submit-data');

            closeModal();
        }

        onMounted(() => {
            modal.value = new Modal(successModal.value);
            modal.value?.show();
        });

        return { closeModal, handleSubmit, successModal }
    }
});
</script>