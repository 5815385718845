import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["type", "id", "name", "placeholder", "pattern", "maxlength", "readonly", "value"]
const _hoisted_3 = {
  key: 0,
  class: "show-password icon-eye"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['text-input', _ctx.customClass, { 'error': _ctx.error }])
  }, [
    _createElementVNode("label", {
      for: _ctx.label,
      class: "body-text"
    }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _createElementVNode("input", {
      type: _ctx.type,
      id: _ctx.label,
      name: _ctx.name,
      placeholder: _ctx.placeholder,
      pattern: _ctx.pattern ? _ctx.pattern : undefined,
      maxlength: _ctx.max,
      readonly: _ctx.readonly,
      class: "links",
      value: _ctx.inputValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
    }, null, 40, _hoisted_2),
    (_ctx.type === 'password')
      ? (_openBlock(), _createElementBlock("i", _hoisted_3))
      : _createCommentVNode("", true)
  ], 2))
}