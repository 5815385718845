import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "plan-content" }
const _hoisted_2 = {
  id: "add-beneficiary-form",
  class: "form",
  method: "POST"
}
const _hoisted_3 = { class: "action-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_SelectComponent = _resolveComponent("SelectComponent")!
  const _component_DateInput = _resolveComponent("DateInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", _hoisted_2, [
      _createVNode(_component_TextInput, {
        type: "text",
        label: "SSN",
        name: "ssn",
        max: 11,
        placeholder: "***-**-****",
        modelValue: _ctx.formData.SSN,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.SSN) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_SelectComponent, {
        label: "Relationship",
        selectedOption: _ctx.formData.Relationship,
        "onUpdate:selectedOption": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.Relationship) = $event)),
        options: _ctx.relationships,
        "single-arrow": true
      }, null, 8, ["selectedOption", "options"]),
      _createVNode(_component_TextInput, {
        type: "text",
        label: "First Name",
        placeholder: "Enter First Name",
        max: 12,
        modelValue: _ctx.formData.FirstName,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.FirstName) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_TextInput, {
        type: "text",
        label: "Last Name",
        placeholder: "Enter Last Name",
        max: 25,
        modelValue: _ctx.formData.LastName,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.LastName) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_SelectComponent, {
        label: "Suffix",
        selectedOption: _ctx.formData.Suffix,
        "onUpdate:selectedOption": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.Suffix) = $event)),
        options: _ctx.suffixes,
        "single-arrow": true
      }, null, 8, ["selectedOption", "options"]),
      _createVNode(_component_DateInput, {
        label: "DOB",
        modelValue: _ctx.formData.DateofBirth,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.DateofBirth) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_TextInput, {
        type: "text",
        label: "Address",
        placeholder: "Enter Address",
        max: 30,
        modelValue: _ctx.formData.Address,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.Address) = $event)),
        required: true
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          href: "",
          class: "btn btn-secondary",
          onClick: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)), ["prevent"]))
        }, " Discard "),
        _createElementVNode("a", {
          href: "",
          class: "btn btn-secondary full-width",
          onClick: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["prevent"]))
        }, " Save & Add new Beneficiary "),
        _createElementVNode("a", {
          href: "",
          class: "btn btn-primary plan-modal",
          onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.openModal('assign-fund', 'Assign Fund To Beneficiary', 'plan-upload')), ["prevent"]))
        }, "Assign Funds ")
      ])
    ])
  ]))
}