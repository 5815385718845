<template>
    <div class="position-fixed top-0 end-0 p-3" style="z-index: 11">
        <div v-if="success" class="alert alert-success">{{ success }}</div>
        <div v-if="info" class="alert alert-info">{{ info }}</div>
        <div v-if="error" class="alert alert-danger"> {{error}} </div>
        <div v-if="errors && errors.length > 0" class="alert alert-danger">
            <template v-if="Array.isArray(errors)">
                <ul>
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>
            </template>
            <template v-else>
                <span>{{ errors }}</span>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        success: String,
        info: String,
        error: String,
        errors: {
            type: [Array, String],
            default: null
        }
    }
};
</script>
