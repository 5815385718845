<template>
    <Alerts :success="success" :info="info" :errors="errors" />
    <!-- Plan Content -->
    <div class="plan-content">
        <!-- General Plan Detail Cards -->
        <PlanGeneral text="All Updates to your address will immediately and permanently change your account with the Fund
                        Office." />

        <!-- Form -->
        <form id="update-address-form" class="form update-address" method="post">
            <h4> Address 1 </h4>
            <div class="inputs-wrapper">
                <SelectComponent label="Address Type" v-model:selectedOption="formData.Type"
                    :options="profileStore.addressTypes" :single-arrow="true" />
                <TextInput type="text" label="Address" placeholder="Enter Address 1" :max=30
                    v-model:modelValue="formData.Street1" :required="true" />
                <TextInput type="text" label="City" placeholder="Enter City" :max=25 v-model:modelValue="formData.City"
                    :required="true" />

                <SelectComponent label="State" v-model:selectedOption="formData.State" :options="filteredStates"
                    :single-arrow="true" />
                <SelectComponent label="Country" v-model:selectedOption="formData.Country"
                    :options="profileStore.countries" :single-arrow="true" />

                <TextInput type="text" label="Postal Code" placeholder="Enter Postal Code" :max=9
                    v-model:modelValue="formData.ZipCode" :required="true" />
            </div>

            <div class="action-buttons">
                <button @click.prevent="closeModal" class="btn btn-secondary"> Discard</button>
                <button @click.prevent="submitData" class="btn btn-primary">
                    {{ loadingSubmit ? 'Saving...' : 'Save Changes' }} </button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, reactive, Ref, defineComponent, computed } from 'vue';
import { CountryStates, SelectOption, AddressInfo } from '@/interfaces/interfaces';
import { useProfileStore } from '@/stores/profile';
import useFormSubmit from '@composable/useFormSubmit';
import Alerts from '@components/utils/AlertsComponent.vue';
import PlanGeneral from '@components/cards/PlanGeneral.vue';
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from "@components/form/SelectComponent.vue";

export default defineComponent({
    components: {
        Alerts,
        PlanGeneral,
        TextInput,
        SelectComponent
    },
    setup(_, { emit }) {
        const profileStore = useProfileStore();
        const formData: AddressInfo = reactive({...profileStore.address});
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const errors: Ref<string[]> = ref([]);
        const success = ref('');
        const info = ref('');
        const route = 'api/user/profile/my-address';

        const closeModal = () => {
            emit('close');
        }

        // Filter states based on selected country
        const filteredStates = computed(() => {
            const selectedCountry = formData?.Country?.key ?? '';
            const states = profileStore.statesByCountry;
            const countryStates = states ? (states as CountryStates[]).filter((state) => state.CountryKey == selectedCountry) : [];

            return (countryStates?.map(state => state.States).flat());
        });

        // Handle Form Submit
        const submitData = async () => {
            const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit });
            const successSubmit = await handleSubmit();

            if (successSubmit) {
                profileStore.setAddress(formData);
            }
        }

        return {
            formData,
            profileStore,
            filteredStates,
            errors,
            success,
            info,
            loading,
            loadingSubmit,
            submitData,
            closeModal,
        };
    }
})
</script>