import { defineStore } from "pinia";

export const useStepStore = defineStore({
    id: "steps",
    state: () => ({
        currentStep: 0,
    }),

    actions: {
        setCurrentStep(step: number) {
            this.currentStep = step;
        },
    },
});
