<template>
    <div class="container mt-4">
        <h4 class="mb-4"> Edit Autoresponder </h4>
        <div v-if="loading"> Loading... </div>
        <form v-else @submit.prevent="handleSubmit">
            <div v-if="errors.length > 0" class="alert alert-danger">
                <ul>
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>
            </div>
            <div class="form-group mb-4 d-flex flex-column gap-4">
                <div class="col-md-6">
                    <label for="description" class="col-form-label text-md-right">Description <span
                            class="text-danger">*</span></label>
                    <input type="text" id="description" name="Description" v-model="autoresponder.Description"
                        class="form-control" required>
                </div>
                <div class="col-md-6">
                    <label for="email_subject" class="col-form-label text-md-right"> Email Subject <span
                            class="text-danger">*</span></label>
                    <input type="text" id="email_subject" name="EmailSubject" v-model="autoresponder.EmailSubject"
                        class="form-control" required>
                </div>
                <div class="col-md-6">
                    <label for="email_body" class="col-form-label text-md-right"> Email Body <span
                            class="text-danger">*</span></label>
                    <textarea type="text" id="email_body" name="EmailBody" class="form-control"
                        v-model="autoresponder.EmailBody" required> </textarea>
                </div>
                <div class="mt-4 col-md-6 d-flex justify-content-between">
                    <router-link :to="{ name: 'superadmin.autoresponders' }" class="btn btn-secondary">Cancel</router-link>
                    <button type="submit" class="btn btn-primary">{{ loadingSubmit ? 'Submitting...' : 'Update' }}</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import axios from 'axios';
import { onMounted, ref, Ref, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Autoresponder } from '@interfaces/interfaces';

export default {
    setup() {
        const autoresponder: Autoresponder = reactive({ Description: '', EmailSubject: '', EmailBody: '' });
        const autoresponderID = useRoute()?.params?.AutoresponderID;
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const errors: Ref<string[]> = ref([]);
        const success = ref('');
        const router = useRouter();

        // Handle Edit Autoresponder
        const handleSubmit = async () => {
            loadingSubmit.value = true;

            await axios
                .post(`api/administrator/autoresponders/${autoresponderID}`, { ...autoresponder, _method: "PATCH" })
                .then((response) => {
                    success.value = response.data?.success;
                    loadingSubmit.value = false;

                    router.push({
                        name: 'superadmin.autoresponders',
                        query: { success: success.value }
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }

                    loadingSubmit.value = false;
                });
        }

        const fetchData = async () => {
            loading.value = true;

            await axios.get(`api/administrator/autoresponders/${autoresponderID}`)
                .then(response => {
                    Object.assign(autoresponder, response.data.Autoresponder);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });

            loading.value = false;
        };

        onMounted(async () => {
            await fetchData();
        });

        return { autoresponder, handleSubmit, loading, loadingSubmit, errors }

    }
}
</script>