<template>
    <div id="employerFields" class="d-flex flex-column gap-2 mt-4">
        <div class="form-group d-flex flex-column gap-4 mb-4 mx-5">
            <div class="d-flex gap-2">
                <input v-model="employerType" type="radio" id="admin_auth" value="auth">
                <label for="admin_auth">Administrator - Authentication/PIN Request</label>
            </div>
            <div class="d-flex gap-2">
                <input type="radio" id="admin_register" v-model="employerType" value="register">
                <label for="admin_register">Administrator - Registration (Registration Requires PIN)</label>
            </div>
        </div>
        <EmployerAuth v-if="employerType === 'auth'" :states="states" :loadingSubmit="loadingSubmit"
            @register="handleRegister" />
        <EmployerRegistration v-else-if="employerType === 'register'" :loadingSubmit="loadingSubmit"
            :securityQuestions="securityQuestions" :employerRequest="employerRequest" @register="handleRegister" />
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent, ref } from 'vue';
import { SecurityQuestion, Country } from '@/interfaces/interfaces';
import { PendingEmployer } from '@/interfaces/admin';
import EmployerAuth from './EmployerAuth.vue';
import EmployerRegistration from './EmployerRegistration.vue';

export default defineComponent({
    components: {
        EmployerAuth,
        EmployerRegistration,
    },
    props: {
        securityQuestions: {
            type: Array as PropType<SecurityQuestion[]>,
            required: true,
        },
        states: Array as PropType<Country[]>,
        employerRequest: Object as PropType<PendingEmployer | null>,
        registerType: {
            type: String as PropType<string | null>,
            required: true,
        },
        loadingSubmit: Boolean
    },
    setup(props, { emit }) {
        const employerType = ref(props.registerType);

        const handleRegister = (employer: any) => {
            emit('register', employer, employerType.value);
        }

        return {
            employerType,
            handleRegister
        };
    }
});
</script>
