<template>
  <div class="container mt-4">
    <h4 class="mb-4">Bulk Email</h4>
    <div v-if="loading"> Loading... </div>
    <form v-else>
      <div v-show="error" class="alert alert-danger">{{ error }}</div>
      <div v-show="success" class="alert alert-success">{{ success }}</div>
      <div class="form-group row mb-4 d-flex align-items-end">
        <div class="col-md-3">
          <label for="csv_file" class="col-form-label text-md-right">Import a CSV</label>
          <input type="file" id="csv_file" accept=".csv" class="form-control" :value="csvFile">
        </div>
        <button class="col-md-1 btn btn-info"> Import </button>
        <div class="col-md-4">
          <label for="user_types" class="col-form-label text-md-right">User Types <span
              class="text-danger">*</span></label>
          <multiselect v-model="selectedUserTypes" :options="userTypes" label="RoleName" track-by="RoleID"
            placeholder="Select User Types" :multiple="true" @select="handleUserTypeChange" required></multiselect>
        </div>
        <div class="col-md-4">
          <label for="fund_types" class="col-form-label text-md-right">Fund Types</label>
          <multiselect v-model="selectedFundTypes" :options="fundTypes" placeholder="Select Fund Types" :multiple="true"
            @select="handleFundTypeChange">
          </multiselect>
        </div>
      </div>
      <div class="form-group row mb-4" v-if="selectedFundTypes.length > 0">
        <div class="row">
          <div class="col-md-4" v-if="isHWSelected || isAllFunds">
            <label for="hw_types" class="col-form-label text-md-right">H&W Types</label>
            <multiselect v-model="selectedHWTypes" :options="hwTypes" placeholder="Select H&W Types" :multiple="true"
              @select="handleHWTypeChange">
            </multiselect>
          </div>
          <div class="col-md-4" v-if="isAnnuitySelected || isAllFunds">
            <label for="annuity_types" class="col-form-label text-md-right">Annuity Types</label>
            <multiselect v-model="selectedAnnuityTypes" :options="annuityTypes" track-by="key" label="value"
              placeholder="Select Annuity Types" :multiple="true" @select="handleAnnuityTypeChange"></multiselect>
          </div>
          <div class="col-md-4" v-if="isPensionSelected || isAllFunds">
            <label for="pension_types" class="col-form-label text-md-right">Pension Types</label>
            <multiselect v-model="selectedPensionTypes" :options="pensionTypes" track-by="key" label="value"
              placeholder="Select Pension Types" :multiple="true" @select="handlePensionTypeChange"></multiselect>
          </div>
        </div>
      </div>
      <div>
        <!-- Pension Filters -->
        <div class="row mt-4" v-if="isPensionSelected || isAllFunds">
          <div class="col-md-4">
            <label for="pension_additional" class="col-form-label text-md-right">Pension Additional</label>
            <select v-model="pension.Selected" class="form-control" id="pension_additional">
              <option v-for="(value, key) in pensionAdditional" :key="key" :value="key">{{ value }}</option>
            </select>
          </div>
          <div class="d-flex align-items-center gap-4 col-md-8" v-if="showPensionDates">
            <div class="col-md-3">
              <label for="start_date" class="col-form-label text-md-right">Start Date</label>
              <input type="date" v-model="pension.StartDate" class="form-control" id="start_date">
            </div>
            <div class="col-md-3">
              <label for="end_date" class="col-form-label text-md-right">End Date</label>
              <input type="date" v-model="pension.EndDate" class="form-control" id="end_date">
            </div>
          </div>
        </div>

        <!-- H&W Filters -->
        <div class="row mt-4" v-if="isHWSelected || isAllFunds">
          <div class="col-md-4">
            <label for="hw_additional" class="col-form-label text-md-right">HW Additional</label>
            <select v-model="hw.Selected" class="form-control" id="hw_additional">
              <option v-for="(value, key) in hwAdditional" :key="key" :value="key">{{ value }}</option>
            </select>
          </div>
          <div class="d-flex align-items-center gap-4 col-md-8" v-if="showHWDates">
            <div class="col-md-3">
              <label for="start_date" class="col-form-label text-md-right">Start Date</label>
              <input type="date" v-model="hw.StartDate" class="form-control" id="start_date">
            </div>
            <div class="col-md-3">
              <label for="end_date" class="col-form-label text-md-right">End Date</label>
              <input type="date" v-model="hw.EndDate" class="form-control" id="end_date">
            </div>
          </div>
        </div>

        <!-- User Filters -->
        <div class="form-group row mb-4" id="user_filters">
          <div class="col-md-3">
            <label for="home_locals" class="col-form-label text-md-right">Home Locals</label>
            <multiselect v-model="selectedHomeLocals" :options="participantFilters.locals" track-by="LocalID" label="Area"
              placeholder="Select Home Local" :multiple="true" :disabled="!isActive" @select="handleLocalChange">
            </multiselect>
          </div>
          <div class="col-md-3">
            <label for="marital_status" class="col-form-label text-md-right">Marital Status</label>
            <multiselect v-model="selectedMaritalStatuses" :options="participantFilters.maritalStatuses" track-by="CodeID"
              label="Description" placeholder="Select Home Local" :multiple="true" :disabled="!isActive"
              @select="handleMaritalStatusChange"></multiselect>
          </div>
          <div class="col-md-3">
            <label for="state" class="col-form-label text-md-right">State</label>
            <multiselect v-model="selectedStates" :options="participantFilters.states" track-by="ShortName"
              label="LongName" placeholder="Select Home Local" :multiple="true" :disabled="!isActive"
              @select="handleStateChange"></multiselect>
          </div>
          <div class="col-md-3">
            <label for="zip_code" class="col-form-label text-md-right">Zip Code</label>
            <multiselect v-model="selectedZipCodes" :options="participantFilters.zipCodes" placeholder="Select Home Local"
              :multiple="true" :disabled="!isActive" @search-change="searchZipCodes" @select="handleZipCodeChange">
            </multiselect>
          </div>
        </div>
      </div>
      <div class="col-md-4 d-flex align-items-center gap-4 mb-4">
        <input type="checkbox" id="foreign_participants" v-model="foreignOnly">
        <label for="foreign_participants"> Send to Foreign Participants Only </label>
      </div>
      <div class="form-group row mb-4">
        <div class="col-md-6">
          <router-link :to="{ name: 'superadmin.dashboard' }" class="btn btn-primary">
            {{ ('Back') }}
          </router-link>
        </div>
        <div class="col-md-6 d-flex justify-content-end gap-4">
          <button type="button" class="btn btn-primary" @click="handleSubmit('Export')">{{ ('Export CSV') }}</button>
          <button type="button" class="btn btn-primary" @click="handleSubmit('Compose')">{{ ('Compose') }}</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { ref, Ref, onMounted, reactive, computed } from 'vue';
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import { MiscellaneousCode, Country } from '@/interfaces/interfaces';

interface Role {
  RoleID: number,
  RoleName: string
}

interface AdditionalData {
  StartDate: string,
  EndDate: string,
  Selected: number
}

interface ParticipantFilters {
  locals: Local[],
  states: Country[],
  maritalStatuses: MiscellaneousCode[],
  zipCodes: string[],
}

interface Local {
  LocalID: number,
  Area: string
}

interface KeyValue {
  key: string; value: string;
}

export default {
  components: {
    Multiselect,
  },
  setup() {
    const pension: AdditionalData = reactive({
      StartDate: '',
      EndDate: '',
      Selected: 0
    });
    const hw: AdditionalData = reactive({
      StartDate: '',
      EndDate: '',
      Selected: 0
    });
    const userTypes = ref<Role[]>([]);
    const fundTypes = ref([]);
    const pensionAdditional = ref([]);
    const hwAdditional = ref([]);
    const hwTypes = ref([]);
    const participantFilters: ParticipantFilters = reactive({
      states: [{ ShortName: '0', LongName: 'ALL' }],
      locals: [{ LocalID: 0, Area: 'ALL' }],
      zipCodes: ['ALL'],
      maritalStatuses: [{ CodeID: 0, Description: 'ALL' }],
    });
    const annuityTypes: Ref<KeyValue[]> = ref([]);
    const pensionTypes: Ref<KeyValue[]> = ref([]);
    const selectedUserTypes: Ref<Role[]> = ref([]);
    const selectedFundTypes: Ref<string[]> = ref([]);
    const selectedHWTypes: Ref<string[]> = ref([]);
    const selectedAnnuityTypes: Ref<KeyValue[]> = ref([]);
    const selectedPensionTypes: Ref<KeyValue[]> = ref([]);
    const selectedHomeLocals: Ref<Local[]> = ref([]);
    const selectedStates: Ref<Country[]> = ref([]);
    const selectedMaritalStatuses: Ref<MiscellaneousCode[]> = ref([]);
    const selectedZipCodes: Ref<string[]> = ref([]);
    const csvFile = ref(null);
    const foreignOnly = ref(null);
    const error = ref(null);
    const loading = ref(true);
    const loadingSubmit = ref(false);
    const success: Ref<string | null> = ref(null);
    const route = 'api/administrator/bulk-email';

    // Handle Visible Fields
    const isActive = computed(() => selectedUserTypes.value.some((userType: Role) => userType.RoleID === 0 || userType.RoleID === 1));
    const isAnnuitySelected = computed(() => selectedFundTypes.value.some((fundType) => fundType === 'Annuity'));
    const isPensionSelected = computed(() => selectedFundTypes.value.some((fundType) => fundType === 'Pension'));
    const isHWSelected = computed(() => selectedFundTypes.value.some((fundType) => fundType === 'H&W'));
    const isAllFunds = computed(() => selectedFundTypes.value.some((fundType) => fundType === 'ALL'));
    const showPensionDates = computed(() => pension.Selected != 0);
    const showHWDates = computed(() => hw.Selected !== 0);


    // Fetch Data
    const fetchData = async () => {
      loading.value = true;

      await axios.get(route)
        .then(response => {
          const data = response.data;
          userTypes.value = data.UserTypes;
          fundTypes.value = data.FundTypes;
          hwTypes.value = data.HWTypes;
          pensionAdditional.value = data.PensionAdditional;
          hwAdditional.value = data.HWAdditional;
          annuityTypes.value = Object.entries(data.AnnuityTypes).map(([key, value]) => ({ key, value: value as string }));
          pensionTypes.value = Object.entries(data.PensionTypes).map(([key, value]) => ({ key, value: value as string }));
          participantFilters.locals = [...participantFilters.locals, ...data.Locals];
          participantFilters.states = [...participantFilters.states, ...data.States];
          participantFilters.maritalStatuses = [...participantFilters.maritalStatuses, ...data.MaritalStatuses];
          participantFilters.zipCodes = [...participantFilters.zipCodes, ...data.ZipCodes];
          selectedAnnuityTypes.value = [annuityTypes.value[0]];
          selectedHWTypes.value = [hwTypes.value[0]];
          selectedPensionTypes.value = [pensionTypes.value[0]];
          selectedMaritalStatuses.value = [participantFilters.maritalStatuses[0]];
          selectedStates.value = [participantFilters.states[0]];
          selectedHomeLocals.value = [participantFilters.locals[0]];
          selectedZipCodes.value = [participantFilters.zipCodes[0]];
        })
        .finally(() => loading.value = false)
    };

    // Handle Select Value Changes
    const handleUserTypeChange = (newSelection: Role) => {
      if (newSelection.RoleID === 0) {
        selectedUserTypes.value = [userTypes.value[0]];
      }
      else {
        const filteredTypes: Role[] = JSON.parse(JSON.stringify(selectedUserTypes.value)).filter((type: Role) => type.RoleID !== 0);
        selectedUserTypes.value = [...filteredTypes];
      }
    }

    const handleFundTypeChange = (newSelection: string) => {
      if (newSelection === 'ALL') {
        selectedFundTypes.value = [fundTypes.value[0]];
      }
      else {
        const filteredTypes: string[] = JSON.parse(JSON.stringify(selectedFundTypes.value)).filter((type: string) => type !== 'ALL');
        selectedFundTypes.value = [...filteredTypes];
      }
    }

    const handleHWTypeChange = (newSelection: string) => {
      if (newSelection === 'ALL') {
        selectedHWTypes.value = [hwTypes.value[0]];
      }
      else {
        const filteredTypes: string[] = JSON.parse(JSON.stringify(selectedHWTypes.value)).filter((type: string) => type !== 'ALL');
        selectedHWTypes.value = [...filteredTypes];
      }
    }

    const handlePensionTypeChange = (newSelection: KeyValue) => {
      if (newSelection.key === '0') {
        selectedPensionTypes.value = [pensionTypes.value[0]];
      }
      else {
        const filteredTypes: KeyValue[] = JSON.parse(JSON.stringify(selectedPensionTypes.value)).filter((type: KeyValue) => type.key !== '0');
        selectedPensionTypes.value = [...filteredTypes];
      }
    }

    const handleAnnuityTypeChange = (newSelection: KeyValue) => {
      if (newSelection.key === '0') {
        selectedAnnuityTypes.value = [annuityTypes.value[0]];
      }
      else {
        const filteredTypes: KeyValue[] = JSON.parse(JSON.stringify(selectedAnnuityTypes.value)).filter((type: KeyValue) => type.key !== '0');
        selectedAnnuityTypes.value = [...filteredTypes];
      }
    }

    const handleLocalChange = (newSelection: Local) => {
      if (newSelection.LocalID === 0) {
        selectedHomeLocals.value = [participantFilters.locals[0]];
      }
      else {
        const filteredTypes: Local[] = JSON.parse(JSON.stringify(selectedHomeLocals.value)).filter((type: Local) => type.LocalID !== 0);
        selectedHomeLocals.value = [...filteredTypes];
      }
    }

    const handleMaritalStatusChange = (newSelection: MiscellaneousCode) => {
      if (newSelection.CodeID === 0) {
        selectedMaritalStatuses.value = [participantFilters.maritalStatuses[0]];
      }
      else {
        const filteredTypes: MiscellaneousCode[] = JSON.parse(JSON.stringify(selectedMaritalStatuses.value)).filter((type: MiscellaneousCode) => type.CodeID !== 0);
        selectedMaritalStatuses.value = [...filteredTypes];
      }
    }

    const handleStateChange = (newSelection: Country) => {
      if (newSelection.ShortName === '0') {
        selectedStates.value = [participantFilters.states[0]];
      }
      else {
        const filteredTypes: Country[] = JSON.parse(JSON.stringify(selectedStates.value)).filter((type: Country) => type.ShortName !== '0');
        selectedStates.value = [...filteredTypes];
      }
    }

    const handleZipCodeChange = (newSelection: string) => {
      if (newSelection === 'ALL') {
        selectedZipCodes.value = [participantFilters.zipCodes[0]];
      }
      else {
        const filteredTypes: string[] = JSON.parse(JSON.stringify(selectedZipCodes.value)).filter((type: string) => type !== 'ALL');
        selectedZipCodes.value = [...filteredTypes];
      }
    }

    // Handle Search ZipCodes
    const searchZipCodes = async (searchTerm: string) => {
      try {
        const response = await axios.get('/api/administrator/zip-codes', {
          params: {
            searchTerm: searchTerm,
          },
        });

        participantFilters.zipCodes = [...participantFilters.zipCodes, ...response.data];

      } catch (error) {
        console.error('Error searching zip codes:', error);
      }
    }

    // Handle Submit (Export / Compose Email)
    const handleSubmit = async (type: string) => {
      const formData = {
        CSVFile: csvFile,
        UserTypes: selectedUserTypes.value,
        FundTypes: selectedFundTypes.value,
        HWTypes: selectedHWTypes.value,
        AnnuityTypes: selectedAnnuityTypes.value,
        PensionTypes: selectedPensionTypes.value,
        PensionAdditional: pension,
        HWAdditional: hw,
        ForeignOnly: foreignOnly,
        Action: type
      }

      loadingSubmit.value = true;

      await axios
        .post(route, { ...formData })
        .then((response) => {
          success.value = response.data?.success;
          error.value = response.data?.error;
        })
        .finally(() => {
          loadingSubmit.value = false;

          setTimeout(() => {
            error.value = null
            success.value = null
          }, 2000);
        })
    };

    onMounted(() => {
      fetchData();
    });

    return {
      selectedUserTypes,
      selectedFundTypes,
      selectedHWTypes,
      selectedAnnuityTypes,
      selectedPensionTypes,
      selectedHomeLocals,
      selectedStates,
      selectedMaritalStatuses,
      selectedZipCodes,
      pension,
      hw,
      participantFilters,
      userTypes,
      fundTypes,
      hwTypes,
      annuityTypes,
      pensionTypes,
      pensionAdditional,
      hwAdditional,
      csvFile,
      foreignOnly,
      error,
      success,
      loading,
      isActive,
      isAnnuitySelected,
      isPensionSelected,
      isHWSelected,
      isAllFunds,
      showHWDates,
      showPensionDates,
      searchZipCodes,
      handleUserTypeChange,
      handleFundTypeChange,
      handleAnnuityTypeChange,
      handleHWTypeChange,
      handlePensionTypeChange,
      handleLocalChange,
      handleMaritalStatusChange,
      handleStateChange,
      handleZipCodeChange,
      handleSubmit,
    };
  },
};
</script>