<template>
    <Alerts :success="success" :info="info" :errors="errors" />
    <div v-if="loading"> Loading... </div>
    <div v-else class="plan-content">
        <MultiStep :steps=3 :activeStep="currentStep" />
        <PlanGeneral
            text="NOTE: If you wish to have your claim payment directly deposited into your bank account, please submit a completed MRP claims direct deposit form to claims@iatsenbf.org. It may take up to two weeks for processing of your request as we wait for your bank to verify your banking information. Once we receive verification, we can then start to deposit your claims reimbursement payments directly to your bank account. The form can be found in the Applications/Forms/Documents link on the Fund website. You can also submit the completed direct deposit form online, in the section where you would normally submit an MRP claim. You can also email it to psc@iatsenbf.org." />
        <form id="claim-submission-form" class="form claim-submission" :class="{ 'step-3': currentStep === 3 }"
            method="POST">
            <CoveredCard :links="links" />
            <ClaimStep1 v-if="currentStep === 1" :patients="patients" />
            <ClaimStep2 v-if="currentStep === 2" :uploadedDocuments="uploadedDocuments"
                :loadingUpload="loadingUpload" :submitting="submitting" :deleting="deleting" :notes="notes"
                @update:notes="notes = $event" @handle-upload="handleUpload" @handle-delete="handleDelete" />
            <ClaimStep3 v-if="currentStep === 3" />

            <div class="action-buttons">
                <a href="" class="btn btn-secondary plan-modal" @click.prevent="handleBack"
                    :disabled="loadingUpload || submitting"> Back </a>
                <a href="" class="btn btn-primary plan-modal" @click.prevent="handleNext"
                    :disabled="loadingUpload || submitting">
                    {{ submitting ? 'Submitting' : 'Next' }} </a>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { onMounted, ref, Ref, reactive, defineComponent } from 'vue';
import { useClaimStore } from '@/stores/claim';
import { useModalStore } from '@/stores/modal';
import { useTableFormatter } from '@/composable/useTableFormatter';
import { Person, TableData, RowData } from '@/interfaces/interfaces';
import { Document, Patient } from '@/interfaces/health';
import axios from 'axios';

import Alerts from "@/components/utils/AlertsComponent.vue";
import ClaimStep1 from '@/components/participant/claims/ClaimStep1.vue';
import ClaimStep2 from '@/components/participant/claims/ClaimStep2.vue';
import ClaimStep3 from '@/components/participant/claims/ClaimStep3.vue';
import CoveredCard from '@components/participant/claims/CoveredCard.vue';
import MultiStep from "@components/form/MultiStep.vue";
import PlanGeneral from '@components/cards/PlanGeneral.vue';

export default defineComponent({
    props: {
        modalProps: Object,
    },
    components: {
        Alerts,
        ClaimStep1,
        ClaimStep2,
        ClaimStep3,
        CoveredCard,
        MultiStep,
        PlanGeneral,
    },
    setup(props, { emit }) {
        const loading = ref(true);
        const loadingUpload = ref(false);
        const submitting = ref(false);
        const deleting = ref(false);
        const loadingSave = ref(false);
        const closeForm = ref(false);
        const claimStore = useClaimStore();
        const modalStore = useModalStore();
        const success = ref(null);
        const info = ref(null);
        const errors: Ref<string[] | string | null> = ref(null);
        const person: Ref<Person | null> = ref(null);
        const uploadedDocuments: Ref<Document[]> = ref([]);
        const notes = ref(claimStore.claim?.Notes ?? '');
        const currentStep = ref(1);

        const patients: TableData = reactive({
            headers: [
                { title: 'Select' },
                { title: 'Name' },
                { title: 'DOB' },
                { title: 'Relationship' },
                { title: 'Eligibility Effective Date' },
                { title: 'Eligibility Term Date' },
            ],
            rows: [],
        })


        const fetchData = async (isLoading: boolean) => {
            if (isLoading)
                loading.value = true;

            await axios.get('api/participant/claims-new', { params: { ClaimID: props.modalProps?.ClaimID, Type: props.modalProps?.Type ?? 'add'} })
                .then(response => {
                    person.value = response.data.Person;
                    patients.rows = response.data.PatientsTable;
                    const existingClaim = response.data.ExistingClaim;
                    const defaultPatient = existingClaim ? existingClaim.PersonID : response.data.Person?.PersonID;

                    claimStore.setClaim({
                        ...response.data.ExistingClaim,
                        Type: props.modalProps?.Type ?? 'add',
                    });

                    claimStore.setPatient(claimStore.claim.Patient ?? defaultPatient);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const handleSubmit = async () => {
            submitting.value = true;
            success.value = null;
            errors.value = [];
            claimStore.setClaim({
                ...claimStore.claim,
                Notes: notes.value,
                Patient: claimStore.patient,
                Documents: uploadedDocuments.value.length > 0 ? uploadedDocuments.value : null
            });

            await axios.post('api/participant/claims-new', { ...claimStore.claim })
                .then(response => {
                    success.value = response.data.success;

                    if (success.value) {
                        errors.value = [];
                    }
                    else {
                        errors.value = response.data.error;
                    }
                })
                .catch(error => {
                    const res = error.response.data;

                    if (res.error) {
                        errors.value = res.error;
                    }
                    else {
                        errors.value = Object.values(res.errors).flat() as string[];
                    }
                })
                .finally(() => {
                    submitting.value = false;
                    claimStore.clearClaim();
                    setTimeout(() => errors.value = [], 5000)
                    emit('close');
                });
        };

        const handleUpload = async (files: FileList) => {
            loadingUpload.value = true;

            await axios
                .post('api/participant/claims/add-doc', files, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    success.value = response.data.success;
                    uploadedDocuments.value = [...uploadedDocuments.value, ...response.data.Files];
                })
                .catch(error => {
                    console.error('There was an error uploading document!', error);
                })
                .finally(() => setTimeout(() => {
                    success.value = null;
                }, 3500));

            loadingUpload.value = false;
        };

        const handleDelete = async (fileHash: string) => {
            deleting.value = true;

            await axios.delete(`api/participant/claims/delete-file/${fileHash}`)
                .then(response => {
                    success.value = response.data.success;
                    const filteredDocs = JSON.parse(JSON.stringify(uploadedDocuments.value)).filter((doc: Document) => doc.FileHash !== fileHash);
                    uploadedDocuments.value = filteredDocs;
                })
                .catch(() => {
                    errors.value = 'There was an error deleting document!'
                });

            deleting.value = false;

            setTimeout(() => {
                success.value = null;
                errors.value = [];
            }, 3500)
        }

        const handleNext = () => {
            if (currentStep.value === 3) {
                handleSubmit();
            }
            else if (currentStep.value === 2 && uploadedDocuments.value.length === 0 && !claimStore.claim?.Documents?.length) {
                errors.value = 'Please upload at least one document';

                setTimeout(() => {
                    errors.value = [];
                }, 3500)
            }
            else {
                currentStep.value += 1;
            }
        }

        const handleBack = () => {
            if (currentStep.value === 1) {
                emit('close');
            }
            else {
                currentStep.value -= 1;
            }
        }

        onMounted(async () => await fetchData(true));

        return {
            loading,
            loadingUpload,
            submitting,
            deleting,
            person,
            patients,
            notes,
            success,
            info,
            errors,
            closeForm,
            loadingSave,
            currentStep,
            links: claimStore.claimLinks,
            uploadedDocuments,
            handleNext,
            handleBack,
            handleSubmit,
            handleUpload,
            handleDelete,
            openModal: modalStore.openModal
        };
    }
});
</script>