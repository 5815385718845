<template>
  <div class="container mt-4">
    <div class="form-group row mb-4">
      <div class="row mb-4">
        <div class="col-md-12 text-center">
          <h1> My Personal Profile </h1>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-12">
          <span>
            This page displays information about you on file at the Fund Office. You can update this
            information by clicking on the Update button under the applicable section if any of it has changed.
          </span>
        </div>
      </div>
      <div v-if="loading"> Loading Data ... </div>
      <div v-else class="d-flex flex-column justify-content-center">
          <div class="form-group row mb-4" id="personalInfo">
            <h3 class="mb-4"> My Personal Information </h3>
            <div class="col-md-6 mb-4">
              <span class="col-form-label text-md-right fw-bold"> Participant ID: </span>
              <span> {{ person?.PersonID }}</span>
            </div>
            <div class="col-md-6 mb-4">
              <span class="col-form-label text-md-right fw-bold"> Name: </span>
              <span id="Name"> {{ person?.FullName }}</span>
            </div>
            <div class="col-md-6 mb-4">
              <span class="col-form-label text-md-right fw-bold"> Date of Birth: </span>
              <span id="DateofBirth">{{ person?.DateofBirth }}</span>
            </div>
            <div class="col-md-6 mb-4">
              <span class="col-form-label text-md-right fw-bold"> Gender: </span>
              <span id="Gender"> {{ person?.Sex }}</span>
            </div>
            <div class="col-md-6 mb-4">
              <span class="col-form-label text-md-right fw-bold"> Marital Status: </span>
              <span id="MaritalStatus"> {{ person?.MaritalStatus }}</span>
            </div>
            <div class="col-md-6 mb-4">
              <span class="col-form-label text-md-right fw-bold"> Home Local: </span>
              <span id="HomeLocal"> {{ person?.HomeLocal }}</span>
            </div>
            <div class="form-group row mb-4">
              <div class="col-md-12">
                <router-link :to="{ name: 'personalInformation' }" class="btn btn-primary">
                  {{ ('Update Personal Information') }}
                </router-link>
              </div>
            </div>
          </div>

        <div class="form-group row mb-4">
          <h3 class="mb-4"> My Address </h3>
          <div class="col-md-6 mb-4">
            <span class="col-form-label text-md-right fw-bold"> Address Type: </span>
            <span>{{ address?.Type }} </span>
          </div>
          <div class="col-md-6 mb-4">
            <span class="col-form-label text-md-right fw-bold">Primary: </span>
            <span>{{ address?.Primary ? 'Yes' : 'No' }} </span>
          </div>
          <div class="col-md-6 mb-4">
            <span class="col-form-label text-md-right fw-bold"> Address: </span>
            <span>{{ address?.Address }}</span>
          </div>
          <div class="col-md-6 mb-4">
            <span class="col-form-label text-md-right fw-bold"> City: </span>
            <span>{{ address?.City }}</span>
          </div>
          <div class="col-md-6 mb-4">
            <span class="col-form-label text-md-right fw-bold">State: </span>
            <span>{{ address?.State }}</span>
          </div>
          <div class="col-md-6 mb-4">
            <span class="col-form-label text-md-right fw-bold"> Country: </span>
            <span>{{ address?.Country }}</span>
          </div>
          <div class="col-md-6 mb-4">
            <span class="col-form-label text-md-right fw-bold">Postal Code: </span>
            <span>{{ address?.ZipCode }}</span>
          </div>
          <div class="form-group row mb-4">
            <div class="col-md-12">
              <router-link :to="{ name: 'address' }" class="btn btn-primary">
                {{ ('Update Primary Address') }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="form-group row mb-4">
              <h3 class="mb-4"> My Contact Information </h3>
              <div class="col-md-6 mb-4">
                  <span class="col-form-label text-md-right fw-bold"> Home Phone: </span>
                  <span>{{ contact?.Home }} </span>
              </div>
              <div class="col-md-6 mb-4">
                  <span class="col-form-label text-md-right fw-bold">Mobile Phone: </span>
                  <span> {{ contact?.Mobile }}</span>
              </div>
              <div class="col-md-6 mb-4">
                  <span class="col-form-label text-md-right fw-bold"> Work Phone: </span>
                  <span> {{ contact?.Work }} </span>
              </div>
              <div class="col-md-6 mb-4">
                  <span class="col-form-label text-md-right fw-bold"> E-mail Address: </span>
                  <span> {{ contact?.Emails?.Primary}}</span>
              </div>
              <div class="col-md-6 mb-4">
                  <span class="col-form-label text-md-right fw-bold"> Alternate E-mail: </span>
                   <span>{{ contact?.Emails?.Secondary }}</span>
              </div>
              <div class="form-group row mb-4">
                  <div class="col-md-12">
                    <router-link :to="{ name: 'contactInformation' }" class="btn btn-primary">
                      {{ ('Update Contact Information') }}
                    </router-link>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { ref, onMounted, reactive } from 'vue';
  import { Person, Address, ContactInfo } from '@interfaces/interfaces';
  import { useProfileStore } from '@/stores/profile';
  import axios from 'axios';

  export default {
    setup() {
      const profileStore = useProfileStore();
      const person = reactive({ ...profileStore.personalData });
      const address = ref<Address|null>(null);
      const contact = ref<ContactInfo|null>(null);
      const loading = ref(true);

      // Get Profile Data
      const fetchData = async() => {
        try {
          const response = await axios.get('api/user/profile');
          // person.value = response.data.Person;
          address.value = response.data.AddressInfo;
          contact.value = response.data.ContactInfo;
          loading.value = false;
        } catch (error) {
          console.error('Error fetching profile data:', error);
        }
      }

      onMounted(async () => {
        await fetchData();
      });

      return {
        person,
        address,
        contact,
        loading,
      };
    },
}
</script>
