<template>
    <div class="custom-select links">
        <label v-if="label" class="body-text"> {{ label }} </label>
        <div class="select-selected filter-arrows" @click="toggleSorting">
            <p class="text">{{ name }} ({{ currentType }})</p>
            <div class="arrows">
                <i class="iatse-icon-select-arrow"></i>
                <i class="iatse-icon-select-arrow"></i>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'ASC',
        },
    },
    setup(props, { emit }) {
        const sort = ref(props.type);

        const toggleSorting = () => {
            sort.value = sort.value === 'ASC' ? 'DESC' : 'ASC';
            emit('update:type', sort.value);
        };

        const currentType = computed(() => sort.value);

        watch(() => props.type, (newType) => {
            sort.value = newType;
        });

        return {
            toggleSorting,
            currentType,
        };
    },
});
</script>