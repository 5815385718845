<template>
    <div class="container">
        <div class="d-flex align-items-center gap-4">
            <h5 class="text-center mt-4 mb-4"> Contact Form Topics </h5>
        </div>
        <div v-if="loading">Loading...</div>
        <div v-else>
            <div v-if="success" class="alert alert-success">
                {{ success }}
            </div>
            <table v-if="topics.length > 0" class="table table-striped mb-4" id="contact_table">
                <thead class="table-dark">
                    <tr>
                        <th> Topic </th>
                        <th> Role </th>
                        <th> Email </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="topic in topics" :key="topic.UIContactTopicID">
                        <td> {{ topic.Topic }} </td>
                        <td> {{ topic.RoleID }} </td>
                        <td> {{ topic.Email }} </td>
                        <td class="text-center">
                            <button class="btn btn-danger btn-sm"
                                @click="handleDelete(topic.UIContactTopicID)">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div v-if="errors.length > 0" class="alert alert-danger">
                <ul>
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>
            </div>

            <form class="container border p-4" @submit.prevent="handleAddContact">
                <h5 class="text-center mt-4 mb-4"> Create New Contact Topic</h5>
                <div class="form-group row mb-4 d-flex flex-column align-items-center gap-4">
                    <div class="d-flex justify-content-between gap-4">
                        <label for="topic" class="col-form-label w-25 w-sm-auto text-md-right"> Topic: </label>
                        <input type="text" id="topic" v-model="newTopic.Topic" class="form-control">
                    </div>
                    <div class="d-flex justify-content-between gap-4">
                        <label for="role" class="col-form-label w-25 w-sm-auto text-md-right"> Role: </label>
                        <select id="role" class="form-control" v-model="newTopic.Role">
                            <option v-for="role in roles" :key="role.RoleID" :value="role.RoleID">{{ role.RoleName }}
                            </option>
                        </select>
                    </div>
                    <div class="d-flex justify-content-between gap-4">
                        <label for="email" class="col-form-label w-25 w-sm-auto text-md-right"> Email: </label>
                        <input type="text" id="email" v-model="newTopic.Email" class="form-control">
                    </div>
                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-secondary"> {{ loadingSubmit ? 'Submitting...' : 'Create' }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script lang="ts">
import axios from 'axios';
import { onMounted, reactive, ref, Ref } from 'vue';

interface ContactTopic {
    UIContactTopicID: number;
    Topic: string;
    RoleID: number | string;
    Email: string;
}

interface Role {
    RoleID: number | string;
    RoleName: string;
}

export default {
    setup() {
        const topics: Ref<ContactTopic[] | []> = ref([]);
        const roles: Ref<Role[] | []> = ref([]);
        const newTopic = reactive({ Topic: '', Role: '1', Email: '' })
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const success = ref(null);
        const errors: Ref<string[]> = ref([]);

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/administrator/contact-form')
                .then(response => {
                    topics.value = response.data.Topics;
                    roles.value = response.data.Roles;
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })
                .finally(() => loading.value = false)
        };

        const handleDelete = async (topicId: number) => {
            try {
                const response = await axios.get(`api/administrator/contact-form/delete/${topicId}`);
                success.value = response.data.success;
                topics.value = topics.value.filter(topic => topic.UIContactTopicID !== topicId);
                clearMessages();
            } catch (error) {
                console.error('Error deleting topic:', error);
            }
        }

        // Handle Add Contact Topic
        const handleAddContact = async () => {
            loadingSubmit.value = true;

            await axios
                .post("api/administrator/contact-form", { ...newTopic, _method: "PATCH" })
                .then((response) => {
                    success.value = response.data?.success;
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }
                })
                .finally(async () => {
                    await fetchData();
                    loadingSubmit.value = false
                    clearMessages();
                })
        }

        const clearMessages = () => {
            setTimeout(() => {
                success.value = null;
                errors.value = [];
            }, 3000)

        }

        onMounted(async () => {
            await fetchData();
        });

        return { topics, roles, loading, loadingSubmit, success, errors, newTopic, handleAddContact, handleDelete }
    }
}
</script>