<template>
    <div id="employerRegister" class="d-flex flex-column gap-2">
        <div class="form-group d-flex flex-column gap-2">
            <label for="tax_id">EIN/ TAXID <span class="text-danger">*</span></label>
            <input v-model="employer.TaxID" type="text" class="form-control required" id="tax_id" name="Employer[TaxID]">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="email_employer">Email <span class="text-danger">*</span></label>
            <input v-model="employer.Email" type="email" class="form-control required" id="email_employer"
                name="Employer[Email]">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="pin">PIN <span class="text-danger">*</span></label>
            <input v-model="employer.PIN" type="text" class="form-control required" id="pin" name="Employer[PIN]"
                maxlength="6">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="first_name_employer">First Name <span class="text-danger">*</span></label>
            <input v-model="employer.FirstName" type="text" class="form-control required" id="first_name_employer"
                name="Employer[FirstName]">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="middle_name">Middle Name </label>
            <input v-model="employer.MiddleName" type="text" class="form-control" id="middle_name"
                name="Employer[MiddleName]">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="last_name_employer">Last Name <span class="text-danger">*</span></label>
            <input v-model="employer.LastName" type="text" class="form-control required" id="last_name_employer"
                name="Employer[LastName]">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="employer_password">Password <span class="text-danger">*</span></label>
            <input v-model="employer.Password" type="password" class="form-control required" id="employer_password"
                name="Employer[Password]">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="employer_password_confirmation">Confirm Password <span class="text-danger">*</span></label>
            <input v-model="employer.Password_confirmation" type="password" class="form-control required"
                id="employer_password_confirmation" name="Employer[Password_confirmation]">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="security_question_1">Security Question 1</label>
            <select v-model="employer.SecurityQuestion1.Question" class="form-control" id="security_question_1"
                @change="() => handleQuestionSelect('1')">
                <option value="0">Security Questions</option>
                <option v-for="question in availableQuestionsForSelect('1').value" :key="question.SecurityQuestionID"
                    :value="question.SecurityQuestionID">
                    {{ question.Question }}
                </option>
            </select>
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label :for="'answer_1'">Answer 1</label>
            <input v-model="employer.SecurityQuestion1.Answer" type="text" class="form-control" id="answer_1" />
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="security_question_2">Security Question 2</label>
            <select v-model="employer.SecurityQuestion2.Question" class="form-control" id="security_question_2"
                @change="() => handleQuestionSelect('2')">
                <option value="0">Security Questions</option>
                <option v-for="question in availableQuestionsForSelect('2').value" :key="question.SecurityQuestionID"
                    :value="question.SecurityQuestionID">
                    {{ question.Question }}
                </option>
            </select>
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label :for="'answer_2'">Answer 2</label>
            <input v-model="employer.SecurityQuestion2.Answer" type="text" class="form-control" id="answer_2" />
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="security_question_3">Security Question 3</label>
            <select v-model="employer.SecurityQuestion3.Question" class="form-control" id="security_question_3"
                @change="() => handleQuestionSelect('3')">
                <option value="0">Security Questions</option>
                <option v-for="question in availableQuestionsForSelect('3').value" :key="question.SecurityQuestionID"
                    :value="question.SecurityQuestionID">
                    {{ question.Question }}
                </option>
            </select>
        </div>
        <div class="form-group d-flex flex-column gap-3">
            <label :for="'answer_3'">Answer 3</label>
            <input v-model="employer.SecurityQuestion3.Answer" type="text" class="form-control" id="answer_3" />
        </div>
    </div>

    <div class="form-group row mb-0">
        <div class="col-md-12 d-flex justify-content-center">
            <button type="button" class="btn btn-primary" @click="handleRegister">{{ loadingSubmit ? 'Submitting...' :
                'Register' }}</button>
        </div>
    </div>
</template>

<script lang="ts">
import { SecurityQuestion } from '@/interfaces/interfaces';
import { PendingEmployer } from '@/interfaces/admin';
import { reactive, PropType, defineComponent, ref, Ref, computed } from 'vue';

interface Employer {
    TaxID: string;
    Email: string;
    PIN: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Password: string;
    Password_confirmation: string;
    SecurityQuestion1: { Question: number, Answer: string };
    SecurityQuestion2: { Question: number, Answer: string };
    SecurityQuestion3: { Question: number, Answer: string };
}

export default defineComponent({
    props: {
        securityQuestions: {
            type: Array as PropType<SecurityQuestion[]>,
            required: true
        },
        employerRequest: Object as PropType<PendingEmployer | null>,
        loadingSubmit: Boolean
    },
    setup(props, { emit }: { emit: (event: string, ...args: any[]) => void }) {
        const employer: Employer = reactive({
            TaxID: props.employerRequest?.TaxID ?? '',
            Email: props.employerRequest?.Email ?? '',
            PIN: props.employerRequest?.Pin ?? '',
            FirstName: props.employerRequest?.FirstName ?? '',
            MiddleName: props.employerRequest?.MiddleName ?? '',
            LastName: props.employerRequest?.LastName ?? '',
            Password: '',
            Password_confirmation: '',
            SecurityQuestion1: { Question: 0, Answer: '' },
            SecurityQuestion2: { Question: 0, Answer: '' },
            SecurityQuestion3: { Question: 0, Answer: '' },
        });

        const selectedQuestions: Ref<{ [key: string]: number }> = ref({
            1: 0,
            2: 0,
            3: 0,
        });

        const availableQuestionsForSelect = (selectKey: string) => computed(() => {
            return props.securityQuestions?.filter(q => {
                return !Object.entries(selectedQuestions.value)
                    .filter(([key, value]) => key !== selectKey && value !== 0)
                    .map(([, value]) => value)
                    .includes(q.SecurityQuestionID as number);
            });
        })

        // Method to handle dropdown change and update selected questions
        const handleQuestionSelect = (index: string) => {
            const questionKey = `SecurityQuestion${index}` as keyof Employer;
            const securityQuestion = employer[questionKey] as { Question: number, Answer: string };
            selectedQuestions.value[index] = securityQuestion.Question;
        };

        const handleRegister = () => {
            const { SecurityQuestion1, SecurityQuestion2, SecurityQuestion3, ...restOfEmployer } = employer;

            const SecurityQuestions = [
                SecurityQuestion1,
                SecurityQuestion2,
                SecurityQuestion3,
            ].map(question => ({ Question: question.Question, Answer: question.Answer }));

            emit('register', { ...restOfEmployer, SecurityQuestions });
        }

        return {
            employer,
            handleRegister,
            availableQuestionsForSelect,
            handleQuestionSelect,
        };
    },
});
</script>