<template>
    <p>
        You must read and digitally sign the certification below in order to receive employee-related health
        coverage information. Please Note: You will have to digitally sign this certification every time you
        request
        health coverage information.
    </p>
    <h5>Click <a :href="instructions" target="_blank">here</a> to read the instructions for obtaining your employees' health
        coverage
        information.</h5>
    <h6>STEP 1 - CERTIFICATION </h6>
    <div class="card">
        <div class="card-body d-flex flex-column gap-4">
            <span>By digitally signing, the undersigned hereby certifies that:</span>
            <span>
                (i) the company submitting this request is an "applicable large employer" (ALE) or ALE Member
                (within
                the meaning of the Affordable Care Act (ACA)); and
            </span>
            <span>
                (ii) the employees for whom the company is requesting health coverage information from the
                I.A.T.S.E.
                National Health & Welfare Fund (the Fund) were “full-time employees” of the company (within the
                meaning
                of the ACA) for at least one month of calendar year 2023; and
            </span>
            <span>
                (iii) the company made contributions to the Fund for such employees during calendar year 2023
                pursuant
                to a collective bargaining or participation agreement; and
            </span>
            <span>
                (iv) the company is submitting this request for information from the Fund because the
                information is
                necessary for the company to comply with the employer reporting requirements imposed by Section
                6056
                of
                the Internal Revenue Code, and the company agrees that it will use the information provided by
                the
                Fund
                solely for that purpose; and
            </span>
            <span>
                (v) the undersigned understands and acknowledges that the company is solely responsible for
                making
                determinations as to its status as an ALE and any employee’s status as a full-time employee for
                whom
                the
                company contributed to the Fund, and the Fund does not verify social security numbers or any
                other
                information for purposes of confirming the accuracy of such determinations by the company.
            </span>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-between gap-4">
        <h6>Click here to digitally sign if you agree to the terms and conditions presented above</h6>
        <button type="button" class="btn btn-secondary col-md-1" @click="handleNext">Sign</button>
        <div class="col-md-2 d-flex align-items-center gap-4">
            <label for="date">Date</label>
            <input type="date" :value="new Date().toISOString().split('T')[0]" class="form-control" disabled />
        </div>
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

export default defineComponent({
    props: {
        instructions: String
    },
    setup(_, { emit }) {
        const handleNext = () => {
            emit('handle-next');
        }

        return { handleNext }
    }
})
</script>