<template>
    <div>
        <h4 class="mb-4 text-center">Create New Local Representative Account</h4>
        <h6 class="mt-4">Search Locals</h6>
        <form method="GET">
            <div class="form-group row mb-4">
                <div class="col-md-2">
                    <label for="org_name" class="col-form-label text-md-right">Local: </label>
                    <input type="text" id="org_name" v-model="filters.OrgName" class="form-control">
                </div>
                <div class="col-md-10 d-flex justify-content-between align-items-end">
                    <button type="button" class="btn btn-success" @click="submitFilters(0)">
                        {{ searching ? 'Searching...' : 'Search' }}
                    </button>
                    <div>
                        <button type="button" class="btn btn-info" @click="submitFilters(1)"> All Locals </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { SearchLocalsFormFilters } from '@/interfaces/admin';

export default defineComponent({
    props: {
        searching: Boolean
    },
    setup(_, { emit }) {
        const filters: SearchLocalsFormFilters = reactive({
            OrgName: '',
        });

        const submitFilters = (type: number) => {
            if (type === 1) {
                filters.OrgName = '';
            }

            emit('update-filters', filters);
        }

        return {
            filters,
            submitFilters
        };
    }
});
</script>