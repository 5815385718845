<template>
    <div class="d-flex flex-column gap-2 mt-4">
        <LocalAuth v-if="registerType === 'auth'" :states="states" :loadingSubmit="loadingSubmit"
            @register="handleRegister" :authRequest="authRequest" :success="success" />
        <LocalRegistration v-else :securityQuestions="securityQuestions" :loadingSubmit="loadingSubmit"
            :localRequest="localRequest" @register="handleRegister" />
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { SecurityQuestion, Country, LocalRegister, ILocalAuth } from '@/interfaces/interfaces';
import { PendingLocal } from '@/interfaces/admin';
import LocalAuth from './LocalAuth.vue';
import LocalRegistration from './LocalRegistration.vue';

export default defineComponent({
    components: {
        LocalAuth,
        LocalRegistration,
    },
    props: {
        securityQuestions: {
            type: Array as PropType<SecurityQuestion[]>,
            required: true
        },
        states: Array as PropType<Country[]>,
        registerType: String,
        loadingSubmit: Boolean,
        localRequest: Object as PropType<PendingLocal | null>,
        authRequest: Object as PropType<ILocalAuth | null>,
        success: String as PropType<string | null>
    },
    setup(props, { emit }) {
        const handleRegister = (local: LocalRegister | ILocalAuth) => {
            emit('register', local, props.registerType);
        }

        return {
            handleRegister
        };
    }
});
</script>
