<template>
    <!-- Documents -->
    <div class="table-wrapper">
        <h4> Claim Documents </h4>
        <div class="documents-wrapper">
            <div class="documents-items" v-if="claim?.Documents?.length > 0">
                <div class="documents-item" v-for="(doc, index) in  claim?.Documents" :key="index">
                    <h5> <a :href="doc.DocumentID" target="_blank"> {{ doc.Description }} </a></h5>
                    <img src="../../../assets/images/doc.png" alt="doc image" width="95" height="55" />
                </div>

                <!-- <div class="documents-item" v-for="(doc, index) in uploadedDocuments" :key="index">
                    <h5> <a :href="doc.FilePath" target="_blank"> {{ doc.FileName }} </a></h5>
                    <img src="../../../assets/images/doc.png" alt="doc image" width="95" height="55" />
                    <i class="iatse-icon-trash" @click="handleDelete(doc.FileHash)"></i>
                </div> -->
            </div>
            <DragDrop :reset="reset" @change="handleUpload" @delete="handleDelete" />
        </div>
    </div>

    <Textarea label="Comments/Notes" v-model:modelValue="localNotes" :max=500 />
</template>

<script lang="ts">
import { watch, ref, defineComponent, PropType } from 'vue';
import { Document } from '@/interfaces/health';
import Textarea from '@/components/form/TextareaComponent.vue';
import DragDrop from '@components/form/DragDrop.vue';

export default defineComponent({
    props: {
        uploadedDocuments: {
            type: Array as PropType<Document[] | []>,
            required: true
        },
        notes: Object,
        loadingUpload: Boolean,
        deleting: Boolean,
        submitting: Boolean,
    },
    components: {
        Textarea,
        DragDrop,
    },
    setup(props, { emit }) {
        const localNotes = ref(props.notes);

        const handleUpload = async (event: Event) => {
            const files = (event.target as HTMLInputElement)?.files || (event as DragEvent)?.dataTransfer?.files;
            console.log(files, '@file uplaoded')

            if (files) {
                emit('handle-upload', files);
            }
        }

        const handleDelete = async (fileHash: string) => {
            emit('handle-delete', fileHash);
        }

        watch(localNotes, (newValue) => {
            emit('update:notes', newValue);
        });

        return {
            localNotes,
            handleUpload,
            handleDelete
        }
    }
})
</script>
