<template>
    <tr>
        <td>{{ PWHD?.PeriodBegin }} - {{ PWHD?.PeriodEnd }}</td>
        <td>{{ PWHD.TranProcDate }}</td>
        <td>{{ PWHD.EmployerName }}</td>
        <td>{{ PWHD.ProductionName }}</td>
        <td>{{ PWHD.Plan }}</td>
        <td>{{ PWHD.DaysWorked }}</td>
        <td>{{ PWHD.Amount }}</td>
        <td>{{ PWHD.FirstOrgName }}</td>
    </tr>
</template>

<script lang="ts">
import { PWHD } from '@/interfaces/interfaces';
import { PropType } from 'vue';

export default {
    props: {
        PWHD: {
            type: Object as PropType<PWHD>,
            required: true,
        }
    },
};
</script>