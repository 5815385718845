<template>
    <div v-if="loading" class="container mt-4">Loading...</div>
    <div v-else class="container  mt-4">
        <div class="d-flex flex-column align-items-start gap-4 mb-4 w-100">
            <h3 class="card-title"> {{ type }} Profile </h3>
            <p>
                This page displays information about your company data on file with the Fund Office.
                <span v-if="type === 'Local'"> If any of this information needs to be changed or is incorrect, please
                    click
                    <router-link :to="{ name: 'contact' }">
                        here
                    </router-link>
                    to email the Fund office.
                </span>
            </p>
            <div v-if="type === 'Local'" class="d-flex gap-4 w-100">
                <span class="fw-bold col-md-4">{{ type }}:</span>
                <span>{{ localName }}</span>
            </div>
            <div v-for="(contact, key) in contacts" :key="key"
                class="d-flex flex-column align-items-start justify-content-between gap-2 w-100">
                <div class="d-flex gap-4 w-100">
                    <span class="fw-bold col-md-4"> <span v-if="type === 'Local'">{{ type }}</span> Name:</span>
                    <span>{{ contact?.OrgName }}</span>
                </div>
                <div class="d-flex gap-4 w-100">
                    <span class="fw-bold col-md-4">Primary Address:</span>
                    <p v-html="contact.Address"></p>
                </div>
                <div class="d-flex gap-4 w-100">
                    <span class="fw-bold col-md-4">Email Address:</span>
                    <div v-if="contact.Emails">
                        <div v-for="(email, key) in contact.Emails" :key="key">
                            {{ email }}
                        </div>
                    </div>
                    <span v-else> No Records </span>
                </div>
                <div class="d-flex gap-4 w-100">
                    <span class="fw-bold col-md-4">Phone #:</span>
                    <div v-if="contact.Phones">
                        <div v-for="(phone, key) in contact.Phones" :key="key">
                            {{ phone }}
                        </div>
                    </div>
                    <span v-else> No Records </span>
                </div>
            </div>

        </div>
        <div class="d-flex flex-column gap-4 mb-4 w-100">
            <h4 class="card-title">Contact Personnel (s)</h4>
            <table class="table table-striped mb-4">
                <thead class="table-dark">
                    <tr class="text-center">
                        <th> Name </th>
                        <th> Designation </th>
                        <th> At Address </th>
                        <th> Phone </th>
                        <th> Start Date </th>
                        <th> End Date </th>
                    </tr>
                </thead>
                <tbody v-if="personnels.length > 0">
                    <tr v-for="personnel in personnels" :key="personnel.PersonID" class="align-middle text-center">
                        <td>{{ personnel.Name }}</td>
                        <td>{{ personnel.Designation }}</td>
                        <td v-html="personnel.AtAddress"></td>
                        <td>
                            <div v-for="(phone, key) in personnel.Phones" :key="key">
                                {{ key }}# {{ phone }}
                            </div>
                        </td>
                        <td>{{ personnel.StartDate }}</td>
                        <td>{{ personnel.EndDate }}</td>
                    </tr>
                </tbody>
                <tbody v-else class="text-center">
                    <tr class="align-middle text-center">
                        <td colspan="6"> No Records</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { Personnel } from '@/interfaces/employer';
import { Ref, ref, onMounted } from 'vue';
import axios from 'axios';
import { useAuthStore } from '../../../stores/auth';

interface Contact {
    OrgName: string,
    Address: string,
    Emails: string[],
    Phones: string[],
}

export default {
    setup() {
        const isPayor = ref(null);
        const localName = ref(null);
        const contacts: Ref<Contact[] | null> = ref(null);
        const personnels: Ref<Personnel[]> = ref([]);
        const loading = ref(true);
        const type = ref(useAuthStore().isLocal ? 'Local' : 'Employer');

        const fetchData = async () => {
            loading.value = true;

            if (type.value === 'Local') {
                await axios.get('api/local/profile')
                    .then(response => {
                        localName.value = response.data.LocalName;
                        contacts.value = response.data.Contacts;
                        personnels.value = response.data.Personnels;
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    })
            }
            else {
                await axios.get('api/employer/profile')
                    .then(response => {
                        isPayor.value = response.data.IsPayor;
                        contacts.value = response.data.Contacts;
                        personnels.value = response.data.Personnels;
                        type.value = isPayor.value ? 'Payroll' : type.value
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    })
            }

            loading.value = false;
        };

        onMounted(async () => await fetchData());

        return {
            type,
            localName,
            isPayor,
            contacts,
            personnels,
            loading
        };
    }
}
</script>