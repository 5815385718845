<template>
    <div class="container mt-4">
        <div class="row">
            <div class="col-md-12 text-center mb-4">
                <h1>{{ userName }} Roster</h1>
            </div>
            <p>
                The Fund Office records indicate these members are part of your home Local. If any of this information
                appears to be incorrect please contact the Fund Office or email us at PSC@iatsenbf.org.
                Due to technical limitations beyond our control, the member roster can only print the contents of the
                current page. For locals with large rosters, please print each webpage separately. Coming soon will be a
                download feature which will allow for exporting entire rosters in csv format. :
            </p>

            <div v-if="loading">Loading Data...</div>
            <div v-else>
                <div class="mb-4">
                    <button class="btn btn-primary" @click="printList">Print List</button>
                </div>
                <table class="table table-bordered table-striped" id="table-roster">
                    <thead class="table-dark">
                        <tr class="text-center align-middle">
                            <th>NBF Participant ID</th>
                            <th>Last Name</th>
                            <th>Middle Name</th>
                            <th>First Name</th>
                            <th>Suffix</th>
                            <th>Date of Birth</th>
                            <th>Gender</th>
                            <th>Marital Status</th>
                        </tr>
                    </thead>
                    <tbody v-if="data.length > 0 && !loading">
                        <tr v-for="person in data" :key="person.PersonID" class="text-center">
                            <td>
                                <router-link
                                    :to="{ name: 'local.member-profile', params: { PersonID: person.PersonID } }">
                                    {{ person.PersonID }}
                                </router-link>
                            </td>
                            <td>{{ person.LastName }}</td>
                            <td>{{ person.MiddleName }}</td>
                            <td>{{ person.FirstName }}</td>
                            <td>{{ person.NameSuffix }}</td>
                            <td>{{ person.DateofBirth }}</td>
                            <td>{{ person.Gender }}</td>
                            <td>{{ person.MaritalStatus }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr class="text-center">
                            <td colspan="8">
                                There is no data
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div id="print"></div>
                <PaginationComponent :paginator="paginator" @fetch-data="fetchPaginationData" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Person } from '@/interfaces/local';
import { Paginator } from "@/interfaces/admin";
import { onMounted, ref, reactive, Ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { usePrint } from '@/composable/usePrint';
import PaginationComponent from '@components/utils/PaginationComponent.vue';
import axios from 'axios';

export default {
    components: {
        PaginationComponent
    },

    setup() {
        const loading = ref(true);
        const data: Ref<Person[] | []> = ref([]);
        const route = "api/local/roster";
        const userName = useAuthStore().userName;
        const paginator: Paginator = reactive({
            per_page: 0,
            total: 0,
            last_page: 0,
            current_page: 0,
        });
        const page = ref(1);
        const table = ref(null);
        const { printElementById } = usePrint();

        const printList = printElementById('table-roster', 'Local Roster');

        const fetchData = async (isLoading: boolean) => {
            if (isLoading)
                loading.value = true;

            await axios.get(route, { params: { page: page.value } })
                .then(response => {
                    data.value = Object.values(response.data.Persons);
                    Object.assign(paginator, response.data.Paginator);
                    page.value = paginator.current_page;
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })

            loading.value = false;
        };

        // Fetch Data For Selected Page
        const fetchPaginationData = (pageNumber: number) => {
            page.value = pageNumber;
            fetchData(false);
        };

        onMounted(async () => await fetchData(true));

        return {
            loading,
            paginator,
            data,
            userName,
            fetchPaginationData,
            printList,
        };
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
}
</style>
