<template>
    <div class="container mt-4">
        <div class="position-fixed top-0 end-0 p-3" style="z-index: 11">
            <div class="alert alert-success" v-show="success">{{ success }}</div>
            <div v-if="errors.length > 0" class="alert alert-danger">
                <ul>
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center mb-4">
                <h1>Request PIN for Local Representative </h1>
            </div>
            <p>
                Complete information requested below for each Local Representative for whom you need a PIN created. The
                Email Address should be unique for each representative. Once you have filled in all the required
                information please click the 'Submit' button. This will generate a PIN for the Representative and they
                will receive the PIN and instructions to register via e-mail.
                Please note: You can request PIN for multiple representative at a time by clicking on 'Add Another
                Representative' button and adding as many representatives as you need before clicking the 'Submit'
                button.
            </p>
            <div v-for="(row, index) in rows" :key="index" class="d-flex gap-2 mb-5 align-items-end">
                <div>
                    <label for="first_name" class="col-form-label text-md-right">First Name<span
                            class="text-danger">*</span></label>
                    <input type="text" id="first_name" class="form-control" maxlength="25" v-model="row.FirstName"
                        required />
                </div>
                <div>
                    <label for="middle_name" class="col-form-label text-md-right">Middle Name</label>
                    <input type="text" id="middle_name" class="form-control" maxlength="12" v-model="row.MiddleName"
                        required />
                </div>
                <div>
                    <label for="last_name" class="col-form-label text-md-right">Last Name<span
                            class="text-danger">*</span></label>
                    <input type="text" id="last_name" class="form-control" maxlength="25" v-model="row.LastName"
                        required />
                </div>
                <div>
                    <label for="designation" class="col-form-label text-md-right">Designation</label>
                    <input type="text" id="designation" class="form-control" maxlength="100"
                        v-model="row.Designation" />
                </div>
                <div>
                    <label for="phone" class="col-form-label text-md-right">Phone<span
                            class="text-danger">*</span></label>
                    <input type="text" id="phone" class="form-control" v-model="row.Phone" @keypress="validateNumber" />
                </div>
                <div>
                    <label for="email" class="col-form-label text-md-right">Email<span
                            class="text-danger">*</span></label>
                    <input type="email" id="email" class="form-control" v-model="row.Email" required />
                </div>
                <div>
                    <button class="btn btn-danger" @click="deleteRepresentative(index)">Delete</button>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column gap-4">
            <button class="btn btn-secondary w-25" @click="addRepresentative">Add Another Representative</button>
            <div class="d-flex justify-content-between">
                <router-link :to="{ name: 'local.representative' }" class="btn btn-secondary">Back</router-link>
                <button class="btn btn-success col-md-2" @click="handleSubmit">
                    {{ loadingSubmit ? 'Submitting...' : 'Submit' }}
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Member } from '@/interfaces/local';
import { ref, Ref } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

export default {
    setup() {
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const row = { FirstName: '', MiddleName: '', LastName: '', Designation: '', Phone: '', Email: '' };
        const errors: Ref<string[]> = ref([]);
        const success = ref(null);
        const router = useRouter();
        const rows: Ref<Member[]> = ref([
            { ...row }
        ]);

        const addRepresentative = () => {
            rows.value.push(row);
        }

        const deleteRepresentative = (index: number) => {
            rows.value.splice(index, 1);

        }

        const validateNumber = (event: KeyboardEvent) => {
            const charCode = event.charCode;

            if (charCode < 48 || charCode > 57) {
                event.preventDefault();
            }
        }

        const handleSubmit = async () => {
            loadingSubmit.value = true;
            const formData = {
                Representatives: rows.value
            }

            await axios
                .post('api/local/representative-accounts', { ...formData })
                .then((response) => {
                    success.value = response.data?.success;
                    clearMessages();
                    router.push({ name: 'local.representative' });
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }

                    clearMessages();
                })
        }

        const clearMessages = () => {
            loadingSubmit.value = false;

            setTimeout(() => {
                success.value = null;
                errors.value = [];
            }, 3500);
        }

        return {
            loading,
            loadingSubmit,
            rows,
            success,
            errors,
            validateNumber,
            addRepresentative,
            deleteRepresentative,
            handleSubmit
        };
    }
}
</script>