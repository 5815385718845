<template>
  <div class="container">
    <h3 class="text-center mb-5 mt-4">Employers</h3>
    <div class="position-fixed top-0 end-0 p-3" style="z-index: 11">
      <div class="alert alert-success" v-show="success">{{ success }}</div>
      <div v-if="errors.length > 0" class="alert alert-danger">
        <ul>
          <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
        </ul>
      </div>
    </div>
    <div class="row" v-if="loading">Loading...</div>
    <div v-else>
      <EmployerRequests :employers="pendingEmployers" :paginator="pendingPaginator" :processed="filters.Processed"
        @filter-requests="filterRequests" @fetch-paginator-data="fetchPendingPaginationData" @send-pin="sendPin"
        @reject-request="rejectRequest" />
      <SearchEmployersFilters :filters="filters" @update-filters="updateFilters" :searching="searching" />
      <SearchEmployersList :employers="employers" :paginator="paginator" @reset-pin="resetPin"
        @activate="activateAccount" @deactivate="deactivateAccount" @fetch-paginator-data="fetchPaginationData" />
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, ref, Ref, onMounted, watch } from "vue";
import {
  SearchEmployer,
  SearchEmployersFormFilters,
  PendingEmployer,
  Paginator,
} from "@/interfaces/admin";
import SearchEmployersList from "@/components/superadmin/SearchEmployersList.vue";
import SearchEmployersFilters from "@/components/superadmin/SearchEmployersFilters.vue";
import EmployerRequests from "@/components/superadmin/EmployerRequests.vue";
import axios from "axios";

export default {
  components: {
    SearchEmployersList,
    SearchEmployersFilters,
    EmployerRequests,
  },
  setup() {
    const loading = ref(true);
    const searching = ref(false);
    const employers = ref<SearchEmployer[]>([]);
    const pendingEmployers = ref<PendingEmployer[]>([]);
    const paginator: Paginator = reactive({
      per_page: 0,
      total: 0,
      last_page: 0,
      current_page: 0,
    });
    const pendingPaginator: Paginator = reactive({
      per_page: 0,
      total: 0,
      last_page: 0,
      current_page: 0,
    });
    const page = ref(0);
    const pendingPage = ref(0);
    const route = "api/administrator/users/employers";
    const success = ref(null);
    const errors: Ref<string[]> = ref([]);
    const filters = reactive<SearchEmployersFormFilters>({
      TaxID: null,
      CompanyName: "",
      Processed: 0,
    });

    const fetchData = async (isLoading: boolean) => {
      try {
        if (isLoading) loading.value = true;

        const response = await axios.get(route, {
          params: { ...filters, employers: page.value, pendingEmployers: pendingPage.value },
        });

        employers.value = response.data.Employers;
        pendingEmployers.value = response.data.PendingEmployers;

        Object.assign(paginator, response.data.Paginator);
        Object.assign(pendingPaginator, response.data.PendingPaginator);
      }
      catch (error) {
        console.error("Error:", error);
      }

      if (isLoading) loading.value = false;
    };

    // Handle Send Pin
    const sendPin = async (requestID: number) => {
      const index = pendingEmployers.value.findIndex(req => req.UIEmployerRequestID === requestID);

      try {
        pendingEmployers.value[index].SendingPin = true;
        const response = await axios.post(
          `${route}/send-pin/${requestID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;
      }
      catch (error: any) {
        errors.value = Object.values(error.response.data.error).flat() as string[];
      }

      await refreshData();
      pendingEmployers.value[index].SendingPin = false;
    };

    // Handle Reset Pin
    const resetPin = async (userID: number) => {
      const index = employers.value.findIndex(employer => employer.UserID === userID);

      try {
        employers.value[index].ResettingPin = true;
        const response = await axios.post(
          `api/administrator/users/reset-pin/${userID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;

        if (response.data.error) {
          errors.value = Object.values(
            response.data.error
          ).flat() as string[];
        }
      }
      catch (error: any) {
        errors.value = Object.values(error.response.data.error).flat() as string[];
      }

      employers.value[index].ResettingPin = false;
      await refreshData();
    };

    // Handle Activate Account
    const activateAccount = async (userID: number) => {
      const index = employers.value.findIndex(employer => employer.UserID === userID);
      employers.value[index].Activating = true;

      try {
        const response = await axios.post(
          `api/administrator/users/activate/${userID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;
      }
      catch (error) {
        console.error("Error activating account:", error);
      }

      await refreshData();
      employers.value[index].Activating = false;
    };

    // Handle Deactivate Account
    const deactivateAccount = async (userID: number) => {
      const index = employers.value.findIndex(employer => employer.UserID === userID);
      employers.value[index].Deactivating = true;

      try {
        const response = await axios.post(
          `api/administrator/users/deactivate/${userID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;
      }
      catch (error) {
        console.error("Error deactivating account:", error);
      }

      await refreshData();
      employers.value[index].Deactivating = false;
    };

    // Handle Reject Request
    const rejectRequest = async (requestID: number) => {
      try {
        const index = pendingEmployers.value.findIndex(req => req.UIEmployerRequestID === requestID);
        pendingEmployers.value[index].Rejecting = true;
        const response = await axios.post(
          `${route}/reject/${requestID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;
        await refreshData();
        pendingEmployers.value[index].Rejecting = false;
      }
      catch (error) {
        console.error("Error rejecting request:", error);
      }
    };

    const refreshData = async () => {
      await fetchData(false);
      setTimeout(() => {
        success.value = null;
        errors.value = [];
      }, 3500);
    };

    // Filter Employers
    const updateFilters = async (
      newFilters: SearchEmployersFormFilters
    ) => {
      page.value = 1;
      Object.assign(filters, newFilters);
    };

    // Filter Employer Requests
    const filterRequests = async (processed: number) => {
      pendingPage.value = 1;
      filters.Processed = processed;
    };

    // Fetch Data For Selected Page
    const fetchPaginationData = (pageNumber: number) => {
      page.value = pageNumber;
      fetchData(false);
    };

    // Fetch Data For Selected Page
    const fetchPendingPaginationData = (pageNumber: number) => {
      pendingPage.value = pageNumber;
      fetchData(false);
    };

    watch(filters, async () => {
      searching.value = true;
      await fetchData(false);
      searching.value = false;
    }, { deep: true });

    onMounted(async () => await fetchData(true));

    return {
      filters,
      employers,
      pendingEmployers,
      loading,
      searching,
      paginator,
      pendingPaginator,
      success,
      errors,
      sendPin,
      rejectRequest,
      resetPin,
      activateAccount,
      deactivateAccount,
      updateFilters,
      filterRequests,
      fetchPaginationData,
      fetchPendingPaginationData
    };
  },
};
</script>
