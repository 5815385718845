<template>
    <div class="container mt-4">
        <h4 class="mb-4"> Autoresponders </h4>
        <div v-if="loading" class="mb-4">Loading...</div>
        <div v-else-if="autoresponders?.length" class="d-flex gap-4 flex-column">
            <div v-if="success" class="alert alert-success mb-4"> {{ success }} </div>
            <div v-for=" autoresponder  in  autoresponders " :key="autoresponder.UIAutoresponderID"
                class="d-flex gap-4 align-items-center">
                <span> {{ autoresponder.Description }} </span>
                <router-link
                    :to="{ name: 'superadmin.autoresponders.edit', params: { AutoresponderID: autoresponder.UIAutoresponderID } }"
                    class="btn btn-success col-md-1">Edit</router-link>
            </div>
        </div>
        <div v-else> No Existing Autoresponders</div>
    </div>
</template>

<script lang="ts">
import axios from 'axios';
import { onMounted, ref, Ref } from 'vue';
import { Autoresponder } from '@interfaces/interfaces';
import { useRoute } from 'vue-router';

export default {
    setup() {
        const autoresponders: Ref<Autoresponder[] | []> = ref([]);
        const loading = ref(true);
        const success = ref(useRoute().query.success || null);

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/administrator/autoresponders')
                .then(response => {
                    autoresponders.value = response.data.Autoresponders;
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })
                .finally(() => loading.value = false)
        };

        onMounted(async () => {
            await fetchData();

            if (success.value) {
                setTimeout(() => success.value = null, 3000)
            }
        });

        return { autoresponders, loading, success }
    }
}
</script>