<template>
    <Alerts :success="success" :errors="errors" />
    <div v-if="loading">Loading...</div>
    <div v-else class="plan-content">
        <!-- Form -->
        <form id="missing-contribution-form" class="form missing-contribution" method="post" @submit.prevent="submitData">
            <div class="inputs-wrapper">
                <TextInput type="text" label="Employer/Production" placeholder="Enter Employer/Production" :max=25
                    v-model:modelValue="formData.Employer" />
                <TextInput type="text" label="Member Name or SSN" placeholder="Enter Member Name or SSN" :max=12
                    v-model:modelValue="formData.MemberName" />
                <DateInput label="Work Period*" v-model:modelValue="formData.WorkPeriod.From" />
                <DateInput label="" v-model:modelValue="formData.WorkPeriod.To" />
                <TextInput type="text" label="Work Location" placeholder="Enter Work Location" :max=50
                    v-model:modelValue="formData.WorkLocation" />
                <SelectComponent label="State" v-model:selectedOption="formData.State" :options="filteredStates"
                    :single-arrow="true" />
            </div>

            <div class="action-buttons">
                <a href="" @click.prevent="closeModal" class="btn btn-secondary"> Discard </a>
                <button type="submit" class="btn btn-primary"> Submit </button>
            </div>

            <div class="inputs-wrapper">
                <TextInput type="text" label="Employer/Production" placeholder="Enter Employer/Production" :max=25
                    v-model:modelValue="formData.Employer" />
                <TextInput type="text" label="Member Name or SSN" placeholder="Enter Member Name or SSN" :max=12
                    v-model:modelValue="formData.MemberName" />
                <DateInput label="Work Period*" v-model:modelValue="formData.WorkPeriod.From" />
                <DateInput label="" v-model:modelValue="formData.WorkPeriod.To" />
                <TextInput type="text" label="Work Location" placeholder="Enter Work Location" :max=50
                    v-model:modelValue="formData.WorkLocation" />
                <TextInput type="text" label="Postal Code" placeholder="Enter Postal Code" :max=9
                    v-model:modelValue="formData.ZipCode" :required="true" />
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, toRefs, reactive, onMounted, watch, computed, Ref, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useModalStore } from '@/stores/modal';
import { useProfileStore } from '@/stores/profile';
import { CountryStates, SelectOption } from '@/interfaces/interfaces';
import Alerts from '@/components/utils/AlertsComponent.vue';
import TextInput from '@/components/form/TextInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import useFormSubmit from '@/composable/useFormSubmit';
import axios from 'axios';

export default defineComponent({
    components: {
        Alerts,
        TextInput,
        DateInput,
        SelectComponent
    },
    props: {
        fund: {
            type: String,
            default: null,
        }
    },
    setup(props, { emit }) {
        const route = useRoute();
        const modalStore = useModalStore();
        const profileStore = useProfileStore();
        const formData = reactive({
            Employer: null,
            MemberName: null,
            WorkPeriod: {
                From: null,
                To: null,
            },
            WorkLocation: '',
            ZipCode: null,
        });

        const errors = ref<string[]>([]);
        const success = ref('');
        const info = ref('');
        const loading = ref(true);
        const loadingSubmit = ref(false);

        // Methods
        const fetchData = async () => {
            try {
                if (!profileStore.countries || !profileStore.statesByCountry) {
                    await profileStore.fetchCountriesStates();
                }

                loading.value = false;
            }
            catch (error) {
                console.error('Error:', error);
            }
        }

        // Filter states based on selected country
        const filteredStates = computed(() => {
            const selectedCountry = 'US';
            const states = profileStore.statesByCountry;
            const countryStates = states ? (states as CountryStates[]).filter(state => state.CountryKey === selectedCountry) : [];

            return (countryStates?.map(state => state.States).flat());
        });

        const closeModal = () => {
            emit('close');
        }

        const submitData = async () => {
            const route = 'api/participant/';
            const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit });
            const successSubmit = await handleSubmit();

            if (successSubmit) {
                // closeModal();
            }
        }

        onMounted(async() => await fetchData());

        return {
            filteredStates,
            formData,
            success,
            errors,
            loading,
            submitData,
            closeModal
        }
    }
});
</script>
