<template>
    <div class="container mt-5">
        <div class="position-fixed top-0 end-0 p-3" style="z-index: 11">
            <div v-show="success" class="alert alert-success">{{ success }}</div>
            <div v-if="errors?.length > 0" class="alert alert-danger">
                <ul>
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">{{ 'Register' }}</div>
                    <div class="card-body">
                        <form @submit.prevent class="d-flex flex-column gap-2">

                            <div class="form-group d-flex flex-column gap-4 mb-4">
                                <label for="radioGroup">Choose an entity from below to create an account for </label>
                                <div class="d-flex gap-2">
                                    <input type="radio" id="participant" name="UserType" v-model="selectedUserType"
                                        :value="1" />
                                    <label for="participant">Participant</label>
                                </div>
                                <div class="d-flex gap-4">
                                    <div class="d-flex gap-2">
                                        <input type="radio" id="local" name="UserType" v-model="selectedUserType"
                                            :value="localRole" />
                                        <label for="local">Local Union</label>
                                    </div>
                                </div>

                                <LocalOptions v-if="selectedUserType == 5 || selectedUserType == 3"
                                    :registerType="registerType" @change-type="handleLocalType" />

                                <div class="d-flex gap-4">
                                    <div class="d-flex gap-2">
                                        <input type="radio" id="employer" name="UserType" v-model="selectedUserType"
                                            :value="6" />
                                        <label for="employer">Employer</label>
                                    </div>
                                    <div class="d-flex gap-2">
                                        <input type="radio" id="payroll_company" name="UserType"
                                            v-model="selectedUserType" :value="7" />
                                        <label for="payroll_company">Payroll Company (Paying Agent)</label>
                                    </div>
                                </div>
                            </div>

                            <LocalFields v-if="selectedUserType == 5 || selectedUserType == 3"
                                :securityQuestions="securityQuestions" :states="states" @register="submitForm"
                                :loadingSubmit="loadingSubmit" :localRequest="localRequest" :authRequest="authRequest"
                                :registerType="registerType" :success="success" />

                            <ParticipantFields v-if="selectedUserType === 1" :securityQuestions="securityQuestions"
                                :loadingSubmit="loadingSubmit" @register="submitForm" />
                            <EmployerFields v-else-if="selectedUserType == 6 || selectedUserType == 7"
                                :securityQuestions="securityQuestions" :employerRequest="employerRequest"
                                :registerType="registerType" :states="states" :loadingSubmit="loadingSubmit"
                                @register="submitForm" />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, onMounted, ref } from 'vue';
import { SecurityQuestion, Country, ILocalAuth } from '@/interfaces/interfaces';
import { PendingLocal, PendingEmployer } from '@/interfaces/admin';
import { useAuthStore } from '@/stores/auth';
import { useRoute, useRouter } from 'vue-router';
import ParticipantFields from '@/components/register/ParticipantFields.vue';
import EmployerFields from '@/components/register/employer/EmployerFields.vue';
import LocalOptions from '@/components/register/local/LocalOptions.vue';
import LocalFields from '@/components/register/local/LocalFields.vue';
import axios from 'axios';

export default {
    components: {
        ParticipantFields,
        EmployerFields,
        LocalFields,
        LocalOptions,
    },
    setup() {
        const selectedUserType: Ref<string | number> = ref(1);
        const errors: Ref<string[]> = ref([]);
        const securityQuestions: Ref<SecurityQuestion[]> = ref([]);
        const localRequest: Ref<PendingLocal | null> = ref(null);
        const authRequest: Ref<ILocalAuth | null> = ref(null);
        const states: Ref<Country[]> = ref([]);
        const employerRequest: Ref<PendingEmployer | null> = ref(null);
        const registerType: Ref<string | null> = ref('auth');
        const success = ref(null);
        const authStore = useAuthStore();
        const router = useRouter();
        const hash = useRoute().query.Hash;
        const loadingSubmit = ref(false);
        const localRole = ref(5);

        const handleLocalType = (payload: string) => {
            registerType.value = payload;
            const type = payload === 'rep_register' ? 3 : 5;
            selectedUserType.value = type;
            localRole.value = type;
        }

        // Fetch Data
        const fetchData = async () => {
            await axios.get('api/register', { params: { Hash: hash, RequestID: useRoute()?.query?.RequestID ?? null } })
                .then(response => {
                    securityQuestions.value = response.data.SecurityQuestions;
                    localRequest.value = response.data.UILocalRequest;
                    authRequest.value = response.data.AuthRequest;
                    registerType.value = response.data.RegisterType;
                    employerRequest.value = response.data.UIEmployerRequest;
                    states.value = response.data.States;
                    selectedUserType.value = response.data.UserType;
                })
        };

        // Function to submit the form
        const submitForm = async (user: any, employerType: string | null) => {
            loadingSubmit.value = true;

            try {
                const formData = {
                    UserType: selectedUserType.value,
                    EmployerType: employerType ?? null,
                    LocalType: registerType.value ?? null,
                    AuthRequest: authRequest.value?.UILocalRequestID ?? null,
                    User: user
                };

                const response = await axios.post('api/register', { ...formData });
                success.value = response.data.success;
                const route = response.data?.route;

                if (route) {
                    const credentials = { email: user.Email, password: user.Password };

                    setTimeout(async () => {
                        await authStore.authenticateUser(credentials);
                    }, 500);
                }
            } catch (error: any) {
                if (error.response.data.error) {
                    errors.value = Object.values(
                        error.response.data.error
                    ).flat() as string[];
                }
            }

            loadingSubmit.value = false;

            setTimeout(() => {
                success.value = null;
                errors.value = [];
            }, 5000);
        };

        onMounted(async () => await fetchData());

        return {
            errors,
            success,
            selectedUserType,
            securityQuestions,
            registerType,
            localRequest,
            authRequest,
            states,
            localRole,
            employerRequest,
            loadingSubmit,
            submitForm,
            handleLocalType
        };
    },
};
</script>