<template>
    <p>
        You must read and digitally sign the certification below in order to receive employee-related health
        coverage information. Please Note: You will have to digitally sign this certification every time you
        request
        health coverage information.
    </p>
    <h6>STEP 2 - CERTIFICATION </h6>
    <div v-if="errors.length > 0" class="alert alert-danger">
        <ul>
            <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
        </ul>
    </div>
    <div class="d-flex flex-column gap-5 align-items-center">
        <div class="overlay-container">
            <div class="card ">
                <div class="card-body d-flex flex-column gap-4">
                    <span>By digitally signing, the undersigned hereby certifies that:</span>
                    <span>
                        (i) the company submitting this request is an "applicable large employer" (ALE) or ALE Member
                        (within
                        the meaning of the Affordable Care Act (ACA)); and
                    </span>
                    <span>
                        (ii) the employees for whom the company is requesting health coverage information from the
                        I.A.T.S.E.
                        National Health & Welfare Fund (the Fund) were “full-time employees” of the company (within the
                        meaning
                        of the ACA) for at least one month of calendar year 2023; and
                    </span>
                    <span>
                        (iii) the company made contributions to the Fund for such employees during calendar year 2023
                        pursuant
                        to a collective bargaining or participation agreement; and
                    </span>
                    <span>
                        (iv) the company is submitting this request for information from the Fund because the
                        information is
                        necessary for the company to comply with the employer reporting requirements imposed by Section
                        6056
                        of
                        the Internal Revenue Code, and the company agrees that it will use the information provided by
                        the
                        Fund
                        solely for that purpose; and
                    </span>
                    <span>
                        (v) the undersigned understands and acknowledges that the company is solely responsible for
                        making
                        determinations as to its status as an ALE and any employee’s status as a full-time employee for
                        whom
                        the
                        company contributed to the Fund, and the Fund does not verify social security numbers or any
                        other
                        information for purposes of confirming the accuracy of such determinations by the company.
                    </span>
                </div>
            </div>
            <div class="overlay">
                <div class="card col-md-6">
                    <div class="card-body d-flex flex-column align-items-start gap-4">
                        <div class="d-flex align-items-center w-100">
                            <label for="name" class="col-md-4">Name <span class="text-danger">*</span></label>
                            <input type="name" class="form-control" v-model="data.Name" id="name" />
                        </div>
                        <div class="d-flex align-items-start w-100">
                            <label for="password" class="col-md-4">Password <span class="text-danger">*</span></label>
                            <input type="password" class="form-control" v-model="data.Password" id="password" />
                        </div>
                        <span> (Note: Password should be the same as used for logging in)</span>
                        <div class="d-flex justify-content-end w-100">
                            <button class="btn btn-secondary" @click="handleNext">Agree to Terms & Conditions and
                                Proceed</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 d-flex align-items-center justify-content-between gap-4">
            <div>
                <button class="btn btn-secondary" @click="handleBack">Back</button>
            </div>
            <div class="col-md-2 d-flex align-items-center gap-4">
                <label for="date">Date</label>
                <input type="date" id="date" :value="new Date().toISOString().split('T')[0]" class="form-control"
                    disabled />
            </div>
            <div class="col-md-6 d-flex align-items-center gap-4">
                <label for="ip" class="col-md-2">IP Address</label>
                <input type="ip" id="ip" :value="ipAddress" class="form-control" disabled />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { useAuthStore } from '@/stores/auth';
import { defineComponent, ref, Ref, reactive } from 'vue';
import axios from 'axios';

export default defineComponent({
    props: {
        ipAddress: String,
        download: String
    },
    setup(props, { emit }) {
        const loadingSubmit = ref(false);
        const errors: Ref<string[]> = ref([]);
        const authStore = useAuthStore();
        const data = reactive({
            Name: authStore.userEmail,
            Password: null,
            Download: props.download
        });

        const handleBack = () => {
            emit('handle-back');
        }

        const handleNext = async () => {
            loadingSubmit.value = true;

            await axios
                .post('api/employer/health-coverage-data', { ...data })
                .then((response) => {
                    emit('handle-next', response.data.Requests);
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }
                })
                .finally(() => {
                    loadingSubmit.value = false;
                    setTimeout(() => errors.value = [], 3500);
                });
        }

        return {
            loadingSubmit,
            data,
            errors,
            handleBack,
            handleNext
        };
    }
})
</script>

<style scoped>
.overlay-container {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>