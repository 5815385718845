<template>
    <p>
        Please choose how you will be providing your employees' social security numbers in order to obtain health coverage
        information.
    </p>
    <h6>STEP 3 - Select Method of Request </h6>
    <div class="card">
        <div class="card-body d-flex flex-column align-items-center gap-4">
            <div class="col-md-6 d-flex align-items-center gap-4">
                <input type="radio" id="upload" v-model="selectedOption" value="0" />
                <label for="upload">Upload Excel file with Employee(s) SSN</label>
            </div>
            <div class="col-md-6 d-flex align-items-center gap-4">
                <input type="radio" id="web_form" v-model="selectedOption" value="1" />
                <label for="web_form">Enter Employee(s) SSN via Web Request Form</label>
            </div>
        </div>
    </div>
    <div class="col-md-12 d-flex align-items-center justify-content-between gap-4">
        <button class="btn btn-secondary" @click="handleBack">Back</button>
        <button class="btn btn-secondary" @click="handleNext">Next</button>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
    setup(_, { emit }) {
        const selectedOption = ref('0');

        const handleBack = () => {
            emit('handle-back');
        }

        const handleNext = () => {
            emit('handle-next', selectedOption.value);
        }

        return {
            selectedOption,
            handleBack,
            handleNext
        }
    }
})
</script>