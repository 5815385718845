<template>
    <div id="localRegister" class="d-flex flex-column gap-2">
        <div class="form-group d-flex flex-column gap-2">
            <label for="email_local">Email <span class="text-danger">*</span></label>
            <input v-model="local.Email" type="email" class="form-control required" id="email_local">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="pin">PIN <span class="text-danger">*</span></label>
            <input v-model="local.PIN" type="text" class="form-control required" id="pin" maxlength="6">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="first_name">First Name <span class="text-danger">*</span></label>
            <input v-model="local.FirstName" type="text" class="form-control required" id="first_name">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="middle_name">Middle Name </label>
            <input v-model="local.MiddleName" type="text" class="form-control" id="middle_name">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="last_name">Last Name <span class="text-danger">*</span></label>
            <input v-model="local.LastName" type="text" class="form-control required" id="last_name">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="local">Local# <span class="text-danger">*</span></label>
            <input v-model="local.OrgName" type="text" class="form-control required" id="local">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="local_password">Password <span class="text-danger">*</span></label>
            <input v-model="local.Password" type="password" class="form-control required" id="local_password">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="local_password_confirmation">Confirm Password <span class="text-danger">*</span></label>
            <input v-model="local.Password_confirmation" type="password" class="form-control required"
                id="local_password_confirmation">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="security_question_1">Security Question 1</label>
            <select v-model="local.SecurityQuestion1.Question" class="form-control" id="security_question_1"
                @change="() => handleQuestionSelect('1')">
                <option value="0">Security Questions</option>
                <option v-for="question in availableQuestionsForSelect('1').value" :key="question.SecurityQuestionID"
                    :value="question.SecurityQuestionID">
                    {{ question.Question }}
                </option>
            </select>
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label :for="'answer_1'">Answer 1</label>
            <input v-model="local.SecurityQuestion1.Answer" type="text" class="form-control" id="answer_1" />
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="security_question_2">Security Question 2</label>
            <select v-model="local.SecurityQuestion2.Question" class="form-control" id="security_question_2"
                @change="() => handleQuestionSelect('2')">
                <option value="0">Security Questions</option>
                <option v-for="question in availableQuestionsForSelect('2').value" :key="question.SecurityQuestionID"
                    :value="question.SecurityQuestionID">
                    {{ question.Question }}
                </option>
            </select>
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label :for="'answer_2'">Answer 2</label>
            <input v-model="local.SecurityQuestion2.Answer" type="text" class="form-control" id="answer_2" />
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="security_question_3">Security Question 3</label>
            <select v-model="local.SecurityQuestion3.Question" class="form-control" id="security_question_3"
                @change="() => handleQuestionSelect('3')">
                <option value="0">Security Questions</option>
                <option v-for="question in availableQuestionsForSelect('3').value" :key="question.SecurityQuestionID"
                    :value="question.SecurityQuestionID">
                    {{ question.Question }}
                </option>
            </select>
        </div>
        <div class="form-group d-flex flex-column gap-3">
            <label :for="'answer_3'">Answer 3</label>
            <input v-model="local.SecurityQuestion3.Answer" type="text" class="form-control" id="answer_3" />
        </div>
    </div>

    <div class="form-group row mb-0">
        <div class="col-md-12 d-flex justify-content-center">
            <button type="button" class="btn btn-primary" @click="handleRegister">{{ loadingSubmit ? 'Submitting...' :
                'Register' }}</button>
        </div>
    </div>
</template>

<script lang="ts">
import { SecurityQuestion, LocalRegister } from '@/interfaces/interfaces';
import { PendingLocal } from '@/interfaces/admin';
import { reactive, PropType, defineComponent, ref, Ref, computed } from 'vue';

export default defineComponent({
    props: {
        securityQuestions: {
            type: Array as PropType<SecurityQuestion[]>,
            required: true
        },
        localRequest: Object as PropType<PendingLocal | null>,
        loadingSubmit: Boolean
    },
    setup(props, { emit }: { emit: (event: string, ...args: any[]) => void }) {
        const local: LocalRegister = reactive({
            OrgName: props.localRequest?.OrgName ?? '',
            Email: props.localRequest?.Email ?? '',
            PIN: props.localRequest?.Pin ?? '',
            FirstName: props.localRequest?.FirstName ?? '',
            MiddleName: props.localRequest?.MiddleName ?? '',
            LastName: props.localRequest?.LastName ?? '',
            Password: '',
            Password_confirmation: '',
            SecurityQuestion1: { Question: 0, Answer: '' },
            SecurityQuestion2: { Question: 0, Answer: '' },
            SecurityQuestion3: { Question: 0, Answer: '' },
        });

        const selectedQuestions: Ref<{ [key: string]: number }> = ref({
            1: 0,
            2: 0,
            3: 0,
        });

        const availableQuestionsForSelect = (selectKey: string) => computed(() => {
            return props.securityQuestions?.filter(q => {
                return !Object.entries(selectedQuestions.value)
                    .filter(([key, value]) => key !== selectKey && value !== 0)
                    .map(([, value]) => value)
                    .includes(q.SecurityQuestionID as number);
            });
        })

        // Method to handle dropdown change and update selected questions
        const handleQuestionSelect = (index: string) => {
            const questionKey = `SecurityQuestion${index}` as keyof LocalRegister;
            const securityQuestion = local[questionKey] as { Question: number, Answer: string };
            selectedQuestions.value[index] = securityQuestion.Question;
        };

        const handleRegister = () => {
            const { SecurityQuestion1, SecurityQuestion2, SecurityQuestion3, ...restOfLocal } = local;

            const SecurityQuestions = [
                SecurityQuestion1,
                SecurityQuestion2,
                SecurityQuestion3,
            ].map(question => ({ Question: question.Question, Answer: question.Answer }));

            emit('register', { ...restOfLocal, SecurityQuestions });
        }

        return {
            local,
            handleRegister,
            availableQuestionsForSelect,
            handleQuestionSelect,
        };
    },
});
</script>