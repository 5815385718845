<template>
    <div class="col-md-4 border d-flex flex-column justify-content-around gap-4 p-4">
        <h3> My Account Summary </h3>
        <div v-if="summary" class="d-flex flex-column justify-content-between gap-4">
            <div>
                <span class="fw-bold"> Most Recent Contribution Received: </span>
                <router-link
                    :to="{ name: 'annuity-details', query: { fromDate: summary.Annuity.LastContribution.FormattedDate } }">
                    {{ summary.Annuity.LastContribution.Amount }} on {{ summary.Annuity.LastContribution.FormattedDate }}
                </router-link>
            </div>
            <div>
                <span class="fw-bold">
                    Total Contributions received for {{ year }} :
                </span>
                <router-link
                    :to="{ name: 'annuity-details', query: { fromDate: summary.FromDate, toDate: summary.ToDate } }">
                    {{ summary.Annuity.Total }}
                </router-link>
            </div>
            <div>
                <span class="fw-bold"> Current Principal Balance: </span>
                <span v-if="accountBalance" class="text-center">
                    {{ accountBalance }}
                </span>
                <span v-else class="text-center">
                    Loading...
                </span>
            </div>
        </div>
        <span v-else class="text-center">
            Loading...
        </span>
        <div class="d-flex flex-column align-items-stretch gap-4">
            <div>
                <button class="btn btn-primary w-100">View My Beneficiaries</button>
            </div>
            <div>
                <button class="btn btn-primary w-100">Add New Beneficiary</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, onMounted, watch, PropType } from 'vue';
import { useRoute } from 'vue-router';
import { AnnuitySummary } from "@interfaces/interfaces";
import axios from 'axios';

export default {
    props: {
        summary: Object as PropType<AnnuitySummary | null>
    },
    setup() {
        const accountBalance = ref(null);
        const route = useRoute();
        const year = ref(route.params.year);
        const loading = ref(false);

        const fetchData = async () => {
            loading.value = true;
            try {
                const response = await axios.get('api/participant/annuity/balance'); // Get Balance
                accountBalance.value = response.data.accountBalance;
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            loading.value = false;
        }

        // Watch for changes in the route parameter
        watch(() => route.params.year, async (newYear) => {
            year.value = newYear;
            await fetchData();
        });

        onMounted(async () => {
            await fetchData();
        });

        return {
            accountBalance,
            loading,
            year
        };
    },
};
</script>