<template>
    <div class="container mt-4">
        <div class="position-fixed top-0 end-0 p-3" style="z-index: 11">
            <div class="alert alert-success" v-show="success">{{ success }}</div>
            <div v-if="errors.length > 0" class="alert alert-danger">
                <ul>
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center mb-4">
                <h1>Create/Manage Local Representative Accounts</h1>
            </div>
            <p>
                This page allows you to manage who in your Local will be permitted access to the IATSE National Benefit
                Fund's Local Portal. You can Add new accounts (by clicking on 'Request PIN for Local Representative'
                button), De-activate existing account(s) or View list of existing account(s) and their status.

            </p>
            <div v-if="loading">Loading Data...</div>
            <div v-else>
                <div class="d-flex mb-4">
                    <router-link :to="{ name: 'local.representative.pin' }" class="btn btn-primary ms-auto">
                        Request PIN for Local Representative
                    </router-link>
                </div>
                <table class="table table-bordered table-striped">
                    <thead class="table-dark">
                        <tr class="text-center align-middle">
                            <th>Login<br>
                                Email Address</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Designation</th>
                            <th>Phone</th>
                            <th>Actions</th>
                            <th>Days to Expiration</th>
                        </tr>
                    </thead>
                    <tbody v-if="members.length > 0 || pendingMembers.length > 0">
                        <tr v-for="member in members" :key="member.UIUserID" class="text-center">
                            <td>{{ member.Email }}</td>
                            <td>{{ member.FirstName }}</td>
                            <td>{{ member.LastName }}</td>
                            <td>{{ member.Designation }}</td>
                            <td>{{ member.Phone }}</td>
                            <td class="d-flex gap-2 justify-content-center">
                                <button type="button" class="btn btn-sm btn-secondary"
                                    @click="handleChangeActive(member.UserID)">
                                    <span> {{ member.Activating ? 'Loading...' : member.Status }}</span>
                                </button>
                                <button type="button" class="btn btn-sm btn-secondary" @click="resetPin(member.UserID)">
                                    <span> {{ member.ResettingPin ? 'Loading...' : 'Reset Pin' }}</span>
                                </button>
                                <button type="button" class="btn btn-sm btn-secondary"
                                    @click="resetPassword(member.UserID)">
                                    <span>{{ member.ResettingPassword ? 'Loading...' : 'Reset Password' }}</span>
                                </button>
                            </td>
                            <td>{{ member.DaysToAccountExpiration }}</td>
                        </tr>
                        <tr v-for="pendingMember in pendingMembers" :key="pendingMember.UILocalRequestID"
                            class="text-center">
                            <td>{{ pendingMember.Email }}</td>
                            <td>{{ pendingMember.FirstName }}</td>
                            <td>{{ pendingMember.LastName }}</td>
                            <td>{{ pendingMember.Designation }}</td>
                            <td>{{ pendingMember.Phone }}</td>
                            <td></td>
                            <td class="expiration-date">Not Activated</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Member, PendingMember } from '@/interfaces/local';
import { onMounted, ref, Ref } from 'vue';
import axios from 'axios';

export default {
    setup() {
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const members: Ref<Member[] | []> = ref([]);
        const pendingMembers: Ref<PendingMember[] | []> = ref([]);
        const errors: Ref<string[]> = ref([]);
        const success = ref(null);
        const route = 'api/local/representative-accounts';

        const fetchData = async (isLoading: boolean) => {
            if (isLoading) loading.value = true;

            await axios.get(route)
                .then(response => {
                    members.value = response.data.Members;
                    pendingMembers.value = response.data.PendingMembers;
                })

            if (isLoading) loading.value = false;
        };

        // Handle Reset Pin
        const resetPin = async (userID: number) => {
            const index = searchUser(userID);

            try {
                members.value[index].ResettingPin = true;
                const response = await axios.post(
                    `${route}/reset-pin/${userID}`,
                    { _method: "PATCH" }
                );
                success.value = response.data.success;
            }
            catch (error: any) {
                if (error.response) {
                    errors.value = Object.values(error?.response.data.error).flat() as string[];
                }
            }

            members.value[index].ResettingPin = false;
            await refreshData();
        };

        // Handle Activate Account
        const handleChangeActive = async (userID: number) => {
            const index = searchUser(userID);
            members.value[index].Activating = true;
            const status = members.value[index]?.Status?.toLowerCase();

            try {
                const response = await axios.post(
                    `${route}/${status}/${userID}`,
                    { _method: "PATCH" }
                );
                success.value = response.data.success;
            }
            catch (error) {
                console.error("Error changing account status:", error);
            }

            await refreshData();
            members.value[index].Activating = false;
        };

        // Handle Reset Password
        const resetPassword = async (userID: number) => {
            const index = searchUser(userID);
            members.value[index].ResettingPassword = true;

            try {
                const response = await axios.post(
                    `${route}/reset-password/${userID}`,
                    { _method: "PATCH" }
                );
                success.value = response.data.success;
            }
            catch (error: any) {
                errors.value = Object.values(error.response.data.errors).flat() as string[];
            }

            await refreshData();
            members.value[index].ResettingPassword = false;
        };

        const searchUser = (userID: number) => {
            return members.value.findIndex(member => member.UserID === userID);
        }

        const refreshData = async () => {
            await fetchData(false);

            setTimeout(() => {
                success.value = null;
                errors.value = [];
            }, 3500);
        };

        onMounted(async () => await fetchData(true));

        return {
            loading,
            loadingSubmit,
            members,
            pendingMembers,
            success,
            errors,
            resetPin,
            resetPassword,
            handleChangeActive,
        };
    }
}
</script>