<template>
    <div>
      <iframe v-if="documentUrl" :src="documentUrl" width="100%" height="600"></iframe>
    </div>
  </template>

  <script lang="ts">
  import { ref, onMounted } from 'vue';
  import DocumentService from '@/services/documentsService';
  import { useRoute } from 'vue-router';

  export default {
    setup() {
      const route = useRoute();
      const documentId = route.params.documentId as string; // Cast to string if necessary
      const documentUrl = ref<string | null>(null);

      const fetchDocument = async () => {
        if (documentId) {
          try {
            const blob = await DocumentService.viewDocument(documentId);
            documentUrl.value = URL.createObjectURL(blob);
          } catch (error) {
            if (error instanceof Error) {
                console.error(error.message);
            } else {
                console.error('An error occurred', error);
            }
          }
        }
      };

      onMounted(fetchDocument);

      return {
        documentUrl,
      };
    },
  };
  </script>