<template>
    <div class="border border-secondary p-4 mb-4">
        <h6 class="text-center mb-4">DOCUMENTS CURRENTLY ON FILE/PENDING ADMIN PREVIEW</h6>
        <table class="table mb-4" id="documents_table" v-show="documents.length > 0">
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody v-show="documents.length > 0">
                <tr v-for="document in documents" :key="document.DocumentID">
                    <td>
                        <router-link :to="{ name: 'smartsearch.file.view', params: { documentId: document.DocumentID } }">
                            {{ document.DocumentDescription }}
                        </router-link>
                    </td>
                    <td>{{ new Date(document.Date).toLocaleDateString() }}</td>
                    <td :class="{ 'text-danger': document.Status === 'Pending' }">{{ document.Status }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="text-center mt-4" v-show="documents.length === 0">
            No documents have been uploaded for this dependent.
        </div>
    </div>
</template>

<script lang="ts">
import { Document } from '@/interfaces/interfaces';
import { PropType } from 'vue';

export default {
    props: {
        documents: {
            type: Array as PropType<Document[] | []>,
            required: true
        }
    }
}
</script>