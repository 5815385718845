<template>
    <div class="row">
        <div class="col-md-12 mb-4">
            <ReviewDependent :data="data" />
            <DocumentsSection :documents="documents" />
        </div>
    </div>
</template>

<script lang="ts">
import { PropType, onMounted, ref } from 'vue';
import { ReviewDependentData, Document } from '@interfaces/interfaces';
import DocumentsSection from './DocumentsSection.vue';
import ReviewDependent from './ReviewDependent.vue';

export default {
    props: {
        data: {
            type: Object as PropType<ReviewDependentData | null>,
            required: true
        },
        documents: {
            type: Array as PropType<Document[] | []>,
            required: true
        }
    },
    components: {
        DocumentsSection,
        ReviewDependent
    }
}
</script>