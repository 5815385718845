<template>
    <div class="mb-5">
        <h4 class="mb-4">Local registration pending requests </h4>
        <table class="table table-striped mb-5">
            <thead class="table-dark">
                <tr>
                    <th> Local </th>
                    <th> First Name </th>
                    <th> Last Name </th>
                    <th> Middle Name </th>
                    <th> Title </th>
                    <th> Email Address </th>
                    <th> Phone </th>
                    <th> Type </th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="local in locals" :key="local.UILocalRequestID">
                    <td class="col-md-2">{{ local.OrgName }} </td>
                    <td class="col-md-4">{{ local.FirstName }}</td>
                    <td class="col-md-4">{{ local.LastName }}</td>
                    <td class="col-md-4">{{ local.MiddleName }}</td>
                    <td class="col-md-4">{{ local.Title }}</td>
                    <td class="col-md-4">{{ local.Email }}</td>
                    <td class="col-md-4">{{ local.Phone }}</td>
                    <td class="col-md-4">{{ local.Type }}</td>
                    <td class="text-center">
                        <button type="button" @click="sendPin(local.UILocalRequestID)"
                            class="btn btn-sm btn-info btn-block pin-width">
                            {{ local.SendingPin ? 'Sending Pin...' : 'Send Pin' }}
                        </button>
                    </td>
                    <td class="text-center">
                        <button type="button" @click="rejectRequest(local.UILocalRequestID)"
                            class="btn btn-sm btn-warning btn-block pin-width">
                            {{ local.Rejecting ? 'Rejecting...' : 'Reject Pin' }}
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <PaginationComponent :paginator="paginator" @fetch-data="fetchPaginationData" />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { PendingLocal, Paginator } from '@/interfaces/admin';
import PaginationComponent from '@components/utils/PaginationComponent.vue';

export default defineComponent({
    props: {
        locals: {
            type: Object as PropType<PendingLocal[]>,
            required: true
        },
        paginator: {
            type: Object as PropType<Paginator>,
            required: true
        },
        actions: Object,
        processed: Number
    },

    components: {
        PaginationComponent
    },

    setup(_, { emit }) {
        const fetchPaginationData = (pageNumber: number) => {
            emit('fetch-paginator-data', pageNumber);
        }

        const sendPin = (requestID: number) => {
            emit('send-pin', requestID);
        }

        const rejectRequest = (requestID: number) => {
            emit('reject-request', requestID);
        }

        return {
            fetchPaginationData,
            sendPin,
            rejectRequest,
        };
    }
});

</script>

<style scoped>
.pin-width {
    width: 120px;
}
</style>