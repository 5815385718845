import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "radio-group" }
const _hoisted_2 = { class: "main-label" }
const _hoisted_3 = { class: "radio-buttons" }
const _hoisted_4 = ["id", "name", "value"]
const _hoisted_5 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "radio-button"
        }, [
          _withDirectives(_createElementVNode("input", {
            type: "radio",
            id: `${_ctx.name}${index + 1}`,
            name: _ctx.name,
            value: _ctx.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
          }, null, 40, _hoisted_4), [
            [_vModelRadio, _ctx.selectedValue]
          ]),
          _createElementVNode("label", {
            class: "body-text",
            for: `${_ctx.name}${index + 1}`
          }, _toDisplayString(option.value), 9, _hoisted_5)
        ]))
      }), 128))
    ])
  ]))
}