<template>
    <div class="container mt-4">
        <div class="row">
            <div class="col-md-12 text-center mb-4">
                <h1>Employer Portal</h1>
            </div>
            <div class="position-fixed top-0 end-0 p-3" style="z-index: 11">
            </div>
            <div v-if="loading" class="row">
                Loading...
            </div>
            <div v-else class="row">
                <PaymentPanel :links="paymentLinks" />
                <FundMaterialsPanel :links="fundMaterialLinks" />
                <ProfilePanel :data="profileData" :type="type" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, onMounted, ref } from 'vue';
import { FundMaterialLinks, PaymentLinks } from '@/interfaces/employer';
import { ProfileData } from '@interfaces/interfaces';
import PaymentPanel from '@/components/employer/dashboard/PaymentPanel.vue';
import FundMaterialsPanel from '@/components/employer/dashboard/FundMaterialsPanel.vue';
import ProfilePanel from '@components/user/dashboard/ProfilePanel.vue';
import axios from 'axios';

export default {
    components: {
        PaymentPanel,
        FundMaterialsPanel,
        ProfilePanel,
    },
    setup() {
        const fundMaterialLinks: Ref<FundMaterialLinks | null> = ref(null);
        const paymentLinks: Ref<PaymentLinks | null> = ref(null);
        const profileData: Ref<ProfileData | null> = ref(null);
        const loading = ref(true);
        const type = ref('Employer');

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/employer/fund-materials')
                .then(response => {
                    fundMaterialLinks.value = response.data.FundMaterialLinks;
                    paymentLinks.value = response.data.PaymentLinks;
                });

            await axios.get('api/employer/dashboard')
                .then(response => {
                    profileData.value = response.data;
                    type.value = response.data.IsPayor ? 'Payroll' : 'Employer';
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })

            loading.value = false;
        };

        onMounted(async () => await fetchData());

        return {
            loading,
            type,
            fundMaterialLinks,
            paymentLinks,
            profileData,
        };
    }
}
</script>