<template>
    <div v-if="loading"> Loading... </div>
    <div v-else class="plan-content">
        <!-- General Plan Detail Cards -->
        <div class="plan-details">
            <ProfileInfo :items="[
                { label: 'Participant ID', value: participantId },
                { label: data?.Label, value: data?.Date },
                { label: 'Days Worked', value: '0' },
            ]" :has-divider="false" :use-container="false" />

                    <ProfileInfo :items="[
                { label: 'Check Destination ', value: data?.CheckDestination },
                { label: 'Contributions', value: data?.Total },
            ]" :has-divider="false" :use-container="false" />
        </div>

        <div class="card">
            <h4 class="pdb-10"> Payments and Contributions </h4>
            <DataTable v-if="history.rows" :table="history" classes="striped small toggle" :toggle="true" />
        </div>

        <!-- Related Links -->
        <div class="account-actions">
            <h4> Related Links </h4>
            <div class="action-buttons">
                <button class="btn btn-secondary plan-modal" @click="openModal('beneficiaries', 'Beneficiaries', '')">
                    Beneficiaries
                </button>
                <button class="btn btn-secondary plan-modal"
                    @click="openModal('check-replacement', 'Check Replacement', '')"> Check Replacement </button>
                <button class="btn btn-secondary plan-modal"
                    @click="openModal('check-destination', 'Check Destination', '')"> ACH/Check
                    Destination </button>
                <a href="" class="btn btn-secondary"> Forms & Documents </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useVacationStore } from '@/stores/vacation';
import { useAuthStore } from '@/stores/auth';

import ProfileInfo from '@/components/cards/ProfileInfo.vue';
import DataTable from '@/components/form/DataTable.vue';

export default defineComponent({
    components: {
        ProfileInfo,
        DataTable
    },
    setup() {
        const authStore = useAuthStore();
        const vacationStore = useVacationStore();
        const historyRoute = '/api/participant/vacation/history';
        const loading = ref(true);

        const fetchData = async () => {
            try {
                if (!vacationStore.history.rows.length) {
                    loading.value = true;
                    await vacationStore.fetchHistory(historyRoute);
                }

                loading.value = false;
            } catch (error) {
                console.error('Error fetching history data:', error);
            }
        }

        onMounted(async () => {
            await fetchData();
        });

        return {
            openModal: useModalStore().openModal,
            participantId: authStore.user?.participantId,
            data: vacationStore.generalInfo,
            history: vacationStore.history,
            loading,
        };
    }
});
</script>