<template>
    <div id="employerAuth" class="d-flex flex-column gap-2">
        <div class="form-group d-flex flex-column gap-2">
            <label for="company_name">Company Name <span class="text-danger">*</span></label>
            <input v-model="employerAuth.CompanyName" type="text" class="form-control required" id="company_name"
                maxlength="60">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="tax_id_auth">EIN/ TAXID <span class="text-danger">*</span></label>
            <input v-model="employerAuth.TaxID" type="text" class="form-control required" id="tax_id_auth">
        </div>
        <div class="d-flex flex-column gap-4">
            <div v-for="(dba, index) in employerAuth.DBANames" :key="index" class="d-flex align-items-end gap-4">
                <div class="form-group col-md-10 d-flex flex-column gap-2">
                    <label for="dba_name">DBA Name</label>
                    <input v-model="dba.name" type="text" class="form-control" :id="'dba_name_' + index"
                        :name="'EmployerAuth[DBANames][]'">
                </div>
                <div class="col-md-2">
                    <button v-if="dba.showDelete" type="button" class="btn btn-danger delete-dba" @click="deleteDBA(index)">
                        Delete
                    </button>
                    <button v-else type="button" class="btn btn-primary" @click="addDBA">
                        + Add More
                    </button>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="address" class="mb-4">Address</label>
            <div class="mx-4 d-flex flex-column gap-2">
                <div class="d-flex flex-column gap-2">
                    <label for="street1">Street 1 <span class="text-danger">*</span></label>
                    <input v-model="employerAuth.Address.Street1" type="text" class="form-control required" id="street1"
                        maxlength="30">
                </div>
                <div class="d-flex flex-column gap-2">
                    <label for="street2">Street 2</label>
                    <input v-model="employerAuth.Address.Street2" type="text" class="form-control" id="street2"
                        maxlength="30">
                </div>
                <div class="d-flex flex-column gap-2">
                    <label for="city">City <span class="text-danger">*</span></label>
                    <input v-model="employerAuth.Address.City" type="text" class="form-control required" id="city">
                </div>
                <div class="form-group">
                    <label for="zip_code">Zip/ Postal Code <span class="text-danger">*</span></label>
                    <input v-model="employerAuth.Address.Zip" type="text" class="form-control required" id="zip_code">
                </div>
                <div class="d-flex flex-column gap-2">
                    <label for="state">State <span class="text-danger">*</span></label>
                    <select v-model="employerAuth.Address.State" class="form-control" id="state">
                        <option v-for="state in states" :value="state.ShortName" :key="state.ShortName">{{ state.LongName }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="first_name_employer_auth">First Name <span class="text-danger">*</span></label>
            <input v-model="employerAuth.FirstName" type="text" class="form-control required" id="first_name_employer_auth"
                maxlength="12">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="middle_name_auth">Middle Name</label>
            <input v-model="employerAuth.MiddleName" type="text" class="form-control" id="middle_name_auth" maxlength="12">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="last_name_employer_auth">Last Name <span class="text-danger">*</span></label>
            <input v-model="employerAuth.LastName" type="text" class="form-control required" id="last_name_employer_auth"
                maxlength="25">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="title">Title/Position</label>
            <input v-model="employerAuth.Title" type="text" class="form-control" id="title">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="phone">Phone <span class="text-danger">*</span></label>
            <input v-model="employerAuth.Phone" type="phone" class="form-control required" id="phone">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="email_employer_auth">Email <span class="text-danger">*</span></label>
            <input v-model="employerAuth.Email" type="email" class="form-control required" id="email_employer_auth">
        </div>
    </div>
    <div class="form-group row mb-0">
        <div class="col-md-12 d-flex justify-content-center">
            <button type="button" class="btn btn-primary" @click="handleRegister">{{ loadingSubmit ? 'Submitting...' :
                'Register' }}</button>
        </div>
    </div>
</template>

<script lang="ts">
import { reactive, PropType, defineComponent } from 'vue';
import { Country } from '@/interfaces/interfaces';

interface EmployerAuth {
    CompanyName: string;
    TaxID: string;
    DBANames: { name: string, showDelete: boolean }[];
    Address: {
        Street1: string;
        Street2: string;
        City: string;
        Zip: string;
        State: string;
    };
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Title: string;
    Phone: string;
    Email: string;
}

export default defineComponent({
    props: {
        states: Array as PropType<Country[]>,
        loadingSubmit: Boolean,
    },
    setup(props, { emit }) {
        const employerAuth: EmployerAuth = reactive({
            CompanyName: '',
            TaxID: '',
            DBANames: [{ name: '', showDelete: false }],
            Address: {
                Street1: '',
                Street2: '',
                City: '',
                Zip: '',
                State: '',
            },
            FirstName: '',
            MiddleName: '',
            LastName: '',
            Title: '',
            Phone: '',
            Email: '',
        });

        const addDBA = () => {
            employerAuth.DBANames.push({ name: '', showDelete: true });
        };

        const deleteDBA = (index: number) => {
            employerAuth.DBANames.splice(index, 1)
        }

        const handleRegister = () => {
            const DBANames = employerAuth.DBANames.map(item => item.name);
            const employer = JSON.parse(JSON.stringify(employerAuth));
            employer.DBANames = DBANames;

            emit('register', employer);
        }

        return {
            employerAuth,
            addDBA,
            deleteDBA,
            handleRegister,
        }
    }
})
</script>