<template>
    <!-- Plan Content -->
    <div class="plan-content">
         <!-- General Plan Detail Cards -->
         <PlanGeneral
            text="Select a coverage option from the offerings below by clicking the applicable box. Failure to male a selection will result in the fund determining your enrollment for the upcoming quarter."/>

        <div class="plan-info">
            <h4> Coverage Options <i class="iatse-icon-info-tooltip"> </i> </h4>
            <h5> Your Coverage selection and pay applicable payment is due no longer than 06/17/2024 for the coverege period starting 07/01/2024 </h5>
        </div>

        <!-- Forms -->
        <form id="make-coverage-form" class="form make-coverage" method="post">
            <div class="bordered-card">
                <div class="bordered-card-header">
                    <SwitchInput option1="Single" option2="Family"/>
                </div>
                <div class="bordered-card-body">
                    <h4> C1 Single <i class="iatse-icon-info-tooltip"> </i> </h4>
                    <div class="bordered-card green-card">
                        <h6> Amount Owned</h6>
                        <h4> $ 799.00 </h4>
                    </div>
                </div>
                <a class="btn btn-primary plan-modal" @click.prevent="openModal('make-co-payment', 'Make CAPP Co-payment', 'plan-card-info')"> Continue this Coverage </a>
            </div>

            <div class="bordered-card">
                <div class="bordered-card-header">
                    <SwitchInput option1="Single" option2="Family"/>
                </div>
                <div class="bordered-card-body">
                    <h4> C1 Single <i class="iatse-icon-info-tooltip"> </i> </h4>
                    <div class="bordered-card green-card">
                        <h6> Amount Owned</h6>
                        <h4> $ 799.00 </h4>
                    </div>
                </div>
                <a class="btn btn-primary plan-modal" @click.prevent="openModal('make-co-payment', 'Make CAPP Co-payment', 'plan-card-info')"> Continue this Coverage </a>
            </div>

            <div class="bordered-card">
                <div class="bordered-card-header">
                    <SwitchInput option1="Single" option2="Family"/>
                </div>
                <div class="bordered-card-body">
                    <h4> C1 Single <i class="iatse-icon-info-tooltip"> </i> </h4>
                    <div class="bordered-card green-card">
                        <h6> Amount Owned</h6>
                        <h4> $ 799.00 </h4>
                    </div>
                </div>
                <a class="btn btn-primary plan-modal" @click.prevent="openModal('make-co-payment', 'Make CAPP Co-payment', 'plan-card-info')"> Continue this Coverage </a>
            </div>

            <div class="bordered-card">
                <div class="bordered-card-header">
                    <SwitchInput option1="Single" option2="Family"/>
                </div>
                <div class="bordered-card-body">
                    <h4> C1 Single <i class="iatse-icon-info-tooltip"> </i> </h4>
                    <div class="bordered-card green-card">
                        <h6> Amount Owned</h6>
                        <h4> $ 799.00 </h4>
                    </div>
                </div>
                <a class="btn btn-primary plan-modal" @click.prevent="openModal('make-co-payment', 'Make CAPP Co-payment', 'plan-card-info')"> Continue this Coverage </a>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useModalStore } from '@/stores/modal';
import SwitchInput from '@/components/form/SwitchInput.vue';
import PlanGeneral from '@/components/cards/PlanGeneral.vue';

export default defineComponent({
    components: {
        SwitchInput,
        PlanGeneral
    },
    setup() {
        return {
            openModal: useModalStore().openModal
        }
    }
})
</script>