<template>
  <main class="main" @click="handleClickOutside">
    <SidebarComponent v-if="isAuthenticated" />
    <MobileHeader v-if="isAuthenticated" @openModal="openModal" />

    <section class="main-content" :class="{ 'authenticated': isAuthenticated }" id="content">
      <HeaderComponent @openModal="openModal" @click.stop />

      <!-- Only show dashboard if no modals are open -->
      <router-view v-if="modals.length === 0" />

      <!-- Render Modals -->
      <div v-if="modals.length > 0" class="modal-container" @click.stop>
        <ModalComponent v-for="(modal, index) in modals" :key="index" :isVisible="modal.isVisible" :index="index"
          :modalType="modal.type" :title="modal.title" :classNames="modal.classes" :fund="modal.fund"
          :modalProps="modal.modalProps" @close="closeModal" />
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useModalStore } from '@/stores/modal';

import HeaderComponent from './components/HeaderComponent.vue';
import ModalComponent from './components/ModalComponent.vue';
import SidebarComponent from './components/sidebar/SidebarComponent.vue';
import MobileHeader from './components/sidebar/MobileHeader.vue';

export default defineComponent({
  components: {
    HeaderComponent,
    SidebarComponent,
    MobileHeader,
    ModalComponent,
  },

  setup() {
    const authStore = useAuthStore();
    const modalStore = useModalStore();
    const isAuthenticated = computed(() => authStore.isAuthenticated);
    const modals = computed(() => modalStore.modals);

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (!target.closest('.modal-container') && !target.closest('.plan-modal')) {
        modalStore.closeAllModals();
      }
    };

    return {
      isAuthenticated,
      modals,
      openModal: modalStore.openModal,
      closeModal: modalStore.closeModal,
      handleClickOutside
    };
  }
})
</script>