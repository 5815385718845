<template>
    <div class="container mt-4">
        <div class="position-fixed top-0 end-0 p-3" style="z-index: 100">
            <div class="alert alert-danger" v-show="error">{{ error }}</div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center mb-4">
                <h1>Member Current Coverage Search</h1>
            </div>
            <p>This page allows you to look for current coverage for your Member(s).</p>
            <div v-if="loadingSearch">Loading...</div>
            <div v-else-if="coverageList.length === 0" class="d-flex flex-column gap-4">
                <h5 class="mb-2">Filters</h5>
                <div class="d-flex align-items-center gap-4 w-100">
                    <span class="col-md-2"> Plan Type: </span>
                    <div class="w-50">
                        <Multiselect v-model="filters.PlanType" :options="planTypes" placeholder="Type to search"
                            :multiple="true" :hide-selected="true" :searchable="true" :close-on-select="true"
                            @select="handleTypeChange" label="description" track-by="code">
                        </Multiselect>
                    </div>
                </div>
                <div class="d-flex align-items-center gap-4 w-100">
                    <span class="col-md-2"> Member's NBF ID / SSN: </span>
                    <input type="text" v-model="filters.ParticipantID" class="form-control w-50" />
                </div>
                <div class="d-flex align-items-center gap-4 w-100">
                    <span class="col-md-2"> Member's First Name: </span>
                    <input type="text" v-model="filters.FirstName" class="form-control w-50" />
                </div>
                <div class="d-flex align-items-center gap-4 w-100">
                    <span class="col-md-2"> Member's Last Name: </span>
                    <input type="text" v-model="filters.LastName" class="form-control w-50" />
                </div>
                <div class="d-flex gap-2 align-self-end w-25">
                    <button class="btn btn-secondary" @click="resetFilters">Reset</button>
                    <button class="btn btn-primary w-50" @click="searchData">
                        {{ loadingSearch ? 'Searching...' : 'Search' }}</button>
                </div>
            </div>
            <div v-else-if="!loadingSearch && coverageList.length > 0" class="row">
                <div class="d-flex justify-content-between mb-4">
                    <button class="btn btn-secondary" @click="resetFilters">Change my current
                        Filters</button>
                    <button class="btn btn-primary" @click="printList">Print List</button>
                </div>
                <table class="table table-bordered table-striped" id="table-coverage-search">
                    <thead class="table-dark">
                        <tr class="text-center align-middle">
                            <th>NBF ID</th>
                            <th>Name</th>
                            <th>Current Coverage</th>
                            <th>Coverage <br> Effective Date - Term Date</th>
                            <th>Bad Address </th>
                            <th>Upcoming Coverage Plan C Balance/Plan A Days <br> Coming Soon </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="person in coverageList" :key="person.PersonID" class="text-center">
                            <td>{{ person.PersonID }}</td>
                            <td>{{ person.FullName }}</td>
                            <td>{{ person.Plan }}</td>
                            <td>{{ person.Coverage }}</td>
                            <td>{{ person.BadAddress }}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <PaginationComponent :paginator="paginator" @fetch-data="fetchPaginationData" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { reactive, ref, Ref } from 'vue';
import { Coverage, PlanType } from "@/interfaces/local";
import { Paginator } from "@/interfaces/interfaces";
import { usePrint } from '@/composable/usePrint';
import Multiselect from 'vue-multiselect';
import PaginationComponent from '@components/utils/PaginationComponent.vue';
import axios from 'axios';
import 'vue-multiselect/dist/vue-multiselect.css';

interface Filters {
    PlanType: PlanType[];
    FirstName: string;
    LastName: string;
    ParticipantID: string;
}

export default {
    components: {
        Multiselect,
        PaginationComponent
    },
    setup() {
        const loadingSearch = ref(false);
        const planTypes: PlanType[] = [
            { code: 'ALL', description: 'ALL' },
            { code: 'PLAN_A', description: 'Plan A' },
            { code: 'PLAN_C', description: 'Plan C' },
            { code: 'COBRA', description: 'COBRA' },
            { code: 'C_MRP', description: 'C-MRP' },
            { code: 'RMRP', description: 'RMRP' },
            { code: 'RETIREE', description: 'Retiree' }
        ];
        const filters: Filters = reactive({
            PlanType: [planTypes[0]],
            FirstName: '',
            LastName: '',
            ParticipantID: '',
        });
        const paginator: Paginator = reactive({
            per_page: 0,
            total: 0,
            last_page: 0,
            current_page: 0,
        });
        const page = ref(1);
        const coverageList: Ref<Coverage[]> = ref([]);
        const error = ref(null);
        const { printElementById } = usePrint();

        const resetFilters = () => {
            coverageList.value = [];

            Object.keys(filters).forEach((key) => {
                if (key === 'PlanType') {
                    (filters[key as keyof typeof filters] as PlanType[]) = [planTypes[0]];
                }
                else {
                    (filters[key as keyof typeof filters] as string) = '';
                }
            });
        }

        const handleTypeChange = (newSelection: PlanType) => {
            if (newSelection.code === 'ALL') {
                filters.PlanType = [planTypes[0]];
            }
            else {
                const filteredTypes: PlanType[] = JSON.parse(JSON.stringify(filters.PlanType)).filter((type: PlanType) => type.code !== 'ALL')

                filters.PlanType = [...filteredTypes];
            }
        }

        const searchData = async () => {
            loadingSearch.value = true;
            coverageList.value = [];

            await axios.get('api/local/search-coverage', {
                params: { ...filters, page: page.value }
            })
                .then(response => {
                    const res = response.data;
                    coverageList.value = res.Paginator.data.length > 0 ? res.Paginator.data : res.Coverages;
                    Object.assign(paginator, res.Paginator);
                    page.value = paginator.current_page;
                })
                .catch(errorRes => {
                    error.value = errorRes.response.data.error;
                    setTimeout(() => error.value = null, 3500);
                })

            loadingSearch.value = false;
        }

        const printList = printElementById('table-coverage-search', 'Member Coverage List');


        // Fetch Data For Selected Page
        const fetchPaginationData = (pageNumber: number) => {
            page.value = pageNumber;

            searchData();
        };

        return {
            loadingSearch,
            error,
            planTypes,
            paginator,
            filters,
            coverageList,
            resetFilters,
            handleTypeChange,
            fetchPaginationData,
            searchData,
            printList
        };
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
}
</style>