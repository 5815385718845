<template>
    <div :class="['bordered-card-wrapper', customClass]">
      <!-- Mobile Card Header -->
      <div class="mobile-card-header hide-desktop">
        <h2>{{ title }}</h2>

        <div v-if="actionButtons" class="action-buttons">
          <i class="iatse-icon-edit plan-modal" @click.prevent="openModal('add-beneficiary', 'Edit Beneficiary', 'plan-form plan-upload')"></i>
          <i class="iatse-icon-trash plan-modal" @click.prevent="openModal('success-modal', null, 'modal-success', null, props)"></i>
        </div>
      </div>

       <!-- Bordered Card Rows - Multiple-->
      <div v-if="mode === 'single'" class="bordered-card-rows-wrapper">
        <div class="bordered-card-rows">
          <div v-for="(item, key) in data" :key="key" class="bordered-card-row">
            <h5>{{ capitalize(key) }}</h5>
            <h4>{{ item }}</h4>

            <!-- Divider -->
            <div v-if="hasDivider && key !== Object.keys(item).length - 1" class="divider"></div>
          </div>
        </div>

        <!-- Delete Item -->
        <div v-if="deleteItem" class="delete-item plan-modal" @click.prevent="openModal('success-modal', null, 'modal-success', null, props)">
          <i class="iatse-icon-trash"></i>
        </div>
      </div>

      <!-- Bordered Card Rows - Single-->
        <template v-else>
            <div v-for="(item, index) in data" :key="index" class="bordered-card-rows-wrapper">
                <div class="bordered-card-rows">
                    <div v-for="(value, key, idx) in item" :key="key" class="bordered-card-row">
                        <!-- <h5>{{ capitalize(key) }}</h5> -->
                        <h4>{{ value }}</h4>

                        <!-- Divider -->
                        <div v-if="hasDivider && idx !== Object.keys(item).length - 1" class="divider"></div>
                    </div>
                </div>

                <!-- Delete Item -->
                <div v-if="deleteItem" class="delete-item plan-modal" @click.prevent="openModal('success-modal', null, 'modal-success', null, props)">
                    <i class="iatse-icon-trash"></i>
                </div>
            </div>
        </template>
    </div>
  </template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { useModalStore } from '@/stores/modal';

  export default defineComponent({
    props: {
      title: {
        type: String,
        default: '',
      },
      mode: {
        type: String,
        default: 'single',
      },
      data: {
        type: Array as () => Record<string, string | number>[],
        required: true,
      },
      actionButtons: {
        type: Boolean,
        default: false,
      },
      customClass: {
        type: String,
        default: '',
      },
      deleteItem: {
        type: Boolean,
        default: false,
      },
      hasDivider: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
        const capitalize = (text: string | number): string | number => {
          if (typeof text === 'string') {
            return text.charAt(0).toUpperCase() + text.slice(1);
          }

          return text;
        }

        return {
            capitalize,
            openModal: useModalStore().openModal,
            props: {
              title: 'Remove Beneficiary',
              content: {
                  type: 'option',
                  value: [
                      { label: 'Other', value: 'Distribute allocation equally among other beneficiaries' },
                      { label: 'Primary', value: 'Distribute allocation to primary beneficiary' },
                  ],
                  name: 'Beneficiary'
              },
              icon: 'iatse-icon-user-verified'
          }
        }
    },
  });
  </script>