<template>
    <div id="participantparticipant" class="d-flex flex-column gap-2">
        <div class="form-group d-flex flex-column gap-2">
            <label for="id_ssn">Participant ID or Last 4 SSN <span class="text-danger">*</span></label>
            <input required v-model="participant.ID_SSN" type="number" class="form-control" id="id_ssn"
                name="Participant[ID_SSN]">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="email">Email <span class="text-danger">*</span></label>
            <input required v-model="participant.Email" type="email" class="form-control" id="email"
                name="Participant[Email]">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="first_name">First Name <span class="text-danger">*</span></label>
            <input required v-model="participant.FirstName" type="text" class="form-control" id="first_name"
                name="Participant[FirstName]">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="last_name">Last Name <span class="text-danger">*</span></label>
            <input required v-model="participant.LastName" type="text" class="form-control" id="last_name"
                name="Participant[LastName]">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="date_of_birth">Date of Birth <span class="text-danger">*</span></label>
            <input required v-model="participant.DateofBirth" type="date" class="form-control" id="date_of_birth"
                name="Participant[DateofBirth]">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="password">Password <span class="text-danger">*</span></label>
            <input required v-model="participant.Password" type="password" class="form-control" id="password"
                name="Participant[Password]">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="password_confirmation">Confirm Password <span class="text-danger">*</span></label>
            <input required v-model="participant.Password_confirmation" type="password" class="form-control"
                id="password_confirmation" name="Participant[Password_confirmation]">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="security_question">Security Question </label>
            <select v-model="participant.SecurityQuestion" class="form-control" id="security_question"
                name="Participant[SecurityQuestion]">
                <option v-for="question in securityQuestions" :key="question.SecurityQuestionID"
                    :value="question.SecurityQuestionID">
                    {{ question.Question }}
                </option>
            </select>
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="answer">Answer</label>
            <input v-model="participant.Answer" type="text" name="Participant[Answer]" class="form-control" />
        </div>
    </div>
    <div class="form-group row mb-0">
        <div class="col-md-12 d-flex justify-content-center">
            <button type="button" class="btn btn-primary" @click="handleRegister">{{ loadingSubmit ? 'Submitting...' :
                'Register' }}</button>
        </div>
    </div>
</template>

<script lang="ts">
import { SecurityQuestion } from '@/interfaces/interfaces';
import { PropType, defineComponent, reactive } from 'vue';

export default defineComponent({
    props: {
        securityQuestions: Array as PropType<SecurityQuestion[]>,
        loadingSubmit: Boolean
    },
    setup(props, { emit }) {
        const participant = reactive({
            ID_SSN: '',
            Email: '',
            FirstName: '',
            LastName: '',
            DateofBirth: '',
            Password: '',
            Password_confirmation: '',
            SecurityQuestion: 1,
            Answer: '',
        });

        const handleRegister = () => {
            emit('register', participant);
        }

        return {
            participant,
            handleRegister
        };
    },
})
</script>