<template>
  <div>
    <h4 class="mb-4 text-center">Manage Employer / Payroll Company (Paying Agent) Accounts</h4>
    <h6 class="mt-4">Search Employer / Payroll Company (Paying Agent)</h6>
    <form method="GET">
      <div class="form-group row mb-4">
        <div class="col-md-2">
          <label for="participant_id" class="col-form-label text-md-right">EIN/TAXID: </label>
          <input type="number" id="participant_id" v-model="filters.TaxID" class="form-control">
        </div>
        <div class="col-md-4">
          <label for="firstname" class="col-form-label text-md-right">Company Name:</label>
          <input type="text" id="firstname" name="FirstName" v-model="filters.CompanyName" class="form-control">
        </div>
        <div class="col-md-6 d-flex justify-content-between align-items-end">
          <button type="button" class="btn btn-success" @click="submitFilters(0)"> {{ searching ? 'Searching...' :
            'Search' }} </button>
          <div>
            <button type="button" class="btn btn-info" @click="submitFilters(1)"> All Employers </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { SearchEmployersFormFilters } from '@/interfaces/admin';

export default defineComponent({
  props: {
    searching: Boolean
  },
  setup(_, { emit }) {
    const filters: SearchEmployersFormFilters = reactive({
      TaxID: null,
      CompanyName: '',
    });

    const submitFilters = (type: number) => {
      if (type === 1) {
        filters.TaxID = null;
        filters.CompanyName = '';
      }

      emit('update-filters', filters);
    }

    return {
      filters,
      submitFilters
    };
  }
});
</script>