/**
 * Transforms an object with key-value pairs into an array of objects with key and value properties.
 *
 * @param obj - The object to transform.
 * @returns An array of objects with key and value properties.
 */
export const useOptionsFormatter = <T>(obj: Record<string, T>): { key: number | string ; value: T }[] => {
    return Object.entries(obj).map(([key, value]) => ({
        key,
        value
    }));
}