import {
    VacationDashboardInfo,
    VacationChecks,
    VacationHistoryRow
} from "@/interfaces/interfaces";
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { useTableFormatter } from "@/composable/useTableFormatter";
import { useOptionsFormatter } from "@utils/optionFormatter";
import { defineStore } from "pinia";
import axios from "axios";

export const useVacationStore = defineStore("vacation", {
    state: () => ({
        data: null as VacationDashboardInfo | null,
        history: {
            headers: [
                { title: 'Date' },
                { title: 'Contributions' },
                { title: 'Disbursements' },
                { title: 'Voids' },
                { title: 'Interest' },
                { title: 'Admin Fee' },
            ],
            rows: {} as any[],
        },
        contributions: {
            headers: [
                { title: 'CheckDate' },
                { title: 'Contributions' },
                { title: 'Disbursements' },
                { title: 'Voids' },
                { title: 'Interest' },
                { title: 'Admin Fee' },
            ],
            rows: [],
        }
    }),
    actions: {
        async fetchVacationData(route: string) {
            try {
                const response = await axios.get(route);
            } catch (error) {
                console.error("Error fetching vacation info:", error);
            }
        },

        async fetchHistory(route: string) {

            try {
                const response = await axios.get(route);

                if (response.data && response.data) {
                    this.history.rows = [];

                    Object.values(response.data).map((historyItem) => {
                        const rows = useTableFormatter(historyItem as Record<string | number, unknown>);

                        if(rows) {
                            this.history.rows.push(rows)
                        }
                    })
                }

            } catch (error) {
                console.error("Error fetching vacation history:", error);
            }
        },

        setVacationData(data: VacationDashboardInfo) {
            this.data = data;
        },

        reset() {
            this.$reset();
        },
    },

    getters: {
        generalInfo: (state) => {
            if (!state.data) {
                return null;
            }

            let data = null;

            if (state.data.TotalContributions.Total > 0) {
                data = {
                    Total: state.data.TotalContributions.Total,
                    Label: "Reporting Period: ",
                    Date:
                        state.data.ReportingYear.From +
                        " - " +
                        state.data.ReportingYear.To,
                };
            } else if (state.data.TotalPrevContributions.Total > 0) {
                data = {
                    Total: state.data.TotalPrevContributions.Total,
                    Label: !state.data.CheckInfo.CurrentCheck
                        ? "Contributions for "
                        : "Payment for ",
                    Date: state.data.TotalPrevContributions.Year,
                };
            } else {
                data = {
                    Total: state.data.LastContribution.Amount,
                    Label: "Last Reporting Period: ",
                    Date:
                        state.data.ReportingYear.From +
                        " - " +
                        state.data.ReportingYear.To,
                };
            }

            return {
                ...data,
                Total: useCurrencyFormatter().formatCurrency(data.Total),
                CheckDestination: state.data.VacationCheckDestination,
            };
        },
    },
});
