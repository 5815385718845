import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container mt-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-12 d-flex flex-column gap-4" }
const _hoisted_4 = { class: "text-center mb-4" }
const _hoisted_5 = {
  class: "position-fixed top-0 end-0 p-3",
  style: {"z-index":"11"}
}
const _hoisted_6 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CoverageStep1 = _resolveComponent("CoverageStep1")!
  const _component_CoverageStep2 = _resolveComponent("CoverageStep2")!
  const _component_DownloadStep = _resolveComponent("DownloadStep")!
  const _component_CoverageStep3 = _resolveComponent("CoverageStep3")!
  const _component_CoverageStep4 = _resolveComponent("CoverageStep4")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString($setup.download ? 'View' : '') + " Employee Health Coverage Information Request ", 1),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("div", { class: "alert alert-success" }, _toDisplayString($setup.success), 513), [
            [_vShow, $setup.success]
          ]),
          ($setup.errors && $setup.errors?.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (Array.isArray($setup.errors))
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.errors, (error, index) => {
                        return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(error), 1))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString($setup.errors), 1))
              ]))
            : _createCommentVNode("", true)
        ]),
        ($setup.currentStep === 1)
          ? (_openBlock(), _createBlock(_component_CoverageStep1, {
              key: 0,
              instructions: $setup.instructions,
              onHandleNext: $setup.handleNext
            }, null, 8, ["instructions", "onHandleNext"]))
          : _createCommentVNode("", true),
        ($setup.currentStep === 2)
          ? (_openBlock(), _createBlock(_component_CoverageStep2, {
              key: 1,
              ipAddress: $setup.ipAddress,
              download: $setup.download,
              onHandleBack: $setup.handleBack,
              onHandleNext: $setup.handleNext
            }, null, 8, ["ipAddress", "download", "onHandleBack", "onHandleNext"]))
          : _createCommentVNode("", true),
        ($setup.currentStep === 3 && $setup.download)
          ? (_openBlock(), _createBlock(_component_DownloadStep, {
              key: 2,
              requests: $setup.requests
            }, null, 8, ["requests"]))
          : _createCommentVNode("", true),
        ($setup.currentStep === 3 && !$setup.download)
          ? (_openBlock(), _createBlock(_component_CoverageStep3, {
              key: 3,
              onHandleBack: $setup.handleBack,
              onHandleNext: $setup.handleNext
            }, null, 8, ["onHandleBack", "onHandleNext"]))
          : _createCommentVNode("", true),
        ($setup.currentStep === 4)
          ? (_openBlock(), _createBlock(_component_CoverageStep4, {
              key: 4,
              onHandleBack: $setup.handleBack,
              onHandleSubmit: $setup.handleSubmit,
              ssnOption: $setup.ssnOption,
              excelModel: $setup.excelModel,
              submitting: $setup.submitting
            }, null, 8, ["onHandleBack", "onHandleSubmit", "ssnOption", "excelModel", "submitting"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}