<template>
    <div class="container mt-4">
        <h1 class="text-center">Contact us</h1>

        <div class="d-flex justify-content-between align-items-center flex-wrap mb-4">
            <div>
                <div class="d-flex flex-column mb-4">
                    <h6> IATSE National Benefits Fund Office </h6>
                    <span> 417 Fifth Avenue </span>
                    <span> 3rd Floor </span>
                    <span> New York, NY 10016-2204 </span>
                </div>
                <div class="d-flex flex-column mb-4">
                    <h6> Main Numbers </h6>
                    <span> Main Number: 212-580-9092 </span>
                    <span> Toll Free Number: 800-456-3863 </span>
                    <span> Fax Number: 212-787-3607 </span>
                </div>
                <div class="d-flex flex-column mb-4">
                    <h6> Email Addresses </h6>
                    <span> Participant Services: psc@iatsenbf.org </span>
                    <span> Webmaster: webadmin@iatsenbf.org - Email Webmaster for any website technical issues. </span>
                </div>
            </div>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.518936076278!2d-73.98528472420662!3d40.75060977138782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25900a9b83733%3A0xa44f7f885d246bb9!2s417%205th%20Ave%203rd%20Floor%2C%20New%20York%2C%20NY%2010016%2C%20USA!5e0!3m2!1sen!2sro!4v1699885258826!5m2!1sen!2sro"
                width="500" height="300" style="border:0;" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
            </iframe>
        </div>
        <form v-if="userName" @submit.prevent="handleSubmit">
            <div class="form-group row mb-4 d-flex flex-column align-items-center gap-4">
                <div v-if="errors.length > 0" class="alert alert-danger">
                    <ul>
                        <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                    </ul>
                </div>
                <div v-show="success" class="alert alert-success">{{ success }}</div>
                <div class="d-flex justify-content-between gap-4">
                    <label for="topic" class="col-form-label col-md-2 w-25 w-sm-auto text-md-right">Topic <span
                            class="text-danger">*</span></label>
                    <div class=" w-75 col-sm-4 d-flex flex-column gap-4">
                        <select name="Topic" id="topic" class="form-control" v-model="formData.Topic">
                            <option value="0">Please select a Help Topic</option>
                            <option v-for="topic in topics" :key="topic.UIContactTopicID"
                                :value="topic.UIContactTopicID">{{
            topic.Topic }}</option>
                        </select>
                    </div>
                </div>
                <div class="d-flex justify-content-between gap-4">
                    <label for="full_name" class="col-form-label col-md-2 w-25 w-sm-auto text-md-right"> Full Name <span
                            class="text-danger">*</span></label>
                    <div class="w-75 col-sm-4 d-flex flex-column gap-4">
                        <input type="text" id="full_name" v-model="formData.FullName" class="form-control">
                    </div>
                </div>
                <div class="d-flex justify-content-between gap-4">
                    <label for="email" class="col-form-label col-md-2 w-25 w-sm-auto text-md-right">Email Address
                        <span class="text-danger">*</span></label>
                    <div class=" w-75 col-sm-4 d-flex flex-column gap-4">
                        <input type="email" id="email" v-model="formData.Email" class="form-control">
                    </div>
                </div>
                <div class="d-flex justify-content-between gap-4">
                    <label for="email_confirmation" class="col-form-label col-md-2 w-25 w-sm-auto text-md-right">Confirm
                        Email
                        <span class="text-danger">*</span> </label>
                    <div class=" w-75 col-sm-4 d-flex flex-column gap-4">
                        <input type="email" id="email_confirmation" v-model="formData.Email_confirmation"
                            class="form-control">
                    </div>
                </div>
                <div class="d-flex justify-content-between gap-4">
                    <label for="phone_number" class="col-form-label col-md-2 w-25 w-sm-auto text-md-right">
                        Phone Number
                        <span class="text-danger">*</span></label>
                    <div class=" w-75 col-sm-4 d-flex flex-column gap-4">
                        <input type="text" id="phone_number" v-model="formData.PhoneNumber" class="form-control">
                    </div>
                </div>
                <div class="d-flex justify-content-between gap-4">
                    <label for="message" class="col-form-label col-md-2 w-25 w-sm-auto text-md-right">
                        Message <span class="text-danger">*</span></label>
                    <div class=" w-75 col-sm-4 d-flex flex-column gap-4">
                        <textarea id="message" v-model="formData.Message" class="form-control"> </textarea>
                    </div>
                </div>
                <div class="d-flex flex-column align-items-end">
                    <button type="submit" class="btn btn-primary">{{ loadingSubmit ? 'Submitting...' : 'Submit'
                        }}</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { useAuthStore } from '@/stores/auth';
import { Ref, onMounted, reactive, ref } from 'vue';
import axios from 'axios';

interface Topic {
    UIContactTopicID: number,
    Topic: string
}

interface FormData {
    FullName: string,
    Email: string
    Email_confirmation: string
    PhoneNumber: string
    Message: string
    Topic: number
}

export default {
    setup() {
        const authStore = useAuthStore();
        const topics: Ref<Topic[]> = ref([]);
        const userName = authStore.userName;
        const userEmail = authStore.userEmail;
        const formData: FormData = reactive({
            FullName: userName ?? '',
            Email: userEmail ?? '',
            Topic: 0,
            Email_confirmation: userEmail ?? '',
            PhoneNumber: '',
            Message: '',
        });
        const route = 'api/contact';
        const loadingSubmit = ref(false);
        const success = ref(null);
        const errors: Ref<string[]> = ref([]);

        // Fetch Data
        const fetchData = async () => {

            await axios.get(route)
                .then(response => {
                    topics.value = response.data.Topics;
                })
        };

        // Send Email
        const handleSubmit = async () => {
            loadingSubmit.value = true;

            await axios
                .post(route, { ...formData })
                .then((response) => {
                    success.value = response.data?.success;
                    loadingSubmit.value = false;
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }

                    loadingSubmit.value = false;
                });

            setTimeout(() => {
                success.value = null;
                errors.value = [];
            }, 5000);
        };
        onMounted(async () => await fetchData());

        return {
            topics,
            userName,
            userEmail,
            formData,
            loadingSubmit,
            errors,
            success,
            handleSubmit
        }

    }
};
</script>
