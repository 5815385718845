<template>
    <div class="range-slider">
        <label v-if="label" class="main-label">{{ label }}</label>

        <div v-for="slider in sliders" :key="slider.id" class="range-slider-item">
            <div class="range-slider-input">
                <label class="label">{{ slider.label || 'Slider' }}</label>
                <input
                    type="range"
                    class="slider"
                    v-model="values[slider.id]"
                    :min="slider.min || 0"
                    :max="slider.max || 100"
                    :step="slider.step || 1"
                    :id="slider.id"
                    :style="{ '--value': sliderValuesAsPercentage[slider.id] + '%' }"
                    @input="updateValue(slider.id)"
                />
            </div>
            <span class="slider-value">{{ sliderValuesAsPercentage[slider.id] }}%</span>
            <a v-if="deleteButton" href="" class="btn btn-secondary" @click.prevent="deleteSlider(slider.id)">Delete</a>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
    props: {
        sliders: {
            type: Array as () => Array<{
                id: string;
                label?: string;
                min?: number;
                max?: number;
                step?: number;
                defaultValue?: number;
            }>,
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
        deleteButton: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const values = ref<Record<string, number>>({});

        // Initialize slider values
        props.sliders.forEach(slider => {
            values.value[slider.id] = slider.defaultValue || 50;
        });

        const updateValue = (id: string) => {
            const value = values.value[id];
            const slider = document.getElementById(id) as HTMLInputElement;
            const percentage = ((value - (Number(slider?.min) || 0)) / ((Number(slider?.max) || 100) - (Number(slider?.min) || 0))) * 100;
            slider.style.setProperty('--value', percentage + '%');
        };

        const sliderValuesAsPercentage = computed(() => {
            const percentages: Record<string, number> = {};

            props.sliders.forEach(slider => {
                const value = values.value[slider.id];
                const percentage = ((value - (Number(slider?.min) || 0)) / ((Number(slider?.max) || 100) - (Number(slider?.min) || 0))) * 100;
                percentages[slider.id] = Number(percentage.toFixed(2));
            });

            return percentages;
        });

        //  Slider deletion logic
        const deleteSlider = (id: string) => {
            console.log('Deleting slider with id:', id);
        };

        return {
            values,
            sliderValuesAsPercentage,
            updateValue,
            deleteSlider,
        };
    },
});
</script>