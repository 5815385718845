<template>
    <div :class="['drag-drop', customClasses]" ref="dragDropArea" @dragover.prevent @dragenter.prevent="handleDragEnter" @dragleave.prevent="handleDragLeave" @drop.prevent="handleDrop ">
      <div class="choose-files" v-if="!files.length">
        <i class="iatse-icon-document-upload"></i>
        <h5>Drag and Drop or <span>Choose From Device</span></h5>
        <input type="file" ref="fileInput" style="display: none;" multiple/>
      </div>

      <div class="drag-drop-files">
        <div v-for="(file, index) in files" :key="index" class="file-preview">
          <img
            src="../../assets/images/doc.png"
            :alt="file.name"
            class="file-image-preview"
            width="95"
            height="55"
          />
          <small class="file-name">{{ file.name }}</small>
        </div>
      </div>
    </div>
  </template>

  <script lang="ts">
  import { defineComponent, ref, watch } from 'vue';

  export default defineComponent({
    props: {
      customClasses: {
        type: String,
        default: '',
      },
      reset: Boolean,
    },
    setup(props, {emit}) {
      const files = ref<File[]>([]);
      const fileInput = ref<HTMLInputElement | null>(null);
      const dragDropArea = ref<HTMLDivElement | null>(null);

      watch(() => props.reset, (newValue) => {
        if(newValue) {
          files.value = [];
        }
      });

      const handleDragEnter = (event: DragEvent) => {
        if (dragDropArea.value) {
          dragDropArea.value.style.border = '2px dashed #8EC986';
        }
      };

      const handleDragLeave = (event: DragEvent) => {
          if (dragDropArea.value) {
            dragDropArea.value.style.border = '1px dashed #8EC986';
          }
      };

      const handleDrop = (event: DragEvent) => {
        if (event.dataTransfer && event.dataTransfer.files) {
          files.value = [...files.value, ...Array.from(event.dataTransfer.files)];
        }

        emit('change', event);
      };


      return {
        files,
        fileInput,
        dragDropArea,
        handleDrop,
        handleDragLeave,
        handleDragEnter
      };
    },
  });
  </script>