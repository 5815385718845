<template>
    <div v-if="loading" class="container">Loading...</div>
    <div v-else class="container">
        <h3 class="text-center myb-4 py-4">{{ title }}</h3>
        <div v-if="errors.length > 0" class="alert alert-danger">
            <ul>
                <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
        </div>
        <div v-if="formRoute === 'participant.dependents.add'">
            <ReviewAddDependent :data="formData" :documents="documents" />
        </div>
        <div v-else-if="formRoute === 'participant.personal.info'">
            <ReviewUpdateInfo :data="formData" />
        </div>
        <ReviewInfo :approveButton="approveButton" :comments="formData?.Comments" :status="status"
            @handle-review="handleReview" />
        <SuccessModal v-if="isVisible" :message="message" title="Confirmation" route="superadmin.requests"
            :param="{ name: 'status', value: status }" @submit-data="handleSubmit" />

        <router-link :to="{ name: 'superadmin.requests', params: { status: status } }" class="btn btn-secondary w-25 h-25">
            Back To Requests
        </router-link>
    </div>
</template>

<script lang="ts">
import { watch, ref, onMounted, Ref, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ReviewDependentData, Document } from '@interfaces/interfaces'
import ReviewAddDependent from '@components/participant/dependents/ReviewAddDependent.vue'
import ReviewUpdateInfo from '@components/profile/personal-info/ReviewUpdateInfo.vue'
import SuccessModal from '@components/participant/dependents/SuccessModal.vue'
import ReviewInfo from '@components/superadmin/ReviewInfo.vue'
import axios from 'axios';

export default {
    components: {
        ReviewAddDependent,
        ReviewUpdateInfo,
        ReviewInfo,
        SuccessModal
    },
    setup() {
        const success = ref(null);
        const loading = ref(false);
        const formRoute = ref('');
        const approveButton: Ref<Record<string, boolean> | null> = ref(null);
        const formData: Ref<ReviewDependentData | null> = ref(null);
        const reviewSection = ref(false);
        const title = ref('');
        const status: Ref<string | number> = ref(1);
        const documents: Ref<Document[] | []> = ref([]);
        const isVisible = ref(false);
        const message = ref('');
        const successModalRef = ref(null);
        const loadingSubmit = ref(false);
        const errors: Ref<string[]> = ref([]);
        const router = useRouter();
        const data = reactive({ Comments: '' });
        let adminID = useRoute().params.UIAdminID;
        const action = ref('');

        watch(
            () => adminID, (newUIAdminID) => {
                adminID = newUIAdminID;
                fetchData();
            }
        );

        // Display Confirmation Modal
        const handleReview = (type: string, commentsInput: string) => {
            message.value = `Are you sure you want to ${type}?`;
            isVisible.value = true;
            data.Comments = commentsInput;
            action.value = type;
        }

        // Resolve Request
        const handleSubmit = () => {
            loadingSubmit.value = true;

            axios.post(`api/administrator/resolve-request/${adminID}`, { ...data, _method: "PATCH" }, { params: { Action: action.value } })
                .then((response) => {
                    const res = response.data;
                    loadingSubmit.value = false;
                    router.push({ name: res.route, params: { status: res.Status } });
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = Object.values(error.response.data.errors).flat() as string[];
                        loadingSubmit.value = false;
                    }
                })
        };

        const fetchData = async () => {
            loading.value = true;

            await axios.get(`api/administrator/review-request/${adminID}`)
                .then(response => {
                    const data = response.data;
                    formRoute.value = data.FormRoute;
                    approveButton.value = data.ApproveButton;
                    formData.value = data.Data;
                    reviewSection.value = data.ReviewSection;
                    title.value = data.Title;
                    documents.value = data.Documents;
                    status.value = data.Status;
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });

            loading.value = false;
        };

        onMounted(async () => await fetchData());

        return {
            formData,
            formRoute,
            title,
            approveButton,
            reviewSection,
            documents,
            status,
            message,
            isVisible,
            success,
            loading,
            errors,
            successModalRef,
            handleReview,
            handleSubmit
        };
    },
};

</script>