import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "card-header-wrapper" }
const _hoisted_3 = { class: "card-header-fund" }
const _hoisted_4 = {
  key: 0,
  class: "line"
}
const _hoisted_5 = {
  key: 1,
  class: "plus iatse-icon-plus"
}
const _hoisted_6 = {
  key: 0,
  class: "card-content card-toggle row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FundAlert = _resolveComponent("FundAlert")!

  return (_openBlock(), _createElementBlock("div", {
    class: "card",
    id: _ctx.cardId
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "icon"),
        _createElementVNode("h2", null, [
          _renderSlot(_ctx.$slots, "title")
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["card-control", { 'plus': !_ctx.visibleContent }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleVisible && _ctx.toggleVisible(...args)))
      }, [
        (_ctx.visibleContent)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4))
          : _createCommentVNode("", true),
        (!_ctx.visibleContent)
          ? (_openBlock(), _createElementBlock("i", _hoisted_5))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _createVNode(_Transition, { name: "slide-fade" }, {
      default: _withCtx(() => [
        (_ctx.visibleContent)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "default")
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }),
    (_ctx.alerts)
      ? (_openBlock(), _createBlock(_component_FundAlert, {
          key: 0,
          alerts: _ctx.alerts,
          onOpen: _ctx.openModal
        }, null, 8, ["alerts", "onOpen"]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}