<template>
    <!-- Plan Content -->
    <div class="plan-content">
        <!-- General Plan Detail Cards -->
        <PlanGeneral
            text="You can select a coverage option from any of the option shown below. Even if you do not owe a co-payment for the co-payment for the coverage you are selecting, you must complete the process by clicking  the “Select this Coverage” button. Your Payment is due 12/23/2012 for the coverage period starting 01/01/2013." />

        <!-- Forms -->
        <form id="make-co-payment-form" class="form make-co-payment" method="post">
            <div class="inputs-wrapper">
                <TextInput type="text" label="Card Type" placeholder="Enter here" :max=25
                    v-model:modelValue="formData.cardType" />
                <TextInput type="text" label="Cardholder Name" placeholder="Enter here" :max=25
                    v-model:modelValue="formData.cardholder" />
                <TextInput type="text" label="Card Number" placeholder="Enter here" :max=19
                    customClass="card-number-input" v-model:modelValue="formData.cardNumber" />
                <DateInput label="Card Expiration Date" v-model:modelValue="formData.expirationDate" pattern="MM/YY"
                    :icon="false" />
                <TextInput type="text" label="CVV" placeholder="XXX" :max=3 customClass="cvv"
                    v-model:modelValue="formData.cvv" />
            </div>

            <div class="bordered-card">
                <div class="bordered-card-header"> <small> Selected Coverage </small></div>
                <div class="bordered-card-body">
                    <h4> Plan C2 Single </h4>
                    <div class="divider"> </div>
                    <div class="bordered-card green-card">
                        <h6> Amount Owned</h6>
                        <h4> $ 1,086.00 </h4>
                    </div>
                    <a class="btn btn-primary"> Change Coverage </a>
                </div>
            </div>

            <div class="action-buttons">
                <a href="" class="btn btn-secondary plan-modal" @click.prevent="closeModal"> Cancel </a>
                <a href="" class="btn btn-primary plan-modal"
                    @click.prevent="openModal('success-modal', null, 'modal-success', null, props)"> Submit </a>
            </div>
        </form>
    </div>
</template>
<script lang="ts">
import { reactive, defineComponent } from 'vue';

import TextInput from '@components/form/TextInput.vue';
import DateInput from '@components/form/DateInput.vue';
import PlanGeneral from '@components/cards/PlanGeneral.vue';
import { useModalStore } from '../../../stores/modal';

export default defineComponent({
    components: {
        TextInput,
        DateInput,
        PlanGeneral
    },
    setup(_, { emit }) {
        const formData = reactive({
            cardType: null,
            cardholder: null,
            cardNumber: null,
            expirationDate: null,
            cvv: null,
        });

        const closeModal = () => {
            emit('close');
        }

        return {
            formData,
            props: {
                title: 'Payment Successful!',
                content: {
                    type: 'text',
                    value: 'An email has been sent to john*****123@gmail.com with payment summary and receipt.',
                },
                icon: 'iatse-icon-user-verified'
            },
            openModal: useModalStore().openModal,
            closeModal
        }
    }
})
</script>