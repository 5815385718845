<template>
    <p>
        This page allows you to view all requests you have made regarding the employer mandate reporting for the past 24
        months. You can also download the output files for each of these requests.
    </p>
    <h6>STEP - Download </h6>
    <table class="table table-bordered table-striped">
        <thead class="table-dark">
            <tr class="text-center">
                <th>Date/Time Requested</th>
                <th>For Coverage Year</th>
                <th># of Employees in Req</th>
                <th>Requestor</th>
                <th>File Name</th>
                <th>Download As</th>
            </tr>
        </thead>
        <tbody v-if="requests?.length">
            <tr v-for="request in requests" :key="request.RequestID">
                <td> {{ request.Date }}</td>
                <td> {{ request.Year }}</td>
                <td> {{ request.EmployeesNumber }}</td>
                <td> {{ request.Requestor }}</td>
                <td> {{ request.FileName }}</td>
                <td class="text-center col-md-2">
                    <button class="btn btn-sm btn-success" @click="handleDownload(request)">
                        {{ request.Downloading ? 'Downloading...' : 'CSV/Excel' }}
                    </button>
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td colspan="6" class="text-center"> No Available Requests</td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from 'vue';
import { HealthCoverageRequest } from '@/interfaces/employer';
import { saveAs } from 'file-saver';
import axios from 'axios';

export default defineComponent({
    props: {
        requests: Array as PropType<HealthCoverageRequest[]>
    },
    setup() {
        const handleDownload = async (request: HealthCoverageRequest) => {
            request.Downloading = true;

            try {
                const response = await axios.get(`api/employer/health-coverage-data/download-file/${request.FileName}`, {
                    responseType: 'blob',
                });

                const blob = new Blob([response.data], {
                    type: response.headers['content-type'],
                });

                saveAs(blob, request.FileName);
            }
            catch (error) {
                console.error('Error downloading file:', error);
            }

            request.Downloading = false;
        }

        return {
            handleDownload
        }
    }
})
</script>