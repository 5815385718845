<template>
    <div class="container mt-4">
        <div class="row d-flex align-items-center mb-4">
            <div class="col-md-8">
                <h1>Vacation Contribution Summary</h1>
            </div>
            <div class="col-md-2 text-center">
                <router-link :to="`/participant/vacation/summary/${parseInt(year) - 1}`" class="btn btn-primary">Previous Year</router-link>
            </div>
            <div class="col-md-2 text-center">
                <router-link :to="`/participant/vacation/summary/${parseInt(year) + 1}`" class="btn btn-primary">Next Year</router-link>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 border d-flex flex-column justify-content-around gap-4 p-4 py-8">
                <div>
                    <span class="fw-bold"> Employer Contributions To Date For
                        Year
                    </span> : <a
                        href=".vacation.details', ['fromDate' => FromDate, 'toDate' => ToDate]">
                            {{ vacationData?.TotalContributions }}
                        </a>
                </div>
                <div>
                    <router-link :to="{ name: 'participant.vacation.benefits-history' }">View Payment History</router-link>
                </div>
                <div>
                    <router-link :to="{ name: 'participant.vacation.check-replacement' }">Check Replacement Request</router-link>
                </div>
                <div>
                    <span class="fw-bold"> Check Destination: </span>
                    <span v-if="vacationData?.Vacation.VacationCheckDestination">
                        {{ vacationData?.Vacation.VacationCheckDestination }}
                    </span>
                </div>
                <div class="d-flex flex-column align-items-stretch gap-4">
                    <div>
                        <button class="btn btn-primary w-100">View My Beneficiaries</button>
                    </div>
                    <div>
                        <button class="btn btn-primary w-100">Add New Beneficiary</button>
                    </div>
                </div>
            </div>

            <VacationSummaryChart v-if="vacationData" :graph-data="vacationData.GraphData" />
        </div>
    </div>
</template>

<script lang="ts">
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';
import VacationSummaryChart from '../../components/participant/VacationSummaryChart.vue';
import { VacationYearlyData } from '@/interfaces/interfaces';

export default {
    setup() {
        const vacationData = ref<VacationYearlyData>();
        const route = useRoute();
        const year = ref(route.params.year as string || new Date().getFullYear().toString());

        const fetchData = async () => {
            try {
                const response = await axios.get('api/participant/vacation/summary/' + year.value);
                vacationData.value = response.data;
            }
            catch (error) {
                console.error('Error:', error);
            }
        };

        watch(() => route.params.year, async (newYear) => {
            year.value = newYear as string;
            await fetchData();
        });

        onMounted(fetchData);

        return {
            vacationData,
            year
        };
    },
    components: {
        VacationSummaryChart
    }
};
</script>
