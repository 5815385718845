<template>
    <div class="container mt-4">
        <div class="row d-flex align-items-center mb-4">
            <div class="col-md-12 text-center">
                <h1 class="mb-4">History of Vacation Benefits Paid</h1>
                <p>This is a history of checks sent to you that were due for vacation benefits.</p>
            </div>
        </div>
            <table v-if="checks.length > 0" class="table table-bordered table-striped">
                <thead>
                    <tr class="row-1">
                        <th>Check #</th>
                        <th>Date</th>
                        <th>Gross Amount</th>
                        <th>Admin Fee</th>
                        <th>Payment Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(check, index) in checks" :key="index">
                        <td>{{ check.CheckNumber }}</td>
                        <td>{{ check.CheckDate }}</td>
                        <td>${{ check.Amount.Gross }}</td>
                        <td>${{ check.Amount.AdminFee }}</td>
                        <td>${{ check.Amount.PaymentAmount }}</td>
                    </tr>
                </tbody>
            </table>
            <div v-else class="col-md-12 text-center my-4">
                <span> There are no vacation checks to be found for the selected period</span>
            </div>
        <a href="javascript:history.back()" class="btn btn-primary">Back</a>
    </div>
</template>

<script lang="ts">
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { VacationCheckDetails, VacationChecksData } from '@/interfaces/interfaces';

export default {
    setup() {
        const checks = ref<VacationChecksData[]>([]);

        const fetchData = async () => {
            try {
                const response = await axios.get('/api/participant/vacation/history');

                if (response.data && response.data.Checks) {
                    checks.value = Object.values(response.data.Checks);
                }
            }
            catch (error) {
                console.error('Error:', error);
            }
        };

        onMounted(() => {
            fetchData();
        });

        return {
            checks
        }
    }
};
</script>
