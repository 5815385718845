<template>
    <div class="col-md-3">
        <div class="card">
            <div class="card-body">
                <h3>H&W</h3>

                <table>
                    <tr>
                        <td></td>
                        <td>Coverage Period: <strong>{{ data.Coverage.Current.From }}</strong> to <strong>{{ data.Coverage.Current.To }}</strong></td>
                    </tr>
                    <tr>
                        <td colspan="2">Coverage Type: <strong>{{ data.Coverage.Current.Plan }}</strong></td>
                    </tr>
                </table>

                
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent, ref, toRefs, watch, onMounted, computed } from 'vue';
    import { HealthDashboardInfo } from '@/interfaces/health';

    export default defineComponent({
        props: {
            data: {
                type: Object as () => HealthDashboardInfo,
                required: true
            }
        },

        setup(props) {
            const { data } = toRefs(props);

            console.log('data');
            console.log(data);
        }
    });
</script>