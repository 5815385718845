<template>
    <div class="modal-body">
        <div class="plan-header">
            <div class="plan-breadcrumbs">
                <div class="close-btn close-plan-modal" @click="closeModal"> <i class="iatse-icon-close"></i></div>
            </div>
        </div>
        <i :class="modalProps.icon"> </i>
        <h2> {{ modalProps.title }} </h2>
        <p v-if="modalProps.content.type === 'text'"> {{ modalProps.content.value }} </p>
        <RadioButton v-else :options="modalProps.content.value" :name="modalProps.content.name"/>
    </div>

    <div class="modal-actions">
        <a href="" class="btn btn-secondary plan-modal" @click.prevent="closeModal"> Cancel </a>
        <a href="" class="btn btn-primary plan-modal" @click.prevent="closeModal"> Save </a>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { RadioOption } from '@/interfaces/interfaces';
    import RadioButton from '@/components/form/RadioButton.vue';

    interface ModalProps {
        title: string;
        content: {
            type: string,
            value: string | RadioOption[]
        };
        icon: string;
    }

    export default defineComponent({
        props: {
            modalProps: {
                type: Object as PropType<ModalProps>,
                default: () => ({
                    title: 'Success',
                    content: {
                        type: 'text',
                        value: 'Default content',
                    },
                    icon: 'iatse-icon-user-verified'
                })
            }
        },
        components: {
            RadioButton
        },
        setup(props, { emit }) {
            const closeModal = () => {
                emit('close');
            }

            console.log(props.modalProps, '@props success modal')

            return {
                closeModal
            }
        }
    })
</script>