<template>
  <div class="col-md-8 border p-4">
    <h4 class="text-center">{{ title }}</h4>
    <canvas v-if="graphData" ref="chartRef"></canvas>
    <div v-else>Loading Chart...</div>
  </div>
</template>

<script lang="ts">
import { ref, watchEffect, onMounted, PropType, defineComponent } from 'vue';
import { GraphDataItem } from '@/interfaces/interfaces';
import Chart from 'chart.js/auto';
import router from '../../../router';

export default defineComponent({
  props: {
    graphData: {
      type: Array as PropType<GraphDataItem[]>,
      required: true
    },
    title: String as PropType<string>,
  },
  setup(props) {
    const chartRef = ref(null);

    watchEffect(() => {
      if (props.graphData) {
        if (chartRef.value) {
          Chart.getChart(chartRef.value)?.destroy();
          const months = Object.keys(props.graphData);

          const data = {
            labels: months,
            datasets: [
              {
                label: 'Annuity',
                backgroundColor: 'rgb(66, 133, 244)',
                borderColor: 'rgb(66, 133, 244)',
                data: months.map(month => props?.graphData[month as any][5]),
              },
              {
                label: 'Salary Deferral',
                backgroundColor: '#CBC3E3',
                borderColor: '#CBC3E3',
                data: months.map(month => props?.graphData[month as any][7]),
              },
            ],
          };

          const config: any = {
            type: 'bar',
            data: data,
            options: {
              onClick: (event: Event, elements: { index: number }[]) => {
                if (elements.length > 0) {
                  const index = elements[0].index;
                  const [month, year] = months[index].split('/');
                  const formattedMonth = month.padStart(2, '0');
                  const fromDate = `${formattedMonth}/01/${year}`;
                  const toDate = `${formattedMonth}/31/${year}`;
                  const routeURL = router.resolve({
                    name: 'annuity-details',
                    query: {
                      fromDate: fromDate,
                      toDate: toDate,
                    },
                  }).href;

                  window.location.href = routeURL;
                }
              },
            },
          };

          onMounted(() => {
            console.log(props, "props");
            console.log(chartRef.value);
          });

          new Chart(chartRef.value, config);
        }
      }
    });

    return {
      chartRef,
    };
  },
});
</script>