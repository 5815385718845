<template>
    <div class="plan-general-details">
        <div class="plan-general-header" v-if="title">
            <h4>{{ title }}</h4>
            <p class="plan-modal" @click="openModal">
                <span class="text-underline plan-modal"> Update </span>
                <i class="iatse-icon-arrow-down plan-modal"> </i>
            </p>
        </div>
        <div v-if="useContainer" class="plan-general-container">
            <template v-for="(item, index) in items" :key="index">
                <div class="plan-general-detail-container" :class="item.class || ''">
                    <div class="plan-general-detail-line">
                    <h5>{{ item.label }}</h5>
                    <h4 :class="item.itemClass || ''">{{ item.value }}</h4>
                    </div>
                </div>
                <div v-if="index < items.length - 1 && hasDivider" :class="['divider', { 'hide-mobile': index % 2 === 1 }]"></div>
            </template>
        </div>

        <template v-else>
            <template v-for="(item, index) in items" :key="index">
            <div class="plan-general-detail-container" :class="item.class || ''">
                <div class="plan-general-detail-line">
                <h5>{{ item.label }}</h5>
                <h4>{{ item.value }}</h4>
                </div>
            </div>
            <div v-if="index < items.length - 1 && hasDivider" :class="['divider', { 'hide-mobile': index % 2 === 1 }]"></div>
            </template>
        </template>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        title: String,
        items: {
            type: Array as () => Array<{ label: string; value: string; class?: string }>,
            required: true,
        },
        hasDivider: {
            type: Boolean,
            default: true
        },
        useContainer: {
            type: Boolean,
            default: true
        }
    },
    setup(_, { emit }) {
        const openModal = () => {
            emit('open-modal');
        }

        return {
            openModal
        }
    },
});
</script>