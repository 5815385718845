
<template>
  <div class="container mt-4">
    <div class="row d-flex align-items-center mb-4">
      <div class="col-md-8">
        <h2> Annuity and My Salary Deferral Summary </h2>
      </div>
      <div class="col-md-2 text-center">
        <router-link :to="{ name: 'annuity-summary', params: { year: year - 1 } }" class="btn btn-primary">
          Previous Year
        </router-link>
      </div>
      <div class="col-md-2 text-center">
        <router-link :to="{ name: 'annuity-summary', params: { year: year + 1 } }" class="btn btn-primary">
          Next Year
        </router-link>
      </div>
    </div>
    <div v-if="loading"> Loading... </div>
    <div v-else class="row">
      <AccountSummary :summary="summary" />
      <ContributionChart :graphData="graphData" title="Employer and My Contributions - Month-by-Month" />
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, watch, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { AnnuitySummary, GraphDataItem } from "@interfaces/interfaces";
import AccountSummary from "./AccountSummary.vue";
import ContributionChart from "./ContributionChart.vue";
import axios from 'axios';

export default {
  components: {
    AccountSummary,
    ContributionChart,
  },
  setup() {
    const summary: Ref<AnnuitySummary | null> = ref(null);
    const graphData: Ref<GraphDataItem[]> = ref([])
    const route = useRoute();
    const year = ref(0);
    const loading = ref(false);

    // Get Contributions Data
    const fetchData = async () => {
      loading.value = true;
      year.value = Number(route.params.year);

      try {
        const response = await axios.get(`api/participant/annuity/summary/${year.value}`);
        summary.value = response.data;
        graphData.value = response.data.GraphData;

      } catch (error) {
        console.error('Error fetching data:', error);
      }

      loading.value = false;
    }

    // Watch for changes in the route parameter (year)
    watch(() => route.params.year, async (newYear) => {
      year.value = Number(newYear);
      await fetchData();
    });

    onMounted(async () => {
      await fetchData();
    });

    return {
      summary,
      year,
      loading,
      graphData,
    }
  }
};
</script>