<template>
  <div class="form-group row">
    <label :for="inputId" class="col-form-label text-md-right">Phone Number</label>
    <div class="d-flex gap-2 align-items-center">
      <div class="col-md-4">
        <input :id="inputId" type="text" class="form-control" v-model="localPhone.ContactInfo"
          @input="handleChanges('update-phone')" required />
      </div>
      <div class="col-md-4" v-if="phoneTypes">
        <select class="form-control" v-model="localPhone.TypeCode" @change="handleChanges('update-phone')" required>
          <option v-for="(type, index) in phoneTypes" :key="index" :value="index">
            {{ type }}
          </option>
        </select>
      </div>
      <div class="d-flex gap-4">
        <label :for="primaryId" class="col-form-label text-md-right d-flex flex-row-reverse justify-content-start gap-2">
          Primary Phone
          <input :id="primaryId" type="radio" @click="handleChanges('set-primary')" :checked="isSelectedPrimary" />
        </label>
        <div v-if="!isNew">
          <button type="button" @click="handleDelete(localPhone.CMIID)"
            class="btn btn-danger delete-phone">Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    phone: Object,
    phoneTypes: Array,
    selectedPrimary: Number,
    isNew: Boolean,
  },
  setup(props, { emit }: { emit: (event: string, ...args: any[]) => void }) {
    const localPhone = ref({ ...props.phone })
    const inputId = `id_${localPhone.value.CMIID}`;
    const primaryId = `primary_${localPhone.value.CMIID}`;
    const isSelectedPrimary = computed(() => props.selectedPrimary === localPhone.value.CMIID);

    const handleChanges = (action: string) => {
      emit("update-data", localPhone.value, action);
    }

    const handleDelete = (phoneId: number) => {
      emit("delete-phone", phoneId);
    };

    return {
      localPhone,
      isSelectedPrimary,
      inputId,
      primaryId,
      handleDelete,
      handleChanges,
    };
  },
});
</script>
