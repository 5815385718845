<template>
    <Alerts :success="success" :errors="errors" />
    <div class="plan-content">
        <!-- Form -->
        <form id="annuity-application-form" class="form" method="POST"
            @submit.prevent="submitData">
            <SelectComponent label="Request Type" v-model:selectedOption="requestType" :options="requestTypes"
                :singleArrow="true" />
            <TextInput type="text" label="Participant ID" placeholder="Enter Participant ID" :max=25
                v-model:modelValue="person.PersonID" :readonly="true" />

            <div class="text-input hide-mobile"> </div>

            <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
                v-model:modelValue="person.FirstName" :readonly="true" />
            <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
                v-model:modelValue="person.LastName" :readonly="true" />
            <DateInput label="DOB" v-model:modelValue="person.DateofBirth" :readonly="true" />

            <Textarea label="Notes" v-model:modelValue="comments" />

            <div class="action-buttons">
                <a href="" @click.prevent="closeModal" class="btn btn-secondary"> Discard </a>
                <button type="submit" class="btn btn-primary"> Submit </button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, toRefs, reactive, defineComponent } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { VacationCheckRequestForm, Person, SelectOption } from '@/interfaces/interfaces';
import Alerts from '@/components/utils/AlertsComponent.vue';
import TextInput from '@/components/form/TextInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import Textarea from '@/components/form/TextareaComponent.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import useFormSubmit from '@/composable/useFormSubmit';
import axios from 'axios';

export default defineComponent ({
    components: {
        Alerts,
        TextInput,
        DateInput,
        Textarea,
        SelectComponent
    },
    props: {
        fund:{
            type: String,
            default: null,
        }
    },
    setup(props, { emit }) {
        const authStore = useAuthStore();
        const person: Person = reactive({
            PersonID: authStore.user?.participantId,
            FirstName:'',
            LastName:'',
            DateofBirth:'',
        });

        const requestTypes = [
            { key: 'vacation', value: 'Vacation' },
            { key: 'medicare', value: 'Medicare Reimbursement' },
            { key: 'pension', value: 'Pension' }
        ];

        const requestType = ref<SelectOption>(requestTypes[0]);
        const { fund } = toRefs(props);

        const comments = ref('');
        const errors = ref<string[]>([]);
        const success = ref('');
        const info = ref('');
        const loading = ref(true);
        const loadingSubmit = ref(false);

        const closeModal = () => {
            emit('close');
        }

        const submitData = async () => {
            const formData: VacationCheckRequestForm = {
                RequestType: requestType.value.key,
                PersonID: person?.PersonID,
                FirstName: person?.FirstName,
                LastName: person?.LastName,
                DateofBirth: person?.DateofBirth,
                Notes: comments.value,
            };

            const route = 'api/participant/annuity-application';
            const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit });
            const successSubmit = await handleSubmit();

            if(successSubmit) {
                // closeModal();
            }
        }

        return {
            person,
            comments,
            requestType,
            requestTypes,
            success,
            errors,
            loading,
            submitData
        }
    }
});
</script>
