<template>
    <div class="col-md-3">
        <div class="card">
            <div class="card-body">
                <h3 class="text-center mb-4">Profile</h3>
                <div class="card mb-4">
                    <div class="card-body d-flex flex-column align-items-start gap-4 mb-4 w-100">
                        <h6 class="card-title"> {{ type }} Profile </h6>
                        <div class=" d-flex flex-column align-items-start gap-2 w-100">
                            <div class="d-flex flex-column gap-2 align-items-start w-100">
                                <span class="fw-bold">{{ type }}:</span>
                                <router-link :to="{ name: 'user.profile' }">
                                    <span>{{ data?.CompanyName }}</span>
                                </router-link>
                            </div>
                            <div v-if="type === 'Local'" class="d-flex flex-column gap-2 align-items-start w-100">
                                <span class="fw-bold">Email Address:</span>
                                <span>{{ data?.ContactEmail }}</span>
                            </div>
                            <div class="d-flex flex-column gap-2 align-items-start w-100">
                                <span class="fw-bold">Contact Name:</span>
                                <span>{{ data?.ContactName }}</span>
                            </div>
                            <div class="d-flex flex-column gap-2 align-items-start justify-content-start w-100">
                                <span class="fw-bold">Contact Phone:</span>
                                <span v-for="(phone, index) in data.ContactPhone" :key="index">
                                    {{ phone }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body d-flex flex-column gap-4 mb-4 w-100">
                        <router-link :to="{ name: 'settings' }">
                            <h6 class="card-title">Web Account</h6>
                        </router-link>
                        <div class="d-flex flex-column gap-2 w-100">
                            <span class="fw-bold">Email Address:</span>
                            <span>{{ data?.Email }}</span>
                        </div>
                        <div v-if="isLocalAdmin">
                            <router-link class="btn btn-sm btn-secondary" :to="{ name: 'local.representative' }">
                                Create / Manage Rep Accounts
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ProfileData } from '@/interfaces/interfaces';
import { PropType, defineComponent } from 'vue';
import { useAuthStore } from '../../../stores/auth';

export default defineComponent({
    props: {
        data: {
            type: Object as PropType<ProfileData | null>,
            required: true
        },
        type: String
    },
    setup() {
        const isLocalAdmin = useAuthStore().isLocalAdmin;

        return {
            isLocalAdmin
        }
    }
})
</script>

<style scoped>
a {
    text-decoration: none;
}

.card-title {
    text-decoration: none;
    color: #0d6efd;
}

.card-title:hover {
    cursor: pointer;
}
</style>