<template>
    <div class="card" :id="cardId">
        <div class="card-header-wrapper">
            <div class="card-header-fund">
                <slot name="icon"></slot>
                <h2>
                    <slot name="title"></slot>
                </h2>
            </div>
            <div class="card-control" :class="{ 'plus': !visibleContent }" @click="toggleVisible">
                <span class="line" v-if="visibleContent"></span>
                <i class="plus iatse-icon-plus" v-if="!visibleContent"></i>
            </div>
        </div>
        <transition name="slide-fade">
            <div v-if="visibleContent" class="card-content card-toggle row">
                <slot></slot>
            </div>
        </transition>

        <!-- Alert Overlay -->
        <FundAlert v-if="alerts" :alerts="alerts" @open="openModal" />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useModalStore } from '@/stores/modal';
import FundAlert from '@/components/cards/FundAlert.vue';

export default defineComponent({
    props: {
        cardId: {
            type: String,
            required: true
        },
        initialVisibility: {
            type: Boolean,
            default: true
        },
        alerts: Array
    },
    components: {
        FundAlert
    },
    setup(props) {
        const modalStore = useModalStore();
        const visibleContent = ref(props.initialVisibility);

        const toggleVisible = () => {
            visibleContent.value = !visibleContent.value;
        }

        return {
            visibleContent,
            toggleVisible,
            openModal: modalStore.openModal
        };
    }
});
</script>