import { reactive } from "vue";

interface DependentState {
    Person: {
      Relationship: number;
      CitizenshipCode: number;
      DateofBirth: string | null;
      FirstName: string | null;
      MiddleName: string | null;
      LastName: string | null;
      NameSuffix: number;
      MaritalStatus: number;
      MarriageDate: string | null;
      Salutation: number;
      Sex: number;
      SSN: string | null;
      SIN: string | null;
      OtherID: string | null;
      SSN_NA: boolean | null;
    };
    Email: string | null;
    PhoneNumber: string | null;
    Documents: any[];
}

// Initial state of the store
const initialDependentState = {
  Person: {
    Relationship: 0,
    CitizenshipCode: 1031,
    DateofBirth: null,
    FirstName: null,
    MiddleName: null,
    LastName: null,
    NameSuffix: 0,
    MaritalStatus: 0,
    MarriageDate: null,
    Salutation: 60,
    Sex: 0,
    SSN: null,
    SIN: null,
    OtherID: null,
    SSN_NA: null,
  },
  Email: null,
  PhoneNumber: null,
  Documents: [],
};

// Create a reactive store with the initial state
export const dependentStore = reactive(deepCopy(initialDependentState));

// Function to reset the store to its initial state
export const resetDependentStore = () => {
    Object.assign(dependentStore, deepCopy(initialDependentState));
  };

// Utility function for deep copying
function deepCopy(obj: DependentState) {
    return JSON.parse(JSON.stringify(obj));
}
