<template>
    <div class="col-md-8 border p-4">
        <h4 class="text-center"> My Employer Contributions Month-by-Month </h4>
        <canvas id="barChart" ref="chartRef"></canvas>
    </div>
</template>

<script lang="ts">
    import { defineComponent, onMounted, PropType, ref, watchEffect } from 'vue';
    // import Chart, { ChartConfiguration, ChartTypeRegistry } from 'chart.js/auto';
    import { Chart, ChartConfiguration, ChartTypeRegistry } from 'chart.js/auto';
    import { VacationGraphData } from '@/interfaces/interfaces';

    export default defineComponent({
        props: {
            graphData: {
                type: Object as PropType<VacationGraphData>,
                required: true
            }
        },

        setup(props) {
            const chartRef = ref<HTMLCanvasElement | null>(null);

            const initializeChart = () => {
                if (!chartRef.value) {
                    console.error('Chart element is not yet available');
                    return;
                }

                const existingChart = Chart.getChart(chartRef.value); // or use chartRef.value.id if you have assigned an id to the canvas
                if (existingChart) {
                    existingChart.destroy();
                }

                const labels = Object.keys(props.graphData);
                const data = {
                    labels: labels,
                    datasets: [{
                        label: 'Contributions',
                        backgroundColor: 'rgb(66, 133, 244)',
                        borderColor: 'rgb(66, 133, 244)',
                        data: Object.values(props.graphData),
                    }]
                };

                const config: any = {
                    type: 'bar',
                    data: data,
                    options: {}
                };

                const ctx = chartRef.value.getContext('2d');
                if (ctx) {
                    new Chart(ctx, config);
                } else {
                    console.error('Unable to get canvas context');
                }
            };

            watchEffect(() => {
                initializeChart();
            });

            return {
                chartRef
            };
        }
    });
</script>