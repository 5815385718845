<template>
    <form id="confirmationForm" class="row">
        <div class="form-group row mb-4">
            <div class="col-md-6">
                <label for="comments" class="col-form-label text-md-right">
                    Comments <span class="text-danger"> * </span>
                </label>
                <textarea id="comments" class="form-control" v-model="comment" required
                    :disabled="status !== '1'"> </textarea>
                <span class="text-danger" v-show="isError"> * Comments field is required </span>
            </div>
            <input type="hidden" id="actionInput" />
            <div class="col-md-3 d-flex justify-content-center align-items-center">
                <p class="text-center"> Fields with RED were edited by user. </p>
            </div>
            <div v-if="status === '1'" class="col-md-3 d-flex justify-content-between align-items-center gap-4">
                <button type="button" class="btn btn-success" :disabled="approveButton.Disabled"
                    @click="handleReview('Approve')">
                    {{ approveButton.Value }}
                </button>
                <button type="button" class="btn btn-danger" @click="handleReview('Reject')"> Reject </button>
            </div>
        </div>
    </form>
</template>

<script lang="ts">
import { ref, defineComponent, toRefs, PropType } from 'vue';

export default defineComponent({
    props: {
        approveButton: {
            type: Object as PropType<Record<string, boolean>>,
            required: true
        },
        comments: String,
        status: String as PropType<string | number>,
    },
    setup(props, { emit }: { emit: (event: string, ...args: any[]) => void }) {
        const { comments: existingComment } = toRefs(props);
        const comment = props.comments !== '' ? existingComment : ref('');
        const isError = ref(false);

        const handleReview = (type: string) => {
            if (comment.value === '') {
                isError.value = true;
                setTimeout(() => isError.value = false, 3000);

                return;
            }

            emit('handle-review', type, comment);
        };

        return {
            comment,
            isError,
            handleReview,
        };
    }
});
</script>