<template>
    <Alerts :success="success" :errors="errors" />

    <!-- Plan Content -->
    <div class="plan-content">
        <!-- General Plan Detail Cards -->
        <PlanGeneral
            text="Select parameters below to search for a specific claim or all claims for a specific date range" />

        <!-- Form -->
        <div v-if="loading"> Loading... </div>
        <form v-else id="claim-search-form" class="form claim-search" method="post">
            <CoveredCard :links="links" />
            <TextInput type="text" label="Enter Claim" placeholder="Enter here" :max=100
                v-model:modelValue="filters.Claim" />
            <TextInput type="text" label="Enter Web Reference" placeholder="Enter Web Reference" :max=25
                v-model:modelValue="filters.WebReference" />
            <PlanGeneral text="If you do not have a claim, use filters below to search for claim" />
            <div class="custom-select links">
                <label class="body-text"> Patient Name </label>
                <Multiselect v-model="filters.PatientName" :options="patients" placeholder="Type to search"
                    :searchable="true" :close-on-select="true" label="FullName" track-by="ID">
                </Multiselect>
            </div>
            <div class="custom-select links">
                <label class="body-text"> Claim Status </label>
                <Multiselect v-model="filters.ClaimStatus" :options="statuses" placeholder="Type to search"
                    :searchable="true" :close-on-select="true" openDirection="bottom">
                </Multiselect>
            </div>
            <DateInput label="Services date" v-model:modelValue="filters.ServiceDate.StartDate" placeholder="From" />
            <DateInput label="" v-model:modelValue="filters.ServiceDate.EndDate" placeholder="To" />

            <DateInput label="Submission Date" v-model:modelValue="filters.SubmissionDate.StartDate"
                placeholder="From" />
            <DateInput label="" v-model:modelValue="filters.SubmissionDate.EndDate" placeholder="To" />

            <div class="action-buttons">
                <a href="" class="btn btn-secondary plan-modal" @click.prevent="closeModal"> Back </a>
                <a href="" class="btn btn-primary plan-modal" @click.prevent="searchData">
                    {{ loadingSearch ? 'Searching...' : 'Search' }} </a>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { onMounted, defineComponent, reactive, ref, Ref } from 'vue';
import { ClaimFilters, Patient } from '@/interfaces/health';
import { useClaimStore } from '@/stores/claim';
import { useModalStore } from '@/stores/modal';
import axios from 'axios';

import Alerts from "@/components/utils/AlertsComponent.vue";
import TextInput from '@components/form/TextInput.vue';
import DateInput from '@components/form/DateInput.vue';
import CoveredCard from '@components/participant/claims/CoveredCard.vue';
import PlanGeneral from '@/components/cards/PlanGeneral.vue';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

export default defineComponent({
    components: {
        Alerts,
        CoveredCard,
        DateInput,
        Multiselect,
        PlanGeneral,
        TextInput,
    },
    setup(_, { emit }) {
        const loading = ref(true);
        const loadingSearch = ref(false);
        const success = ref(null);
        const errors: Ref<string[] | string | null> = ref(null);
        const patients: Ref<Patient[]> = ref([]);
        const statuses = ref([]);
        const claimStore = useClaimStore();
        const filters: ClaimFilters = reactive({ ...JSON.parse(JSON.stringify(claimStore.filters)) });
        const openModal = useModalStore().openModal;
        const closeModal = () => {
            emit('close');
        }

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/participant/claims-search')
                .then(response => {
                    patients.value = response.data.Patients;
                    statuses.value = response.data.Statuses;
                    filters.ClaimStatus = statuses.value[0];
                    filters.PatientName = patients.value[0];
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        // Get Claims History
        const searchData = async () => {
            loadingSearch.value = true;

            await axios
                .post('api/participant/claims-history', {
                    ...filters
                })
                .then(response => {
                    claimStore.setHistory(response.data, filters);
                })
                .catch(error => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }
                })
                .finally(() => {
                    loadingSearch.value = false;

                    if (!errors.value?.length) {
                        openModal('claim-history-results', 'Claim History Search', 'plan-card-info')
                    }

                    setTimeout(() => {
                        success.value = null
                        errors.value = [];
                    }, 3500);
                })
        }

        onMounted(async () => await fetchData());

        return {
            loading,
            loadingSearch,
            success,
            errors,
            filters,
            patients,
            statuses,
            links: claimStore.claimLinks,
            searchData,
            closeModal,
            openModal,
        };
    }
})
</script>