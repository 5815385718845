<template>
    <Alerts :success="success" :info="info" :errors="errors" />
    <!-- Plan Content -->
    <div class="plan-content">
        <PlanGeneral
            text="Enter the information you wish to update in the applicable field. These changes automatically update your account." />

        <form id="update-contact-information-form" class="form update-contact-information" method="post">
            <!-- Phone Numbers Section -->
            <div v-for="(phone, key) in profileStore.phones" :key="key" class="inputs-wrapper">
                <TextInput type="tel" label="Phone Number" placeholder="333-333-3333" :max="12"
                    v-model:modelValue="phone.ContactInfo" :required="true" />
                <SelectComponent label="Primary Phone" v-model:selectedOption="phone.Primary" :options="yesNoOptions"
                    :single-arrow="true" />
            </div>
            <div class="w-full">
                <a href="" class="btn btn-secondary add-btn" @click.prevent="profileStore.addPhoneNumber()">
                    <p class="icon-wrapper"> <i class="iatse-icon-plus"> </i> </p> Add New Phone Number
                </a>
            </div>

            <!-- Web Emails Section -->
            <div v-for="(email, key) in profileStore.emails" :key="key" class="inputs-wrapper">
                <TextInput type="email" label="Web Email Address" placeholder="Enter Web Email Address" :max="60"
                    v-model:modelValue="email.ContactInfo" :required="true" />
                <SelectComponent label="Primary Email" v-model:selectedOption="email.Primary" :options="yesNoOptions"
                    :single-arrow="true" />
            </div>

            <div class="w-full">
                <a href="" class="btn btn-secondary add-btn" @click.prevent="profileStore.addEmailAddress()">
                    <p class="icon-wrapper"> <i class="iatse-icon-plus"> </i> </p> Add New Email Address
                </a>
            </div>

            <div class="action-buttons">
                <button @click.prevent="closeModal" class="btn btn-secondary"> Discard</button>
                <button @click.prevent="submitData" class="btn btn-primary">
                    {{ loadingSubmit ? 'Saving...' : 'Save Changes' }} </button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, reactive, Ref, defineComponent, computed } from 'vue';
import { ContactInfo } from '@/interfaces/interfaces';
import { useProfileStore } from '@/stores/profile';
import useFormSubmit from '@composable/useFormSubmit';
import Alerts from '@components/utils/AlertsComponent.vue';
import PlanGeneral from '@components/cards/PlanGeneral.vue';
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from "@components/form/SelectComponent.vue";

type UpdateState = {
    phone: boolean;
    email: boolean;
};

type UpdateType = keyof UpdateState;

export default defineComponent({
    components: {
        Alerts,
        PlanGeneral,
        TextInput,
        SelectComponent
    },
    setup(_, { emit }) {
        const profileStore = useProfileStore();
        const formData: ContactInfo = reactive({...profileStore.contact});
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const errors: Ref<string[]> = ref([]);
        const success = ref('');
        const info = ref('');
        const route = 'api/user/profile/my-contact-information';

        const yesNoOptions = [
            { key: '1', value: 'Yes' },
            { key: '0', value: 'No' }
        ];

        const update = ref<UpdateState>({
            phone: false,
            email: false,
        })

        const loadingUpdate = ref<UpdateState>({
            phone: false,
            email: false,
        })

        const closeModal = () => {
            emit('close');
        }

        const updateContact = (type: UpdateType) => {
            update.value[type] = !update.value[type];
        }

        // Handle Form Submit
        const submitData = async () => {
            const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit });
            const successSubmit = await handleSubmit();

            if (successSubmit) {
                profileStore.setContact(formData);
            }
        }

        return {
            formData,
            profileStore,
            yesNoOptions,
            update,
            errors,
            success,
            info,
            loading,
            loadingSubmit,
            loadingUpdate,
            updateContact,
            submitData,
            closeModal,
        };
    }
})
</script>