<template>
    <div class="container mt-4">
        <div class="position-fixed top-0 end-0 p-3" style="z-index: 100">
            <div v-if="errors.length > 0" class="alert alert-danger">
                <ul>
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center mb-4">
                <h1>Missing Contributions Inquiry</h1>
            </div>
            <p>Complete the information below and hit the “Submit” button in the lower right hand corner.</p>
            <div class="d-flex flex-column gap-4">
                <div class="d-flex align-items-center gap-4 w-100">
                    <span class="col-md-2"> Work Period: <span class="text-danger">*</span> </span>
                    <div class="w-50 d-flex align-items-center gap-4">
                        <input type="date" v-model="filters.WorkPeriod.StartDate" class="form-control w-50" required />
                        <span>-</span>
                        <input type="date" v-model="filters.WorkPeriod.EndDate" class="form-control w-50" required />
                    </div>
                </div>
                <div class="d-flex align-items-center gap-4 w-100">
                    <span class="col-md-2"> Employer(s): </span>
                    <div class="w-50">
                        <Multiselect v-model="filters.Employers" :options="employers" placeholder="Type to search"
                            :multiple="true" :hide-selected="true" :searchable="true" :close-on-select="true"
                            @select="handleEmployerChange" label="Name" track-by="ID"
                            @search-change="handleEmployersSearch" @remove="handleProductions"
                            :loading="loadingEmployers">
                        </Multiselect>
                    </div>
                </div>
                <div class="d-flex align-items-center gap-4 w-100">
                    <span class="col-md-2"> Production(s) <span v-if="hasEmployers"> for selected
                            Employer(s)</span>: </span>
                    <div class="w-50">
                        <Multiselect v-model="filters.Productions" :options="productions" placeholder="Type to search"
                            :multiple="true" :hide-selected="true" :searchable="true" :close-on-select="true"
                            @select="handleProductionChange" label="Name" track-by="ID" @open="handleProductionsOpen"
                            @search-change="handleProductionsSearch" :loading="loadingProductions">
                        </Multiselect>
                    </div>
                </div>
                <div class="d-flex align-items-center gap-4 w-100">
                    <span class="col-md-2"> Member Name or SSN: </span>
                    <input type="text" v-model="filters.ParticipantID" class="form-control w-50" />
                </div>
                <div class="d-flex align-items-center gap-4 w-100">
                    <span class="col-md-2"> Additional Info: </span>
                    <textarea type="text" v-model="filters.Additional" class="form-control w-50"> </textarea>
                </div>
                <div class="d-flex gap-2 align-self-end w-25">
                    <button class="btn btn-primary w-50" @click="handleSubmit">
                        {{ loadingSubmit ? 'Loading...' : 'Submit' }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { reactive, ref, Ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import 'vue-multiselect/dist/vue-multiselect.css';

interface Filters {
    WorkPeriod: {
        StartDate: string,
        EndDate: string,
    };
    Employers: Employer[] | null;
    Productions: Employer[] | null;
    ParticipantID: string;
    Additional: string;
}

interface Employer {
    ID: string | number;
    Name: string;
}

export default {
    components: {
        Multiselect,
    },
    setup() {
        const loadingEmployers = ref(false);
        const loadingProductions = ref(false);
        const loadingSubmit = ref(false);
        const allOption = { ID: 0, Name: 'ALL' };
        const filters: Filters = reactive({
            WorkPeriod: {
                StartDate: '',
                EndDate: '',
            },
            Employers: [allOption],
            Productions: [allOption],
            ParticipantID: '',
            Additional: '',
        });
        const employers = ref([]);
        const productions: Ref<Employer[]> = ref([]);
        const errors: Ref<string[]> = ref([]);
        const success = ref(null);
        const router = useRouter();

        const hasEmployers = computed(() => filters.Employers?.some(employer => employer.ID !== 0));
        const hasProductions = computed(() => filters.Productions?.some(prod => prod.ID !== 0));

        // Handle employers search
        const handleEmployersSearch = async (query: string) => {
            loadingEmployers.value = true;

            try {
                const response = await axios.get(`api/local/employers?query=${query}`);
                employers.value = response.data;
            }
            catch (error) {
                console.error('Error searching employer:', error);
            }

            loadingEmployers.value = false;
        };

        // Handle productions search
        const handleProductionsSearch = async (query: string) => {
            loadingProductions.value = true;
            const employerIds = filters.Employers?.map((employer: Employer) => {
                if (employer.ID !== 0)
                    return employer.ID
            });

            try {
                const response = await axios.get(`api/local/productions?query=${query}&&EmployerIDs=${employerIds}`);
                productions.value = response.data;
            }
            catch (error) {
                console.error('Error searching production:', error);
            }

            loadingProductions.value = false;
        };

        const handleEmployerChange = (newSelection: Employer) => {
            if (newSelection.ID === 0) {
                filters.Employers = [allOption];
            }
            else {
                const filteredTypes: Employer[] = JSON.parse(JSON.stringify(filters.Employers)).filter((type: Employer) => type.ID !== 0)
                filters.Employers = [...filteredTypes];
                handleProductions();
            }
        }

        const handleProductions = async () => {
            await handleProductionsSearch('');

            if (filters.Productions && filters.Productions[0]?.ID !== 0) {
                filters.Productions = filters.Productions?.filter(prod => productions.value.some(p => p.ID === prod.ID)) || [];
            }
        }

        const handleProductionChange = (newSelection: Employer) => {
            if (newSelection.ID === 0) {
                filters.Productions = [allOption];
            }
            else {
                const filteredTypes: Employer[] = JSON.parse(JSON.stringify(filters.Productions)).filter((type: Employer) => type.ID !== 0)
                filters.Productions = [...filteredTypes];
            }
        }

        const handleProductionsOpen = async (query: string) => {
            const employerIds = filters.Employers?.map((employer: Employer) => employer.ID);
            const keyword = query ?? '';

            if (!employerIds?.includes(0)) {
                handleProductionsSearch(keyword);
            }
        };

        const handleSubmit = async () => {
            loadingSubmit.value = true;
            const formData = { ...filters };

            if (!hasEmployers.value) {
                formData.Employers = null;
            }

            if (!hasProductions.value) {
                formData.Productions = null;
            }

            await axios
                .post('api/local/missing-contributions', {
                    ...formData
                })
                .then(response => {
                    success.value = response.data.success;
                    router.push({ name: 'local.dashboard', params: { successMessage: success.value } });
                })
                .catch(error => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        success.value = null
                        errors.value = []
                    }, 3500);
                })

            loadingSubmit.value = false;
        }

        return {
            loadingSubmit,
            loadingEmployers,
            loadingProductions,
            errors,
            success,
            filters,
            employers,
            productions,
            hasEmployers,
            handleEmployerChange,
            handleProductions,
            handleProductionChange,
            handleProductionsOpen,
            handleEmployersSearch,
            handleProductionsSearch,
            handleSubmit,
        };
    }
}
</script>