import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "switch" }
const _hoisted_2 = { class: "switch-input" }
const _hoisted_3 = ["name", "checked"]
const _hoisted_4 = { class: "switch-slider" }
const _hoisted_5 = { class: "switch-label left" }
const _hoisted_6 = { class: "switch-label right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createElementVNode("input", {
        type: "checkbox",
        name: _ctx.name,
        checked: _ctx.modelValue,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
      }, null, 40, _hoisted_3),
      _createElementVNode("span", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.option1), 1),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.option2), 1)
      ])
    ])
  ]))
}