<template>
    <div class="container mt-4">
        <h4 class="mb-4"> Document Mapping Screen </h4>
        <div v-show="success" class="alert alert-success">{{ success }}</div>
        <div v-show="info" class="alert alert-info">{{ info }}</div>
        <div v-if="loading">Loading...</div>
        <form v-else>
            <div class="d-flex gap-4">
                <table class="d-block table table-striped mb-4">
                    <thead class="table-dark">
                        <tr class="text-center">
                            <th class="w-25"> Area </th>
                            <th class="w-25"> View </th>
                        </tr>
                        <tr class="text-center">
                            <th>
                                <select class="form-control" @change="handleAreaChange" v-model="selectedArea">
                                    <option value="All"> All </option>
                                    <option value="Employers"> Employers </option>
                                    <option value="Locals"> Locals </option>
                                    <option value="Participants"> Participants </option>
                                </select>
                            </th>
                            <th>
                                <input type="search" class="form-control" @input="handleSearchView">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="2"> Click the rows to see the assigned documents </td>
                        </tr>
                        <tr v-for="form in state.forms" :key="form.FormID"
                            :class="{ 'form-row': true, 'selected-form': selectedForm === form.FormID }"
                            @click="handleSelectedForm(form.FormID)">
                            <td class="cursor-pointer-hover"> {{ form?.Area }}</td>
                            <td class="cursor-pointer-hover"> {{ form?.View }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-striped mb-4 align-self-start">
                    <thead class="table-dark">
                        <tr class="text-center">
                            <th class="w-50"> Documents for <span id="name"> </span></th>
                        </tr>
                        <tr class="text-center">
                            <th class="d-flex gap-4 justify-content-between">
                                <input type="text" class="form-control w-50" @input="handleSearchDocument">
                                <button type="button" class="btn btn-sm btn-success w-25" @click="handleSave()">
                                    {{ loadingSubmit ? 'Saving...' : 'Save Changes' }}
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="document in state.documents" :key="document.CodeID" class="document-row">
                            <td>
                                <input type="checkbox" :id="document.CodeID.toString()" @change="handleDocumentChange()"
                                    class="document-checkbox" :value="document.CodeID" v-model="checkedDocuments">
                                <label :for="document.CodeID.toString()" class="px-2">
                                    {{ document.Description }} </label>

                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { MiscellaneousCode } from '@/interfaces/interfaces';
import { onMounted, reactive, ref, Ref } from 'vue';
import axios from 'axios';

interface UIForm {
    FormID: number,
    Area: string,
    View: string,
    Documents: number[]
}

export default {
    setup() {
        const initialDocuments: Ref<MiscellaneousCode[] | []> = ref([]);
        const initialForms: Ref<UIForm[] | []> = ref([]);
        const state = reactive({
            forms: [...initialForms.value],
            documents: [...initialDocuments.value]
        });
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const success = ref(null);
        const info = ref(null);
        const selectedArea = ref('All');
        const selectedForm: Ref<number | null> = ref(null);
        const currentForm: Ref<UIForm | null> = ref(null);
        const checkedDocuments: Ref<number[]> = ref([]);
        const route = 'api/administrator/document-mapping-screen';

        const fetchData = async () => {
            loading.value = true;

            await axios.get(route)
                .then(response => {
                    const res = response.data;
                    initialForms.value = res.UIForms;
                    initialDocuments.value = res.Documents;
                    state.forms = [...initialForms.value];
                    state.documents = [...initialDocuments.value];
                    selectedForm.value = state.forms[0].FormID;
                    currentForm.value = initialForms.value.find(form => form.FormID === selectedForm.value) ?? null;
                    checkedDocuments.value = currentForm.value?.Documents ?? [];
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })
                .finally(() => loading.value = false)
        };

        // Handle Save Changes
        const handleSave = async () => {
            loadingSubmit.value = true;

            await axios
                .post(route, { ...state.forms, _method: "PATCH" })
                .then((response) => {
                    success.value = response.data?.success;
                    info.value = response.data?.info;
                })
                .finally(() => {
                    loadingSubmit.value = false;

                    setTimeout(() => {
                        success.value = null
                        info.value = null
                    }, 3500);
                })
        }

        // Set Selected Form
        const handleSelectedForm = async (formID: number) => {
            selectedForm.value = formID;
            currentForm.value = initialForms.value.find(form => form.FormID === selectedForm.value) ?? null;
            checkedDocuments.value = JSON.parse(JSON.stringify(currentForm.value?.Documents)) ?? [];
        }

        // Handle Checked Documents
        const handleDocumentChange = () => {
            const filteredDocuments = checkedDocuments.value.filter(doc => doc !== 0);

            state.forms.forEach(form => {
                if (form.FormID === selectedForm.value) {
                    form.Documents = [...filteredDocuments];
                }
            });
        };

        // Handle Change Area from Select
        const handleAreaChange = () => {
            if (selectedArea.value === 'All') {
                state.forms = [...initialForms.value];
            }
            else {
                const filteredForms: UIForm[] = JSON.parse(JSON.stringify(initialForms.value)).filter((form: UIForm) => form?.Area == selectedArea.value);
                state.forms = [...filteredForms];
            }
        }

        // Handle Search View
        const handleSearchView = (event: Event) => {
            const searchQuery = (event.target as HTMLInputElement)?.value.toLowerCase();
            let filteredForms: UIForm[]

            if (searchQuery.length > 0) {
                filteredForms = JSON.parse(JSON.stringify(state.forms)).filter((form: UIForm) => {
                    return form.View.toLowerCase().includes(searchQuery);
                });
            }
            else {
                handleAreaChange();
                filteredForms = [...state.forms];
            }

            state.forms = [...filteredForms];
        };

        // Handle Search Document
        const handleSearchDocument = (event: Event) => {
            const searchQuery = (event.target as HTMLInputElement)?.value.toLowerCase();

            if (searchQuery.length > 0) {
                const filteredDocuments = initialDocuments.value.filter(document => {
                    return document.Description.toLowerCase().includes(searchQuery);
                });

                state.documents = [...filteredDocuments];
            }
            else {
                state.documents = [...initialDocuments.value];
            }
        };

        onMounted(async () => {
            await fetchData();
        });

        return { state, selectedArea, selectedForm, loading, loadingSubmit, success, info, currentForm, checkedDocuments, handleSave, handleSearchView, handleSearchDocument, handleAreaChange, handleSelectedForm, handleDocumentChange }
    }
}
</script>

<style scoped>
.selected-form td {
    background-color: rgb(225, 225, 225);
}

.form-row {
    cursor: pointer
}
</style>