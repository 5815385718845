import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "form-group row" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "d-flex gap-2 align-items-center" }
const _hoisted_4 = { class: "col-md-4" }
const _hoisted_5 = ["id"]
const _hoisted_6 = {
  key: 0,
  class: "col-md-4"
}
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "d-flex gap-4" }
const _hoisted_9 = ["for"]
const _hoisted_10 = ["id", "checked"]
const _hoisted_11 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.inputId,
      class: "col-form-label text-md-right"
    }, "Phone Number", 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          id: _ctx.inputId,
          type: "text",
          class: "form-control",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localPhone.ContactInfo) = $event)),
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleChanges('update-phone'))),
          required: ""
        }, null, 40, _hoisted_5), [
          [_vModelText, _ctx.localPhone.ContactInfo]
        ])
      ]),
      (_ctx.phoneTypes)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _withDirectives(_createElementVNode("select", {
              class: "form-control",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localPhone.TypeCode) = $event)),
              onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleChanges('update-phone'))),
              required: ""
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phoneTypes, (type, index) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: index,
                  value: index
                }, _toDisplayString(type), 9, _hoisted_7))
              }), 128))
            ], 544), [
              [_vModelSelect, _ctx.localPhone.TypeCode]
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", {
          for: _ctx.primaryId,
          class: "col-form-label text-md-right d-flex flex-row-reverse justify-content-start gap-2"
        }, [
          _createTextVNode(" Primary Phone "),
          _createElementVNode("input", {
            id: _ctx.primaryId,
            type: "radio",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleChanges('set-primary'))),
            checked: _ctx.isSelectedPrimary
          }, null, 8, _hoisted_10)
        ], 8, _hoisted_9),
        (!_ctx.isNew)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("button", {
                type: "button",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleDelete(_ctx.localPhone.CMIID))),
                class: "btn btn-danger delete-phone"
              }, "Delete")
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}