import axios from 'axios';
import { useAuthStore } from '@/stores/auth';

// Set withCredentials globally for Axios
axios.defaults.withCredentials = true;

// Set the base URL from environment variables
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

axios.interceptors.request.use((config) => {
    const authStore = useAuthStore();
    const { token, impersonationToken } = authStore; // Destructure to get the impersonation token

    // Attach the regular token if available
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    // Conditionally attach the impersonation token if available
    if (impersonationToken) {
        // config.headers['X-Impersonation-Token'] = `Bearer ${impersonationToken}`;
        config.headers['X-Impersonation-Token'] = `${impersonationToken}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export default axios;