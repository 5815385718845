<template>
    <Alerts :success="success" :errors="errors" />
    <div v-if="loading">Loading...</div>
    <div v-else class="plan-content">
        <!-- Form -->
        <form v-if="person && person.DateofBirth" id="check-destination-form" class="form" method="POST"
            @submit.prevent="submitData">
            <SelectComponent label="Check Type" v-model:selectedOption="requestType" :options="checkTypes"
                :singleArrow="true" />
            <SelectComponent label="Type" v-model:selectedOption="checkType" :options="paymentMethods"
                :singleArrow="true" />
            <TextInput type="text" label="Participant ID" placeholder="Enter Participant ID" :max=25
                v-model:modelValue="person.PersonID" :readonly="true" />
            <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
                v-model:modelValue="person.FirstName" :readonly="true" />
            <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
                v-model:modelValue="person.LastName" :readonly="true" />
            <DateInput label="DOB" v-model:modelValue="person.DateofBirth" :readonly="true" />

            <div class="headers">
                <h4 v-if="checkType.key === 'check'">Address for Check Destination currently on File </h4>
                <h4 v-else>Bank Account Information</h4>
                <h5 v-if="checkType.key === 'directdeposit'"> Current Banking Information </h5>
            </div>

            <div class="w-full" v-if="address && checkType.key === 'check'">
                <TextInput type="text" label="Address 1" placeholder="Enter Address 1" :max=30
                    v-model:modelValue="address.Street1" :required="true" />
                <TextInput type="text" label="Address 2" placeholder="Enter Address 2" :max=30
                    v-model:modelValue="address.Street2" :required="true" />
                <TextInput type="text" label="Address 3" placeholder="Enter Address 3" :max=30
                    v-model:modelValue="address.Street3" :required="true" />
                <TextInput type="text" label="City" placeholder="Enter City" :max=25 v-model:modelValue="address.City"
                    :required="true" />
                <SelectComponent label="Country" v-model:selectedOption="address.Country" :options="countries"
                    :single-arrow="true" />
                <SelectComponent label="State" v-model:selectedOption="address.State" :options="filteredStates"
                    :single-arrow="true" />
                <TextInput type="text" label="ZIP Code" placeholder="Enter Postal Code" :max=9
                    v-model:modelValue="address.Zip" :required="true" />
            </div>

            <div class="w-full" v-else>
                <TextInput type="text" label="Bank Name" placeholder="Enter Bank Name" :max=30
                    v-model:modelValue="bank.Name" :required="true" />
                <TextInput type="text" label="Bank Address" placeholder="Enter Bank Address" :max=30
                    v-model:modelValue="bank.Address" :required="true" />
                <TextInput type="text" label="Routing (ABA)" placeholder="Enter Routing (ABA)" :max=30
                    v-model:modelValue="bank.Routing" :required="true" />
                <SelectComponent label="Account Type" v-model:selectedOption="bank.AccountType" :options="accountTypes"
                    :single-arrow="true" />
                <TextInput type="text" label="Account Number" placeholder="Enter Account Number" :max=25
                    v-model:modelValue="bank.AccountNumber" :required="true" />
                <TextInput type="text" label="Repeat Account Number" placeholder="Repeat Account Number" :max=25
                    v-model:modelValue="bank.RepeatAccountNumber" :required="true" />
                <TextInput type="text" label="Beneficiary Name" placeholder="Enter Beneficiary Name" :max=50
                    v-model:modelValue="bank.BeneficiaryName" :required="true" />
            </div>

            <Textarea label="Notes" v-model:modelValue="comments" />

            <div class="action-buttons">
                <a href="" @click.prevent="closeModal" class="btn btn-secondary"> Discard </a>
                <button type="submit" class="btn btn-primary"> Submit </button>
            </div>
        </form>
        <div v-else class="d-flex flex-column align-items-center gap-4">
            <h3>To Continue Please...</h3>
            <h6>Please <a href="profile.personal.edit">update your personal
                    information</a> to include your correct date of birth.</h6>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, toRefs, onMounted, watch, computed, Ref, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useModalStore } from '@/stores/modal';
import { useProfileStore } from '@/stores/profile';
import { VacationCheckRequestForm, CountryStates, Person, AddressInfo, Bank, SelectOption, VacationCheckRequestLine } from '@/interfaces/interfaces';
import Alerts from '@/components/utils/AlertsComponent.vue';
import TextInput from '@/components/form/TextInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import Textarea from '@/components/form/TextareaComponent.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import useFormSubmit from '@/composable/useFormSubmit';
import axios from 'axios';

export default defineComponent({
    components: {
        Alerts,
        TextInput,
        DateInput,
        Textarea,
        SelectComponent
    },
    props: {
        fund: {
            type: String,
            default: null,
        }
    },
    setup(props, { emit }) {
        const route = useRoute();
        const modalStore = useModalStore();
        const profileStore = useProfileStore();
        const statesByCountry: Ref<CountryStates[] | null> = ref(null);
        const person = ref<Person>();
        const accountTypes = ref(null);
        const address = ref<AddressInfo>({
            Street1: null,
            Street2: null,
            Street3: null,
            Country: null,
            City: null,
            State: null,
            ZipCode: null,
        });

        const bank = ref<Bank>({
            Name: null,
            Address: null,
            Routing: null,
            AccountType: null,
            AccountNumber: null,
            RepeatAccountNumber: null,
            BeneficiaryName: null,
        });

        const checkTypes = [
            { key: 'vacation', value: 'Vacation' },
            { key: 'medicare', value: 'Medicare Reimbursement' },
            { key: 'pension', value: 'Pension' }
        ];

        const paymentMethods = [
            { key: 'directdeposit', value: 'ACH' },
            { key: 'check', value: 'Check Destination' }
        ];

        const checks = ref<VacationCheckRequestLine[]>([]);
        const checkOptions = ref<SelectOption[]>([]);
        const selectedCheck = ref<SelectOption | null>({ key: 0, value: '-Select check' });
        const requestType = ref<SelectOption>(checkTypes[0]);
        const checkType = ref<SelectOption>(paymentMethods[0]);
        const checkDate = ref('');
        const checkNumber: Ref<string> = ref('');
        const { fund } = toRefs(props);
        const comments = ref('');
        const errors = ref<string[]>([]);
        const success = ref('');
        const info = ref('');
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const updateDepositRoute = 'api/participant/claims-update-deposit';
        const updateAddressRoute = 'api/participant/check-replacement';
        const postRoute = ref(updateDepositRoute);

        // Methods
        const fetchData = async () => {
            try {
                const response = await axios.get('api/participant/check-replacement');
                person.value = response.data.Person;
                address.value = response.data.Address;
                bank.value = response.data.Bank;
                accountTypes.value = response.data.AccountTypes;
                checks.value = response.data.Checks;
                checkOptions.value = response.data.CheckOptions;

                if (!profileStore.countries || !profileStore.statesByCountry) {
                    await profileStore.fetchCountriesStates();
                }

                loading.value = false;
            }
            catch (error) {
                console.error('Error:', error);
            }
        }

        const updateRequestTypeFromQuery = () => {
            const queryParamValue = fund.value;

            if (typeof queryParamValue === 'string' && ['vacation', 'medicare', 'pension'].includes(queryParamValue)) {
                requestType.value = checkTypes.find(type => type.key === queryParamValue) || checkTypes[0];
            }
        };

        const handleSelectedCheck = (option: SelectOption) => {
            if (option.key) {
                const check: VacationCheckRequestLine | undefined = checks.value.find(
                    (check: VacationCheckRequestLine) => check.Number === option.key
                );

                if (check && check.Date) {
                    checkDate.value = new Date(check.Date).toISOString().split('T')[0];
                }
            } else {
                checkDate.value = '';
            }

            checkNumber.value = option?.key as string;
        }

        const closeModal = () => {
            emit('close');
        }


        watch(() => fund.value, () => {
            updateRequestTypeFromQuery();
        });

        watch(() => checkType.value, (newType) => {
            postRoute.value = newType.key === 'directdeposit' ? updateDepositRoute : updateAddressRoute;
        });

        // Filter states based on selected country
        const filteredStates = computed(() => {
            const selectedCountry = address.value.Country?.key;
            const states = profileStore.statesByCountry;
            const countryStates = states ? (states as CountryStates[]).filter(state => state.CountryKey === selectedCountry) : [];

            return (countryStates?.map(state => state.States).flat());
        });

        const submitData = async () => {
            const formData = {
                PersonID: person.value?.PersonID,
                FirstName: person.value?.FirstName,
                LastName: person.value?.LastName,
                DateofBirth: person.value?.DateofBirth,
                Address: {
                    CheckCategory: requestType.value.key,
                    CheckType: checkType.value.key,
                    CheckDate: checkDate.value,
                    CheckNumber: checkNumber.value,
                    Street: address.value.Street1,
                    Street2: address.value.Street2,
                    Street3: address.value.Street3,
                    State: address.value.State,
                    ZipCode: address.value.Zip,
                    SelectedCheck: selectedCheck.value?.value ?? '',
                },
                Bank: bank.value,
                Notes: comments.value,
            };

            const route = postRoute.value;

            const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit });
            const successSubmit = await handleSubmit();

            if (successSubmit) {
                // closeModal();
            }
        }

        onMounted(async () => {
            await fetchData();
            updateRequestTypeFromQuery();
        });

        return {
            countries: profileStore.countries,
            statesByCountry,
            filteredStates,
            person,
            comments,
            address,
            bank,
            checks,
            checkOptions,
            selectedCheck,
            requestType,
            checkTypes,
            paymentMethods,
            accountTypes,
            checkType,
            checkDate,
            checkNumber,
            success,
            errors,
            loading,
            handleSelectedCheck,
            submitData
        }
    }
});
</script>
