import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.documentUrl)
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 0,
          src: $setup.documentUrl,
          width: "100%",
          height: "600"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true)
  ]))
}