import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dashboard-detail-item pdl-0" }
const _hoisted_2 = { class: "info-card" }
const _hoisted_3 = { class: "info-card__icon" }
const _hoisted_4 = { class: "info-card__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("i", {
          class: _normalizeClass(_ctx.iconClass)
        }, null, 2)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.detail), 1)
        ])
      ])
    ])
  ]))
}