<template>
  <div>
    <h6 class="mt-4">Search Persons</h6>
    <form method="GET" action="">
      <div class="form-group row mb-4">
        <div class="col-md-2">
          <label for="participant_id" class="col-form-label text-md-right">Participant ID</label>
          <input type="number" id="participant_id" v-model="filters.PersonID" class="form-control">
        </div>
        <div class="col-md-3">
          <label for="firstname" class="col-form-label text-md-right">First Name</label>
          <input type="text" id="firstname" name="FirstName" v-model="filters.FirstName" class="form-control">
        </div>
        <div class="col-md-3">
          <label for="lastname" class="col-form-label text-md-right">Last Name</label>
          <input type="text" id="lastname" v-model="filters.LastName" class="form-control">
        </div>
        <div class="col-md-4 d-flex justify-content-between align-items-end">
          <button type="button" class="btn btn-success w-50" @click="submitFilters(0)">
            {{ searching ? 'Searching...' : 'Search' }}
          </button>
          <button type="button" class="btn btn-info" @click="submitFilters(1)"> All Persons </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { SearchParticipantsFormFilters } from '@/interfaces/admin';

export default defineComponent({
  props: {
    searching: Boolean,
  },
  setup(_, { emit }) {
    const filters: SearchParticipantsFormFilters = reactive({
      PersonID: null,
      FirstName: '',
      LastName: '',
    });

    const submitFilters = (type: number) => {
      if (type === 1) {
        filters.PersonID = null;
        filters.FirstName = '';
        filters.LastName = '';
      }

      emit('update-filters', filters);
    }

    return {
      filters,
      submitFilters
    };
  }
});
</script>