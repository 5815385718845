<template>
  <Alerts :success="success" :info="info" :errors="errors" />
  <div class="plan-content">
    <!-- General Plan Detail Cards -->
    <PlanGeneral text="All Updates to your personal information will immediately and permanently change your account with the Fund
                        Office." />
    <!-- Steps -->
    <MultiStep :steps=2 :activeStep="currentStep" />
    <UpdateInfoStep1 v-if="currentStep === 1" @next="goToNextStep" @close="closeModal" />
    <UpdateInfoStep2 v-else />

    <div class="action-buttons">
      <button v-if="currentStep === 2" type="button" class="btn btn-secondary" @click="goToPreviousStep">
        Discard</button>
      <button v-if="currentStep === 2" type="button" class="btn btn-primary" :disabled="loadingSubmit"
        @click.prevent="submitData">
        {{ loadingSubmit ? 'Saving...' : 'Save' }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, Ref, defineComponent } from 'vue';
import { useProfileStore } from '@/stores/profile';
import { Person } from '@/interfaces/interfaces';
import useFormSubmit from '@composable/useFormSubmit';
import UpdateInfoStep1 from "@/components/profile/personal-info/UpdateInfoStep1.vue";
import UpdateInfoStep2 from "@/components/profile/personal-info/UpdateInfoStep2.vue";
import Alerts from '@components/utils/AlertsComponent.vue';
import PlanGeneral from '@components/cards/PlanGeneral.vue';
import MultiStep from "@components/form/MultiStep.vue";

export default defineComponent({
  components: {
    UpdateInfoStep1,
    UpdateInfoStep2,
    MultiStep,
    PlanGeneral,
    Alerts
  },
  setup(_, { emit }) {
    const profileStore = useProfileStore();
    const errors = ref<string[]>([]);
    const success = ref('');
    const info = ref('');
    const currentStep = ref<number>(1);
    const title: Ref<string> = ref('');
    const isReview = ref(false);
    const loading = ref(true);
    const loadingSubmit = ref(false);

    const goToNextStep = () => {
      currentStep.value++;
    };

    const goToPreviousStep = () => {
      currentStep.value--;
    };

    const closeModal = () => {
      emit('close');
    }

    // Handle Form Submit
    const submitData = async () => {
      const formData: Person = {...profileStore.personalData};
      const route = 'api/user/profile/my-personal-information';
      const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit });
      const successSubmit = await handleSubmit();

      if (successSubmit) {
        profileStore.fetchPersonalData('api/user/profile/my-personal-information');
      }

      closeModal();
    }

    return {
      currentStep,
      loading,
      loadingSubmit,
      isReview,
      title,
      success,
      errors,
      info,
      closeModal,
      goToPreviousStep,
      goToNextStep,
      submitData,
    };
  }
})
</script>
