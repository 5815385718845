import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container mt-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "col-md-12"
}
const _hoisted_5 = { class: "text-center mb-4" }
const _hoisted_6 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DependentStep1 = _resolveComponent("DependentStep1")!
  const _component_DependentStep2 = _resolveComponent("DependentStep2")!
  const _component_SuccessModal = _resolveComponent("SuccessModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($setup.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Loading..."))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("h3", _hoisted_5, _toDisplayString($setup.data?.Title), 1),
            _createElementVNode("form", null, [
              ($setup.errors.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.errors, (error, index) => {
                        return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(error), 1))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!$setup.isValidStep1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_DependentStep1, {
                      data: $setup.data,
                      onCheckData: $setup.checkData
                    }, null, 8, ["data", "onCheckData"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_DependentStep2, {
                      documentTypes: $setup.documentTypes,
                      onCheckData: $setup.checkData,
                      onSubmitData: $setup.handleSubmit
                    }, null, 8, ["documentTypes", "onCheckData", "onSubmitData"])
                  ]))
            ])
          ]))
    ]),
    ($setup.isModalVisible)
      ? (_openBlock(), _createBlock(_component_SuccessModal, {
          key: 0,
          message: $setup.success,
          title: "Success",
          route: "dependents"
        }, null, 8, ["message"]))
      : _createCommentVNode("", true)
  ]))
}