<template>
  <div class="container mt-4">
    <div class="row d-flex align-items-center mb-4">
      <div class="col-md-12 text-center">
        <h1 class="mb-4">Annuity Contribution Detail</h1>
        <p>This reflects the detail of the amount shown on your Account Summary on the prior page.</p>
      </div>
    </div>
    <div v-if="loading">Loading...</div>
    <div v-else>
      <TableComponent :PWHDs="PWHDs" :totalDaysWorked="TotalDaysWorked" :totalContributions="TotalContributions"
        :headerNames="headerNames" :sortOrder="sortOrder" @sort-data="sortData" />
      <router-link :to="{ name: 'annuity-summary', params: { year: year } }">
        <button type="button" class="btn btn-primary col-md-2">{{ ('Back') }}</button>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { Ref, onMounted, ref } from 'vue';
import { LocationQueryValue, useRoute } from 'vue-router';
import { PWHD } from '@/interfaces/interfaces';
import axios from 'axios'
import TableComponent from './TableComponent.vue';

export default {
  setup() {
    const route = useRoute();
    const PWHDs: Ref<PWHD[]> = ref([]);
    const TotalContributions = ref(0);
    const TotalDaysWorked = ref(0);
    const year = ref('');
    const fromDate: Ref<LocationQueryValue | LocationQueryValue[]> = ref(null);
    const toDate: Ref<LocationQueryValue | LocationQueryValue[]> = ref(null);
    const loading = ref(true);
    const sortOrder: Ref<number> = ref(1);
    const headerNames: Record<string, string> = {
      'PeriodBegin': 'Work Period',
      'TranProcDate': 'Date Rcvd. by Fund Office',
      'EmployerName': 'Employer',
      'ProductionName': 'Production',
      'Plan': 'Plan',
      'DaysWorked': 'Days Reported',
      'Amount': 'Contribution Amt $',
      'FirstOrgName': 'Juris. Local'
    }

    const sortData = async (sortBy: string) => {
      sortOrder.value = sortOrder.value === 1 ? -1 : 1;

      try {
        const response = await axios.get(`api/participant/annuity/details`, {
          params: {
            fromDate: fromDate.value,
            toDate: toDate.value,
            sortBy: sortBy,
            sortOrder: sortOrder.value
          }
        });

        PWHDs.value = response.data.PWHDs;
        TotalContributions.value = response.data.TotalContributions;
        TotalDaysWorked.value = response.data.TotalDaysWorked;
        year.value = response.data.Year;
      }
      catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    const fetchData = async () => {
      loading.value = true;
      fromDate.value = route.query.fromDate;
      toDate.value = route.query.toDate;

      try {
        const response = await axios.get(`api/participant/annuity/details`, {
          params: {
            fromDate: fromDate.value,
            toDate: toDate.value
          }
        });
        PWHDs.value = response.data.PWHDs;
        TotalContributions.value = response.data.TotalContributions;
        TotalDaysWorked.value = response.data.TotalDaysWorked;
        year.value = response.data.Year;
      }
      catch (error) {
        console.error('Error fetching data:', error);
      }

      loading.value = false;
    }

    onMounted(async () => await fetchData())

    return {
      PWHDs,
      TotalDaysWorked,
      TotalContributions,
      year,
      loading,
      headerNames,
      sortOrder,
      sortData
    };
  },
  components: {
    TableComponent
  },
};
</script>
