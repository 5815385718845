import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = {
  key: 1,
  class: "container"
}
const _hoisted_3 = { class: "text-center myb-4 py-4" }
const _hoisted_4 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReviewAddDependent = _resolveComponent("ReviewAddDependent")!
  const _component_ReviewUpdateInfo = _resolveComponent("ReviewUpdateInfo")!
  const _component_ReviewInfo = _resolveComponent("ReviewInfo")!
  const _component_SuccessModal = _resolveComponent("SuccessModal")!
  const _component_router_link = _resolveComponent("router-link")!

  return ($setup.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Loading..."))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString($setup.title), 1),
        ($setup.errors.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.errors, (error, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(error), 1))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        ($setup.formRoute === 'participant.dependents.add')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_ReviewAddDependent, {
                data: $setup.formData,
                documents: $setup.documents
              }, null, 8, ["data", "documents"])
            ]))
          : ($setup.formRoute === 'participant.personal.info')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_ReviewUpdateInfo, { data: $setup.formData }, null, 8, ["data"])
              ]))
            : _createCommentVNode("", true),
        _createVNode(_component_ReviewInfo, {
          approveButton: $setup.approveButton,
          comments: $setup.formData?.Comments,
          status: $setup.status,
          onHandleReview: $setup.handleReview
        }, null, 8, ["approveButton", "comments", "status", "onHandleReview"]),
        ($setup.isVisible)
          ? (_openBlock(), _createBlock(_component_SuccessModal, {
              key: 3,
              message: $setup.message,
              title: "Confirmation",
              route: "superadmin.requests",
              param: { name: 'status', value: $setup.status },
              onSubmitData: $setup.handleSubmit
            }, null, 8, ["message", "param", "onSubmitData"]))
          : _createCommentVNode("", true),
        _createVNode(_component_router_link, {
          to: { name: 'superadmin.requests', params: { status: $setup.status } },
          class: "btn btn-secondary w-25 h-25"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Back To Requests ")
          ]),
          _: 1
        }, 8, ["to"])
      ]))
}