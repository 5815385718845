import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group row mb-4" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "col-md-6" }
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "col-md-6 d-flex justify-content-start mb-4" }
const _hoisted_6 = ["for"]
const _hoisted_7 = ["id", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.inputId,
      class: "col-form-label text-md-right"
    }, "Web Email Address", 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        type: "email",
        id: _ctx.inputId,
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailData.ContactInfo) = $event)),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChanges && _ctx.handleChanges(...args))),
        required: ""
      }, null, 40, _hoisted_4), [
        [_vModelText, _ctx.emailData.ContactInfo]
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", {
        for: _ctx.primaryId,
        class: "col-form-label text-md-right d-flex flex-row-reverse justify-content-start gap-2"
      }, [
        _createTextVNode(" Primary Email "),
        _createElementVNode("input", {
          type: "radio",
          id: _ctx.primaryId,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updatePrimary())),
          checked: _ctx.emailData.PrimaryFlag == 1 ? true : false
        }, null, 8, _hoisted_7)
      ], 8, _hoisted_6)
    ])
  ]))
}