<template>
    <div class="container mt-4">
        <div class="position-fixed top-0 end-0 p-3" style="z-index: 11">
            <div v-show="info" class="alert alert-info">{{ info }}</div>
            <div v-show="success" class="alert alert-success">{{ success }}</div>
            <div v-show="errors.length > 0" class="alert alert-danger">
                <ul>
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center mb-4">
                <h1>Missing Demographic Information</h1>
            </div>
            <p>
                The Fund office is in receipt of employer contributions on behalf of the following participant(s).
                However,
                vital demographic information is missing. Please provide the information by correcting/adding data and
                clicking on the “Update” button. In the event you are unable to identify the participant by the
                information
                provided, please contact the Participant Services Center at PSC@iatsenbf.org.
            </p>
            <div v-if="loading">Loading Data...</div>
            <div v-else>
                <table class="table table-bordered table-striped">
                    <thead class="table-dark">
                        <tr class="text-center align-middle">
                            <th>NBF ID</th>
                            <th>Member Name<br>(Last, First)</th>
                            <th>Most Recent<br>Employer/Production</th>
                            <th>Date of Birth</th>
                            <th>Gender</th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody v-if="data.length > 0">
                        <tr v-for="person in data" :key="person.PersonID" class="text-center">
                            <td> {{ person.PersonID }} </td>
                            <td> {{ person.Name }} </td>
                            <td> {{ person.Employer }} <br />
                                {{ person.Production }}
                            </td>
                            <td>
                                <input type="date" v-model="person.DateofBirth" class="form-control"
                                    :max="new Date().toISOString().split('T')[0]" />
                            </td>
                            <td>
                                <select v-model="person.Sex" class="form-control" id="gender" name="Sex">
                                    <option value="0"> Gender </option>
                                    <option v-for="(value, key) in genders" :value="key" :key="key">
                                        {{ value }}
                                    </option>
                                </select>
                            </td>
                            <td class="d-flex gap-2">
                                <div class="d-flex flex-column gap-2">
                                    <input type="text" v-model="person.Street1" class="form-control"
                                        placeholder="Address Line 1" required />
                                    <input type="text" v-model="person.City" class="form-control" placeholder="City"
                                        required />
                                    <input type="number" v-model="person.Zip" class="form-control"
                                        placeholder="Postal Code" required maxlength="15" />
                                </div>
                                <div class="d-flex flex-column gap-2">
                                    <input type="text" v-model="person.Street2" class="form-control"
                                        placeholder="Address Line 2" />
                                    <select v-model="person.State" class="form-control" id="state" name="State"
                                        required>
                                        <option value="0"> State/Province </option>
                                        <option v-for="state in person?.FilteredStates" :value="state?.ShortName"
                                            :key="state?.ShortName">
                                            {{ state?.LongName }}
                                        </option>
                                    </select>
                                    <select class="form-control" id="country" v-model="person.Country"
                                        @change="updateFilteredStates(person.PersonID)" required>
                                        <option v-for="country in countries" :value="country.ShortName"
                                            :key="country.ShortName">
                                            {{ country.LongName }}
                                        </option>
                                    </select>
                                    <button type="button" class="btn btn-secondary"
                                        @click="handleSubmit(person.PersonID)">
                                        {{ person.LoadingSubmit ? 'Updating...' : 'Update' }}</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr class="text-center">
                            <td colspan="6">
                                There are no employees that require demographic data updates at this time
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Country, CountryStates } from '@/interfaces/interfaces';
import { PersonDemographicInfo } from '@/interfaces/employer';
import { onMounted, ref, Ref } from 'vue';
import { useUtilsStore } from '@/stores/utils';
import axios from 'axios';

export default {
    setup() {
        const loading = ref(true);
        const data: Ref<PersonDemographicInfo[] | []> = ref([]);
        const utilsStore = useUtilsStore();
        const genders = ref(null);
        const statesByCountry: Ref<CountryStates[] | null> = ref(null);
        const countries: Ref<Country[] | null> = ref(null);
        const errors: Ref<string[]> = ref([]);
        const success = ref(null);
        const info = ref(null);
        const route = `api/user/demographic-information`;

        const updateFilteredStates = (personId: string) => {
            const person: PersonDemographicInfo | null = data.value.find(record => record.PersonID === personId) ?? null;

            if (person) {
                person.State = '0';
                const countryStates = statesByCountry.value ? (statesByCountry.value as CountryStates[]).filter(state => state.CountryKey === person.Country) : [];
                person.FilteredStates = countryStates?.map((state: CountryStates) => state.States).flat();
            }
        };

        const isStateDropdownVisible = (selectedCountry: string): boolean => {
            return selectedCountry === 'Canada' || selectedCountry === 'US' || selectedCountry === 'Mexico';
        };

        const fetchData = async (isLoading: boolean) => {
            if (isLoading)
                loading.value = true;

            await axios.get(route)
                .then(response => {
                    data.value = response.data.Records;

                    data.value.forEach((person, index) => {
                        if (!person.Sex) {
                            data.value[index].Sex = '0';
                        }

                        if (!person.State) {
                            data.value[index].State = '0';
                        }
                    })
                })

            if (data.value.length > 0) {
                await utilsStore.fetchGenders()
                    .then(() => utilsStore.fetchStatesByCountry())
                    .then(() => utilsStore.fetchCountries())
                    .catch((error) => {
                        console.error('Error fetching data:', error);
                    });

                genders.value = utilsStore.genderOptions;
                statesByCountry.value = utilsStore.statesByCountryOptions;
                countries.value = utilsStore.countryOptions;
            }

            if (isLoading)
                loading.value = false;
        };

        const handleSubmit = async (personId: string) => {
            const person: PersonDemographicInfo | null = data.value.find(record => record.PersonID === personId) ?? null;

            if (person) {
                person.LoadingSubmit = true;

                await axios
                    .post(`${route}/${personId}`, { ...person, _method: "PATCH" })
                    .then((response) => {
                        success.value = response.data?.success;
                        info.value = response.data?.info;
                        fetchData(false);
                    })
                    .catch((error) => {
                        if (error.response) {
                            errors.value = Object.values(
                                error.response.data.errors
                            ).flat() as string[];
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            success.value = null;
                            info.value = null;
                            errors.value = []
                        }, 3500);

                        person.LoadingSubmit = false;
                    })
            }
        };

        onMounted(async () => await fetchData(true));

        return {
            loading,
            data,
            genders,
            statesByCountry,
            countries,
            success,
            info,
            errors,
            updateFilteredStates,
            handleSubmit,
            isStateDropdownVisible
        };
    }
}
</script>