<template>
    <div v-if="PWHDs.length == 0" class="col-md-12 text-center my-4">
        <span> No Contribution Details Available </span>
    </div>
    <table v-else class="table table-bordered table-striped" id="detail_table" style="max-height: 300px">
        <thead class="table-dark">
            <tr>
                <th v-for="(header, key) in Object.entries(headerNames)" :key="key" @click="sortBy(header[0])"
                    :style="{ cursor: 'pointer' }">
                    {{ header[1] }}
                </th>
            </tr>
        </thead>
        <tbody>
            <TableRow v-for="PWHD in PWHDs" :key="PWHD.ParticipantWHID" :PWHD="PWHD" />
        </tbody>
        <tfoot>
            <tr>
                <td colspan="5">Totals:</td>
                <td>{{ totalDaysWorked }}</td>
                <td>{{ totalContributions?.toFixed(2) }}</td>
                <td></td>
            </tr>
        </tfoot>
    </table>
</template>
<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { PWHD } from '@/interfaces/interfaces';
import TableRow from './TableRow.vue';

export default defineComponent({
    props: {
        PWHDs: {
            type: Array as PropType<PWHD[]>,
            required: true,
        },
        headerNames: {
            type: Object as PropType<Record<string, string>>,
            required: true,
        },
        totalDaysWorked: Number,
        totalContributions: Number,
    },
    setup(_, { emit }: { emit: (event: string, ...args: string[]) => void }) {

        const sortBy = (sortBy: string) => {
            emit("sort-data", sortBy);
        };

        return {
            sortBy
        }
    },
    components: {
        TableRow
    },
});
</script>