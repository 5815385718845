<template>
    <div class="form-group row mb-4">
        <div class="col-md-3">
            <label for="participant_id" class="col-form-label text-md-right">Participant ID</label>
            <input type="text" id="participant_id" v-model="person.PersonID" :class="{
                'form-control': true, 'border-danger': changes.PersonID
            }" readonly>
        </div>
        <div class="col-md-3">
            <label for="social_security" class="col-form-label text-md-right"> Social Security #
            </label>
            <a href="#" class="form-control btn btn-outline-secondary" id="show_ssn">Show
                SSN</a>
        </div>
        <div class="col-md-6">
            <label for="salutation" class="col-form-label text-md-right">Salutation</label>
            <input type="text" v-model="person.Salutation" :class="{
                'form-control': true, 'border-danger': changes.Salutation
            }" readonly>
        </div>
    </div>

    <div class="form-group row mb-4">
        <div class="col-md-6">
            <label for="first_name" class="col-form-label text-md-right">First Name <span
                    class="text-danger">*</span></label>
            <input type="text" id="first_name" v-model="person.FirstName" :class="{
                'form-control': true, 'border-danger': changes.FirstName
            }" required>
        </div>
        <div class="col-md-6">
            <label for="middle_name" class="col-form-label text-md-right">Middle Name</label>
            <input type="text" id="middle_name" v-model="person.MiddleName" :class="{
                'form-control': true, 'border-danger': changes.MiddleName
            }">
        </div>
    </div>

    <div class="form-group row mb-4">
        <div class="col-md-6">
            <label for="last_name" class="col-form-label text-md-right">Last Name <span class="text-danger">*</span></label>
            <input type="text" id="last_name" v-model="person.LastName" :class="{
                'form-control': true, 'border-danger': changes.LastName
            }" required>
        </div>
        <div class="col-md-6">
            <label for="suffix" class="col-form-label text-md-right">Suffix</label>
            <input type="text" id="suffix" v-model="person.NameSuffix" :class="{
                'form-control': true, 'border-danger': changes.NameSuffix
            }" required>
        </div>
    </div>

    <div class="form-group row mb-4">
        <div class="col-md-6">
            <label for="date_of_birth" class="col-form-label text-md-right">Date of Birth <span
                    class="text-danger">*</span></label>
            <input type="text" id="date_of_birth" v-model="person.DateofBirth" :class="{
                'form-control': true, 'border-danger': changes.DateofBirth
            }" required>
        </div>
        <div class="col-md-6">
            <label for="gender" class="col-form-label text-md-right">Gender</label>
            <input type="text" id="gender" v-model="person.Sex" :class="{
                'form-control': true, 'border-danger': changes.Sex
            }" required>
        </div>
    </div>

    <div class="form-group row mb-4">
        <div class="col-md-6">
            <label for="marital_status" class="col-form-label text-md-right">Marital Status</label>
            <input type="text" id="marital_status" v-model="person.MaritalStatus" :class="{
                'form-control': true, 'border-danger': changes.MaritalStatus
            }" required>
        </div>
        <div class="col-md-6">
            <label for="local" class="col-form-label text-md-right">Home Local</label>
            <input type="text" id="local" v-model="person.HomeLocal" :class="{
                'form-control': true, 'border-danger': changes.HomeLocal
            }" required>
        </div>
    </div>
</template>

<script lang="ts">
import { ReviewDependentData } from '@/interfaces/interfaces';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
    props: {
        data: {
            type: Object as PropType<ReviewDependentData | null>,
            required: true
        },
    },
    setup(props) {
        const person = JSON.parse(JSON.stringify(props.data?.Person));
        const changes = JSON.parse(JSON.stringify(props.data?.Changes));

        return { person, changes }
    }
})
</script>
