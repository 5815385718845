export function usePrint() {
    function printElementById(id: string, title: string) {
        return function () {
            const contents = document.getElementById(id)?.innerHTML;
            const frame1 = document.createElement("iframe");
            frame1.name = "frame1";
            frame1.style.position = "absolute";
            frame1.style.top = "-1000000px";
            document.body.appendChild(frame1);
            const frameDoc: any = frame1.contentWindow || frame1.contentDocument;

            if (frameDoc) {
                frameDoc.document.open();
                frameDoc.document.write(
                    '<html lang="en"><head><title>' + title + "</title>"
                );
                frameDoc.document.write(`
                    <style>
                    /* Add your styles here */
                    table {
                        width: 100%;
                        border-collapse: collapse;
                        font-size:10px;
                    }
                    th, td {
                        border: 1px solid black;
                        padding: 5px;
                        text-align: left;
                    }
                    th {
                        background-color: #f2f2f2;
                    }
                    a {
                        color:black;
                        text-decoration: none;
                    }
                    </style>
                `);
                frameDoc.document.write("</head><body><table>");
                frameDoc.document.write(contents);
                frameDoc.document.write("</table></body></html>");
                frameDoc.document.close();
                setTimeout(function () {
                    (window.frames as any)["frame1"].focus();
                    (window.frames as any)["frame1"].print();
                    document.body.removeChild(frame1);
                }, 500);
            }

            return false;
        };
    }

    return { printElementById };
}
