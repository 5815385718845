<template>
    <div class="container mt-4">
        <Alerts :success="success" :errors="errors" />
        <div class="row">
            <div class="col-md-12 d-flex flex-column gap-4">
                <h1 class="text-center mb-4">
                    Claims History Search
                </h1>
                <div class="card">
                    <div class="card-body">
                        Select parameters below to search for a specific claim or all claims for a specific date range.
                    </div>
                </div>

                <div v-if="loading"> Loading... </div>

                <!-- Filters -->
                <div v-else-if="!loading && claimsHistory?.length === 0" class="d-flex flex-column gap-4">
                    <div class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2"> Enter Claim # </span>
                        <input type="text" v-model="filters.Claim" class="form-control w-50" />
                    </div>
                    <div class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2">Enter Web Reference # </span>
                        <input type="text" v-model="filters.WebReference" class="form-control w-50" />
                    </div>
                    <span>If you do not have a Claim # use filters below to search for Claim</span>
                    <div class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2"> Patient Name: </span>
                        <div class="w-50">
                            <Multiselect v-model="filters.PatientName" :options="patients" placeholder="Type to search"
                                :searchable="true" :close-on-select="true" label="FullName" track-by="ID">
                            </Multiselect>
                        </div>
                    </div>
                    <div class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2"> Service Date(s): </span>
                        <div class="w-50 d-flex align-items-center gap-4">
                            <input type="date" v-model="filters.ServiceDate.StartDate" class="form-control w-50"
                                required />
                            <span>-</span>
                            <input type="date" v-model="filters.ServiceDate.EndDate" class="form-control w-50"
                                required />
                        </div>
                    </div>
                    <div class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2"> Submission Date(s): </span>
                        <div class="w-50 d-flex align-items-center gap-4">
                            <input type="date" v-model="filters.SubmissionDate.StartDate" class="form-control w-50"
                                required />
                            <span>-</span>
                            <input type="date" v-model="filters.SubmissionDate.EndDate" class="form-control w-50"
                                required />
                        </div>
                    </div>
                    <div class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2"> Claim Status: </span>
                        <div class="w-50">
                            <Multiselect v-model="filters.ClaimStatus" :options="statuses" placeholder="Type to search"
                                :searchable="true" :close-on-select="true" openDirection="bottom">
                            </Multiselect>
                        </div>
                    </div>
                    <div class="d-flex gap-2 justify-content-between w-100">
                        <router-link class="btn btn-secondary" :to="{ name: 'participant.claims' }">Back</router-link>
                        <button class="btn btn-primary w-25" @click="searchData">
                            {{ loadingSearch ? 'Searching...' : 'Search' }}</button>
                    </div>
                </div>

                <!-- Results -->
                <div v-else-if="!loadingSearch && claimsHistory?.length > 0">
                    <button class="btn btn-secondary col-md-2 mb-4" @click="resetFilters">
                        Change my current Filters
                    </button>
                    <table class="table table-bordered table-striped">
                        <thead class="table-dark">
                            <tr class="text-center align-middle">
                                <th colspan="2">Claim #</th>
                                <th>Web Ref #</th>
                                <th>Date(s) of <br>Service</th>
                                <th colspan="3">Patient Information</th>
                                <th>Claim Status </th>
                                <th>Claim Document(s)</th>
                                <th>EoB Document</th>
                            </tr>
                            <tr class="text-center align-middle">
                                <!-- Subheaders -->
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>Name</th>
                                <th>DOB</th>
                                <th>Relationship</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <!-- End of Subheaders -->
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(entry, index) in claimsHistory" :key="index">
                                <tr class="text-center">
                                    <td>
                                        <button class="btn btn-sm btn-secondary" @click="toggleDetails(index)">
                                            {{ entry.showDetails ? ' - ' : ' + ' }}
                                        </button>
                                    </td>
                                    <td v-if="entry.Claim?.ClaimID">
                                        <router-link
                                            :to="{ name: 'participant.claims.new', params: { ClaimID: entry.Claim?.ClaimID, Type: entry.Type } }">
                                            {{ entry.Claim?.ClaimID }}
                                        </router-link>
                                    </td>
                                    <td v-else>
                                        {{ entry.Claim?.ClaimID }}
                                    </td>
                                    <td v-if="!entry.Claim?.ClaimID">
                                        <router-link
                                            :to="{ name: 'participant.claims.new', params: { ClaimID: entry.Claim?.NEICID, Type: entry.Type } }">
                                            {{ entry.Claim?.NEICID }}
                                        </router-link>
                                    </td>
                                    <td v-else>
                                        {{ entry.Claim?.NEICID }}
                                    </td>
                                    <td>{{ entry.Claim?.DatesOfService }}</td>
                                    <td>{{ entry.Claim?.Name }}</td>
                                    <td>{{ entry.Claim?.DoB }}</td>
                                    <td>{{ entry.Claim?.Relationship }}</td>
                                    <td>{{ entry.Claim?.ClaimStatus }}</td>
                                    <td>{{ entry.Claim?.ClaimDocs }}</td>
                                    <td>{{ entry.Claim?.EOBDocs }}</td>
                                </tr>
                                <tr v-if="entry.showDetails" :key="'details-' + index">
                                    <td colspan="10">
                                        <table class="table table-striped">
                                            <thead class="table-light">
                                                <tr class="text-center align-middle">
                                                    <th><b>Amt Requested</b></th>
                                                    <th><b>Pd By Other Carrier</b></th>
                                                    <th><b>Admin Fee</b></th>
                                                    <th><b>Amt Paid</b></th>
                                                    <th><b>Reqst Date</b></th>
                                                    <th><b>Payment Info</b></th>
                                                    <th><b>Check / Deposit Date</b></th>
                                                    <th><b>Previous Claim #</b></th>
                                                    <th><b>Notes</b></th>
                                                </tr>
                                            </thead>
                                            <tr v-for="(detail, detailIndex) in entry.Details" :key="detailIndex"
                                                class="text-center align-middle">
                                                <td>{{ detail.AmtRequested }}</td>
                                                <td>{{ detail.OtherCarrier }}</td>
                                                <td>{{ detail.AdminFee }}</td>
                                                <td>{{ detail.AmtPaid }}</td>
                                                <td>{{ detail.DateReceived }}</td>
                                                <td>{{ detail.PaymentInfo }}</td>
                                                <td>{{ detail.CheckDepositDate }}</td>
                                                <td>{{ detail.PreviousClaim }}</td>
                                                <td>{{ detail.Notes }}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </template>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { onMounted, reactive, ref, Ref } from 'vue';
import { ClaimHistory } from '@interfaces/health';
import Alerts from "@/components/utils/AlertsComponent.vue";
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import 'vue-multiselect/dist/vue-multiselect.css';

interface Patient {
    ID: string;
    FullName: string;
}

interface Filters {
    ServiceDate: {
        StartDate?: string,
        EndDate?: string,
    };
    SubmissionDate: {
        StartDate?: string,
        EndDate?: string,
    };
    PatientName: Patient;
    Claim: string | null;
    WebReference: string | null;
    ClaimStatus: [];
}

export default {
    components: {
        Alerts,
        Multiselect,
    },

    setup() {
        const loading = ref(true);
        const loadingSearch = ref(false);
        const success = ref(null);
        const errors: Ref<string[] | string | null> = ref(null);
        const patients: Ref<Patient[]> = ref([]);
        const statuses = ref([]);
        const claimsHistory: Ref<ClaimHistory[]> = ref([]);
        const initialFilters = {
            Claim: null,
            WebReference: null,
            PatientName: [],
            ServiceDate: {
                StartDate: null,
                EndDate: null,
            },
            SubmissionDate: {
                StartDate: null,
                EndDate: null,
            },
            ClaimStatus: [],
        };
        const filters: Filters = reactive({ ...JSON.parse(JSON.stringify(initialFilters)) });

        const resetFilters = () => {
            claimsHistory.value = [];
            Object.assign(filters, JSON.parse(JSON.stringify(initialFilters)));
        }

        const toggleDetails = (index: number) => {
            claimsHistory.value.forEach((entry, entryIndex) => {
                if (entryIndex !== index) {
                    entry.showDetails = false;
                }
            });

            claimsHistory.value[index].showDetails = !claimsHistory.value[index].showDetails;
        };

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/participant/claims-search')
                .then(response => {
                    patients.value = response.data.Patients;
                    statuses.value = response.data.Statuses;
                    filters.ClaimStatus = statuses.value[0];
                    filters.PatientName = patients.value[0];
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        // Get Claims History
        const searchData = async () => {
            loadingSearch.value = true;
            claimsHistory.value = [];

            await axios
                .post('api/participant/claims-history', {
                    ...filters
                })
                .then(response => {
                    claimsHistory.value = Object.values(response.data.Claims);
                })
                .catch(error => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }
                })
                .finally(() => {
                    loadingSearch.value = false;

                    setTimeout(() => {
                        success.value = null
                        errors.value = []
                    }, 3500);
                })
        }

        onMounted(async () => await fetchData());

        return {
            loading,
            loadingSearch,
            success,
            errors,
            filters,
            patients,
            statuses,
            claimsHistory,
            resetFilters,
            searchData,
            toggleDetails,
        };
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
}
</style>
