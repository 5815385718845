<template>
    <section class="work-history" data-modal-name="work-history" style="width: 100%;">
        <!-- Plan Header -->
        <div class="plan-buttons hide-desktop">
            <a href="" class="btn btn-primary"> Missing Contributions Form </a>
            <p class="btn btn-secondary plan-modal" data-modal-name="pension-contribution-history"> View Details </p>
        </div>
        <div class="plan-content">
            <WorkHistoryFilters :filters="filters" @update-filters="updateFilters" />
            <WorkHistoryListAlternative :data="workHistoryData" />
        </div>
    </section>
</template>

<script lang="ts">
  import { reactive, ref, onMounted, watch } from 'vue';
  import axios from 'axios';
  // import WorkHistoryList from '../../components/participant/WorkHistoryList.vue';
  import WorkHistoryListAlternative from '../../components/participant/WorkHistoryListAlternative.vue';
  import WorkHistoryFilters from '../../components/participant/WorkHistoryFilters.vue';
  import { WorkHistoryData, WorkHistoryFilterKeys } from '@/interfaces/interfaces';

  export default {
    setup() {
      const workHistoryData = ref<WorkHistoryData>({ Years: {}, GrandTotals: {}, GrandTotalUnits: null });
      const filters = reactive<WorkHistoryFilterKeys>({
        period: null,
        from_date: null,
        to_date: null,
        employers: [],
        productions: [],
        funds: []
      });

      const fetchData = async () => {
        try {
          const response = await axios.get('api/participant/work-history', { params: filters });
          workHistoryData.value = response.data; // Assign to ref's value
        } catch (error) {
          console.error('Error:', error);
        }
      };

      onMounted(fetchData);

      const updateFilters = (newFilters: WorkHistoryFilterKeys) => {
        Object.assign(filters, newFilters);
      };

      watch(filters, fetchData, { deep: true });

      return {
        workHistoryData, // No longer a method, but a reactive ref
        filters,
        updateFilters
      };
    },
    components: {
      WorkHistoryListAlternative,
      WorkHistoryFilters
    }
  };
</script>