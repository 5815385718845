<template>
    <div class="row">
        <div class="col-md-12 mb-4">
            <h6> STEP 1 - Dependent Specific Information </h6>
            <div class="form-group row mb-4">
                <div class="col-md-6 d-flex align-items-center gap-4">
                    <label for="relationship" class="col-md-2 col-form-label text-md-right">
                        Relationship <span class="text-danger">*</span>
                    </label>
                    <input type="text" id="relationship" name="Relationship" v-model="dependent.Relationship" :class="{
                        'form-control': true, 'border-danger': changes.Relationship
                    }" readonly>
                </div>
            </div>

            <div class="form-group row d-flex align-items-end mb-4">
                <div class="col-md-4">
                    <label for="country" class="col-form-label text-md-right">
                        Country <span class="text-danger">*</span>
                    </label>
                    <input type="text" id="country" name="CitizenshipCode" v-model="person.CitizenshipCode" :class="{
                        'form-control': true, 'border-danger': changes.CitizenshipCode
                    }" readonly>
                </div>
                <div class="col-md-3">
                    <label for="social_security" class="col-form-label text-md-right">
                        Social Security #
                    </label>
                    <a href="#" class="form-control btn btn-outline-secondary" id="show_ssn">Show SSN</a>
                </div>
                <div class="col-md-2">
                    <label for="participant_id" class="col-form-label text-md-right">
                        Participant ID
                    </label>
                    <input type="text" id="participant_id" name="PersonID" v-model="person.PersonID" :class="{
                        'form-control': true
                    }" readonly>
                </div>
            </div>

            <div class="form-group row mb-4">
                <div class="col-md-2">
                    <label for="salutation" class="col-form-label text-md-right">Salutation</label>
                    <input type="text" id="salutation" name="Salutation" v-model="person.Salutation" :class="{
                        'form-control': true, 'border-danger': changes.Salutation
                    }" readonly>
                </div>
                <div class="col-md-2">
                    <label for="first_name" class="col-form-label text-md-right">
                        First Name <span class="text-danger">*</span>
                    </label>
                    <input type="text" id="first_name" name="FirstName" maxlength="12" v-model="person.FirstName"
                        :class="{
                        'form-control': true, 'border-danger': changes.FirstName
                    }" readonly>
                </div>
                <div class="col-md-2">
                    <label for="middle_name" class="col-form-label text-md-right">Middle Name</label>
                    <input type="text" id="middle_name" name="MiddleName" maxlength="12" v-model="person.MiddleName"
                        :class="{
                        'form-control': true, 'border-danger': changes.MiddleName
                    }" readonly>
                </div>
                <div class="col-md-2">
                    <label for="last_name" class="col-form-label text-md-right">
                        Last Name <span class="text-danger">*</span>
                    </label>
                    <input type="text" id="last_name" name="LastName" maxlength="25" v-model="person.LastName" :class="{
                        'form-control': true, 'border-danger': changes.LastName
                    }" readonly>
                </div>
                <div class="col-md-2">
                    <label for="suffix" class="col-form-label text-md-right">Suffix</label>
                    <input type="text" id="suffix" name="NameSuffix" v-model="person.NameSuffix" :class="{
                        'form-control': true, 'border-danger': changes.NameSuffix
                    }" readonly>
                </div>
                <div class="col-md-2">
                    <label for="date_of_birth" class="col-form-label text-md-right">
                        Date of Birth <span class="text-danger">*</span>
                    </label>
                    <input type="date" id="date_of_birth" name="DateofBirth" v-model="person.DateofBirth" :class="{
                        'form-control': true, 'border-danger': changes.DateofBirth
                    }" readonly>
                </div>
            </div>

            <div class="form-group row mb-4">
                <div class="col-md-2">
                    <label for="gender" class="col-form-label text-md-right">Gender</label>
                    <input type="text" id="gender" name="Sex" v-model="person.Sex" :class="{
                        'form-control': true, 'border-danger': changes.Sex
                    }" readonly>
                </div>
                <div class="col-md-2">
                    <label for="phone_number" class="col-form-label text-md-right"> Deceased Date </label>
                    <input type="text" id="phone_number" name="DeceasedDate" v-model="person.DateofDeath" :class="{
                        'form-control': true, 'border-danger': changes.DateofDeath
                    }" readonly>
                </div>
                <div class="col-md-2">
                    <label for="effective_date" class="col-form-label text-md-right"> Dependent Effective Date </label>
                    <input type="text" id="effective_date" v-model="dependent.EffectiveDate" :class="{
                        'form-control': true, 'border-danger': changes.EffectiveDate
                    }" readonly>
                </div>
                <div class="col-md-2">
                    <label for="drop_date" class="col-form-label text-md-right"> Dependent Drop Date </label>
                    <input type="text" id="drop_date" v-model="dependent.DropDate" :class="{
                        'form-control': true, 'border-danger': changes.DropDate
                    }" readonly>
                </div>
                <div class="col-md-2">
                    <label for="phone_number" class="col-form-label text-md-right"> Telephone Number </label>
                    <input type="text" id="phone_number" :class="{
                        'form-control': true, 'border-danger': changes.Phone
                    }" v-model="contact.Phone" readonly>
                </div>
                <div class="col-md-2">
                    <label for="email" class="col-form-label text-md-right"> Email Address </label>
                    <input type="email" id="email" :class="{
                        'form-control': true, 'border-danger': changes.Email
                    }" v-model="contact.Email" readonly>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ReviewDependentData } from '@/interfaces/interfaces';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
    props: {
        data: {
            type: Object as PropType<ReviewDependentData | null>,
            required: true
        },
    },
    setup(props) {
        const dependent = JSON.parse(JSON.stringify(props.data?.Dependent));
        const person = JSON.parse(JSON.stringify(props.data?.Person));
        const changes = props.data?.Changes ? JSON.parse(JSON.stringify(props.data?.Changes)) : [];
        const contact = props.data?.ContactMethod ? JSON.parse(JSON.stringify(props.data?.ContactMethod)) : [];

        return { dependent, person, changes, contact }
    }
})
</script>
