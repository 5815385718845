<template>
    <Alerts :error="error" />
    <!-- Documents Uploaded Table -->
    <div class="table-wrapper">
        <h4> Documents on File/Pending Admin Review </h4>
        <DataTable v-if="uploadedDocs.rows?.length" :table="uploadedDocs" classes="striped small" />
    </div>

    <!-- Form -->
    <form id="update-personal-info-form" class="form update-personal-info" method="post" v-if="!existingPersonID">
        <SelectComponent label="Document Type" v-model:selectedOption="selectedDocType" :options=documentTypes />
        <UploadFile label="Upload Files" :modelValue="fileNames" :multiple="true" @change="handleFileSelection"
            :disabled="loading" :reset="reset" />
        <DragDrop customClasses="grey large" @change="handleFileSelection" :reset="reset" />
    </form>
    <div v-else>
        <p>Documents can be uploaded after saving</p>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref } from 'vue';
import { SelectOption, TableData } from '@/interfaces/interfaces';
import { useProfileStore } from '@/stores/profile';
import axios from 'axios';

import SelectComponent from '@components/form/SelectComponent.vue';
import DataTable from '@components/form/DataTable.vue';
import UploadFile from '@components/form/UploadFile.vue';
import DragDrop from '@components/form/DragDrop.vue';
import Alerts from '@components/utils/AlertsComponent.vue';

export default defineComponent ({
    components: {
        SelectComponent,
        DataTable,
        UploadFile,
        DragDrop,
        Alerts
    },
    setup(_, { emit }: { emit: (event: string, ...args: any[]) => void }) {
        const selectedDocType: Ref<SelectOption> = ref({ key: 0, value: '-Select Document Type-' });
        const uploadedDocs: Ref<TableData> = ref({
            headers: [
                { title: 'Type' },
                { title: 'Date' },
                { title: 'Status' }
            ],
            rows: [],
        });
        const profileStore = useProfileStore();
        const documentTypes: Ref<SelectOption[] | null> = ref(profileStore.documentTypes);
        const fileNames = ref('');
        const fileInput = ref<HTMLInputElement | null>(null);
        const formData = new FormData();
        const error: Ref<string | null> = ref(null);
        const loading = ref(false);
        const reset = ref(false);

        const preventDefault = (ev: Event) => {
            ev.preventDefault();
        }

        const browseFiles = () => {
            fileInput.value?.click();
        }

        const handleSubmit = () => {
            emit('submit-data');
        }

        const handleStep1 = () => {
            emit("check-data", false);
        }

        const handleFileSelection = async (event: Event) => {
            loading.value = true;
            reset.value = false;
            const type: string = selectedDocType.value.key as string;

            if (!type) {
                error.value = 'The selected document type is invalid.';
                loading.value = false;
                reset.value = true;

                setTimeout(() => {
                    error.value = '';
                }, 3000);

                return;
            }

            const files = (event.target as HTMLInputElement)?.files || (event as DragEvent)?.dataTransfer?.files;
            formData.append('DocumentType', type);
            // console.log(files, '@uploaded files');

            if (files) {
                await uploadFiles(files);
            }

            fileNames.value = '';
            loading.value = false;
        };

        // Upload Files in Storage
        const uploadFiles = async (files: FileList) => {
            for (let i = 0; i < files.length; i++) {
                formData.append('Files[]', files[i]);
                fileNames.value += files[i].name;
            }

            await axios.post('api/smartsearch/personal-file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(response => {
                    uploadedDocs.value.rows = response.data.Rows;
                })
                .catch(error => {
                    console.error('There was an error uploading document!', error);
                });
        }

        return {
            existingPersonID: false,
            selectedDocType,
            documentTypes,
            fileNames,
            uploadedDocs,
            fileInput,
            error,
            loading,
            reset,
            preventDefault,
            browseFiles,
            handleFileSelection,
            handleSubmit,
            handleStep1,
        };
    },
});
</script>