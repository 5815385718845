<template>
    <div v-show="paginator.last_page !== 1" class="pagination-container">
        <button class="pagination-btn" :disabled="paginator.current_page === 1" @click="prevPage">
            <i class="fa-solid fa-angle-left"></i>
        </button>
        <div v-if="paginator.current_page < 10">
            <template v-for="pageNumber in Math.min(10, paginator.last_page)" :key="pageNumber">
                <button class="pagination-btn" @click="goToPage(pageNumber)"
                    :class="{ 'active-page': paginator.current_page === pageNumber }">
                    {{ pageNumber }}
                </button>
            </template>
        </div>
        <div v-if="paginator.current_page >= 10">
            <button class="pagination-btn" @click="goToPage(1)"
                :class="{ 'active-page': paginator.current_page === 1 }">
                1
            </button>
            <button class="pagination-btn" @click="goToPage(2)"
                :class="{ 'active-page': paginator.current_page === 2 }">
                2
            </button>

            <!-- Display ellipsis if there are more than 4 pages -->
            <span v-if="paginator.last_page > 10" class="pagination-btn">...</span>

            <template
                v-for="pageNumber in [Math.max(2, paginator.current_page - 5), Math.min(paginator.current_page - 1, paginator.last_page - 1)]"
                :key="pageNumber">
                <button v-if="paginator.last_page > 10" class="pagination-btn" @click="goToPage(pageNumber)"
                    :class="{ 'active-page': paginator.current_page === pageNumber }">
                    {{ pageNumber }}
                </button>
            </template>

            <!-- Display current page -->
            <button class="pagination-btn" @click="goToPage(paginator.current_page)" :class="{ 'active-page': true }">
                {{ paginator.current_page }}
            </button>

            <!-- Display next 3 pages of the current page -->

            <template
                v-for="pageNumber in [paginator.current_page + 1, Math.min(paginator.current_page + 3, paginator.last_page - 1)]"
                :key="pageNumber">
                <button v-if="paginator.current_page < paginator.last_page - 1" class="pagination-btn"
                    @click="goToPage(pageNumber)" :class="{ 'active-page': paginator.current_page === pageNumber }">
                    {{ pageNumber }}
                </button>
            </template>
            <!-- Display last page of the current page -->

            <template v-if="paginator.current_page === paginator.last_page - 1">
                <button class="pagination-btn" @click="goToPage(paginator.last_page)"
                    :class="{ 'active-page': paginator.current_page === paginator.last_page }">
                    {{ paginator.last_page }}
                </button>
            </template>
        </div>

        <!-- Display ellipsis if there are more than 4 pages -->
        <span v-if="paginator.last_page > 10 && paginator.current_page < paginator.last_page - 2"
            class="pagination-btn">...</span>

        <!-- Display last 2 pages -->

        <template v-for="pageNumber in [paginator.last_page - 1, paginator.last_page]" :key="pageNumber">
            <div v-if="paginator.last_page > 10">
                <button v-if="paginator.current_page < paginator.last_page - 2" class="pagination-btn"
                    @click="goToPage(pageNumber)" :class="{ 'active-page': paginator.current_page === pageNumber }">
                    {{ pageNumber }}
                </button>
            </div>
        </template>
        <button class="pagination-btn" :disabled="paginator.current_page === paginator.last_page" @click="nextPage">
            <i class="fa-solid fa-angle-right"></i>
        </button>
    </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, toRefs } from 'vue';
import { Paginator } from '@interfaces/interfaces';
export default defineComponent({
    props: {
        paginator: {
            type: Object as () => Paginator,
            required: true
        },
    },
    setup(props, { emit }) {
        const dynamicPageRange = computed(() => {
            const start = Math.max(1, props.paginator.current_page - 10);
            const end = Math.min(props.paginator.last_page, start + 8);
            const range = [];
            for (let i = start; i <= end; i++) {
                range.push(i);
            }
            return range;
        });
        const { paginator } = toRefs(props);
        const showEllipsisBefore = computed(() => dynamicPageRange.value[0] > 1);
        const showEllipsisAfter = computed(() => dynamicPageRange.value[dynamicPageRange.value.length - 1] < props.paginator.last_page);
        const lastPage = computed(() => props.paginator.last_page);

        const nextPage = () => {
            if (paginator.value.current_page && paginator.value.current_page < paginator.value.last_page) {
                emit('fetch-data', paginator.value.current_page + 1);
            }
        };

        const prevPage = () => {
            if (paginator.value.current_page && paginator.value.current_page > 1) {
                emit('fetch-data', paginator.value.current_page - 1);
            }
        };

        const goToPage = (pageNumber: number) => {
            console.log(pageNumber, '@pageNumber-goToPage', paginator);
            if (pageNumber >= 1 && pageNumber <= paginator.value.last_page) {
                emit('fetch-data', pageNumber);
            }
        };

        return {
            dynamicPageRange,
            showEllipsisBefore,
            showEllipsisAfter,
            lastPage,
            nextPage,
            goToPage,
            prevPage
        };
    }
});
</script>
<style scoped>
.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.pagination-btn {
    cursor: pointer;
    background-color: white;
    padding: 5px 20px;
    border: 1px solid #dee2e6;
    color: #0d6efd;
    border-radius: 3px;
    font-size: 12px;
}

.pagination-btn:disabled {
    cursor: not-allowed;
}

.active-page {
    font-size: 14px;
    color: black;
}
</style>
