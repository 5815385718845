<template>
    <Alerts :success="success" :errors="errors" />
    <div v-if="loading">Loading...</div>
    <div v-else class="plan-content">
        <PlanGeneral text="This report is in process." />
        <!-- Form -->
        <form v-if="person && person.DateofBirth" id="check-replacement-form" class="form" method="POST"
            @submit.prevent="submitData">
            <SelectComponent label="Check Type" v-model:selectedOption="requestType" :options="checkTypes"
                :singleArrow="true" />
            <SelectComponent label="Type" v-model:selectedOption="checkType" :options="paymentMethods"
                :singleArrow="true" />
            <TextInput type="text" label="Participant ID" placeholder="Enter Participant ID" :max=25
                v-model:modelValue="person.PersonID" :readonly="true" />
            <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
                v-model:modelValue="person.FirstName" :readonly="true" />
            <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
                v-model:modelValue="person.LastName" :readonly="true" />
            <DateInput label="DOB" v-model:modelValue="person.DateofBirth" :readonly="true" />
            <div class="w-full" v-if="checkType.key === 'check'">
                <SelectComponent label="Check List" v-model:selectedOption="selectedCheck" :options="checkOptions"
                    :singleArrow="true" @change="handleSelectedCheck" />
                <DateInput label="Check Date" v-model:modelValue="checkDate" />
                <TextInput type="text" label="Check #" v-model:modelValue="checkNumber" />
            </div>
            <div class="headers">
                <h4>Address for Check Destination currently on File </h4>
            </div>

            <div class="w-full" v-if="address">
                <TextInput type="text" label="Address 1" placeholder="Enter Address 1" :max=30
                    v-model:modelValue="address.Street1" :required="true" />
                <TextInput type="text" label="Address 2" placeholder="Enter Address 2" :max=30
                    v-model:modelValue="address.Street2" :required="true" />
                <TextInput type="text" label="Address 3" placeholder="Enter Address 3" :max=30
                    v-model:modelValue="address.Street3" :required="true" />
                <TextInput type="text" label="City" placeholder="Enter City" :max=25 v-model:modelValue="address.City"
                    :required="true" />
                <SelectComponent label="Country" v-model:selectedOption="address.Country" :options="countries"
                    :single-arrow="true" />
                <SelectComponent label="State" v-model:selectedOption="address.State" :options="filteredStates"
                    :single-arrow="true" />
                <TextInput type="text" label="ZIP Code" placeholder="Enter Postal Code" :max=9
                    v-model:modelValue="address.Zip" :required="true" />
            </div>

            <Textarea label="Notes" v-model:modelValue="comments" />

            <div class="action-buttons">
                <a href="" @click.prevent="closeModal" class="btn btn-secondary"> Discard </a>
                <button type="submit" class="btn btn-primary"> Submit </button>
            </div>
        </form>
        <div v-else class="d-flex flex-column align-items-center gap-4">
            <h3>To Continue Please...</h3>
            <h6>Please <a href="profile.personal.edit">update your personal
                    information</a> to include your correct date of birth.</h6>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, toRefs, onMounted, watch, computed, Ref, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useModalStore } from '@/stores/modal';
import { useProfileStore } from '@/stores/profile';
import { VacationCheckRequestForm, CountryStates, Person, AddressInfo, VacationCheckRequestLine, SelectOption } from '@/interfaces/interfaces';
import Alerts from '@/components/utils/AlertsComponent.vue';
import PlanGeneral from '@/components/cards/PlanGeneral.vue';
import TextInput from '@/components/form/TextInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import Textarea from '@/components/form/TextareaComponent.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import useFormSubmit from '@/composable/useFormSubmit';
import axios from 'axios';

export default defineComponent({
    components: {
        Alerts,
        PlanGeneral,
        TextInput,
        DateInput,
        Textarea,
        SelectComponent
    },
    props: {
        fund: {
            type: String,
            default: null,
        }
    },
    setup(props, { emit }) {
        const route = useRoute();
        const modalStore = useModalStore();
        const profileStore = useProfileStore();
        const statesByCountry: Ref<CountryStates[] | null> = ref(null);
        const person = ref<Person>();
        const address = ref<AddressInfo>({
            Street1: null,
            Street2: null,
            Street3: null,
            Country: null,
            City: null,
            State: null,
            ZipCode: null,
        });

        const checkTypes = [
            { key: 'vacation', value: 'Vacation' },
            { key: 'medicare', value: 'Medicare Reimbursement' },
            { key: 'pension', value: 'Pension' }
        ];

        const paymentMethods = [
            { key: 'directdeposit', value: 'Direct Deposit' },
            { key: 'check', value: 'Check' }
        ];

        const checks = ref<VacationCheckRequestLine[]>([]);
        const checkOptions = ref<SelectOption[]>([]);
        const selectedCheck = ref<SelectOption | null>({ key: 0, value: '-Select check' });
        const requestType = ref<SelectOption|null>(checkTypes[0]);
        const checkType = ref<SelectOption|null>(paymentMethods[0]);
        const checkDate = ref('');
        const checkNumber: Ref<string> = ref('');
        const { fund } = toRefs(props);

        const comments = ref('');
        const errors = ref<string[]>([]);
        const success = ref('');
        const info = ref('');
        const loading = ref(true);
        const loadingSubmit = ref(false);

        // Methods
        const fetchData = async () => {
            try {
                const response = await axios.get('api/participant/check-replacement');
                person.value = response.data.Person;
                address.value = response.data.Address;
                checks.value = response.data.Checks;
                checkOptions.value = response.data.CheckOptions;

                if (!profileStore.countries || !profileStore.statesByCountry) {
                    await profileStore.fetchCountriesStates();
                }

                loading.value = false;
            }
            catch (error) {
                console.error('Error:', error);
            }
        }

        const updateRequestTypeFromQuery = () => {
            const queryParamValue = fund.value;

            if (typeof queryParamValue === 'string' && ['vacation', 'medicare', 'pension'].includes(queryParamValue)) {
                requestType.value = checkTypes.find(type => type.key === queryParamValue) || null;
            }
        };

        const handleSelectedCheck = (option: SelectOption) => {
            if (option) {
                const check: VacationCheckRequestLine | undefined = checks.value.find((check: VacationCheckRequestLine) => check.Number === option.key);

                if (check && check.Date) {
                    checkDate.value = new Date(check.Date).toISOString().split('T')[0];
                }
            } else {
                checkDate.value = '';
            }

            checkNumber.value = option?.key as string;
        }

        watch(() => fund.value, () => {
            updateRequestTypeFromQuery();
        });

        // Filter states based on selected country
        const filteredStates = computed(() => {
            const selectedCountry = address.value.Country?.key;
            const states = profileStore.statesByCountry;
            const countryStates = states ? (states as CountryStates[]).filter(state => state.CountryKey === selectedCountry) : [];

            return (countryStates?.map(state => state.States).flat());
        });

        const closeModal = () => {
            emit('close');
        }

        const submitData = async () => {
            const formData = {
                PersonID: person.value?.PersonID,
                FirstName: person.value?.FirstName,
                LastName: person.value?.LastName,
                DateofBirth: person.value?.DateofBirth,
                CheckCategory: requestType.value?.key ?? '',
                CheckType: checkType.value?.key,
                CheckDate: checkDate.value,
                CheckNumber: checkNumber.value,
                Street: address.value.Street1,
                Street2: address.value.Street2,
                Street3: address.value.Street3,
                State: address.value.State,
                ZipCode: address.value.Zip,
                Notes: comments.value,
                SelectedCheck: selectedCheck.value?.value ?? '',
            };

            const route = 'api/participant/check-replacement';
            const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit });
            const successSubmit = await handleSubmit();

            if (successSubmit) {
                // closeModal();
            }
        }

        // Mounted
        onMounted(fetchData);

        updateRequestTypeFromQuery();

        return {
            countries: profileStore.countries,
            statesByCountry,
            filteredStates,
            person,
            comments,
            address,
            checks,
            checkOptions,
            selectedCheck,
            requestType,
            checkTypes,
            paymentMethods,
            checkType,
            checkDate,
            checkNumber,
            success,
            errors,
            loading,
            handleSelectedCheck,
            submitData
        }
    }
});
</script>
