<template>
    <div class="aside-logo">
      <figure>
        <img src="../../assets/images/logo.svg" alt="Logo" title="IATSE Benefits Fund Logo" />
      </figure>
      <div class="spotlight"></div>
    </div>
  </template>

  <script lang="ts">
    export default {
        name: 'LogoComponent'
    }
  </script>