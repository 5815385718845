<template>
    <FundPanel cardId="card-vacation">
        <template #icon>
            <i class="iatse-icon-fund-vacation"></i>
        </template>
        <template #title>
            Vacation
        </template>
        <div class="col-md-9">
            <div class="fund-cover fund-cover__vacation d-flex align-items-start flex-column">
                <div class="fund-cover-header">
                    <div class="coverage-column">
                        <div class="fund-cover-row">
                            <i class="iatse-icon-receipt"></i>
                            <p class="links"><strong>Contributions:</strong></p>
                        </div>
                        <a href="" class="plan-modal" @click.prevent>
                            <strong>{{ vacationContributions.Total }}</strong>
                            <small> ({{ vacationContributions.Label + vacationContributions.Date }})</small>
                        </a>
                    </div>
                    <div class="divider"> </div>
                    <div class="coverage-column">
                        <div class="fund-cover-row">
                            <i class="iatse-icon-map"></i>
                            <p class="links text-underline plan-modal" data-modal-name="check-destination">Vacation
                                Check Destination</p>
                        </div>
                        <p><strong>{{ vacationContributions.CheckDestination }}</strong></p>
                    </div>
                </div>
                <div class="fund-cover-buttons">
                    <button class="btn btn-primary plan-modal"
                        @click="openModal('work-history', 'Work History', '', 'vacation')">Work History</button>
                    <button class="btn btn-secondary plan-modal"
                        @click="openModal('vacation', 'Vacation', 'plan-tables')">Payment History</button>
                </div>
            </div>
        </div>
        <div class="col-md-3 related-links-wrapper sm-pdt-10">
            <div class="related-links-header">
                <p>Related Links</p>
                <div class="card-control plus">
                    <span class="line"></span>
                    <i class="plus iatse-icon-plus"></i>
                </div>
            </div>
            <div class="related-links">
                <button class="btn btn-tertiary btn-large plan-modal"
                    @click="openModal('check-replacement', 'Check Replacement', 'plan-form', 'vacation')"> Check
                    Replacement
                </button>
                <button class="btn btn-tertiary btn-large plan-modal"
                    @click="openModal('check-destination', 'ACH/Check Destination', 'plan-form', 'vacation')">
                    ACH/Check</button>
                <a href="" class="btn btn-tertiary btn-large">ID Card(s)</a>
            </div>
        </div>
    </FundPanel>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useVacationStore } from '@/stores/vacation';

import FundPanel from '@/components/cards/FundPanel.vue';

export default defineComponent({
    components: {
        FundPanel
    },
    setup() {
        const modalStore = useModalStore();
        const vacationStore = useVacationStore();
        const vacationContributions = vacationStore.generalInfo;

        return {
            vacationContributions,
            openModal: modalStore.openModal
        };
    }
});
</script>