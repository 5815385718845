<template>
    <div class="plan-content">
        <div class="filters">
            <div class="filters-section">
                <div class="filter-item">
                    <h5> Name: </h5>
                    <small> {{ filters?.PatientName.FullName }}</small>
                </div>
                <div class="filter-item">
                    <h5> Status: </h5>
                    <small> {{ filters?.ClaimStatus }}</small>
                </div>
            </div>
            <div class="filters-section">
                <div class="filter-item">
                    <h5> Service Date: </h5>
                    <SortComponent v-model:type="filters.Sorting.ServiceDate.StartDate" name="From" :readonly="true" />
                    <SortComponent v-model:type="filters.Sorting.ServiceDate.EndDate" name="To" :readonly="true" />
                </div>
                <div class="filter-item">
                    <h5> Submission Date: </h5>
                    <SortComponent v-model:type="filters.Sorting.SubmissionDate.StartDate" name="From"
                        :readonly="true" />
                    <SortComponent v-model:type="filters.Sorting.SubmissionDate.EndDate" name="To" :readonly="true" />
                </div>
            </div>
        </div>
        <DataTable :table="history" classes="striped small" />
    </div>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue';
import { useClaimStore } from '@/stores/claim';
import { storeToRefs } from 'pinia'

import SortComponent from '@/components/form/SortComponent.vue';
import DataTable from '@/components/form/DataTable.vue';

export default defineComponent({
    components: {
        SortComponent,
        DataTable,
    },
    setup() {
        const claimStore = useClaimStore();
        const { filters, history } = storeToRefs(claimStore);

        watch(() => filters, () => {
            claimStore.performSearch();
        }, { deep: true });

        return {
            history,
            filters
        }
    },
})
</script>