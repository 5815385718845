<template>
    <div class="container mt-4">
        <div class="row d-flex align-items-center mb-4">
            <h3>Pension Payments</h3>

            <table>
                <thead>
                    <tr>
                        <th>Check #</th>
                        <th>Date</th>
                        <th>Run Type</th>
                        <th>Reversal</th>
                        <th>Gross Amount</th>
                        <th>Deductions</th>
                        <th>Net Amount</th>
                        <th>Status</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(payment, id) in payments" :key="id">
                        <td>{{ payment.CheckNumber }}</td>
                        <td>{{ payment.CheckDate }}</td>
                        <td>{{ payment.RunType }}</td>
                        <td>{{ payment.TransactionType === 'R' ? 'Yes' : 'No' }}</td>
                        <td>{{ payment.Amount.Gross }}</td>
                        <td>
                            <div v-if="payment.Deductions.length > 0">
                                <div v-for="(deduction, index) in formatDeductions(payment.Deductions)" :key="index" v-html="deduction"></div>
                            </div>
                            <div v-else>{{ payment.Amount.Deductions }}</div>
                        </td>
                        <td>{{ payment.Amount.Net }}</td>
                        <td>{{ payment.ClearDate === null ? 'Not Cleared' : 'Cleared' }}</td>
                    </tr>
                </tbody>
            </table>

            <br/>
            <h5>Totals</h5>
            <table>
                <tr>
                    <td>{{ totals.Gross }}</td>
                    <td>{{ totals.Deductions }}</td>
                    <td>{{ totals.Net }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';
import { PensionPayment, PensionPayments, PensionPaymentData, PensionPaymentDeduction, PensionPaymentsTotals } from '@/interfaces/interfaces';

export default {
    setup() {
        const route = useRoute();
        const from = ref(route.query.from as string);
        const to = ref(route.query.to as string);
        const payments = ref<PensionPayments>({});
        const totals = ref<PensionPaymentsTotals>({
            Gross: null,
            Deductions: null,
            Net: null
        });

        const fetchData = async () => {
            try {
                const response = await axios.get('api/participant/pension/payments?from=' + from.value + '&to=' + to.value);
                if (response.data && response.data.Payments) {
                    payments.value = response.data.Payments;
                    totals.value = response.data.Totals;
                }
            }
            catch (error) {
                console.error('Error:', error);
            }
        };

        const formatDate = (dateString: string) => {
            if (!dateString) return '';

            const date = new Date(dateString);
            return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
        };

        const formatDeductions = (deductions: PensionPaymentDeduction[]) => {
            return deductions.map((deduction: PensionPaymentDeduction) => {
                let type = deduction.CASWT && deduction.CASWT > 0 ? 'SWT' : 'FWT';

                const amount = deduction.Amount ? `$${deduction.Amount}` : "N/A";
                const checkDate = deduction.CheckDate ? formatDate(deduction.CheckDate) : "Unknown date";

                return `<b>${type}</b>: <b>${amount}</b> on ${checkDate}`;
            });
        };


        onMounted(fetchData);

        return {
            payments,
            totals
        };
    }
};
</script>
