<template>
    <div class="container mt-4">
        <div class="position-fixed top-0 end-0 p-3" style="z-index: 100">
            <div v-if="errors.length > 0" class="alert alert-danger">
                <ul>
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center mb-4">
                <h1>Summarized Contract Information</h1>
            </div>
            <p>This page allows you to look for specific CBA information based on different search parameters that are
                on file at the Fund Office.</p>
            <div v-if="contractInformation.length === 0" class="d-flex flex-column gap-4">
                <h5 class="mb-2">Filters</h5>
                <div class="d-flex flex-column gap-4">
                    <div class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2"> Work Period: <span class="text-danger">*</span> </span>
                        <div class="w-50 d-flex align-items-center gap-4">
                            <input type="date" v-model="filters.WorkPeriod.StartDate" class="form-control w-50"
                                required />
                            <span>-</span>
                            <input type="date" v-model="filters.WorkPeriod.EndDate" class="form-control w-50"
                                required />
                        </div>
                    </div>
                    <div class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2"> Employer(s): </span>
                        <div class="w-50">
                            <Multiselect v-model="filters.Employers" :options="data.employers"
                                placeholder="Type to search" :multiple="true" :hide-selected="true" :searchable="true"
                                :close-on-select="true" @select="handleEmployerChange" label="Name" track-by="ID"
                                @search-change="handleEmployersSearch" @remove="handleProductions"
                                :loading="loading.employers">
                            </Multiselect>
                        </div>
                    </div>
                    <div class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2"> Production(s) <span v-if="hasEmployers"> for selected
                                Employer(s)</span>: </span>
                        <div class="w-50">
                            <Multiselect v-model="filters.Productions" :options="data.productions"
                                placeholder="Type to search" :multiple="true" :hide-selected="true" :searchable="true"
                                :close-on-select="true" @select="handleProductionChange" label="Name" track-by="ID"
                                @open="handleProductionsOpen" @search-change="handleProductionsSearch"
                                :loading="loading.productions">
                            </Multiselect>
                        </div>
                    </div>
                    <div class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2"> Contract Type: </span>
                        <div class="w-50">
                            <Multiselect v-model="filters.ContractTypes" :options="data.contractTypes"
                                placeholder="Type to search" :multiple="true" :hide-selected="true" :searchable="true"
                                :close-on-select="true" label="Name" track-by="ID" @select="handleContractChange"
                                @search-change="handleContractSearch" @open="handleContractOpen"
                                :loading="loading.contractTypes">
                            </Multiselect>
                        </div>
                    </div>
                    <div class="d-flex gap-2 align-self-end w-25">
                        <button class="btn btn-secondary" @click="resetFilters">Reset</button>
                        <button class="btn btn-primary w-50" @click="searchData">
                            {{ loadingSearch ? 'Searching...' : 'Search' }}</button>
                    </div>
                </div>
            </div>
            <div v-else-if="!loadingSearch && contractInformation.length > 0" class="row">
                <div class="d-flex justify-content-between mb-4">
                    <button class="btn btn-secondary" @click="resetFilters">Change my current
                        Filters</button>
                    <button class="btn btn-primary" @click="printList">Print List</button>
                </div>
                <table class="table table-bordered table-striped" id="table-contract-info">
                    <thead class="table-dark">
                        <tr class="text-center align-middle">
                            <th>Employer</th>
                            <th>Production</th>
                            <th>Contract Type</th>
                            <th>Contract<br>Sub Type</th>
                            <th>Contract Begin Date </th>
                            <th>Contract End Date </th>
                            <th>Rate Group Name</th>
                            <th>Rate </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(entry, index) in contractInformation" :key="index" class="text-center">
                            <td>{{ entry.Employer }}</td>
                            <td>{{ entry.Production }}</td>
                            <td>{{ entry.Contract }}</td>
                            <td>{{ entry.ContractSubType }}</td>
                            <td>{{ entry.ContractBeginDate }}</td>
                            <td>{{ entry.ContractEndDate }}</td>
                            <td>{{ entry.RateGroupName }}</td>
                            <td>
                                <div class="d-flex flex-column gap-2" v-for="(rate, key) in entry.Rate" :key="key">
                                    <span class="fw-bold"> {{ key }} </span>
                                    <span v-for="(value, index) in rate" :key="index">
                                        {{ value }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { reactive, ref, Ref, computed } from 'vue';
import { Coverage, PlanType } from "@/interfaces/local";
import { usePrint } from '@/composable/usePrint';
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import 'vue-multiselect/dist/vue-multiselect.css';

interface Filters {
    WorkPeriod: {
        StartDate?: string,
        EndDate?: string,
    };
    Employers: Employer[] | null;
    Productions: Employer[] | null;
    ContractTypes: Employer[] | null;
}

interface Employer {
    ID: string | number;
    Name: string;
}

interface Data {
    employers: Employer[];
    productions: Employer[];
    contractTypes: Employer[];
}

export default {
    components: {
        Multiselect,
    },
    setup() {
        const loadingSearch = ref(false);
        const loading = reactive({
            employers: false,
            productions: false,
            contractTypes: false,
        })
        const allOption = { ID: 0, Name: 'ALL' };
        const contractInformation: Ref<Coverage[]> = ref([]);
        const { printElementById } = usePrint();
        const errors: Ref<string[]> = ref([]);
        const success = ref(null);
        const data: Data = reactive({
            employers: [],
            productions: [],
            contractTypes: [],
        })
        const initialFilters = {
            WorkPeriod: {
                StartDate: '',
                EndDate: '',
            },
            Employers: [allOption],
            Productions: [allOption],
            ContractTypes: [allOption],
        };
        const filters: Filters = reactive({ ...JSON.parse(JSON.stringify(initialFilters)) });
        const hasEmployers = computed(() => filters.Employers?.some(employer => employer.ID !== 0));
        const hasProductions = computed(() => filters.Productions?.some(prod => prod.ID !== 0));
        const hasContractTypes = computed(() => filters.ContractTypes?.some(contract => contract.ID !== 0));
        const printList = printElementById('table-contract-info', 'Summarized Contract Information');

        const resetFilters = () => {
            contractInformation.value = [];
            Object.assign(filters, JSON.parse(JSON.stringify(initialFilters)));
        }

        // Handle employers search
        const handleEmployersSearch = async (query: string) => {
            await handleSelectionSearch('employers', query);
        };

        // Handle productions search
        const handleProductionsSearch = async (query: string) => {
            await handleSelectionSearch('productions', query);
        };

        // Handle contract types search
        const handleContractSearch = async (query: string) => {
            await handleSelectionSearch('contractTypes', query);
        };

        const handleContractChange = async (newSelection: Employer) => {
            await handleSelectionChange('ContractTypes', newSelection);
        }

        const handleEmployerChange = async (newSelection: Employer) => {
            await handleSelectionChange('Employers', newSelection);
        }

        const handleProductionChange = async (newSelection: Employer) => {
            await handleSelectionChange('Productions', newSelection);
        }

        // Handle Search in Multiselect
        const handleSelectionSearch = async (type: string, query: string) => {
            loading[type as keyof Data] = true;
            let route = `api/local/${type}?query=${query}`

            if (type === 'productions') {
                const employerIds = filters.Employers?.map((employer: Employer) => {
                    if (employer.ID !== 0)
                        return employer.ID
                });

                route += `&&EmployerIDs=${employerIds}`;
            }

            try {
                const response = await axios.get(route);
                data[type as keyof Data] = response.data;
            }
            catch (error) {
                console.error(`Error searching ${type}:`, error);
            }

            loading[type as keyof Data] = false;
        }

        // Handle Change Selection
        const handleSelectionChange = async (type: string, newSelection: Employer) => {
            if (type !== 'WorkPeriod') {
                if (newSelection.ID === 0) {
                    filters[type as keyof typeof filters] = [allOption];
                }
                else {
                    const filteredTypes: Employer[] = JSON.parse(JSON.stringify(filters[type as keyof typeof filters])).filter((type: Employer) => type.ID !== 0)
                    filters[type as keyof typeof filters] = [...filteredTypes];

                    if (type === 'Employers') {
                        await handleProductions();
                    }
                }
            }
        };

        // Filter selected productions for selected employers
        const handleProductions = async () => {
            await handleProductionsSearch('');

            // if is not selected ALL option, filter selected productions
            if (filters.Productions && filters.Productions[0]?.ID !== 0) {
                const filteredProductions = filters.Productions?.filter(prod => data.productions.some(p => p.ID === prod.ID)) || [];
                filters.Productions = filteredProductions;
            }
        }

        const handleProductionsOpen = async (query: string) => {
            const employerIds = filters.Employers?.map((employer: Employer) => employer.ID);
            const keyword = query ?? '';

            if (!employerIds?.includes(0)) {
                await handleProductionsSearch(keyword);
            }
        };

        const handleContractOpen = async () => {
            setTimeout(() => {
                handleContractSearch('');
            }, 1000)
        };

        // Get Contract Information
        const searchData = async () => {
            loadingSearch.value = true;
            contractInformation.value = [];
            const formData = { ...filters };
            formData.Employers = !hasEmployers.value ? null : formData.Employers;
            formData.Productions = !hasProductions.value ? null : formData.Productions;
            formData.ContractTypes = !hasContractTypes.value ? null : formData.ContractTypes;

            await axios
                .post('api/local/contract-information', {
                    ...formData
                })
                .then(response => {
                    contractInformation.value = response.data;
                })
                .catch(error => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }
                })
                .finally(() => {
                    loadingSearch.value = false;

                    setTimeout(() => {
                        success.value = null
                        errors.value = []
                    }, 3500);
                })
        }

        return {
            loading,
            errors,
            success,
            filters,
            data,
            hasEmployers,
            handleEmployerChange,
            handleProductions,
            handleProductionChange,
            handleContractChange,
            handleProductionsOpen,
            handleContractOpen,
            handleEmployersSearch,
            handleProductionsSearch,
            handleContractSearch,
            loadingSearch,
            contractInformation,
            resetFilters,
            searchData,
            printList,
        };
    }
}
</script>