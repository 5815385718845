import { defineStore } from "pinia";
import { Person } from "@/interfaces/local";

export const useMembersStore = defineStore({
    id: "members",
    state: (): { membersList: Person[] | []} => ({
        membersList: [],
    }),
    getters: {
        list(): Person[] {
            return this.membersList;
        },
    },
    actions: {
        setList(members: Person[]|[]) {
            this.membersList = members;
        },
    },
});
