<template>
  <div :class="['text-input', { 'error': error }]">
    <label class="body-text" :for="label">{{ label }}</label>
    <div class="input-date-wrapper">
      <input ref="inputRef" :type="currentType" :id="label" :placeholder="placeholder"
        :class="['links', 'date-input', { 'has-icon': icon }]" :pattern="pattern" :maxlength="maxlength"
        :value="inputValue" :readonly="readonly" @input="onInput" @focus="onFocus" />
      <i v-if="icon" class="custom-date-icon iatse-icon-calendar" @click="focusInput"></i>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, watch, ref } from 'vue';

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    icon: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    inputType: {
      type: String,
      default: 'date'
    }
  },
  setup(props, { emit }) {
    // const inputType = computed(() => (props.placeholder ? 'text' : 'date'));
    const currentType = ref(props.inputType);
    const maxlength = computed(() => (props.placeholder ? (props.placeholder.length || 10) : 10));
    const inputValue = ref(props.modelValue);
    const inputRef = ref<HTMLInputElement | null>(null);

    const pattern = computed(() => {
      return props.placeholder === 'MM/YY'
        ? '^(0[1-9]|1[0-2])/\\d{2}$'
        : '^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/(0[1-9]|1[0-2])/\\d{4}$';
    });

    watch(() => props.modelValue, (newValue) => {
      inputValue.value = newValue;
    });

    const onInput = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const value = target.value;

      // Simple validation for MM/DD/YYYY format
      if (props.placeholder === 'MM/DD/YYYY') {
        const numericValue = value.replace(/\D/g, '');

        let formattedValue = '';

        if (numericValue.length > 0) {
          formattedValue += numericValue.substring(0, 2); // MM
        }
        if (numericValue.length > 2) {
          formattedValue += '/' + numericValue.substring(2, 4); // DD
        }
        if (numericValue.length > 4) {
          formattedValue += '/' + numericValue.substring(4, 8); // YYYY
        }

        if (numericValue) {
          inputValue.value = formattedValue;
          emit('update:modelValue', formattedValue);
        } else {
          target.value = inputValue.value;
        }
      } else {
        inputValue.value = value;
        emit('update:modelValue', value);
      }
    };

    const onFocus = () => {
      currentType.value = props.inputType;
    };

    const focusInput = () => {
      if (inputRef.value && !props.readonly) {
        const inputElement = inputRef.value as HTMLInputElement & { showPicker: () => void };

        inputElement.focus();
        inputElement.showPicker();
      }
    };

    return {
      inputRef,
      pattern,
      maxlength,
      inputValue,
      currentType,
      onFocus,
      onInput,
      focusInput
    };
  },
});
</script>
