<template>
    <div class="container mt-4">
        <div class="position-fixed top-0 end-0 p-3" style="z-index: 100">
            <div class="alert alert-success" v-show="success">{{ success }}</div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center mb-4">
                <h1>Local Portal</h1>
            </div>
            <div v-if="loading" class="row">
                Loading...
            </div>
            <div v-else class="row">
                <EmployerContributionsPanel />
                <AboutMembersPanel />
                <ProfilePanel :data="profileData" type="Local" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useMembersStore } from '@/stores/members'
import { ProfileData } from '@interfaces/interfaces';
import EmployerContributionsPanel from '@components/local/dashboard/EmployerContributionsPanel.vue';
import AboutMembersPanel from '@/components/local/dashboard/AboutMembersPanel.vue';
import ProfilePanel from '@components/user/dashboard/ProfilePanel.vue';
import axios from 'axios';

export default {
    components: {
        EmployerContributionsPanel,
        AboutMembersPanel,
        ProfilePanel,
    },
    setup() {
        const profileData: Ref<ProfileData | null> = ref(null);
        const loading = ref(true);
        const route = useRoute();
        const success: Ref<string | null> = ref(null);

        const fetchData = async () => {
            loading.value = true;
            success.value = route.params.successMessage as string;
            useMembersStore().setList([]);

            if (success.value) {
                setTimeout(() => success.value = null, 3500);
            }

            await axios
                .get('api/local/dashboard')
                .then(response => {
                    profileData.value = response.data;
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })

            loading.value = false;
        };

        onMounted(async () => await fetchData());

        return {
            loading,
            success,
            profileData,
        };
    }
}
</script>