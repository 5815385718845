<template>
    <div class="container mt-4">
        <h1 class="text-center">Contact us</h1>

        <div class="d-flex justify-content-between align-items-center flex-wrap mb-4">
            <div>
                <div class="d-flex flex-column mb-4">
                    <h6> IATSE National Benefits Fund Office </h6>
                    <span> 417 Fifth Avenue </span>
                    <span> 3rd Floor </span>
                    <span> New York, NY 10016-2204 </span>
                </div>
                <div class="d-flex flex-column mb-4">
                    <h6> Main Numbers </h6>
                    <span> Main Number: 212-580-9092 </span>
                    <span> Toll Free Number: 800-456-3863 </span>
                    <span> Fax Number: 212-787-3607 </span>
                </div>
                <div class="d-flex flex-column mb-4">
                    <h6> Email Addresses </h6>
                    <span> Participant Services: psc@iatsenbf.org </span>
                    <span> Webmaster: webadmin@iatsenbf.org - Email Webmaster for any website technical issues. </span>
                </div>
            </div>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.518936076278!2d-73.98528472420662!3d40.75060977138782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25900a9b83733%3A0xa44f7f885d246bb9!2s417%205th%20Ave%203rd%20Floor%2C%20New%20York%2C%20NY%2010016%2C%20USA!5e0!3m2!1sen!2sro!4v1699885258826!5m2!1sen!2sro"
                width="500" height="300" style="border:0;" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
            </iframe>
        </div>
    </div>
</template>