<template>
    <div class="container mt-4">
        <h4 class="mb-4"> Alert Types </h4>
        <div v-show="success" class="alert alert-success">{{ success }}</div>
        <div v-if="loading">Loading...</div>
        <table v-else-if="alertTypes.length > 0" class="table table-striped mb-4">
            <thead class="table-dark">
                <tr class="text-center">
                    <th> Alert ID </th>
                    <th> Document Type ID </th>
                    <th> Fund Type </th>
                    <th> Type </th>
                    <th> Message </th>
                    <th> </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="alertType in alertTypes" :key="alertType.AlertTypeID" class="align-middle text-center">
                    <td> {{ alertType.AlertTypeID }} </td>
                    <td class="col-md-3">
                        <Multiselect v-model="alertType.Documents" :options="documents" placeholder="Type to search"
                            :multiple="true" :hide-selected="true" track-by="CodeID" label="Description" :searchable="true"
                            :close-on-select="true" :taggable="true" @search-change="handleDocumentSearch">
                        </Multiselect>
                    </td>
                    <td>
                        <select class="form-control" v-model="alertType.FundType">
                            <option value="0"> N/A </option>
                            <option v-for="(fund, index) in fundTypes" :key="index" :value="fund">
                                {{ fund }}
                            </option>
                        </select>
                    </td>
                    <td> {{ alertType.Name }} </td>
                    <td>
                        <textarea name="Message" v-model="alertType.Message"></textarea>
                    </td>
                    <td class="col-md-1">
                        <form @submit.prevent="handleSave(alertType.AlertTypeID)">
                            <button type="submit" class="btn btn-secondary btn-sm" :disabled="loadingSubmit">
                                {{ alertType.Submitting ? 'Saving...' : 'Save' }}
                            </button>
                        </form>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-else> No Alert Types have been created </div>
    </div>
</template>

<script lang="ts">
import { MiscellaneousCode } from '@/interfaces/interfaces';
import { onMounted, ref, Ref } from 'vue';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import axios from 'axios';

interface AlertType {
    AlertTypeID: number;
    FundType: string;
    Name: string;
    Message: string;
    Documents: MiscellaneousCode;
    Submitting: boolean
}

export default {
    components: { Multiselect },
    setup() {
        const alertTypes: Ref<AlertType[] | []> = ref([]);
        const fundTypes = ref([]);
        const documents = ref([]);
        const loading = ref(false);
        const loadingSubmit = ref(false);
        const loadingSearch = ref(false);
        const success = ref(null);
        const multiselectRef = ref([]);
        const value = '';

        const fetchData = async (isLoading: boolean) => {
            if (isLoading)
                loading.value = true;

            await axios.get('api/administrator/alerts')
                .then(response => {
                    alertTypes.value = response.data.AlertTypes.map((alertType: AlertType) => ({
                        ...alertType,
                        Submitting: false,
                    }));
                    fundTypes.value = response.data.FundTypes;
                })
                .finally(() => {
                    if (isLoading)
                        loading.value = false
                })
        };

        // Handle Save Alert Type
        const handleSave = async (alertId: number) => {
            const data = (alertTypes.value).filter(alert => alert.AlertTypeID === alertId)[0];
            data.Documents = JSON.parse(JSON.stringify(data.Documents)).map((document: MiscellaneousCode) => document.CodeID).join(',');
            data.Submitting = true;

            await axios
                .post(`api/administrator/alerts/${alertId}`, { ...data, _method: "PATCH" })
                .then((response) => {
                    success.value = response.data?.success;
                })
                .finally(async () => {
                    await fetchData(false);
                    setTimeout(() => success.value = null, 3000);
                })
        }


        // Handle document search
        const handleDocumentSearch = async (query: string, alertId: number) => {
            loadingSearch.value = true;

            if (query.length > 2) {
                try {
                    const response = await axios.get(`api/administrator/documents?query=${query}`);
                    documents.value = response.data;
                    console.log(documents.value, multiselectRef.value);
                } catch (error) {
                    console.error('Error searching documents:', error);
                }
            }

            loadingSearch.value = false;
        };

        onMounted(async () => {
            await fetchData(true);
        });

        return {
            alertTypes, fundTypes, documents, loading, loadingSubmit, loadingSearch, value, success, multiselectRef,
            handleSave, handleDocumentSearch
        }
    }
}
</script>