<template>
    <div class="container mt-4">
        <div class="row d-flex align-items-center mb-4">
            <div class="col-md-12 text-center">
                <h1 class="mb-4">Check Replacement Request </h1>
                <p>If you have misplaced your vacation check or it is stale-dated, you can request a replacement by
                    completing the information below.</p>
            </div>
        </div>
        <form v-if="person && person.DateofBirth" method="POST"
            @submit.prevent="submitCheckReplacement">
            <div v-if="success" class="alert alert-success">
                {{ success }}
            </div>
            <div v-if="errors.length > 0" class="alert alert-danger">
                <ul>
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>
            </div>

            <div class="form-group row mb-4">
                <div class="col-md-3">
                    <label for="participant_id" class="col-form-label text-md-right">Participant ID</label>
                    <input type="text" id="participant_id" name="PersonID" v-model="person.PersonID" class="form-control"
                        readonly>
                </div>
                <div class="col-md-3">
                    <label for="first_name" class="col-form-label text-md-right"> First Name </label>
                    <input type="text" id="first_name" name="FirstName" v-model="person.FirstName" class="form-control"
                        readonly>
                </div>
                <div class="col-md-3">
                    <label for="last_name" class="col-form-label text-md-right"> Last Name </label>
                    <input type="text" id="last_name" name="LastName" v-model="person.LastName" class="form-control"
                        readonly>
                </div>
                <div class="col-md-3">
                    <label for="date_of_birth" class="col-form-label text-md-right"> Date of Birth </label>
                    <input type="text" id="date_of_birth" name="DateofBirth" v-model="person.DateofBirth"
                        class="form-control" readonly>
                </div>
            </div>
            <div class="form-group row mb-4">
                <div class="col-md-6">
                    <label for="check_type" class="col-form-label text-md-right"> Check Type </label>
                    <select class="form-control" id="check_type" v-model="requestType">
                        <option value="vacation">Vacation</option>
                        <option value="medicare">Medicare Reimbursement</option>
                        <option value="pension">Pension</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="type" class="col-form-label text-md-right"> Type </label>
                    <select class="form-control" id="type" v-model="checkType">
                        <option value="directdeposit">Direct Deposit</option>
                        <option value="check">Check</option>
                    </select>
                </div>
            </div>
            <div class="form-group row mb-4 check-fields" v-if="checkType == 'check'">
                <div class="col-md-4">
                    <label for="check_list" class="col-form-label text-md-right"> Check List </label>
                    <multiselect v-model="selectedCheck" :options="checks" :multiple="false" placeholder="Select one"
                        label="FormattedText" track-by="check.Number" name="CheckCategory"></multiselect>
                </div>
                <div class="col-md-4">
                    <label for="check_date" class="col-form-label text-md-right"> Check Date </label>
                    <input id="check_date" type="date" class="form-control datepicker" v-model="checkDate" />
                </div>
                <div class="col-md-4">
                    <label for="check_number" class="col-form-label text-md-right"> Check # </label>
                    <input id="check_number" type="text" class="form-control numberpicker" v-model="checkNumber" />
                </div>
            </div>
            <h3> Address for Check Destination currently on File </h3>
            <div class="form-group row mb-4" v-if="address">
                <div class="col-md-4">
                    <label for="address" class="col-form-label text-md-right">Address</label>
                    <input type="text" id="address" name="Street" v-model="address.Street1" class="form-control">
                </div>
                <div class="col-md-4">
                    <label for="address2" class="col-form-label text-md-right">Address 2</label>
                    <input type="text" id="address2" name="Street2" v-model="address.Street2" class="form-control">
                </div>
                <div class="col-md-4">
                    <label for="country">Country</label>
                    <select class="form-control" id="country" v-model="address.Country">
                        <option value="">Select Country</option>
                        <option v-for="country in countries" :value="country.ShortName" :key="country.ShortName">
                            {{ country.LongName }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="state" class="col-form-label text-md-right"> State </label>
                    <select class="form-control" id="state" name="State" v-model="address.State">
                        <option value="">Select State</option>
                        <option v-for="state in filteredStates" :value="state.ShortName" :key="state.ShortName">
                            {{ state.LongName }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="city" class="col-form-label text-md-right">City</label>
                    <input type="text" id="city" name="City" v-model="address.City" class="form-control">
                </div>
                <div class="col-md-4">
                    <label for="zip_code" class="col-form-label text-md-right">Zip Code</label>
                    <input type="text" id="zip_code" name="ZipCode" v-model="address.Zip" class="form-control">
                </div>
            </div>
            <div class="form-group row mb-4">
                <div class="col-md-12">
                    <label for="comments" class="col-form-label text-md-right"> Comments (max 500 characters)
                    </label>
                    <textarea class="form-control" id="comments" v-model="comments"> </textarea>
                </div>
            </div>
            <div class="form-group row col-md-12 mb-4 d-flex justify-content-end gap-2">
                <button type="reset" class="btn btn-primary col-md-1">Reset</button>
                <button class="btn btn-primary col-md-1">Submit</button>
            </div>
        </form>
        <div v-else class="d-flex flex-column align-items-center gap-4">
            <h3>To Continue Please...</h3>
            <h6>Please <a href="profile.personal.edit">update your personal
                    information</a> to include your correct date of birth.</h6>
        </div>

        {{ person }}
    </div>
</template>

<script lang="ts">
import { ref, onMounted, watch, computed, Ref } from 'vue';
import { VacationCheckRequestForm, UICountry, CountryStates, Person, Address, VacationCheckRequestLine } from '@/interfaces/interfaces';
import { useRoute } from 'vue-router';
import Multiselect from 'vue-multiselect';
import axios from 'axios';

import 'vue-multiselect/dist/vue-multiselect.css';

export default {
    setup() {
        // Variables
        const route = useRoute();
        const countries = ref<UICountry[]>([]);
        const statesByCountry: Ref<CountryStates[] | null> = ref(null);
        const person = ref<Person>();
        const address = ref<Address>({
            Street1: null,
            Street2: null,
            Country: '',
            City: null,
            State: null,
            ZipCode: null,
        });

        const checks = ref<VacationCheckRequestLine[]>([]);
        const requestType = ref<string>('');
        const checkType = ref<string>('directdeposit');
        const selectedCheck = ref<VacationCheckRequestLine | null>(null);
        const checkDate = ref('');
        const checkNumber = ref('');

        const comments = ref('');
        const errors = ref<string[]>([]);
        const success = ref('');

        // Methods
        const fetchData = async () => {
            try {
                const response = await axios.get('api/participant/check-replacement');
                countries.value = response.data.Countries;
                person.value = response.data.Person;
                address.value = response.data.Address;
                checks.value = response.data.Checks;
            }
            catch (error) {
                console.error('Error:', error);
            }
        }

        const updateRequestTypeFromQuery = () => {
            const queryParamValue = route.query.fund;

            if (typeof queryParamValue === 'string' && ['vacation', 'medicare', 'pension'].includes(queryParamValue)) {
                requestType.value = queryParamValue;
            }
        };

        watch(selectedCheck, (newCheck, oldCheck) => {
            // Directly use newCheck without .value, as newCheck is the value contained within selectedCheck ref
            if (newCheck && newCheck.Date) {
                const formattedDate = new Date(newCheck.Date).toISOString().split('T')[0];
                checkDate.value = formattedDate;
            } else {
                checkDate.value = '';
            }
            checkNumber.value = newCheck?.Number ?? '';
        });

        // watch(address, (newAddress, oldAddress) => {
        //     const newCountry = newAddress.Country;

        //     if (newCountry) {
        //         const selectedCountry = countries.value.find(country => country.ShortName === newCountry);
        //         // Assuming you need to transform the data to match the CountryStates type
        //         statesByCountry.value = selectedCountry ? [{
        //             CountryKey: String(selectedCountry.UICountryID), // or another property that serves as the country key
        //             States: selectedCountry.u_i_states || []
        //         }] : [];
        //     } else {
        //         statesByCountry.value = [];
        //     }
        // }, { deep: true });

        watch(() => route.query.fund, () => {
            updateRequestTypeFromQuery();
        });

        const filteredStates = computed(() => {
            const selectedCountry = countries.value.find(country => country.ShortName === address.value.Country);

            return selectedCountry?.u_i_states || [];
        });

        const submitCheckReplacement = () => {
            const formData: VacationCheckRequestForm = {
                // Initialize with all the required properties, including SelectedCheck
                PersonID: person.value?.PersonID,
                FirstName: person.value?.FirstName,
                LastName: person.value?.LastName,
                DateofBirth: person.value?.DateofBirth,
                CheckCategory: requestType.value,
                CheckType: checkType.value,
                CheckDate: checkDate.value,
                CheckNumber: checkNumber.value,
                Street: address.value.Street1,
                Street2: address.value.Street2,
                State: address.value.State,
                ZipCode: address.value.ZipCode,
                Notes: comments.value,
                SelectedCheck: '',
            };

            let selectedCheckText = '';

            if (selectedCheck.value !== null) {
                selectedCheckText = selectedCheck.value?.FormattedText ?? '';
            }

            formData.SelectedCheck = selectedCheckText;

            try {
                axios.post('api/participant/check-replacement', { ...formData, _method: 'PATCH' })
                    .then(response => {
                        success.value = response.data.success;
                    })
                    .catch(error => {
                        if (error.response) {
                            errors.value = Object.values(error.response.data.errors).flat() as string[];
                        }
                    });
            }
            catch (error) {
                console.log(error);
            }
        };

        // Mounted
        onMounted(fetchData);

        updateRequestTypeFromQuery();

        return {
            countries,
            statesByCountry,
            filteredStates,
            person,
            address,
            checks,
            selectedCheck,
            requestType,
            checkType,
            checkDate,
            checkNumber,
            success,
            errors,
            submitCheckReplacement
        }
    },

    components: {
        Multiselect
    }
};
</script>
