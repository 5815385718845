<template>
    <p>
        This page will guide you through uploading Excel file with your employees' social security numbers for obtaining
        health coverage information.

        Please note: Each Excel request file can contain up to 25,000 social security numbers. If you have more than 25,000
        social security numbers then you will need to return to the site to upload each additional request file.
    </p>
    <h6>STEP 4 - Upload/Enter List of Employees </h6>
    <div class="position-fixed top-0 end-0 p-3" style="z-index: 11">
        <div v-show="success" class="alert alert-success">{{ success }}</div>
        <div v-show="error" class="alert alert-danger">{{ error }}</div>
    </div>
    <div class="card">
        <div class="card-body d-flex flex-column align-items-start gap-4">
            <div class="col-md-12 d-flex justify-content-start align-items-center">
                <label for="year" class="col-md-5">Year of Coverage <span class="text-danger">*</span></label>
                <div class="col-md-2 d-flex align-items-center">
                    <select v-model="year" id="year" class="form-control" required>
                        <option v-for="option in years" :key="option" :value="option">{{ option }}</option>
                    </select>
                </div>
            </div>
            <div v-if="ssnOption === '0'"
                class="col-md-12 d-flex flex-column justify-content-start gap-4 align-items-start">
                <div class="col-md-12 d-flex align-items-center">
                    <label for="excel" class="col-md-5">
                        Excel File Containing SSN(s) of Full Time Employee(s) <span class="text-danger">*</span>
                    </label>
                    <input type="file" ref="fileInput" accept=".xlsx, .csv" @change="handleFileSelection"
                        style="display:none" required />
                    <button class="btn btn-secondary col-md-2" @click.prevent="browseFiles" :disabled="loading || deleting">
                        {{ loading ? 'Uploading File...' : 'Browse' }}
                    </button>
                </div>
                <div v-if="fileHash" class="form-control w-50 d-flex gap-4 align-items-center">
                    <span> {{ fileHash }} </span>
                    <button class="btn btn-sm btn-danger" @click="handleDelete"> {{ deleting ? 'Deleting...' :
                        'Delete' }}</button>
                </div>
                <span class="text-warning">
                    There is only one acceptable Excel file format. It must contain a single column with
                    "SSN" as the header.
                    Each SSN must be in number format with hyphens (No other special characters or space)
                    and must contain 9 digits.
                    For Example: XXX-XX-XXXX. Click <a :href="excelModel" target="_blank">here</a> for sample format
                </span>
            </div>
            <div v-else class="col-md-12 d-flex flex-column justify-content-start align-items-start gap-4">
                <label for="ssn" class="col-md-12 d-flex justify-content-start align-items-center">
                    <div class="col-md-5">
                        # SSN <span class="text-danger">*</span>
                    </div>
                    <button class="btn btn-secondary w-25" @click="addAnotherEmployee">Add Another Employee</button>
                </label>
                <div v-for="(ssn, index) in ssnList" :key="index" class="d-flex gap-4 align-items-center">
                    <span>{{ index + 1 }}.</span>
                    <input v-model="ssnList[index]" type="text" class="form-control" pattern="\d{3}-\d{2}-\d{4}"
                        placeholder="***-**-****" maxlength="11" @input="formatSSN" @keyup.enter="addAnotherEmployee" />
                    <button v-if="index > 0" class="btn btn-sm btn-danger" @click="deleteEmployee(index)">Delete</button>
                </div>
                <span class="text-warning">
                    Social Security Number should be 9 digit numeric only. After entry of an SSN hit Enter or click the ‘Add
                    Another Employee’ button to enter the next SSN.
                </span>
            </div>
        </div>
    </div>
    <div class="col-md-12 d-flex align-items-center justify-content-between gap-4">
        <div class="d-flex gap-4">
            <button class="btn btn-secondary" @click="handleBack" :disabled="loading || deleting">Back</button>
            <button class="btn btn-secondary" @click="handleCancel" :disabled="loading || deleting">Cancel</button>
        </div>
        <button class="btn btn-secondary" @click="handleSubmit" :disabled="loading">
            {{ submitting ? 'Submitting...' : 'Submit Request' }}
        </button>
    </div>
</template>

<script lang="ts">
import axios from 'axios';
import { defineComponent, ref, onMounted, Ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
    props: {
        ssnOption: String,
        excelModel: String,
        submitting: Boolean
    },
    setup(props, { emit }) {
        const ssnList: Ref<string[]> = ref(['']);
        const year = ref(new Date().getFullYear());
        const years: Ref<number[]> = ref([]);
        const router = useRouter();
        const fileHash = ref('');
        const formData = ref(new FormData());
        const loading = ref(false);
        const deleting = ref(false);
        const success = ref(null);
        const error: Ref<string | null> = ref(null);
        const fileInput = ref<HTMLInputElement | null>(null);
        const files: Ref<FileList | null> = ref(null);

        const handleBack = () => {
            emit('handle-back');
        }

        const handleSubmit = () => {
            const formData = {
                'Year': year.value,
                'SSNs': ssnList.value.every(item => item.trim() === '') ? null : ssnList.value.filter(item => item.trim() !== ''),
                'Type': props.ssnOption === '0' ? 'excel' : 'web',
                'FileHash': fileHash.value
            }

            emit('handle-submit', formData);
        }

        const handleDelete = async () => {
            deleting.value = true;
            success.value = null;
            error.value = null;

            await axios.delete(`api/employer/health-coverage-data/delete-file/${fileHash.value}`)
                .then(response => {
                    success.value = response.data.success;
                    fileHash.value = '';
                })
                .catch(() => {
                    error.value = 'There was an error deleting document!'
                });

            deleting.value = false;
            files.value = null;
            fileInput.value = null;

            setTimeout(() => {
                success.value = null;
                error.value = null;
            }, 3500)
        };

        const browseFiles = async () => {
            if (fileInput.value) {
                fileInput.value.value = '';
            }

            fileInput.value?.click();
        }

        const addAnotherEmployee = () => {
            ssnList.value.push('');
        };

        const deleteEmployee = (index: number) => {
            ssnList.value.splice(index, 1);
        };

        const handleCancel = () => {
            router.push({ name: 'employer.dashboard' });
        }

        const formatSSN = () => {
            ssnList.value.forEach((element, index) => {
                let ssn = element.replace(/\D/g, '');

                if (ssn.length <= 3) {
                    ssnList.value[index] = ssn;
                } else if (ssn.length <= 5) {
                    ssnList.value[index] = `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
                } else {
                    ssnList.value[index] = `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
                }
            });
        }

        const handleFileSelection = async (event: Event) => {
            loading.value = true;
            files.value = (event.target as HTMLInputElement)?.files || (event as DragEvent)?.dataTransfer?.files || null;
            fileHash.value = '';
            success.value = null;
            error.value = null;

            if (files.value) {
                console.log(files.value, '@files-mee')
                for (let i = 0; i < files.value.length; i++) {
                    formData.value.append('Files[]', files.value[i]);
                }

                await axios.post('api/employer/health-coverage-data/add-file', formData.value)
                    .then(response => {
                        success.value = response.data.success;
                        fileHash.value = response.data.FileHash;
                    })
                    .catch(error => {
                        error.value = 'There was an error uploading document!'
                    });

                setTimeout(() => {
                    success.value = null;
                    error.value = null;
                    formData.value = new FormData();
                }, 3500)
            }

            loading.value = false;
        };

        onMounted(() => {
            const currentYear = new Date().getFullYear(); // Dynamically generate years from 2015 to 10 years in the future
            for (let i = 2015; i <= currentYear + 10; i++) {
                years.value.push(i);
            }
        });

        return {
            ssnList,
            year,
            years,
            loading,
            deleting,
            success,
            error,
            fileInput,
            fileHash,
            browseFiles,
            handleBack,
            handleSubmit,
            handleCancel,
            addAnotherEmployee,
            deleteEmployee,
            formatSSN,
            handleDelete,
            handleFileSelection
        }
    }
})
</script>