import { ref, computed } from "vue";

export function useCurrencyFormatter() {
    const formatCurrency = (value: number | string): string => {
        let numericValue: number;

        if (typeof value === "string") {
            // Ensure we handle both strings with and without commas/periods correctly
            numericValue = parseFloat(value.replace(/,/g, ""));
        } else if (typeof value === "number") {
            numericValue = value;
        } else {
            numericValue = 0;
        }

        // If value is still not a number, return a default formatted value
        if (isNaN(numericValue)) {
            return "$0.00";
        }

        // Format to USD with two decimal places
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(numericValue);
    };

    const formattedCurrency = (value: number) => {
        return computed(() => formatCurrency(value));
    };

    return {
        formatCurrency,
        formattedCurrency,
    };
}