<template>
    <div class="container mt-4">
        <div class="position-fixed top-0 end-0 p-3" style="z-index: 100">
            <div class="alert alert-danger" v-show="error">{{ error }}</div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center mb-4">
                <h1>Member Search</h1>
            </div>
            <p>You can filter your member search by first name, middle name, last name, SSN and date of birth.</p>
            <div v-if="membersStore.list?.length === 0" class="d-flex flex-column gap-4">
                <h5 class="mb-2">Filters</h5>
                <div class="d-flex align-items-center gap-4 w-100" v-for="(value, key) in filters" :key="key">
                    <div v-if="key === 'DateofBirth'" class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2"> Date of Birth: </span>
                        <input type="date" v-model="filters[key]" class="form-control w-50" />
                    </div>
                    <div v-else-if="key === 'ParticipantID'" class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2"> NBF Participant ID or SSN:  <span class="text-warning fs-6"> (maximum length is 9 chars) </span></span>
                        <input type="text" v-model="filters[key]" class="form-control w-50" maxlength="9" @input="validateParticipantID" />
                    </div>
                    <div v-else class="d-flex align-items-center gap-4 w-100">
                        <span class="col-md-2">{{ toTitleCase(key) }}:</span>
                        <input type="text" v-model="filters[key]" class="form-control w-50" />
                    </div>
                </div>
                <div class="d-flex gap-2 align-self-end w-25">
                    <button class="btn btn-secondary" @click="resetFilters">Reset</button>
                    <button class="btn btn-primary w-50" @click="searchData">
                        {{ loading ? 'Searching...' : 'Search' }}</button>
                </div>
            </div>
            <div v-if="!loading && membersStore.list?.length > 0" class="row">
                <div class="d-flex justify-content-between mb-4">
                    <button class="btn btn-secondary" @click="resetFilters">Change my current
                        Filters</button>
                    <button class="btn btn-primary" @click="printList">Print List</button>
                </div>
                <table class="table table-bordered table-striped" id="table-member-search">
                    <thead class="table-dark">
                        <tr class="text-center align-middle">
                            <th>Name</th>
                            <th>Date of Birth</th>
                            <th>NBF Participant ID</th>
                            <th>Phone No.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="person in membersStore.list" :key="person.PersonID" class="text-center">
                            <td>
                                <router-link
                                    :to="{ name: 'local.member-profile', params: { PersonID: person.PersonID } }">
                                    {{ person.FullName }}
                                </router-link>
                            </td>
                            <td>{{ person.DateofBirth }}</td>
                            <td>{{ person.PersonID }}</td>
                            <td>{{ person.Phone }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { reactive, ref } from 'vue';
import { useMembersStore } from '@/stores/members';
import { usePrint } from '@/composable/usePrint';
import { Person } from "@/interfaces/local";
import axios from 'axios';

export default {
    setup() {
        const loading = ref(false);
        const membersStore = useMembersStore();
        const { printElementById } = usePrint();
        const error = ref(null);
        const filters = reactive({
            FirstName: '',
            MiddleName: '',
            LastName: '',
            ParticipantID: '',
            DateofBirth: '',
        })

        const printList = printElementById('table-member-search', 'Member List');

        const resetFilters = () => {
            membersStore.setList([]);

            Object.keys(filters).forEach((key) => {
                filters[key as keyof typeof filters] = '';
            });
        }

        const toTitleCase = (str: string) => {
            return str
                .replace(/([a-z])([A-Z])/g, '$1 $2')
        }

        const validateParticipantID = (event: InputEvent) => {
            const input = event.target as HTMLInputElement;
            const inputValue = input.value.trim();
            const numericRegex = /^[0-9]*$/;

            // Check if the input value contains only numeric characters
            if (!numericRegex.test(inputValue)) {
                input.value = inputValue.replace(/[^0-9]/g, '');
            }
        };

        const searchData = async () => {
            loading.value = true;
            membersStore.setList([]);

            await axios.get('api/local/search-member', {
                params: { ...filters }
            })
                .then(response => {
                    membersStore.setList(response.data.Persons as Person[]);
                })
                .catch(errorRes => {
                    error.value = errorRes.response.data.error;

                    setTimeout(() => error.value = null, 3500);
                })

            loading.value = false;
        }

        return {
            loading,
            error,
            filters,
            membersStore,
            validateParticipantID,
            resetFilters,
            searchData,
            toTitleCase,
            printList
        };
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
}
</style>