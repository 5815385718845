<template>
    <div class="d-flex flex-column gap-2">
        <div class="form-group d-flex flex-column gap-4 mb-4 mx-5">
            <div class="d-flex gap-2">
                <input v-model="type" type="radio" id="admin_auth" value="auth">
                <label for="admin_auth">Administrator - Authentication/PIN Request</label>
            </div>
            <div class="d-flex gap-2">
                <input type="radio" id="admin_register" v-model="type" value="register">
                <label for="admin_register">Administrator - Registration (Registration Requires PIN)</label>
            </div>
            <div class="d-flex gap-2">
                <input type="radio" id="rep_register" v-model="type" value="rep_register">
                <label for="rep_register">Representative - Registration (Registration Requires PIN)</label>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
    props: {
        registerType: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const type = ref(props.registerType);

        watch(
            type,
            (newValue) => {
                emit('change-type', newValue);
            }
        );

        return {
            type,
        };
    }
});
</script>
