<template>
    <div class="dashboard-detail-item pdl-0">
        <div class="info-card">
            <div class="info-card__icon">
                <i :class="iconClass"></i>
            </div>
            <div class="info-card__text">
                <h6>{{ title }}</h6>
                <p><strong>{{ detail }}</strong></p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'InfoCard',
    props: {
        iconClass: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        detail: {
            type: String,
            required: true,
        },
    },
})
</script>