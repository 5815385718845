<template>
    <div class="switch">
        <label class="switch-input">
            <input type="checkbox" :name="name" :checked="modelValue" @change="handleChange" />
            <span class="switch-slider">
                <span class="switch-label left">{{ option1 }}</span>
                <span class="switch-label right">{{ option2 }}</span>
            </span>
        </label>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';

export default defineComponent({
    props: {
        option1: {
            type: String,
            required: true,
        },
        option2: {
            type: String,
            required: true,
        },
        modelValue: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: '',
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const handleChange = (event: Event) => {
            const input = event.target as HTMLInputElement;
            emit('update:modelValue', input.checked);
        };

        return {
            handleChange,
        };
    },
});
</script>
