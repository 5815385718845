import { ref } from 'vue';

// Create a map of fund IDs to their names
const fundMap = ref<Record<string | number, string>>({
    1: 'Pension Plan B',
    2: 'Pension Plan C',
    3: 'Welfare Plan A',
    4: 'Welfare Plan C',
    5: 'Annuity',
    6: 'Vacation',
    7: '401K',
    8: 'Annuity 401K',
    9: 'Pension Plan A',
    10: 'Welfare Plan B',
    11: 'Escrow',
    12: 'Clearance',
    13: 'WF Clearance',
    14: 'WF Welfare',
    15: 'Pension Old',
    16: 'Annuity Old',
    17: 'Vacation Old',
    18: '401K Old',
    19: 'Escrow Old'
});

export function useFunds() {
    // Function to get the name of the fund by its ID
    const getFundName = (id: string): string => {
        return fundMap.value[id] || 'Unknown Fund';
    };

    return {
        getFundName
    };
}
