<template>
  <div class="form-group row mb-4">
    <label :for="inputId" class="col-form-label text-md-right">Web Email Address</label>
    <div class="col-md-6">
      <input type="email" :id="inputId" class="form-control" v-model="emailData.ContactInfo" @input="handleChanges"
        required>
    </div>
    <div class="col-md-6 d-flex justify-content-start mb-4">
      <label :for="primaryId" class="col-form-label text-md-right d-flex flex-row-reverse justify-content-start gap-2">
        Primary Email
        <input type="radio" :id="primaryId" @click="updatePrimary()"
          :checked="emailData.PrimaryFlag == 1 ? true : false" />
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { Contact } from '@/interfaces/interfaces';
import { ref, PropType, defineComponent } from 'vue';

export default defineComponent({
  props: {
    email: Object as PropType<Contact>
  },
  setup(props, { emit }: { emit: (event: string, ...args: any[]) => void }) {
    const emailData = ref({ ...props?.email });
    const inputId = `phone_${emailData.value?.CMIID}_info`;
    const primaryId = `phone_${emailData.value?.CMIID}_primary`;

    const updatePrimary = () => {
      emailData.value.PrimaryFlag = 1;

      handleChanges();
    };

    const handleChanges = () => {
      emit("update-data", emailData.value, 'update-email');
    }

    return {
      emailData,
      inputId,
      primaryId,
      updatePrimary,
      handleChanges
    };
  },
});
</script>
