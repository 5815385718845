import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-md-8 border p-4" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = {
  key: 0,
  ref: "chartRef"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.title), 1),
    (_ctx.graphData)
      ? (_openBlock(), _createElementBlock("canvas", _hoisted_3, null, 512))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, "Loading Chart..."))
  ]))
}