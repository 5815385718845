import axios from "axios";
import { defineStore } from "pinia";

export const useUtilsStore = defineStore({
    id: "utils",

    state: () => ({
        genders: null,
        statesByCountry: null,
        countries: null,
    }),

    actions: {
        // Action to fetch genders
        async fetchGenders() {
            const response = await axios.get("api/user/genders");
            this.genders = response.data;
        },

        // Action to fetch states by country
        async fetchStatesByCountry() {
            const response = await axios.get("api/user/states-by-country");
            this.statesByCountry = response.data;
        },

        // Action to fetch countries
        async fetchCountries() {
            const response = await axios.get("api/user/countries");
            this.countries = response.data;
        },
    },

    getters: {
        genderOptions: (state) => state.genders,
        statesByCountryOptions: (state) => state.statesByCountry,
        countryOptions: (state) => state.countries,
    },
});
