<template>
    <div v-if="loading" class="col-md-12 text-center my-4">
        <span> Loading... </span>
    </div>

    <div v-else class="plan-content">
        <div v-if="beneficiaries?.rows.length === 0 || !beneficiaries" class="col-md-12 text-center my-4">
            <span> Currently there are no beneficiaries on file </span>
        </div>
        <DataTable v-else :table="beneficiaries" type="striped small" />

        <!-- Account Actions -->
        <div class="account-actions">
            <div class="action-buttons">
                <a href="" class="btn btn-secondary plan-modal"
                    @click.prevent="openModal('dependents', 'My Dependents', null)"> View Dependents </a>
                <a href="" class="btn btn-secondary plan-modal"
                    @click.prevent="openModal('add-beneficiary', 'Add Beneficiary', 'plan-form plan-upload')"> Add
                    Beneficiary </a>
                <a href="" class="btn btn-secondary plan-modal"
                    @click.prevent="openModal('assign-fund', 'Assign Fund To Beneficiary', 'plan-upload')">
                    Add/Modify Fund
                    Assignment </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, reactive, Ref, onMounted } from 'vue';
import { Dependent, TableData } from '@interfaces/interfaces';
import { useTableFormatter } from '@/composable/useTableFormatter';
import { useModalStore } from '@/stores/modal';
import DataTable from '@components/form/DataTable.vue';
import axios from 'axios';

export default {
    components: {
        DataTable
    },
    setup() {
        const beneficiaries: TableData = reactive({
            headers: [
                { title: 'Fund' },
                { title: 'Beneficiary Name' },
                { title: 'Relationship' },
                { title: 'Type' },
                { title: '%' },
                { title: 'Status' },
                { title: 'Date Appointed' },
                { title: 'QSRO' },
                { title: 'Document(s)' },
            ],
            rows: []
        });
        const loading = ref(true);
        const modalStore = useModalStore();

        const fetchData = async () => {
            loading.value = false;
        };

        onMounted(async () => {
            await fetchData();
        });

        return {
            beneficiaries,
            loading,
            openModal: modalStore.openModal
        };
    }
};
</script>