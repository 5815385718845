<template>
    <div class="mobile-header">
        <figure>
            <img src="../../assets/images/logo.svg" alt="Logo" title="IATSE Benefits Fund Logo">
        </figure>
        <div class="header-navigation">
            <p class="header-notifications plan-modal" @click="openModal('alerts', 'Alerts', null)"><i
                    class="iatse-icon-Bell"></i>
                <span v-if="unreadAlerts" class="notification-count"> {{ unreadAlerts }} </span>
            </p>
            <p @click="openModal('profile', 'My Profile', 'plan-profile plan-tables')" class="plan-modal"><i
                    class="iatse-icon-user-rounded"></i></p>
            <p @click="openModal('account-settings', 'Account Settings', 'plan-card-info')" class="plan-modal"><i
                    class="iatse-icon-Settings"></i></p>
            <a href="" class="mobile-menu"><i class="iatse-icon-menu"></i></a>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useAuthStore } from '@/stores/auth';

export default defineComponent ({
    name: 'MobileHeader',
    setup(_, { emit }) {
        const authStore = useAuthStore();
        const unreadAlerts = computed(() => authStore.unreadAlerts);

        const openModal = (type: string, title: string, classes: string | null) => {
            emit('openModal', type, title, classes);
        };

        return {
            unreadAlerts,
            openModal
        }
    },
});
</script>