<template>
    <div class="col-md-3">
        <div class="card">
            <div class="card-body">
                <h3 class="text-center mb-4">Fund Materials</h3>
                <div class="card mb-4">
                    <div class="card-body d-flex flex-column align-items-start gap-4 w-100">
                        <h6>Plan Forms & Documents</h6>
                        <div class=" d-flex flex-column align-items-start gap-2 w-100">
                            <a target="_blank" :href="links?.MultiTrustFundAcceptance">Multi Fund Trust Acceptance
                                Document</a>
                            <a target="_blank" :href="links?.EmployerContributionsandCollectionsGuidebook">Contributions and
                                Collections Guidebook</a>
                            <a target="_blank" :href="links?.TheWheel">H&W Plan C Eligibility Wheel</a>
                            <a target="_blank" :href="links?.ContributionRemittanceForm2013">Contributions Remittance
                                Form</a>
                            <a target="_blank" :href="links?.DeferredSalaryAgreementForm">Deferred Salary Agreement Form</a>
                            <a target="_blank" :href="links?.IANBFHomePlan">IATSENBF As Home Plan</a>
                            <a target="_blank" :href="links?.IANBFHomePlanforLocal600PuertoRicoOnly">IATSENBF As Home Plan -
                                Local 600 - PR ONLY</a>
                            <a target="_blank" :href="links?.IANBFHomePlanforUSA829Only">IATSENBF As Home Plan -USA829
                                ONLY</a>
                            <a target="_blank" :href="links?.ParticipationAgreementAnnuityDeferral">Participation Agreement
                                - Annuity Deferral - 3%</a>
                            <a target="_blank" :href="links?.ParticipationAgreementAnnuitySMDeferral">Participant Agreement
                                - Annuity SM Deferral</a>
                            <a target="_blank" :href="links?.NoticereEndofYearReportsAllYears">End of Year Remittance
                                Reports - Reminder</a>
                            <a href="#">SPDs</a>
                            <a target="_blank" :href="links?.CompanyDataSheet">Company Data Sheet</a>
                            <a target="_blank" :href="links?.EINAndRefundPolicyNoticeToPayors">Refund Policy &amp; Company
                                data Sheet</a>
                        </div>
                    </div>
                </div>
                <div class="card mb-4">
                    <div class="card-body d-flex flex-column gap-2 w-100">
                        <h6>Required Fund Rates</h6>
                        <a href="#">H&W
                            Plan C CAPP Rates</a>
                        <a href="#">H&W Plan
                            A Rates</a>
                        <a target="_blank" :href="links?.PlanBRates">Pension Plan B
                            Rates</a>
                        <a target="_blank" :href="links?.IRSDollarLimits">IRS
                            Retirement Plan Limits</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { FundMaterialLinks } from '@/interfaces/employer';

export default defineComponent({
    props: {
        links: Object as PropType<FundMaterialLinks | null>
    }
})
</script>

<style scoped>
a {
    text-decoration: none;
}

.card-title {
    text-decoration: none;
    color: #0d6efd;
}

.card-title:hover {
    cursor: pointer;
}
</style>
