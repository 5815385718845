<template>
  <!-- Plan Content -->
  <div v-if="loading"> Loading Data ... </div>
  <div v-else class="plan-content">
    <PlanGeneral text="This page displays information about you on file at the Fund Office. You can update this
            information by clicking on the Update button under the applicable section if any of it has changed."
      classes="first" />

    <!-- General Plan Detail Cards -->
    <div class="plan-details">
      <ProfileInfo title="My Personal Information" :items="personalInfoItems" @open-modal="openModal('update-personal-info', 'Update Personal Information', 'plan-upload plan-card-info plan-profile-update')" />
      <ProfileInfo title="My Address" :items="addressInfoItems" @open-modal="openModal('update-address', 'Update Address', 'plan-upload plan-card-info plan-profile-update')" />
      <ProfileInfo title="My Contact Information" :items="contactInfoItems" @open-modal="openModal('update-contact-information', 'Update Contact Information', 'plan-upload plan-card-info')" />

      <!-- Quick Links -->
      <div class="plan-general-details quick-links">
        <h4 class="hide-mobile"> Quick Links </h4>
        <div class="plan-general-container">
          <button type="button" class="btn btn-quaternary plan-modal"
            @click="openModal('plan-a-family', 'Plan A Family', '')">H&amp;W Coverage</button>
          <button type="button" class="btn btn-quaternary plan-modal" @click="openModal('claims', 'Claims', '')">
            Claims</button>
          <button type="button" class="btn btn-quaternary plan-modal"
            @click="openModal('dependents', 'Dependents', '')">
            My Dependents </button>
          <button type="button" class="btn btn-quaternary plan-modal"
            @click="openModal('beneficiaries', 'Beneficiaries', '')">
            Beneficiaries </button>
          <button type="button" class="btn btn-quaternary plan-modal" @click="openModal('plan-c-mrp', 'Plan C', '')">
            Plan C Statements </button>
          <button type="button" class="btn btn-quaternary plan-modal"
            @click="openModal('pension-contribution-history', 'Pension', '')"> Pension </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useProfileStore } from '@/stores/profile';

import ProfileInfo from '@components/cards/ProfileInfo.vue';
import PlanGeneral from '@components/cards/PlanGeneral.vue';

type ProfileChange = {
    [key: string]: boolean;
}

export default {
  components: {
    ProfileInfo,
    PlanGeneral,
  },
  setup() {
    const modalStore = useModalStore();
    const profileStore = useProfileStore();
    const personalInfoRoute = 'api/user/profile/my-personal-information';
    const addressInfoRoute = 'api/user/profile/my-address';
    const contactInfoRoute = 'api/user/profile/my-contact-information';
    const loading = ref(true);

    const getClass = (label: keyof ProfileChange): string => {
      return profileStore.changes[label] ? 'grey-text' : '';
    };

    const personalInfoItems = computed(() => [
      { label: 'Participant ID', value: profileStore.personalData?.PersonID },
      { label: 'Name', value: profileStore.personalData?.FullName, class: 'right', itemClass: getClass('FirstName') || getClass('LastName') },
      { label: 'Date of Birth', value: profileStore.personalData?.DateofBirth, itemClass: getClass('DateofBirth') },
      { label: 'Gender', value: profileStore.personalData?.Sex?.value, class: 'right', itemClass: getClass('Gender') },
      { label: 'Marital Status', value: profileStore.personalData?.MaritalStatus?.value, itemClass: getClass('MaritalStatus') },
      { label: 'Home Local', value: profileStore.personalData?.HomeLocal?.value, class: 'right', itemClass: getClass('HomeLocal') }
    ]);

    const addressInfoItems = computed(() => [
      { label: 'Address Type', value: profileStore.address?.Type?.value },
      { label: 'Primary', value: profileStore.address?.Primary ? 'Yes' : 'No', class: 'right' },
      { label: 'Address', value: profileStore.address?.Street1 },
      { label: 'City', value: profileStore.address?.City, class: 'right' },
      { label: 'Address', value: profileStore.address?.State?.value ?? ''},
      { label: 'Country', value: profileStore.address?.Country?.value ??'', class: 'right' },
      { label: 'Postal Code', value: profileStore.address?.ZipCode },
    ]);

    const contactInfoItems = computed(() => [
      { label: 'Phone Number', value: profileStore.contact?.Home ?? profileStore.contact?.Mobile ?? profileStore.contact?.Work },
      { label: 'Primary Phone', value: profileStore.contact?.Home ? 'Yes' : 'No', class: 'right' },
      { label: 'Web Email Address', value: profileStore.contact?.Emails?.Primary },
      { label: 'Primary Email', value: profileStore.contact?.Emails?.Primary !== '' ? 'Yes' : 'No', class: 'right' }
    ]);

    // Get Profile Data
    const fetchData = async () => {
      try {
        if (!profileStore.personalData || !profileStore.changes.length) {
          loading.value = true;
          await profileStore.fetchPersonalData(personalInfoRoute);
        }

        if (!profileStore.address) {
          loading.value = true;
          await profileStore.fetchAddress(addressInfoRoute);
        }

        if (!profileStore.contact) {
          loading.value = true;
          await profileStore.fetchContactData(contactInfoRoute);
        }

        if (!profileStore.generalData) {
          loading.value = true;
          await profileStore.fetchGeneralData();
        }

        if (!profileStore.countries || !profileStore.statesByCountry) {
          loading.value = true;
          await profileStore.fetchCountriesStates();
        }

        loading.value = false;
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    }

    onMounted(async () => {
      await fetchData();
    });

    return {
      profileStore,
      loading,
      personalInfoItems,
      addressInfoItems,
      contactInfoItems,
      openModal: modalStore.openModal,
    };
  }
}
</script>