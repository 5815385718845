<template>
  <div class="container mt-4">
    <div class="row mb-4">
      <div class="col-md-12 text-center">
        <h1>My web account settings</h1>
      </div>
    </div>
    <div v-if="loading">Loading...</div>
    <form v-else @submit.prevent="updateSettings">
      <div class="form-group mb-4">
        <div v-if="success" class="alert alert-success">
          {{ success }}
        </div>
        <div v-if="errors.length > 0" class="alert alert-danger">
          <ul>
            <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
          </ul>
        </div>
      </div>
      <div class="form-group mb-4">
        <span>If you wish to change your password, e-mail address, or security question, please do so in the
          corresponding
          boxes below.</span>
      </div>
      <div class="form-group mb-4 d-flex gap-2">
        <div class="form-group d-flex flex-column justify-content-center gap-2 col-md-6 align-middle">
          <label for="password" class="fw-bold">Enter New Password:</label>
          <div class="input-group form-control d-flex justify-content-between align-items-center h-50">
            <input v-model="password" class="input-password reset" :type="showPassword ? 'text' : 'password'"
              name="password" id="password">
            <button @click="togglePassword" type="button" class="reset">
              <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
            </button>
          </div>
        </div>
        <div class="form-group d-flex flex-column gap-2 col-md-6">
          <label for="password_confirmation" class="fw-bold">Confirm New Password:</label>
          <div class="input-group form-control d-flex justify-content-between align-items-center h-50">
            <input v-model="passwordConfirmation" class="input-password reset"
              :type="showPasswordConfirmation ? 'text' : 'password'" name="password_confirmation"
              id="password_confirmation">
            <button @click="togglePasswordConfirmation" class="reset" type="button">
              <i :class="showPasswordConfirmation ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="form-group mb-4">
        <label class="fw-bold">Email Address: </label>
        <span class="mx-2">{{ userEmail }}</span>
      </div>
      <div class="form-group mb-4 d-flex gap-2">
        <div class="form-group d-flex flex-column gap-2 col-md-6">
          <label for="email" class="fw-bold">Enter New Email Address:</label>
          <input v-model="email" class="form-control" type="email" name="email" id="email">
        </div>
        <div class="form-group d-flex flex-column gap-2 col-md-6">
          <label for="email_confirmation" class="fw-bold">Confirm New Email Address:</label>
          <input v-model="emailConfirmation" class="form-control" type="email" name="email_confirmation"
            id="email_confirmation">
        </div>
      </div>
      <div v-if="numberOfQuestions > 0">
        <div v-for="index in numberOfQuestions" :key="index">
          <SecurityQuestionSelect :questions="availableQuestionsForSelect(`select${index}`).value"
            :userQuestion="userQuestions[index - 1]" label="Security Question"
            :questionId="userQuestions[index - 1]?.ID" :index="index" @handle-question="handleQuestion"
            :resetInputs="resetInputs" />
        </div>
      </div>
      <div v-else>
        <SecurityQuestionSelect :questions="securityQuestions" :userQuestion="userQuestions[0]"
          label="Security Question" :questionId="userQuestions[0]?.ID ?? 0" :index="0" @handle-question="handleQuestion"
          :resetInputs="resetInputs" />
      </div>
      <div class="form-group mb-4 row d-flex flex-column align-items-end">
        <div class="col-md-1">
          <button type="submit" class="btn btn-primary" :disabled="loadingUpdate">
            {{ loadingUpdate ? 'Updating...' : ('Update') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, Ref, computed } from 'vue';
import { SecurityQuestion } from '@/interfaces/interfaces';
import { SelectedQuestion } from '@/interfaces/employer';
import { useAuthStore } from '@/stores/auth';
import SecurityQuestionSelect from '@components/user/SecurityQuestionSelect.vue';
import axios from 'axios';

export default {
  components: {
    SecurityQuestionSelect
  },
  setup() {
    const securityQuestions: Ref<SecurityQuestion[]> = ref([]);
    const userQuestions: Ref<SecurityQuestion[]> = ref([]);
    const showPassword = ref(false);
    const showPasswordConfirmation = ref(false);
    const password = ref('');
    const passwordConfirmation = ref('');
    const email = ref('');
    const emailConfirmation = ref('');
    const errors = ref<string[]>([]);
    const success = ref('');
    const isEmployer = useAuthStore().isEmployer;
    const isLocal = useAuthStore().isLocal;
    const userEmail = ref('');
    const numberOfQuestions = isEmployer || isLocal ? 3 : 1;
    const selectedQuestions: Ref<SelectedQuestion> = ref([]);
    const loading = ref(true);
    const loadingUpdate = ref(false);
    const resetInputs = ref(false);

    // Togle between hide/show password
    const togglePassword = () => {
      showPassword.value = !showPassword.value;
    }

    const togglePasswordConfirmation = () => {
      showPasswordConfirmation.value = !showPasswordConfirmation.value;
    }

    const selectedQuestionsId = ref<{ [key: string]: number }>({ select1: 0, select2: 0, select3: 0 });

    const availableQuestionsForSelect = (selectKey: string) => computed(() => {
      return securityQuestions.value?.filter(q => {
        return !Object.entries(selectedQuestionsId.value)
          .filter(([key, value]) => key !== selectKey && value !== 0)
          .map(([, value]) => value)
          .includes(q.SecurityQuestionID as number);
      });
    })

    // Handle Security Questions Changes
    const handleQuestion = (payload: SelectedQuestion, index: number) => {
      console.log(payload, '@payload');
      selectedQuestionsId.value[`select${index}`] = payload[index].Question;
      selectedQuestions.value = { ...selectedQuestions.value, ...payload };
    }

    // Get Security Questions
    const fetchData = async (isLoading: boolean) => {
      if (isLoading)
        loading.value = true;

      await axios.get('api/user/settings')
        .then(response => {
          securityQuestions.value = response.data.SecurityQuestions;
          userEmail.value = response.data.Email;

          if (response.data.UserSecurityQuestions.length > 0) {
            response.data.UserSecurityQuestions.forEach((element: SecurityQuestion, index: number) => {
              selectedQuestionsId.value[`select${index + 1}`] = element.SecurityQuestionID as number;
            });
          }

          userQuestions.value = response.data.UserSecurityQuestions;
        })
        .finally(() => {
          if (isLoading)
            loading.value = false;
        });
    };

    // Submit Form
    const updateSettings = () => {
      resetInputs.value = false;

      const formData = {
        email: email.value,
        email_confirmation: emailConfirmation.value,
        password: password.value,
        password_confirmation: passwordConfirmation.value,
        security_questions: selectedQuestions.value,
      };

      loadingUpdate.value = true;

      axios.post('api/user/settings', { ...formData, _method: 'PATCH' })
        .then(response => {
          success.value = response.data.success;
          fetchData(false);

          setTimeout(() => {
            email.value = '';
            emailConfirmation.value = '';
            password.value = '';
            passwordConfirmation.value = '';
            resetInputs.value = true;
          }, 1000);
        })
        .catch(error => {
          if (error.response) {
            errors.value = Object.values(error.response.data.errors).flat() as string[];
          }
        })
        .finally(() => {
          setTimeout(() => {
            errors.value = [];
            success.value = '';
          }, 3500);

          loadingUpdate.value = false;
        });
    };

    onMounted(async () => await fetchData(true));

    return {
      password,
      passwordConfirmation,
      email,
      emailConfirmation,
      showPassword,
      showPasswordConfirmation,
      securityQuestions,
      userQuestions,
      numberOfQuestions,
      isEmployer,
      selectedQuestions,
      userEmail,
      errors,
      success,
      loading,
      loadingUpdate,
      resetInputs,
      togglePassword,
      togglePasswordConfirmation,
      handleQuestion,
      updateSettings,
      availableQuestionsForSelect
    };
  },
}
</script>

<style>
.input-password {
  width: 90%;
}

.reset {
  border: none;
  outline: none;
  align-self: flex-start;
  background-color: transparent;
}

.input-password:focus,
.input-password:active {
  border: none;
  box-shadow: none;
}
</style>