<template>
  <div class="container mt-4">
    <h4 class="mb-4">Form Submissions</h4>
    <div v-show="success" class="alert alert-success">{{ success }}</div>
    <div v-if="loading">Loading...</div>
    <form v-else class="mb-4" @submit.prevent>
      <table class="table table-striped mb-4">
        <thead class="table-dark align-middle">
          <tr class="text-center">
            <th> Participant ID </th>
            <th> Web Submission Date </th>
            <th> Form Type </th>
            <th> Person Name </th>
            <th> Person Date of Birth </th>
            <th> Notes </th>
            <th> Status </th>
            <th> </th>
          </tr>
          <tr class="text-center">
            <td>
              <input type="number" v-model="filters.ParticipantID" class="form-control">
            </td>
            <td>
              <input type="date" v-model="filters.WebSubmissionDate" class="form-control">
            </td>
            <td class="col-md-2">
              <select class="form-control" v-model="filters.FormType">
                <option value="0"> All </option>
                <option v-for="formType in formTypes" :key="formType.UIRequestFormID" :value="formType.UIRequestFormID">
                  {{ formType.Name }}
                </option>
              </select>
            </td>
            <td>
              <input type="text" v-model="filters.PersonName" class="form-control">
            </td>
            <td></td>
            <td>
              <input type="text" v-model="filters.Notes" name="Notes" class="form-control">
            </td>
            <td class="col-md-1">
              <select class="form-control" name="Status" v-model="filters.Status">
                <option value="All" :class="{ 'selected': filters.Status == 'All' }"> All </option>
                <option value="1" :class="{ 'selected': filters.Status == '1' }"> Closed </option>
                <option value="0" :class="{ 'selected': filters.Status == '0' }"> Open </option>
              </select>
            </td>
            <td>
              <button type="button" class="btn btn-secondary button-width" @click="handleSearch">
                {{ loadingSearch ? 'Searching...' : 'Search' }}
              </button>
            </td>
          </tr>
        </thead>
        <tbody v-if="submissions.length > 0">
          <tr v-for="submission in submissions" :key="submission.SubmissionID"
            :class="{ 'text-center': true, 'closed': submission.Status === 'Closed' }">
            <td>{{ submission.PersonID }}</td>
            <td>{{ submission.WebSubmissionDate }}</td>
            <td>{{ submission.FormType }}</td>
            <td>{{ submission.PersonName }}</td>
            <td class="col-md-1">{{ submission.PersonDateofBirth }}</td>
            <td>{{ submission.Notes }}</td>
            <td>{{ submission.Status }}</td>
            <td class="w-status">
              <button type="button" v-if="submission.Status === 'Open'"
                @click="handleCloseSubmission(submission.SubmissionID)" class="btn btn-sm btn-danger">
                {{ submission.Submitting ?
      'Closing...' : 'Close' }}</button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center py-5">
            <td colspan="8"> No submissions found </td>
          </tr>
        </tbody>
      </table>
      <PaginationComponent :paginator="paginator" @fetch-data="fetchPaginationData" />
    </form>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, Ref, reactive } from 'vue';
import { Paginator, Submission, FormType, Filters } from '@/interfaces/admin';
import PaginationComponent from '@components/utils/PaginationComponent.vue';
import axios from 'axios';

export default {
  components: {
    PaginationComponent
  },

  setup() {
    const submissions: Ref<Submission[]> = ref([]);
    const formTypes: Ref<FormType[]> = ref([]);
    const filters: Filters = reactive({ FormType: '0', Notes: '', ParticipantID: '', PersonName: '', WebSubmissionDate: '', Status: 'All' });
    const paginator: Paginator = reactive({ per_page: 15, total: 300, last_page: 300, current_page: 1 });
    const loading = ref(true);
    const loadingSearch = ref(false);
    const success = ref(null);
    const page: Ref<number> = ref(1);
    const route = "api/administrator/form-submissions";

    const handleSearch = async () => {
      loadingSearch.value = true
      page.value = 0;
      await fetchData(false);
      loadingSearch.value = false
    }

    const handleCloseSubmission = async (submissionID: number) => {
      const submission = submissions.value.find((submission: Submission) => submission.SubmissionID === submissionID);

      if (submission) {
        submission.Submitting = true;

        await axios
          .post(`${route}?SubmissionID=${submissionID}`, { _method: "PATCH" })
          .then((response) => {
            success.value = response.data?.success;
          })
          .finally(() => {
            submission.Submitting = false;
            setTimeout(() => success.value = null, 3500);
            fetchData(false);
          });
      }
    }

    const fetchData = async (isLoading: boolean) => {
      if (isLoading)
        loading.value = true;

      await axios.get(route, { params: { page: page.value, Filters: filters } })
        .then(response => {
          submissions.value = response.data.Submissions;
          formTypes.value = response.data.FormTypes;
          Object.assign(filters, response.data.Filters);
          Object.assign(paginator, response.data.Paginator);
          page.value = paginator.current_page;
        })
        .finally(() => {
          if (isLoading)
            loading.value = false;
        });
    };

    const fetchPaginationData = (pageNumber: number) => {
      page.value = pageNumber;
      fetchData(false);
    }

    onMounted(async () => await fetchData(true));

    return {
      filters,
      submissions,
      formTypes,
      paginator,
      success,
      loading,
      loadingSearch,
      handleSearch,
      handleCloseSubmission,
      fetchData,
      fetchPaginationData,
    };
  }

}
</script>

<style scoped>
.button-width {
  width: 120px;
}

.closed {
  font-weight: 600;
}
</style>
