<template>
    <transition name="fade">
        <div v-if="isVisible" :class="['plan', { active: isVisible, [classNames]: true, [modalType]: true }]"
            :style="modalStyles" @click.self="handleClickOutside">
            <div class="plan-header" v-if="title">
                <div class="plan-breadcrumbs">
                    <div class="plan-breadcrumbs-left">
                        <i class="iatse-icon-left-arrow close-plan-modal" @click="closeModal"></i>
                        <h3>{{ title }}</h3>
                    </div>
                    <div class="close-btn close-plan-modal" @click="closeModal"> <i class="iatse-icon-close"></i></div>
                </div>
            </div>
            <component :is="getModalContent(modalType)" @close="closeModal" :fund="fund" :modalProps="modalProps"/>
        </div>
    </transition>
    <div v-if="modalType === 'success-modal'" class="modal-overlay"></div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useModalStore } from '@/stores/modal';

export default defineComponent ({
    name: 'ModalComponent',
    props: {
        isVisible: {
            type: Boolean,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        modalType: {
            type: String,
            default: ''
        },
        classNames: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        fund:{
            type: String,
            default: null,
        },
        modalProps:{
            type: Object,
            default: null,
        }
    },
    emits: ['update:isVisible', 'close'],
    setup(props, { emit }) {
        const modalStore = useModalStore();

        const closeModal = () => {
            emit('close', props.index);
        };

        const modalStyles = computed(() => {
            const offset = props.index * 20;

            return {
                "--modal-width": `calc(85% - ${offset}px)`,
            };
        });

        const handleClickOutside = () => {
            modalStore.closeAbove(props.index);
        }

        return {
            modalStyles,
            closeModal,
            handleClickOutside,
            getModalContent: modalStore.getModalContent,
        };
    }
});
</script>