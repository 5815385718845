<template>
  <div class="container">
    <h3 class="text-center mb-5 mt-4">Participants</h3>
    <SearchParticipantsFilters :filters="filters" @update-filters="updateFilters" />
    <SearchParticipantsList :persons="persons" />
  </div>
</template>

<script lang="ts">
import { reactive, ref, onMounted, watch } from 'vue';
import axios from 'axios';
import SearchParticipantsList from '@/components/superadmin/SearchParticipantsList.vue';
import SearchParticipantsFilters from '@/components/superadmin/SearchParticipantsFilters.vue';
import { SearchPerson, SearchParticipantsFormFilters } from '@/interfaces/admin';

export default {
  setup() {
    const persons = ref<SearchPerson[]>([]);
    const filters = reactive<SearchParticipantsFormFilters>({
      PersonID: null,
      FirstName: '',
      LastName: '',
    });

    const fetchData = async () => {
      try {
        const response = await axios.get('api/administrator/users/participants', { params: filters });
        console.log(response.data.Participants);
        persons.value = response.data.Participants;
      } catch (error) {
        console.error('Error:', error);
      }
    };

    onMounted(fetchData);

    const updateFilters = (newFilters: SearchParticipantsFormFilters) => {
      Object.assign(filters, newFilters);
    };

    watch(filters, fetchData, { deep: true });

    return {
      filters,
      persons,
      updateFilters
    };
  },
  components: {
    SearchParticipantsList,
    SearchParticipantsFilters
  }
};
</script>