<template>
    <div class="d-flex flex-column gap-2">
        <div v-if="authRequest" class="form-group d-flex flex-column gap-2">
            <label for="organization_id">Organization ID <span class="text-danger">*</span></label>
            <Multiselect v-model="localAuth.Organization" :options="organizations"
                placeholder="Enter at least 3 characters to search" track-by="OrganizationID" label="OrgName"
                :multiple="false" :hide-selected="true" :searchable="true" :close-on-select="true"
                @search-change="handleSearch" :open-direction="'bottom'" :loading="loadingSearch">
            </Multiselect>
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="email">Email <span class="text-danger">*</span></label>
            <input v-model="localAuth.Email" type="email" class="form-control required" id="email">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="first_name">First Name <span class="text-danger">*</span></label>
            <input v-model="localAuth.FirstName" type="text" class="form-control required" id="first_name"
                maxlength="12">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="middle_name">Middle Name</label>
            <input v-model="localAuth.MiddleName" type="text" class="form-control" id="middle_name" maxlength="12">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="last_name">Last Name <span class="text-danger">*</span></label>
            <input v-model="localAuth.LastName" type="text" class="form-control required" id="last_name" maxlength="25">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="title">Title</label>
            <input v-model="localAuth.Title" type="text" class="form-control" id="title">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="local">Local # <span class="text-danger">*</span></label>
            <input v-model="localAuth.OrgName" type="text" class="form-control" id="local">
        </div>
        <div class="form-group d-flex flex-column gap-2">
            <label for="phone">Phone <span class="text-danger">*</span></label>
            <input v-model="localAuth.Phone" type="phone" class="form-control required" id="phone">
        </div>
        <div class="form-group row mb-0">
            <div class="col-md-12 d-flex justify-content-center">
                <button type="button" class="btn btn-primary" @click="handleRegister">{{ loadingSubmit ? 'Submitting...'
            :
                    'Register' }}</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { reactive, defineComponent, PropType, ref, watch } from 'vue';
import { ILocalAuth } from '@/interfaces/interfaces';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import axios from 'axios';

export default defineComponent({
    props: {
        loadingSubmit: Boolean,
        authRequest: Object as PropType<ILocalAuth | null>,
        success: String as PropType<string | null>

    },
    components: { Multiselect },
    setup(props, { emit }) {
        const localAuth: ILocalAuth = reactive({
            FirstName: props.authRequest?.FirstName ?? '',
            MiddleName: props.authRequest?.MiddleName ?? '',
            LastName: props.authRequest?.LastName ?? '',
            Title: props.authRequest?.Title ?? '',
            Phone: props.authRequest?.Phone ?? '',
            OrgName: props.authRequest?.OrgName ?? '',
            Organization: '',
            Email: props.authRequest?.Email ?? '',
        });
        const organizations = ref([]);
        const loadingSearch = ref(false);

        // Watch for changes in props.success
        watch(() => props.success, (newValue) => {
            if (newValue !== null) {
                localAuth.FirstName = '';
                localAuth.MiddleName = '';
                localAuth.LastName = '';
                localAuth.Title = '';
                localAuth.Phone = '';
                localAuth.OrgName = '';
                localAuth.Organization = '';
                localAuth.Email = '';
            }
        });

        const handleRegister = () => {
            emit('register', localAuth);
        }

        // Handle Organizations Search
        const handleSearch = async (query: string) => {
            loadingSearch.value = true;

            if (query.length >= 2) {
                try {
                    const response = await axios.get(`api/organizations?query=${query}`);
                    organizations.value = response.data;
                } catch (error) {
                    console.error('Error searching organizations:', error);
                }
            }

            loadingSearch.value = false;
        };

        return {
            localAuth,
            loadingSearch,
            organizations,
            handleRegister,
            handleSearch,
        }
    }
})
</script>