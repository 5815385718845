<template>
    <div class="mb-5">
        <h4 class="mb-4">Employer registration pending requests </h4>
        <div class="form-group row mb-4">
            <div class="col-md-2 d-flex justify-content-between">
                <button type="button" class="btn btn-secondary" :class="{ 'btn-info': processed === 0 }"
                    @click="submitFilters(0)"> Pending </button>
                <button type="button" class="btn btn-secondary" :class="{ 'btn-info': processed === 3 }"
                    @click="submitFilters(3)"> Rejected </button>
            </div>
        </div>
        <table class="table table-striped mb-5">
            <thead class="table-dark">
                <tr>
                    <th> EIN / Tax ID </th>
                    <th> Company Name </th>
                    <th> Employer / Payor </th>
                    <th> First Name </th>
                    <th> Last Name </th>
                    <th> Title </th>
                    <th> Email Address </th>
                    <th> Phone </th>
                    <th> Type </th>
                    <th v-if="processed == 0"></th>
                    <th v-if="processed == 0"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="employer in employers" :key="employer.TaxID">
                    <td class="col-md-2">{{ employer.TaxID }} </td>
                    <td class="col-md-4">{{ employer.CompanyName }}</td>
                    <td class="col-md-4">{{ employer.Type }}</td>
                    <td class="col-md-4">{{ employer.FirstName }}</td>
                    <td class="col-md-4">{{ employer.LastName }}</td>
                    <td class="col-md-4">{{ employer.Title }}</td>
                    <td class="col-md-4">{{ employer.Email }}</td>
                    <td class="col-md-4">{{ employer.Phone }}</td>
                    <td class="col-md-4">{{ employer.Role }}</td>
                    <td v-if="processed == 0" class="text-center">
                        <button type="button" @click="sendPin(employer.UIEmployerRequestID)"
                            class="btn btn-sm btn-info btn-block pin-width">
                            {{ employer.SendingPin ? 'Sending Pin...' : 'Send Pin' }}
                        </button>
                    </td>
                    <td v-if="processed == 0" class="text-center">
                        <button type="button" @click="rejectRequest(employer.UIEmployerRequestID)"
                            class="btn btn-sm btn-warning btn-block">
                            {{ employer.Rejecting ? 'Rejecting...' : 'Reject' }}
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <PaginationComponent :paginator="paginator" @fetch-data="fetchPaginationData" />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { PendingEmployer, Paginator } from '@/interfaces/admin';
import PaginationComponent from '@components/utils/PaginationComponent.vue';

export default defineComponent({
    props: {
        employers: {
            type: Object as PropType<PendingEmployer[]>,
            required: true
        },
        paginator: {
            type: Object as PropType<Paginator>,
            required: true
        },
        actions: Object,
        processed: Number
    },
    components: {
        PaginationComponent
    },
    setup(props, { emit }) {
        const submitFilters = (processed: number) => {
            emit('filter-requests', processed);
        };

        const fetchPaginationData = (pageNumber: number) => {
            emit('fetch-paginator-data', pageNumber);
        }

        const sendPin = (requestID: number) => {
            emit('send-pin', requestID);
        }

        const rejectRequest = (requestID: number) => {
            emit('reject-request', requestID);
        }

        return {
            submitFilters,
            fetchPaginationData,
            sendPin,
            rejectRequest,
        };
    }
});
</script>

<style scoped>
.pin-width {
    width: 120px;
}
</style>