<template>
    <div v-if="loading" class="col-md-12 text-center my-4">
        <span> Loading... </span>
    </div>
    <div v-else-if="dependents?.rows.length === 0 || !dependents" class="col-md-12 text-center my-4">
        <span> Currently there are no dependents on file </span>
    </div>
    <div v-else class="plan-content">
        <DataTable v-if="dependents.rows?.length" :table="dependents" classes="striped small" />

        <!-- Account Actions -->
        <div class="account-actions">
            <div class="action-buttons">
                <a href="" class="btn btn-secondary plan-modal"
                    @click.prevent="openModal('beneficiaries', 'Beneficiaries', null)">
                    View Beneficiaries </a>
                <a href="" class="btn btn-primary plan-modal"
                    @click.prevent="openModal('add-dependent', 'Add Dependent', 'plan-form plan-upload')"> Add Dependent
                </a>
            </div>
        </div>
    </div>

    <!-- <router-link
        :to="{ name: 'dependent-documents', query: { PartnerID: dependent.PartnerID, DependentID: dependent.DependentID, Status: dependent.Status } }">
        <i class="fas fa-paperclip"> </i>
    </router-link> -->
</template>

<script lang="ts">
import { ref, reactive, onMounted } from 'vue';
import { Dependent, TableData, RowData } from '@interfaces/interfaces';
import { useTableFormatter } from '@/composable/useTableFormatter';
import { useModalStore } from '@/stores/modal';
import DataTable from '@components/form/DataTable.vue';
import axios from 'axios';

export default {
    components: {
        DataTable
    },
    setup() {
        const dependents: TableData = reactive({
            headers: [
                { title: 'Dependent Name' },
                { title: 'Relation' },
                { title: 'DOB' },
                { title: 'Gender' },
                { title: 'Coverage Effective Date' },
                { title: 'Coverage End Date' },
                { title: 'Status' },
                { title: 'Date of Marriage' },
                { title: '' }
            ],
            rows: []
        });
        const loading = ref(false);
        const modalStore = useModalStore();

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/participant/dependents')
                .then(response => {
                    dependents.rows = Object.values(response.data);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });

            loading.value = false;
        };

        onMounted(async () => {
            await fetchData();
        });

        return {
            dependents,
            loading,
            openModal: modalStore.openModal
        };
    }
};
</script>