<template>
    <div class="col-md-3">
        <div class="card">
            <div class="card-body">
                <h3 class="text-center mb-4">Employer Contributions in Your Jurisdiction</h3>
                <div class="card mb-4">
                    <div class="card-body d-flex flex-column align-items-start gap-4 mb-4 w-100">
                        <h6 class="card-title"> Employer Contributions / Payments </h6>
                        <div class=" d-flex flex-column align-items-start gap-2 w-100">
                            <div class="d-flex flex-column gap-2 align-items-start w-100">
                                <span class="fw-bold">Most Recent</span>
                            </div>
                            <a href="#">Contributions History Search</a>
                            <router-link :to="{ name: 'local.missing-contributions' }">
                                Missing Contributions Inquiry
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body d-flex flex-column gap-4 mb-4 w-100">
                        <h6 class="card-title">Contract Info</h6>
                        <router-link :to="{ name: 'local.contract-information' }">
                            Summarized Contract Information
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ProfileData } from '@/interfaces/employer';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
    props: {
        data: {
            type: Object as PropType<ProfileData | null>,
            required: false
        }
    }
})
</script>

<style scoped>
a {
    text-decoration: none;
}

.card-title {
    text-decoration: none;
    color: #0d6efd;
}

.card-title:hover {
    cursor: pointer;
}
</style>